import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PopupNotification from '../../components/PopupNotification';
import { useStores } from '../../contexts/storesContext';
import { getCookie } from '../../utils/helpers';
import Header from '../Header';
import { AppBar } from './styled';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapped = styled.div`
  margin-top: 60px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
  `};
  @media screen and (min-width: 767px) {
    display: flex;
  }
`;

const WrappedContent = styled.main`
  flex-grow: 1;
`;

export const MENU_ITEMS = [];

const BlankLayout = observer(({ children }: { children: React.ReactNode }) => {
  const {
    root: { notificationStore, notificationModalStore, userStore },
  } = useStores();

  const {
    open,
    onClose,
    message,
    autoHideDuration,
    severity,
    anchorOrigin,
  } = notificationStore;

  const {
    message: messagePopup,
    desc,
    onClose: onClosePopup,
  } = notificationModalStore;

  useEffect(() => {
    try {
      const authData = getCookie('auth_data')
        ? JSON.parse(getCookie('auth_data'))
        : '';

      const { accessToken } = authData;
      if (accessToken && !userStore.profile.address) {
        userStore.reLoginByAccessToken(accessToken);
      }
    } catch (e) {
      console.error('Error reLoginByAccessToken: ', e);
      // do nothing
    }
  }, [userStore]);

  return (
    <>
      <AppBar
        open={open}
        position={'relative'}
        color={'transparent'}
        elevation={0}
      >
        <Header />
      </AppBar>

      <Wrapped>
        <WrappedContent>{children}</WrappedContent>
      </Wrapped>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <PopupNotification
        message={messagePopup}
        desc={desc}
        onClose={onClosePopup}
      />
    </>
  );
});

export default BlankLayout;
