import { IconButton, Modal } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import animationData from '../../assets/images/animations/box.json';
import Tooltip from '../../components/Tooltip/index';
import { useStores } from '../../contexts/storesContext';
// import { networkConnectors } from '../../provider/networkConnectors';
import { LoadingSpinner } from '../../theme/components';
import { getStatsErrorMessage } from '../../utils/apiRequest';
import { authRequest } from '../../utils/authRequest';
import { getImageRareByName, getImageSeasonByName } from '../../utils/land';
import { getAttributeByName } from '../../utils/subgraph';

interface LandInfo {
  metaData: any;
}

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  min-height: 501px;
  p {
    color: #a1a6b6;
    margin-bottom: 2rem;
    line-height: 20px;
    text-align: center;
  }
`;

const StyledContentWrap = styled.div`
  width: 200px;
  margin: auto;
`;

const StyledContentPacked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 382px;
  img.nft-unbox {
    max-width: 60%;
    padding: 20px 20px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-end;
  width: 100%;
  margin: auto;
  img.item {
    position: absolute;
    max-width: 60%;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledBgItem = styled.img`
  height: auto;
  width: 100%;
`;

const StyledSeason = styled.img`
  width: 45px;
  height: 45px;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
`;

const StyledWrapBox = styled.div`
  background: url('${require('../../assets/images/bg-nft.svg')}') center no-repeat;
  background-size: contain;
`;
const StyledSeasonContained = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const SEASON = ['spring', 'summer', 'autumn', 'winter'];
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const PopupUnboxLand = observer(
  ({ open, setOpen, itemId, handleGetDetail }) => {
    const {
      root: { notificationStore },
    } = useStores();
    const [isStopped, setStopped] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [landDetail, setLandDetail] = useState<LandInfo>({
      metaData: {},
    });
    const [isUnbox, setUnbox] = useState(false);
    // const authApi = networkConnectors.getStatsUrl();
    function onClose() {
      handleGetDetail();
      setOpen(false);
    }
    useEffect(() => {
      setUnbox(false);
      setStopped(true);
    }, [itemId]);
    const options = {
      loop: false,
      autoplay: false,
      animationData,
    };

    const handleOpen = async () => {
      setLoading(true);
      setUnbox(false);
      setStopped(true);
      authRequest
        .post('https://market-api-v2.wanakafarm.tech/api/land/open-land', {
          itemId,
        })
        .then(async res => {
          notificationStore.showSuccessNotification('Unpack Success');
          setStopped(false);
          await sleep(3000);
          setUnbox(true);
          setLandDetail({
            metaData: { ...res.data.d },
          });
        })
        .catch(e => {
          const message =
            e.response?.data?.data?.c?.error ||
            getStatsErrorMessage(e) ||
            'Something went wrong';
          notificationStore.showErrorNotification(message);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const { metaData } = landDetail;
    return (
      <StyledModal open={open} onClose={onClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={() => onClose()}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            {isUnbox ? (
              <>
                {metaData?.isPacked || _.isEmpty(landDetail.metaData) ? (
                  <StyledWrapBox>
                    <StyledContentPacked>
                      <img
                        className={'nft-unbox'}
                        src={metaData?.image}
                        alt=""
                      />
                    </StyledContentPacked>
                  </StyledWrapBox>
                ) : (
                  <div style={{ marginBottom: '1rem' }}>
                    <StyledWrapBox>
                      <StyledContentWrap>
                        <StyledContent>
                          <StyledBgItem
                            src={getImageRareByName(
                              getAttributeByName('Rare', metaData?.attributes)
                                ?.value
                            )}
                            alt={
                              getAttributeByName('Rare', metaData?.attributes)
                                ?.value
                            }
                          />
                          <img
                            className={'item'}
                            src={metaData?.image}
                            alt=""
                          />
                        </StyledContent>
                      </StyledContentWrap>
                    </StyledWrapBox>
                    <StyledContentWrap>
                      <StyledSeasonContained>
                        {SEASON.map(season => (
                          <Tooltip title={season} key={season + 'season'}>
                            <StyledSeason
                              src={getImageSeasonByName(season)}
                              className={`${season?.toLowerCase()} ${
                                getAttributeByName(
                                  'Season',
                                  metaData.attributes
                                )
                                  ?.value?.split(',')
                                  ?.findIndex(
                                    v => v?.toLowerCase() === season
                                  ) !== -1
                                  ? 'active'
                                  : ''
                              }`}
                            />
                          </Tooltip>
                        ))}
                      </StyledSeasonContained>
                    </StyledContentWrap>
                  </div>
                )}
              </>
            ) : (
              <Lottie
                options={options}
                height={isMobile ? 300 : 350}
                width={isMobile ? 300 : 350}
                isStopped={isStopped}
              />
            )}
            {isUnbox ? (
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                disabled={isLoading}
                style={{ minHeight: '48px' }}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={isLoading}
                style={{ minHeight: '48px' }}
              >
                Unpack
                {isLoading ? <LoadingSpinner size={24} /> : null}
              </Button>
            )}
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    );
  }
);

export default PopupUnboxLand;
