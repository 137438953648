import { Interface } from 'ethers/utils';
import { action, computed, observable } from 'mobx';
import tokenAbi from '../abi/Token.json';
import token721Abi from '../abi/Token721.json';
import { TokenInfo } from '../configs/tokens';
import { ChainId } from '../constants';
import { networkConnectors } from '../provider/networkConnectors';
import { NETWORKS_CONFIG } from '../provider/networks';
import { shortenAddress } from '../utils';
import { BigNumber } from '../utils/bignumber';
import {
  bnum,
  isAddressEqual,
  toBalanceFormatted,
  toChecksum,
} from '../utils/helpers';
import * as helpers from '../utils/helpers';
import { ContractTypes } from './Provider';
import RootStore from './Root';
import { FetchCode } from './Transaction';

export interface ContractMetadata {
  bFactory: string;
  proxy: string;
  weth: string;
  multicall: string;
  tokens: TokenMetadata[];
}

export interface ContractMetadataMap {
  [index: number]: ContractMetadata;
}

export interface TokenBalance extends TokenInfo {
  balance: BigNumber;
}

export interface UserAllowance {
  allowance: BigNumber;
  lastFetched: number;
}

interface TokenBalanceMap {
  [index: string]: TokenBalance | {};
}

export interface BigNumberMap {
  [index: string]: BigNumber;
}

export interface TokenMetadata {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  precision?: number;
  balanceFormatted?: string;
  balanceBn?: BigNumber;
  allowance?: BigNumber;
}

interface UserAllowanceMap {
  [index: string]: {
    [index: string]: {
      [index: string]: UserAllowance;
    };
  };
}

export const EtherKey = 'ether';
export const EtherKeyMarks = ['ether', 'eth', 'bnb', 'matic'];
export const EtherAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export default class TokenStore {
  @computed get isLoadedInitialData() {
    const { providerStore } = this.rootStore;
    const { account } = providerStore.providerStatus;
    return (
      !account ||
      (this.isLoadedBalancerTokenData && this.isLoadedDsProxyAllowance)
    );
  }
  @computed get getWANABalance(): string {
    const { providerStore } = this.rootStore;
    const { account } = providerStore.providerStatus;

    const token = networkConnectors.getMainToken();

    const address = token.address;
    const balance = this.getBalance(address, account);
    return balance ? toBalanceFormatted(balance, 18) : '';
  }

  @computed get getWAIBalance(): string {
    const { providerStore } = this.rootStore;
    const { account } = providerStore.providerStatus;

    const tokens = networkConnectors.getProtocolTokens();

    const address = tokens.WAI.address;
    const balance = this.getBalance(address, account);
    return balance ? toBalanceFormatted(balance, 18) : '';
  }

  @computed get getLANDBalance(): number {
    const { providerStore } = this.rootStore;
    const { account } = providerStore.providerStatus;

    const tokens = networkConnectors.getProtocolTokens();
    const token = tokens.LAND;
    const address = token.address;
    const balance = this.getBalance(address, account);
    return balance ? +toBalanceFormatted(balance, 0) : 0;
  }
  @computed get getITEMBalance(): number {
    const { providerStore } = this.rootStore;
    const { account } = providerStore.providerStatus;
    const tokens = networkConnectors.getProtocolTokens();
    const token = tokens.ITEM;
    const address = token.address;
    const balance = this.getBalance(address, account);
    return balance ? +toBalanceFormatted(balance, 0) : 0;
  }
  // @computed get getWANABalance(): {
  //   balanceBn: BigNumber;
  //   balanceFormatted: string;
  // } {
  //   const { providerStore } = this.rootStore;
  //   const { account } = providerStore.providerStatus;

  //   const token = networkConnectors.getMainToken();

  //   const symbol = token.symbol;
  //   const tokenWithBalance = this.balances[symbol];
  //   if (!account || !tokenWithBalance) {
  //     return { balanceBn: bnum(0), balanceFormatted: '0.00' };
  //   }
  //   console.log(`[Token] Getting On-Chain Balance: ${symbol}`);
  //   const balanceFormatted = toBalanceFormatted(
  //     bnum(tokenWithBalance.balance),
  //     tokenWithBalance.decimals
  //   );
  //   return {
  //     balanceBn: bnum(tokenWithBalance.balance),
  //     balanceFormatted,
  //   };
  // }
  @observable public balances: TokenBalanceMap;
  @observable public allowances: UserAllowanceMap;
  @observable public isLoadedBalancerTokenData: boolean;
  @observable public isLoadedDsProxyAllowance: boolean;
  @observable public currentBalanceChain: any;
  @observable public priceWANA: number;
  @observable public priceWAI: number;
  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.balances = {} as TokenBalanceMap;
    this.allowances = {} as UserAllowanceMap;
    this.isLoadedBalancerTokenData = false;
    this.isLoadedDsProxyAllowance = false;
    this.currentBalanceChain = null;
    this.priceWANA = 0;
    this.priceWAI = 0;
  }

  @action public setIsLoadedBalancerTokenData() {
    this.isLoadedBalancerTokenData = true;
  }

  @action public setIsLoadedDsProxyAllowance() {
    this.isLoadedDsProxyAllowance = true;
  }

  // tslint:disable-next-line: variable-name
  public getBalance(
    tokenAddressParam: string,
    accountParam?: string
  ): BigNumber | undefined {
    const chainBalances = this.balances;
    const tokenAddress = toChecksum(tokenAddressParam);
    const account = toChecksum(accountParam);
    if (chainBalances && account) {
      const tokenBalances =
        chainBalances[tokenAddress] || chainBalances[tokenAddressParam];
      if (tokenBalances) {
        const balance = tokenBalances[account] || tokenBalances[accountParam];
        if (balance) {
          if (balance.balance) {
            return balance.balance;
          }
        }
      }
    }
    return undefined;
  }

  public getAccountBalances(
    tokens: TokenMetadata[],
    account: string
  ): BigNumberMap {
    const userBalances = this.balances;
    if (!userBalances) {
      throw new Error('Attempting to get user balances for untracked chainId');
    }
    const result: BigNumberMap = {};
    tokens.forEach(value => {
      if (userBalances[value.address] && userBalances[value.address][account]) {
        result[value.address] = userBalances[value.address][account].balance;
      }
    });

    return result;
  }
  public isAllowanceFetched(
    tokenAddress: string,
    owner: string,
    spender: string
  ) {
    const chainApprovals = this.allowances;
    return (
      !!chainApprovals[tokenAddress] &&
      !!chainApprovals[tokenAddress][owner] &&
      !!chainApprovals[tokenAddress][owner][spender]
    );
  }

  public isAllowanceStale(
    tokenAddress: string,
    owner: string,
    spender: string,
    blockNumber: number
  ) {
    const chainApprovals = this.allowances;
    return (
      chainApprovals[tokenAddress][owner][spender].lastFetched < blockNumber
    );
  }

  public setAllowances(
    tokens: string[],
    owner: string,
    spenderParam: string,
    approvals: BigNumber[],
    fetchBlock: number
  ) {
    const chainApprovals = this.allowances;
    const spender = spenderParam?.toLowerCase();

    approvals.forEach((approval, index) => {
      const tokenAddress = tokens[index]?.toLowerCase();

      if (
        (this.isAllowanceFetched(tokenAddress, owner, spender) &&
          this.isAllowanceStale(tokenAddress, owner, spender, fetchBlock)) ||
        !this.isAllowanceFetched(tokenAddress, owner, spender)
      ) {
        if (!chainApprovals[tokenAddress]) {
          chainApprovals[tokenAddress] = {};
        }

        if (!chainApprovals[tokenAddress][owner]) {
          chainApprovals[tokenAddress][owner] = {};
        }

        chainApprovals[tokenAddress][owner][spender] = {
          allowance: approval,
          lastFetched: fetchBlock,
        };
      }
    });

    this.allowances = {
      ...this.allowances,
      ...chainApprovals,
    };
  }

  public isBalanceFetched(tokenAddress: string, account: string) {
    const chainBalances = this.balances;
    return (
      !!chainBalances[tokenAddress] && !!chainBalances[tokenAddress][account]
    );
  }

  public isBalanceStale(
    tokenAddress: string,
    account: string,
    blockNumber: number
  ) {
    const chainBalances = this.balances;
    return chainBalances[tokenAddress][account].lastFetched < blockNumber;
  }

  public setBalances(
    tokens: string[],
    balances: BigNumber[],
    account: string,
    fetchBlock: number,
    forceUpdate?: boolean
  ) {
    const fetchedBalances: TokenBalanceMap = {};

    balances.forEach((balance, index) => {
      const tokenAddress = tokens[index];

      if (
        forceUpdate ||
        !this.isBalanceFetched(tokenAddress, account) ||
        this.isBalanceStale(tokenAddress, account, fetchBlock)
      ) {
        if (this.balances[tokenAddress]) {
          fetchedBalances[tokenAddress] = this.balances[tokenAddress];
        } else {
          fetchedBalances[tokenAddress] = {};
        }

        fetchedBalances[tokenAddress][account] = {
          balance,
          lastFetched: fetchBlock,
        };
      }
    });

    this.balances = {
      ...this.balances,
      ...fetchedBalances,
    };
  }

  public getBaseCurrencies(chainId?: ChainId): [string, string] {
    const cid = chainId || networkConnectors.getCurrentChainId();
    // return ['ETH', 'WETH']
    return (cid && NETWORKS_CONFIG[cid]?.baseTokens) || ['', ''];
  }

  public findTokenByAddress(tokenAddress: string): TokenMetadata | any {
    const { providerStore } = this.rootStore;
    const tokens = providerStore.getContractMetaData()?.tokens;
    if (tokenAddress === EtherKey || tokenAddress === EtherAddress) {
      return { symbol: this.getBaseCurrencies()[0], decimals: 18 };
    }
    return (
      tokens.find(t => isAddressEqual(t.address, tokenAddress)) ||
      ({} as TokenMetadata)
    );
  }

  @action public approve = async ({ amountWei, tokenAddress, spender }) => {
    try {
      const { providerStore, tokenStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }

      const token = tokenStore.findTokenByAddress(tokenAddress);
      const result = await providerStore.sendTransaction(
        ContractTypes.Token,
        tokenAddress,
        'approve',
        [spender, amountWei],
        {},
        `Approve ${token.symbol || 'token'} to ${shortenAddress(spender)}`
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
      } else if (txResponse && txResponse?.hash) {
        await txResponse.wait();
        await await this.fetchAllowancesData(account, [tokenAddress], spender);
        notificationStore.showSuccessNotification('Approved Success');
      }
      return txResponse;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  @action public approveMax = async (
    tokenAddress,
    spender,
    callback?: (error?: any, result?: any) => void
  ) => {
    const { providerStore } = this.rootStore;
    // maxUint = helpers.MAX_UINT.toString()
    const maxUint =
      '115792089237316195423570985008687907853269984665640564039457584007913129639935';
    const token = this.findTokenByAddress(tokenAddress);
    const result = await providerStore.sendTransaction(
      ContractTypes.Token,
      tokenAddress,
      'approve',
      [spender, maxUint],
      {},
      `Approve ${token.symbol || 'token'}`
    );
    if (callback) {
      const { error, txResponse } = result || {};
      if (error && error.message) {
        callback(error?.data?.message || error.message);
      } else if (txResponse && txResponse.hash) {
        await txResponse.wait();
        const { account } = providerStore.providerStatus;
        await this.fetchAllowancesData(account, [tokenAddress], spender);
        callback(null, result);
      }
    }
    return result;
  };

  @action public approveMaxCallback = async (tokenAddress, spender) => {
    const { tokenStore } = this.rootStore;

    return new Promise((resolve, reject) => {
      tokenStore.approveMax(
        tokenAddress,
        spender,
        (error?: any, result?: any) => {
          if (error) {
            return reject(error);
            // return resolve(null);
          }
          resolve(result);
        }
      );
    });
  };

  @action public fetchBalancerTokenData = async (
    account,
    tokensToTrack?: string[],
    spender?: string
  ): Promise<FetchCode> => {
    if (!account) {
      return FetchCode.FAILURE;
    }
    const { providerStore } = this.rootStore;
    const promises: Array<Promise<any>> = [];
    const balanceCalls = [];
    const allowanceCalls = [];
    const decimalsCalls = [];
    const tokenList = [];
    const contractMetadata = providerStore.getContractMetaData();
    const routerAddress = spender;

    const multiAddress = contractMetadata.multiCallContract;
    const multi = providerStore.getContract(
      ContractTypes.MultiCall,
      multiAddress
    );

    const iface = new Interface(tokenAbi);

    const tokens = tokensToTrack;
    tokens.forEach(address => {
      tokenList.push(toChecksum(address));
      if (address !== EtherKey) {
        balanceCalls.push([
          address,
          iface.functions.balanceOf.encode([account]),
        ]);
        if (routerAddress) {
          allowanceCalls.push([
            address,
            iface.functions.allowance.encode([account, routerAddress]),
          ]);
        }

        decimalsCalls.push([address, iface.functions.decimals.encode([])]);
      }
    });

    promises.push(multi.aggregate(balanceCalls));
    promises.push(multi.aggregate(allowanceCalls));
    promises.push(multi.getEthBalance(account));
    promises.push(multi.aggregate(decimalsCalls));

    try {
      const [
        [balBlock, mulBalance],
        [allBlock, mulAllowance],
        mulEth,
        [, mulDecimals],
      ] = await Promise.all(promises);

      const balances = mulBalance.map(value =>
        bnum(iface.functions.balanceOf.decode(value))
      );
      const allowances = mulAllowance.map(value =>
        bnum(iface.functions.allowance.decode(value))
      );

      if (tokens[0] === EtherKey) {
        const ethBalance = bnum(mulEth);
        balances.unshift(ethBalance);
        allowances.unshift(bnum(helpers.setPropertyToMaxUintIfEmpty()));
      }

      const decimalsList = mulDecimals.map(value =>
        bnum(iface.functions.decimals.decode(value)).toNumber()
      );

      if (routerAddress) {
        this.setAllowances(
          tokenList,
          account,
          routerAddress,
          allowances,
          allBlock.toNumber()
        );
      }
      const changedNetwork =
        this.currentBalanceChain !== providerStore.providerStatus.activeChainId;
      this.setDecimals(tokenList, decimalsList);
      this.setBalances(
        tokenList,
        balances,
        account,
        balBlock.toNumber(),
        changedNetwork
      );
      this.setIsLoadedBalancerTokenData();
      this.currentBalanceChain = providerStore.providerStatus.activeChainId;
      console.debug('[fetchBalancerTokenData Success]');
    } catch (e) {
      console.error('[Fetch] fetchBalancerTokenData', { error: e });
      return FetchCode.FAILURE;
    }
    return FetchCode.SUCCESS;
  };

  @action public fetchBalancerTokenERC721Data = async (
    account,
    tokensToTrack?: string[]
  ): Promise<FetchCode> => {
    if (!account) {
      return FetchCode.FAILURE;
    }
    const { providerStore } = this.rootStore;
    const promises: Array<Promise<any>> = [];
    const balanceCalls = [];
    const tokenList = [];
    const contractMetadata = providerStore.getContractMetaData();
    const multiAddress = contractMetadata.multiCallContract;
    const multi = providerStore.getContract(
      ContractTypes.MultiCall,
      multiAddress
    );

    const iface = new Interface(token721Abi);

    const tokens = tokensToTrack;
    tokens.forEach(address => {
      tokenList.push(toChecksum(address));
      if (address !== EtherKey) {
        balanceCalls.push([
          address,
          iface.functions.balanceOf.encode([account]),
        ]);
      }
    });

    promises.push(multi.aggregate(balanceCalls));

    try {
      const [[balBlock, mulBalance]] = await Promise.all(promises);

      const balances = mulBalance.map(value =>
        bnum(iface.functions.balanceOf.decode(value))
      );

      const changedNetwork =
        this.currentBalanceChain !== providerStore.providerStatus.activeChainId;
      this.setBalances(
        tokenList,
        balances,
        account,
        balBlock.toNumber(),
        changedNetwork
      );
      // console.debug(
      //   '[fetchBalancerTokenERC721Data Success]',
      //   tokenList,
      //   balances
      // );
    } catch (e) {
      console.error('[Fetch] fetchBalancerTokenERC721Data', { error: e });
      return FetchCode.FAILURE;
    }
    return FetchCode.SUCCESS;
  };

  // @action public fetchBalancerTokenData = async (
  //   account: string,
  //   token: TokenInfo
  // ): Promise<FetchCode> => {
  //   try {
  //     if (!account) {
  //       return FetchCode.FAILURE;
  //     }

  //     const { providerStore } = this.rootStore;
  //     const TokenContract = providerStore.getContract(
  //       ContractTypes.Token,
  //       token.address
  //     );
  //     const balance = await TokenContract.balanceOf(account);
  //     const changedNetwork =
  //       this.currentBalanceChain !== providerStore.providerStatus.activeChainId;
  //     this.setBalances(token.symbol, { balance, ...token }, changedNetwork);
  //     this.setIsLoadedBalancerTokenData();
  //     this.currentBalanceChain = providerStore.providerStatus.activeChainId;
  //     console.error('[All Fetches Success]', balance);
  //     return FetchCode.SUCCESS;
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // @action public getAllowanceOnChain = async (
  //   tokenAddress,
  //   spender,
  //   account
  // ): Promise<BigNumber> => {
  //   try {
  //     const { providerStore } = this.rootStore;
  //     const TokenContract = providerStore.getContract(
  //       ContractTypes.Token,
  //       tokenAddress
  //     );
  //     const data = await TokenContract.allowance(account, spender);
  //     this.setAllowances([tokenAddress], account, spender, [bnum(data)]);
  //     console.log('===data', bnum(data), this.allowances);
  //     return data;
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };
  public getAllowance = (
    tokenAddressParam,
    account,
    spenderParam
  ): BigNumber | undefined => {
    const chainApprovals = this.allowances;
    if (chainApprovals) {
      const tokenAddress = tokenAddressParam?.toLowerCase();
      const spender = spenderParam?.toLowerCase();
      const tokenApprovals = chainApprovals[tokenAddress];

      if (tokenApprovals) {
        const userApprovals = tokenApprovals[account];
        if (userApprovals) {
          if (userApprovals[spender]) {
            return userApprovals[spender].allowance;
          }
        }
      }
    }
    return undefined;
  };

  public computedAllowance = (
    tokenAddress,
    account,
    spender
  ): BigNumber | undefined => {
    return computed(() => {
      return this.getAllowance(tokenAddress, account, spender);
    }).get();
  };

  @action public fetchAllowancesData = async (
    account,
    tokens: string[],
    spender: string
  ) => {
    if (!account) {
      return FetchCode.FAILURE;
    }
    const { providerStore } = this.rootStore;
    const promises: Array<Promise<any>> = [];
    const allowanceCalls = [];
    const tokenList = [];
    const contractMetadata = providerStore.getContractMetaData();
    const multiAddress = contractMetadata.multiCallContract;
    const multi = providerStore.getContract(
      ContractTypes.MultiCall,
      multiAddress
    );

    const iface = new Interface(tokenAbi);

    tokens.forEach(address => {
      tokenList.push(address);
      if (address && address !== EtherKey) {
        allowanceCalls.push([
          address,
          iface.functions.allowance.encode([account, spender]),
        ]);
      }
    });

    promises.push(multi.aggregate(allowanceCalls));

    try {
      const [[allBlock, mulAllowance]] = await Promise.all(promises);

      const allowances = mulAllowance.map(value =>
        bnum(iface.functions.allowance.decode(value))
      );
      this.setAllowances(
        tokenList,
        account,
        spender,
        allowances,
        allBlock.toNumber()
      );
      return allowances;
    } catch (e) {}
    return [];
  };

  public setTokenDecimals(address: string, decimals: number) {
    const tokenUrl = this.findTokenByAddress(address);
    if (tokenUrl) {
      tokenUrl.decimals = decimals;
    }
  }

  public getTrackedTokenAddresses(): string[] {
    const { providerStore } = this.rootStore;
    const tokens = providerStore.getContractMetaData().tokens;
    return tokens.map(token => token.address);
  }

  public getTrackedToken721Addresses(): string[] {
    const tokens = networkConnectors.getProtocolTokens();
    // const chainId = networkConnectors.getCurrentChainId();
    const tokens721 = [tokens.LAND, tokens.ITEM];
    return tokens721.map(token => token.address);
  }

  public async getUSDPriceWana() {
    try {
      const contractAddress = networkConnectors.getContractPriceWANA();
      const { providerStore } = this.rootStore;
      if (!!contractAddress.PairUsdtNative) {
        // calculator price of native token ETH,BNB,...
        // const contractMetadata = providerStore.getContractMetaData();
        const instancePairUsdtNative = providerStore.getContract(
          ContractTypes.PancakePair,
          contractAddress.PairUsdtNative
        );
        //
        const token0UsdtNative = await instancePairUsdtNative.token0();
        const reservesUsdtNaive = await instancePairUsdtNative.getReserves();
        // tslint:disable-next-line: one-variable-per-declaration
        let reserves0UsdtNative = reservesUsdtNaive[0],
          reserves1Token = reservesUsdtNaive[1];
        if (
          token0UsdtNative.toLowerCase() !==
          contractAddress.USDT.address.toLowerCase()
        ) {
          [reserves0UsdtNative, reserves1Token] = [
            reserves1Token,
            reserves0UsdtNative,
          ];
        }
        const price =
          reserves0UsdtNative /
          Math.pow(10, +contractAddress.USDT.decimals) /
          (reserves1Token / Math.pow(10, 18));
        this.priceWANA = price;
        return price;
      }
    } catch (error) {
      console.error('getUSDPriceWana\n', error);
    }
  }
  public async getUSDPriceWai() {
    try {
      const contractAddress = networkConnectors.getContractPriceWAI();
      const { providerStore } = this.rootStore;
      if (!!contractAddress.PairUsdtNative) {
        // calculator price of native token ETH,BNB,...
        // const contractMetadata = providerStore.getContractMetaData();
        const instancePairUsdtNative = providerStore.getContract(
          ContractTypes.PancakePair,
          contractAddress.PairUsdtNative
        );
        //
        const token0UsdtNative = await instancePairUsdtNative.token0();
        const reservesUsdtNaive = await instancePairUsdtNative.getReserves();
        // tslint:disable-next-line: one-variable-per-declaration
        let reserves0UsdtNative = reservesUsdtNaive[0],
          reserves1Token = reservesUsdtNaive[1];
        if (
          token0UsdtNative.toLowerCase() !==
          contractAddress.USDT.address.toLowerCase()
        ) {
          [reserves0UsdtNative, reserves1Token] = [
            reserves1Token,
            reserves0UsdtNative,
          ];
        }
        const price =
          reserves0UsdtNative /
          Math.pow(10, +contractAddress.USDT.decimals) /
          (reserves1Token / Math.pow(10, 18));
        this.priceWAI = price;
        return price;
      }
    } catch (error) {
      console.error('getUSDPriceWai\n', error);
    }
  }

  private setDecimals(tokens: string[], decimals: number[]) {
    let index = 0;
    tokens.forEach(tokenAddr => {
      if (tokenAddr === EtherKey) {
        this.setTokenDecimals(tokenAddr, 18);
      } else {
        this.setTokenDecimals(tokenAddr, decimals[index]);
        index += 1;
      }
    });
  }
}
