import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIDEBAR_WITH, TIME_FETCH_LIST } from '../constants';
import { useStores } from '../contexts/storesContext';
import {
  MAX_LEVEL,
  MAX_PRICE,
  MIN_LEVEL,
  MIN_PRICE,
} from '../stores/WlandStore';
import { getLands, LIMIT_HOME_ITEMS, getOffersOld } from '../utils/subgraph';

export default function useFetchLandData({ modeOwned = false }): any {
  const {
    root: { wlandStore, providerStore, tokenStore },
  } = useStores();
  const { account } = providerStore.providerStatus;
  const {
    environments,
    rares,
    seasons,
    itemId,
    sortBy,
    sale,
    page,
    isOwned,
    statusPacked,
    yourOffers,
    rangePrice,
    rangeLV,
    yourOldOffers,
    setOwned,
    setPage,
    setYourOffers,
    setYourOldOffers,
    syncFilterToQueryParams,
    syncQueryParamsToFilter,
    getEnvironmentsSelected,
    getRaresSelected,
    getSeasonsSelected,
    clearFilter,
  } = wlandStore;
  const [listLands, setListLands] = useState([]);
  const [totalLands, setTotalLands] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const landRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    clearFilter({
      yourOffers: false,
      isOwned: modeOwned,
      yourOldOffers: false,
    });
    setPage(1);
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let limitItemByScreen =
    Math.floor((window.screen.width - SIDEBAR_WITH - 64 + 8) / 236) * 3;
  if (window.screen.width < 1213) {
    limitItemByScreen = LIMIT_HOME_ITEMS - 3;
  } else if (window.screen.width < 1600) {
    limitItemByScreen =
      Math.floor((window.screen.width - SIDEBAR_WITH - 64 + 8) / 224) * 3;
  }

  const fetchData = useCallback(async () => {
    const where: any = { isActive: true };
    const environmentsSelected = getEnvironmentsSelected(environments);
    const raresSelected = getRaresSelected(rares);
    const seasonsSelected = getSeasonsSelected(seasons);
    // price and latest
    if (sortBy === 'lowest-price') {
      where.orderByPrice = 'asc';
    } else if (sortBy === 'highest-price') {
      where.orderByPrice = 'desc';
    } else if (sortBy === 'lowest-increase-mutant-rate') {
      where.orderByIncreaseMutantRate = 'asc';
    } else if (sortBy === 'highest-increase-mutant-rate') {
      where.orderByIncreaseMutantRate = 'desc';
    } else if (sortBy === 'lowest-time-reduce') {
      where.orderByTimeReduce = 'asc';
    } else if (sortBy === 'highest-time-reduce') {
      where.orderByTimeReduce = 'desc';
    }

    // order
    if (sale === 'order') {
      where.onSale = true;
    } else if (sale === 'not-order') {
      where.onSale = false;
    }

    // itemId
    if (itemId) {
      where.id = Number(itemId);
    }

    // environment
    if (environmentsSelected.length > 0) {
      where.environment = environmentsSelected;
    }

    // rare
    if (raresSelected.length > 0) {
      where.rare = raresSelected;
    }

    // season
    if (seasonsSelected.length > 0) {
      where.season = seasonsSelected;
    }

    // owner
    if (isOwned) {
      where.yourOwned = account && account.toLowerCase();
    }
    // box
    if (statusPacked !== 'all') {
      where.isPacked = !!JSON.parse(statusPacked);
    }

    // price
    if (
      (rangePrice[0] !== null && rangePrice[0] !== MIN_PRICE) ||
      (rangePrice[1] !== null && rangePrice[1] !== MAX_PRICE)
    ) {
      where.rangePrice = [
        Number(rangePrice[0]) || MIN_PRICE,
        Number(rangePrice[1] || MAX_PRICE),
      ];
    }

    // level
    if (rangeLV[0] !== MIN_LEVEL || rangeLV[1] !== MAX_LEVEL) {
      where.level = [Number(rangeLV[0]), Number(rangeLV[1])];
    }

    try {
      let whereMore;
      let results, total;
      if (yourOffers) {
        whereMore = { yourOffer: account };
      } else {
        whereMore = where;
      }

      if (yourOldOffers) {
        const res = await getOffersOld({
          limit: 1000,
          category: 'wland',
          page,
          buyer: account,
        });

        results = res.results;
        total = res.total;
      } else {
        const res = await getLands({
          first: limitItemByScreen,
          skip: (page - 1) * limitItemByScreen,
          whereMore,
        });
        results = res.results;
        total = res.total;
      }

      if (!isMounted.current) {
        return;
      }

      if (_.isEmpty[results]) {
        setListLands([]);
        setLoading(false);
        return;
      }

      setTotalLands(isOwned ? tokenStore.getLANDBalance : total || 0);
      setListLands(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setListLands([]);
      setTotalLands(0);
    }
  }, [
    getEnvironmentsSelected,
    environments,
    getRaresSelected,
    rares,
    getSeasonsSelected,
    seasons,
    sortBy,
    sale,
    itemId,
    isOwned,
    statusPacked,
    rangePrice,
    rangeLV,
    account,
    yourOffers,
    yourOldOffers,
    limitItemByScreen,
    page,
    tokenStore.getLANDBalance,
  ]);

  useEffect(() => {
    setLoading(true);
    setListLands([]);
    const handler = setTimeout(fetchData, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchData]);

  useEffect(() => {
    const handler = setInterval(() => {
      fetchData();
    }, TIME_FETCH_LIST);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchData]);

  useEffect(() => {
    syncQueryParamsToFilter(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncQueryParamsToFilter]);

  useEffect(() => {
    syncFilterToQueryParams(history);
  }, [
    history,
    syncFilterToQueryParams,
    sortBy,
    sale,
    itemId,
    isOwned,
    yourOffers,
    yourOldOffers,
    environments,
    page,
    rangePrice,
    rangeLV,
    seasons,
    rares,
  ]);

  const handleChangeOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOwned(event.target.checked);
  };

  const handleChangeOffers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYourOffers(event.target.checked);
  };

  const handleChangeOldOffers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setYourOldOffers(event.target.checked);
  };

  const handleChangePage = (event, pageNumber: number) => {
    setPage(pageNumber);
  };

  return {
    account,
    landRef,
    totalLands,
    isOwned,
    yourOldOffers,
    handleChangeOwner,
    yourOffers,
    handleChangeOffers,
    listLands,
    page,
    loading,
    handleChangePage,
    limitItemByScreen,
    handleChangeOldOffers,
  };
}
