import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';
import { NftMetaDataProps } from '../../types';
import ViewListItem from './viewListItem';
import ViewModuleItem from './viewModuleItem';
import { NoDataText } from '../Common';

const StyledItems = styled.div<{ isViewList: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
  ${({ isViewList }) =>
    isViewList
      ? css`
          align-items: center;
          ${({ theme }) => theme.mediaWidth.upToSmall`
          min-height: calc(100vh - 540px);
        `};
        `
      : css`
          min-height: calc(100vh - 374px);
          ${({ theme }) => theme.mediaWidth.upToSmall`
            min-height: calc(100vh - 540px);
          `};
        `}
`;

interface ItemsProps {
  listData: NftMetaDataProps[];
  loading?: boolean;
  isViewList: boolean;
}

const Items = ({ listData, loading, isViewList }: ItemsProps) => {
  return (
    <StyledItems isViewList={isViewList}>
      {listData
        ?.filter(data => data.typeName)
        ?.map((item, index) =>
          isViewList ? (
            <ViewListItem item={item} key={index} type="nftItem" />
          ) : (
            <ViewModuleItem item={item} key={index} />
          )
        )}
      <Spinner loading={loading} />
      {(!listData || (listData && listData.length === 0)) && !loading ? (
        <NoDataText size="18px" />
      ) : null}
    </StyledItems>
  );
};

export default Items;
