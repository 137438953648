import ReactGA from 'react-ga';
ReactGA.initialize('UA-222054508-1');

class GAClass {
  firstSend: boolean;
  userEvent: any;
  page: string;
  constructor() {
    this.init();
  }
  async init() {
    this.firstSend = true;
    this.page = 'home';
  }
  sendEvent({
    page,
    event,
    label,
    value,
  }: {
    page?: string;
    event: string;
    label?: string;
    value?: number;
  }) {
    return ReactGA.event({
      category: `${page || this.page}_${event}`,
      action: event,
      label,
      value,
    });
  }

  userInteractionEvent() {
    setTimeout(() => {
      if (this.firstSend) {
        this.sendEvent({ page: null, event: 'first_interaction' });
        this.firstSend = false;
        window.removeEventListener('scroll', this.userEvent);
        window.removeEventListener('click', this.userEvent);
      }
    }, 2000);
  }

  viewPageEvent({ landingView, page }) {
    if (landingView) {
      setTimeout(() => {
        ReactGA.pageview(page);
      }, 0);
    }
    if (page) {
      this.page = page;
    }
    this.userEvent = this.userInteractionEvent.bind(this);
    window.addEventListener('scroll', this.userEvent);
    window.addEventListener('click', this.userEvent);
  }

  actionPageEvent({ event }) {
    this.sendEvent({ page: null, event });
  }

  mountedPage(options = { landingView: true, page: 'home' }) {
    this.viewPageEvent(options);
  }

  setPage(page) {
    switch (page) {
      case 'lands':
      case 'items':
      case 'land-detail':
      case 'item-detail':
      default:
        page = 'home';
    }
    this.page = page;
  }
  unmountedPage() {
    this.firstSend = true;
    window.removeEventListener('scroll', this.userEvent);
    window.removeEventListener('click', this.userEvent);
  }
}

export default new GAClass();
