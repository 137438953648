// @ts-ignore
import FrameCommon from '../assets/images/market/common.png';
// @ts-ignore
import FrameImmortal from '../assets/images/market/immortal.png';
// @ts-ignore
import FrameLegendary from '../assets/images/market/legendary.png';
// @ts-ignore
import FrameMythical from '../assets/images/market/mythical.png';
// @ts-ignore
import FrameRate from '../assets/images/market/rare.png';
// @ts-ignore
import autumnImg from '../assets/images/market/season-1.svg';
// @ts-ignore
import summerImg from '../assets/images/market/season-2.svg';
// @ts-ignore
import springImg from '../assets/images/market/season-3.svg';
// @ts-ignore
import winterImg from '../assets/images/market/season-4.svg';
// @ts-ignore
import FrameUncommon from '../assets/images/market/uncommon.png';
// @ts-ignore
import { COLOR_ENVIRONMENT } from '../constants';

export const RATE_FRAME_IMG = {
  common: FrameCommon,
  uncommon: FrameUncommon,
  rare: FrameRate,
  immortal: FrameImmortal,
  legendary: FrameLegendary,
  mythical: FrameMythical,
};

const SEASONS_IMG = {
  spring: springImg,
  summer: summerImg,
  autumn: autumnImg,
  winter: winterImg,
};

export const getImageRareByName = (name: string): any => {
  if (!name) {
    return;
  }
  return RATE_FRAME_IMG[name.toLowerCase()];
};
export const getImageSeasonByName = (name: string): any => {
  if (!name) {
    return;
  }
  return SEASONS_IMG[name.toLowerCase()];
};

export const getColorByEnvironment = (id: string | any): any => {
  if (!id) {
    return COLOR_ENVIRONMENT.default;
  }
  return COLOR_ENVIRONMENT[id] || COLOR_ENVIRONMENT.default;
};
