import { Switch } from '@material-ui/core';
import { ViewListRounded, ViewModuleRounded } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { observer } from 'mobx-react';
import { transparentize } from 'polished';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import ListItem from '../../components/items';
import useFetchItemData from '../../hooks/useFetchItemData';
import SidebarItem from './SidebarItem';
import GA from '../../utils/ga';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledMainContent = styled.div`
  flex: 1;
  padding: 24px 32px 32px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  margin: 0 auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: calc(100vh - 223px);
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 100%;
  `}
`;

const StyledFormControl = styled.div`
  margin: 10px;
`;
const StyledHeaderFilter = styled.div`
  display: flex;
  align-items: center;
`;
const StyledHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 1rem;
  justify-content: space-between;
  margin: 0 -10px 1rem;
`;

const StyledTotal = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

const StyledAccountFilter = styled.div`
  display: flex;
`;

const StyledView = styled.div`
  display: flex;
`;

const StyleButtonView = styled.div<{ isActive: boolean }>`
  &.button-view--left {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  &.button-view--right {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  margin-right: -1px;
  width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid  ${({ theme, isActive }) =>
    isActive ? theme.primary : theme.borderColor}
  &:hover {
    border-color: #ddd
  }
  &:active {
    background: ${({ theme }) => transparentize(0.7, theme.primary)}
  }
  background: ${({ theme, isActive }) =>
    isActive ? transparentize(0.9, theme.primary) : ''}
`;

const NftItems = observer(() => {
  const {
    account,
    itemRef,
    totalNftItems,
    isOwned,
    handleChangeOwner,
    yourOffers,
    handleChangeOffers,
    listItems,
    page,
    loading,
    handleChangePage,
    limitItemByScreen,
    yourOldOffers,
    handleChangeOldOffers,
  } = useFetchItemData({ modeOwned: false });
  const [viewList, setViewList] = useState<boolean>(false);

  useEffect(() => {
    GA.mountedPage({
      page: 'items',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      {isMobile ? null : <SidebarItem />}
      <StyledMainContent ref={itemRef} className="scroll-custom">
        <StyledHeader>
          <StyledFormControl>
            <StyledTotal>{totalNftItems} items</StyledTotal>
          </StyledFormControl>
          <StyledHeaderFilter>
            {account ? (
              <StyledAccountFilter>
                <StyledFormControl>
                  <div>is Owned</div>
                  <Switch
                    checked={isOwned}
                    onChange={handleChangeOwner}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <div>Your Offers</div>
                  <Switch
                    checked={yourOffers}
                    onChange={handleChangeOffers}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <div>Your Old Offers</div>
                  <Switch
                    checked={yourOldOffers}
                    onChange={handleChangeOldOffers}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </StyledFormControl>
              </StyledAccountFilter>
            ) : null}
            <StyledView>
              <StyleButtonView
                className="button-view--left"
                isActive={!viewList}
                onClick={() => {
                  setViewList(false);
                }}
              >
                <ViewModuleRounded />
              </StyleButtonView>
              <StyleButtonView
                className="button-view--right"
                isActive={viewList}
                onClick={() => {
                  setViewList(true);
                }}
              >
                {' '}
                <ViewListRounded />
              </StyleButtonView>
            </StyledView>
          </StyledHeaderFilter>
        </StyledHeader>
        <ListItem
          listData={listItems}
          loading={loading}
          isViewList={viewList}
        />
        {/* <ArrowPaginate
          page={page}
          onChange={handleChangePage}
          size={listItems.length}
          limit={limitItemByScreen}
        /> */}
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
          variant="outlined"
          color="primary"
          size="large"
          page={page}
          count={Math.ceil(totalNftItems / limitItemByScreen)}
          onChange={handleChangePage}
        />
      </StyledMainContent>
    </StyledContainer>
  );
});

export default NftItems;
