import { ChainId } from '../constants';
import { networkConnectors } from './networkConnectors';

export interface NetworkInterface {
  name: string;
  shortName?: string;
  chainName?: string;
  chainId: ChainId | number;
  chainSymbol: string;
  testnet?: boolean;
  icon: any;
  tokenType?: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorer: {
    name: string;
    url: string;
  };
  baseTokens: [string, string]; // [ETH, WETH]
  assetsUrl?: string;
  secondBlock: number;
}

export const NETWORKS_CONFIG: { [chainId in ChainId]?: NetworkInterface } = {
  [ChainId.MAINNET]: {
    name: 'Binance Smart Chain',
    shortName: 'BSC',
    chainId: ChainId.MAINNET,
    chainSymbol: 'bsc',
    icon: require('../assets/networks/binance-smart-chain.svg'),
    tokenType: 'BEP20',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed.binance.org',
    ],
    blockExplorer: {
      name: 'BscScan',
      url: 'https://bscscan.com',
    },
    baseTokens: ['BNB', 'WBNB'],
    assetsUrl:
      'https://raw.githubusercontent.com/firebird-finance/firebird-assets/master/blockchains/smartchain/assets/{checksum}/logo.png',
    secondBlock: 3,
  },
  [ChainId.CHAPEL]: {
    name: 'BSC (Testnet)',
    chainId: ChainId.CHAPEL,
    chainSymbol: 'bsc-testnet',
    icon: require('../assets/networks/binance-smart-chain.svg'),
    testnet: true,
    tokenType: 'BEP20',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-1-s2.binance.org:8545/',
      'https://data-seed-prebsc-1-s3.binance.org:8545/',
    ],
    blockExplorer: {
      name: 'BscScan (testnet)',
      url: 'https://testnet.bscscan.com',
    },
    baseTokens: ['BNB', 'WBNB'],
    secondBlock: 3,
  },
};

export const NETWORKS_SELECTORS: ChainId[] = [ChainId.MAINNET];

export const getNetworkConfigs = (
  chainId: number = undefined
): NetworkInterface | undefined => {
  const cid = chainId ?? networkConnectors.getCurrentChainId();
  return cid ? NETWORKS_CONFIG[cid] : undefined;
};
