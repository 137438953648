import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { networkConnectors } from '../provider/networkConnectors';
import { getStatsErrorMessage } from '../utils/apiRequest';
import { authRequest } from '../utils/authRequest';
import PopupConfirmEmail from './PopupConfirmEmail';

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
`;

const PopupEditAccount = observer(({ open, setOpen, type }) => {
  const {
    root: { userStore, notificationStore },
  } = useStores();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openConfirm, setConfirm] = useState<boolean>(false);
  const baseUrl = networkConnectors.getStatsUrl();
  const loginFormRef = useRef(null);
  const [repeatPassword, setRepeatPassword] = useState<any>('');
  const [codeVerifyEmailOld, setCodeVerifyEmailOld] = useState<number | ''>('');

  useEffect(() => {
    if (type === 'pass') {
      setEmail(userStore.profile.email);
    }
  }, [type, userStore.profile.email]);

  const resetForm = () => {
    setPassword('');
    setEmail('');
    setRepeatPassword('');
    if (loginFormRef.current) {
      loginFormRef.current.resetValidations();
    }
  };
  useEffect(() => {
    if (open) {
      resetForm();
      if (type === 'pass') {
        setEmail(userStore.profile.email);
      }
      if (type === 'email' && !codeVerifyEmailOld) {
        handleShowConfirm(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, type]);

  function handleCloseConfirm() {
    if (type === 'email' && codeVerifyEmailOld) {
      setCodeVerifyEmailOld('');
    }
    setConfirm(false);
  }

  function handleChangeRepeatPassword(e) {
    setRepeatPassword(e?.target?.value);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit(code) {
    if (type === 'email' && !codeVerifyEmailOld) {
      setCodeVerifyEmailOld(code);
      setConfirm(false);
      setOpen(true);
      return;
    }
    setLoading(true);
    const params: {
      email?: string;
      codeVerify: number;
      password?: string;
      codeVerifyEmailOld?: number | '';
    } = {
      codeVerify: Number(code),
    };
    if (type === 'email') {
      params.codeVerifyEmailOld = Number(codeVerifyEmailOld);
      params.email = email;
    } else if (type === 'pass') {
      params.password = password;
    } else {
      params.email = email;
      params.password = password;
    }

    userStore
      .updateProfile(params, type)
      .then(() => {
        handleClose();
        setConfirm(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChangeMail(e) {
    setEmail(e?.target?.value);
  }

  function handleChangePassword(e) {
    setPassword(e?.target?.value);
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value !== password) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [password, repeatPassword]);

  function handleShowConfirm(isGetCodeOld = false) {
    let apiPath = '/user/update-email-verify-code';
    const params: { email?: string } = {};
    if (type === 'email') {
      apiPath = '/user/update-email-verify-code';
      params.email = userStore.profile.email;
      if (isGetCodeOld) {
        authRequest
          .post(`${baseUrl}${apiPath}`, params)
          .then(() => {
            handleClose();
            setConfirm(true);
          })
          .catch(error => {
            const msg = getStatsErrorMessage(error) || 'Something went wrong';
            notificationStore.showErrorNotification(msg);
          });
        return;
      }
    }
    if (type === 'pass') {
      apiPath = '/user/change-pwd-verify-code';
      params.email = email;
    } else {
      apiPath = '/user/update-email-verify-code';
      params.email = email;
    }

    loginFormRef.current.isFormValid().then(res => {
      if (res) {
        setLoading(true);
        authRequest
          .post(`${baseUrl}${apiPath}`, params)
          .then(() => {
            setLoading(false);
            handleClose();
            setConfirm(true);
          })
          .catch(error => {
            setLoading(false);
            const msg = getStatsErrorMessage(error) || 'Something went wrong';
            notificationStore.showErrorNotification(msg);
          });
      }
    });
  }

  let title = 'Setup Email Password';
  switch (type) {
    case 'email':
      title = 'Change Email';
      break;
    case 'pass':
      title = 'Change Password';
      break;
    default:
      title = 'Setup Email Password';
      break;
  }

  return (
    <>
      <StyledModal open={open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <h2>{title}</h2>
            <ValidatorForm
              onSubmit={() => handleShowConfirm(false)}
              ref={loginFormRef}
              style={{ with: '100%' }}
            >
              {type === 'email' ? (
                <TextValidator
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'This field is required',
                    'Email is not valid',
                  ]}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus={true}
                  value={email}
                  onChange={handleChangeMail}
                />
              ) : null}
              {type === 'pass' ? (
                <>
                  <TextValidator
                    validators={[
                      'required',
                      'minStringLength:8',
                      'maxStringLength:32',
                    ]}
                    errorMessages={[
                      'This field is required',
                      'This field must be at least 8 characters long',
                      'This field maximum 32 characters long',
                    ]}
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    label="New Password"
                    type="password"
                    id="new-password"
                    value={password}
                    onChange={handleChangePassword}
                    autoFocus={true}
                    autoComplete="new-password"
                  />
                  <TextValidator
                    validators={[
                      'isPasswordMatch',
                      'required',
                      'minStringLength:8',
                    ]}
                    errorMessages={[
                      'Password mismatch',
                      'This field is required',
                      'This field must be at least 8 characters long',
                    ]}
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    label="Confirm Password"
                    type="password"
                    id="confirm-new-password"
                    autoComplete="off"
                    value={repeatPassword}
                    onChange={handleChangeRepeatPassword}
                  />
                </>
              ) : null}
              {type === 'email-pass' && (
                <>
                  <TextValidator
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'This field is required',
                      'Email is not valid',
                    ]}
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus={true}
                    value={email}
                    onChange={handleChangeMail}
                  />
                  <TextValidator
                    validators={['required', 'minStringLength:8']}
                    errorMessages={[
                      'This field is required',
                      'This field must be at least 8 characters long',
                    ]}
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </>
              )}
              <Button
                style={{ marginTop: '10px', minHeight: '48px' }}
                disabled={loading}
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </ValidatorForm>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
      <PopupConfirmEmail
        open={openConfirm}
        onClose={handleCloseConfirm}
        loading={loading}
        onConfirm={handleSubmit}
        email={email || userStore.profile.email}
      />
    </>
  );
});

export default PopupEditAccount;
