import React from 'react';
import styled from 'styled-components';
import Tooltip from '../../components/Tooltip';
import {
  ITEM_TYPE_ID,
  TypeCategory,
  TypeCategoryItem,
  TypeCategoryPet,
} from '../../constants';
import { getEtherscanLink } from '../../utils';
import { amountFormat } from '../../utils/helpers';

const Card = styled.div`
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px 12px;
  `}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const LabelAttribute = styled.div`
  font-weight: 700;
  color: #1dc48c;
`;

const StyledStats = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  margin: -12px;
  align-items: end;
`;

const StyledWrapStat = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '33.33333%'};
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 50%;
    padding: 10px 4px;
  `}
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  max-width: calc(100% - 45px);
`;

const StyledLinkExtend = styled.a`
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIcon = styled.div`
  margin-right: 8px;
  width: 30px;
  min-width: 30px;
  height: 35px;
  text-align: center;
  img {
    height: 100%;
    max-height: 35px;
    max-width: 30px;
    object-fit: contain;
  }
`;

const StyledAddress = styled.div`
  display: flex;
  padding: 20px 10px 10px;
  width: 100%;
  align-items: center;
`;

const StyledIconTransfer = styled.img`
  width: 26px;
  margin-left: 16px;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 16px;
  `}
`;

const StyledAddressOwner = styled.div`
  display: flex;
  align-items: center;
`;

const AttributeData = ({ nftItemDetail, setPopupTransfer, isOwner }) => {
  function openPopupTransfer() {
    setPopupTransfer(true);
  }
  const isTypeItem =
    ITEM_TYPE_ID[nftItemDetail?.typeId]?.typeCategory === TypeCategory.ITEM;
  const isCategoryItem =
    ITEM_TYPE_ID[nftItemDetail?.typeId]?.category ===
    TypeCategoryItem.GROWING_ITEM;
  const isCategoryBuilding =
    ITEM_TYPE_ID[nftItemDetail?.typeId]?.category ===
    TypeCategoryItem.BUILDING_ITEM;
  const isCategoryPetCare =
    ITEM_TYPE_ID[nftItemDetail?.typeId]?.category === TypeCategoryPet.PET_CARE;
  // const numSeasons = getAttributeByName(
  //   'season',
  //   metaData?.attributes
  // )?.value?.split(',')?.length;
  return (
    <div>
      <Title>About</Title>
      <Card>
        <StyledStats>
          <StyledWrapStat width="50%">
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-item.png')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Name</LabelAttribute>
              <div>{nftItemDetail?.name || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat width="50%">
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-environment.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Environment</LabelAttribute>
              <div>{nftItemDetail?.environment || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
        </StyledStats>
        <StyledStats>
          <StyledAddress>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-main.png')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Owner</LabelAttribute>
              <StyledAddressOwner>
                {nftItemDetail.owner ? (
                  <StyledLinkExtend
                    href={getEtherscanLink(
                      undefined,
                      nftItemDetail.owner,
                      'address'
                    )}
                    target="_blank"
                  >
                    {nftItemDetail.owner}
                  </StyledLinkExtend>
                ) : (
                  '__'
                )}

                {isOwner && (
                  <Tooltip title={`Transfer NFT`}>
                    <StyledIconTransfer
                      src={require('../../assets/images/icon-transfer.png')}
                      alt=""
                      onClick={openPopupTransfer}
                    />
                  </Tooltip>
                )}
              </StyledAddressOwner>
            </StyledStat>
          </StyledAddress>
        </StyledStats>
      </Card>
      {isTypeItem && isCategoryItem && (
        <>
          <Title style={{ marginTop: '10px' }}>Stats</Title>
          <Card>
            <StyledStats>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-level.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Quality</LabelAttribute>
                  <div>{amountFormat(nftItemDetail.quality, 2) || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-kg.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Weight</LabelAttribute>
                  <div>{amountFormat(nftItemDetail.weight, 2) || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-category.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Category</LabelAttribute>
                  <div>{nftItemDetail.category || '__'}</div>
                </StyledStat>
              </StyledWrapStat>

              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-wai.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Max Reward</LabelAttribute>
                  <div>{nftItemDetail.maxWaiReward?.toString() || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-wai.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Remaining</LabelAttribute>
                  <div>{nftItemDetail.maxWaiCanClaim?.toString() || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-wai.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Claimable</LabelAttribute>
                  <div>
                    {nftItemDetail.quantityCanClaim?.toString() || '__'}
                  </div>
                </StyledStat>
              </StyledWrapStat>
            </StyledStats>
          </Card>
        </>
      )}
      {isCategoryBuilding && (
        <>
          <Title style={{ marginTop: '10px' }}>Stats</Title>
          <Card>
            <StyledStats>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-level.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Level</LabelAttribute>
                  <div>{nftItemDetail.level || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-category.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Category</LabelAttribute>
                  <div>{nftItemDetail.category || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
            </StyledStats>
          </Card>
        </>
      )}

      {isCategoryPetCare && (
        <>
          <Title style={{ marginTop: '10px' }}>Stats</Title>
          <Card>
            <StyledStats>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-rare.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Rare</LabelAttribute>
                  <div>{nftItemDetail.rare || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-level.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Level</LabelAttribute>
                  <div>{nftItemDetail.level || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-category.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Category</LabelAttribute>
                  <div>{nftItemDetail.category || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-health.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Max Health</LabelAttribute>
                  <div>{nftItemDetail.maxHealthBalance || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-satiety.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Max Satiety</LabelAttribute>
                  <div>{nftItemDetail.maxSatietyBalance || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-energy.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Max Energy</LabelAttribute>
                  <div>{nftItemDetail.maxEnergyBalance || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-slot.png')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Max Slot</LabelAttribute>
                  <div>{nftItemDetail.maxSlots || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-kg.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Weight Bonus</LabelAttribute>
                  <div>{nftItemDetail.weightBonusRate?.toString() || '__'}</div>
                </StyledStat>
              </StyledWrapStat>
              <StyledWrapStat>
                <StyledIcon>
                  <img
                    src={require('../../assets/images/market/icon-level.svg')}
                    alt=""
                  />
                </StyledIcon>
                <StyledStat>
                  <LabelAttribute>Quality Bonus</LabelAttribute>
                  <div>
                    {nftItemDetail.qualityBonusRate?.toString() || '__'}
                  </div>
                </StyledStat>
              </StyledWrapStat>
            </StyledStats>
          </Card>
        </>
      )}

      <br />
      <Card>
        <LabelAttribute>Description</LabelAttribute>
        <div>{nftItemDetail.description}</div>
      </Card>
    </div>
  );
};

export default AttributeData;
