import { useCallback, useMemo } from 'react';
import { useStores } from '../contexts/storesContext';
import { NetworkInterface, NETWORKS_CONFIG } from '../provider/networks';
import { web3Window } from '../provider/Web3Window';
import { EtherKey } from '../stores/Token';
import { isAddress, toChecksum } from '../utils';

export const useAddTokenToMetaMask = (
  token: any
): {
  valid: boolean;
  callback: () => Promise<any>;
} => {
  const {
    root: { providerStore },
  } = useStores();
  const { account, activeChainId } = providerStore.providerStatus;

  const valid = useMemo((): boolean => {
    const validToken =
      !!token?.address &&
      !!token?.decimals &&
      !!token?.symbol &&
      token?.address !== EtherKey;
    const hasEthereumRequest =
      !!web3Window?.ethereum?.request || !!web3Window?.ethereum?.sendAsync;
    // return (
    //   !!account && hasEthereumRequest && validToken && !isMobile
    // );
    return !!account && hasEthereumRequest && validToken;
  }, [account, token]);

  let tokenImage = '';
  if (token.image) {
    tokenImage = token.image;
  } else {
    const network = NETWORKS_CONFIG[activeChainId] || ({} as NetworkInterface);
    const checkSum = isAddress(token.address)
      ? toChecksum(token.address)
      : token.address;
    tokenImage = network.assetsUrl?.replace('{checksum}', checkSum);
  }

  const callback = useCallback(async (): Promise<any> => {
    if (valid) {
      const requestData = {
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: tokenImage,
          },
        },
        id: Math.round(Math.random() * 100000),
      };

      let requestPromise = null;
      if (web3Window?.ethereum?.request) {
        requestPromise = web3Window.ethereum.request(requestData);
      } else {
        requestPromise = new Promise((resolve, reject) => {
          web3Window.ethereum.sendAsync(requestData, (err, added) => {
            if (added) {
              resolve(added);
            } else {
              reject(err);
            }
          });
        });
      }
      return new Promise((resolve, reject) => {
        requestPromise
          .then(async response => {
            if (response) {
              console.log(
                `${token.symbol} was added to your wallet.`,
                response
              );
              resolve(token.address);
            } else {
              console.log(`${token.symbol} was not added to your wallet.`);
              resolve(false);
            }
          })
          .catch(err => {
            console.log('Error add token ', err);
            const errorMsg =
              err?.message ||
              `Failed to add ${token.symbol} token to your MetaMask wallet`;
            reject(new Error(errorMsg));
          });
      });
    }
    return undefined;
  }, [valid, token.address, token.symbol, token.decimals, tokenImage]);

  return { valid, callback };
};
