import { observer } from 'mobx-react';
import React from 'react';
import { getCookie } from '../utils/helpers';
import { useStores } from './storesContext';
export const AuthContext = React.createContext(null);

const AuthContextProvider = observer(props => {
  const {
    root: { userStore },
  } = useStores();
  const { address } = userStore.profile;
  const authData = getCookie('auth_data')
    ? JSON.parse(getCookie('auth_data'))
    : '';
  const { accessToken } = authData;

  const isAuthenticated = address || accessToken;

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {props.children}
    </AuthContext.Provider>
  );
});
export default AuthContextProvider;
