import React from 'react';
import styled from 'styled-components';

export const IconCaretUp = styled(({ active, ...props }: any) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      focusable="false"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
      fill="currentColor"
      {...props}
    >
      <path
        className="st0"
        d="M9.1,7.1L5.2,2.6c-0.1-0.1-0.3-0.1-0.4,0L0.9,7.1c-0.1,0.2,0,0.4,0.2,0.4h7.7C9.1,7.5,9.2,7.2,9.1,7.1z"
      />
    </svg>
  );
})`
  color: ${({ active, theme }) => (active ? theme.primary : '#abb2c8')};
`;

export const IconCaretDown = styled(({ active, ...props }: any) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      focusable="false"
      x="0px"
      y="0px"
      viewBox="0 0 10 10"
      fill="currentColor"
      {...props}
    >
      <path
        className="st0"
        d="M8.8,2.5H1.2C1,2.5,0.8,2.7,1,2.9l3.8,4.5c0.1,0.1,0.3,0.1,0.4,0L9,2.9C9.2,2.8,9.1,2.5,8.8,2.5z"
      />
    </svg>
  );
})`
  color: ${({ active, theme }) => (active ? theme.primary : '#abb2c8')};
`;

export const IconMaxReturn = styled(({ active, ...props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <defs>
        <path
          id="u75igf2jxa"
          d="M0 0.005L19.964 0.005 19.964 19.961 0 19.961z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1239 -904) translate(1154 123) translate(31 773) translate(54 8) translate(0 .034)">
                  <mask id="mlet5b9pjb" fill="#fff">
                    <use xlinkHref="#u75igf2jxa" />
                  </mask>
                  <path
                    fill="currentColor"
                    d="M18.791 10.005c.018-4.858-3.92-8.813-8.791-8.83-4.858-.017-8.813 3.92-8.83 8.79-.017 4.859 3.92 8.814 8.79 8.832 4.859.017 8.814-3.92 8.831-8.792m-9.434-10h1.247c.057.012.113.025.17.034.544.087 1.097.135 1.63.267 6.506 1.607 9.594 9.135 6.108 14.868C16.3 18.81 12.085 20.62 7.879 19.74 3.934 18.916.832 15.692.151 11.706.089 11.342.05 10.975 0 10.609V9.362c.064-.442.111-.886.193-1.324C.938 4.058 4.252.815 8.241.158c.37-.06.744-.102 1.116-.153"
                    mask="url(#mlet5b9pjb)"
                  />
                </g>
                <path
                  fill="currentColor"
                  d="M16.452 10.023c.002-3.565-2.904-6.475-6.468-6.475-3.567 0-6.471 2.9-6.475 6.468-.004 3.562 2.904 6.473 6.468 6.475 3.566.002 6.473-2.902 6.475-6.468M9.987 2.378c4.217.006 7.648 3.447 7.635 7.658-.014 4.213-3.44 7.63-7.648 7.625-4.215-.005-7.647-3.448-7.635-7.658.013-4.213 3.44-7.63 7.648-7.625"
                  transform="translate(-1239 -904) translate(1154 123) translate(31 773) translate(54 8)"
                />
                <path
                  fill="currentColor"
                  d="M9.402 6.307V5.139h1.157v1.174c.654.117 1.165.432 1.522.979.224.345.34.723.335 1.151h-1.14c-.007-.019-.019-.041-.024-.065-.126-.634-.458-.905-1.11-.905-.156 0-.312-.004-.468 0-.542.018-.963.442-.968.973-.005.533.429.97.974.99.369.014.748-.008 1.103.073.983.225 1.661 1.121 1.641 2.1-.02 1.022-.718 1.865-1.721 2.083-.044.01-.086.026-.14.043v1.16H9.401v-1.167c-.71-.134-1.256-.492-1.605-1.123-.172-.311-.257-.644-.25-1.012h1.147c.018.091.03.182.054.268.113.398.468.683.886.701.227.01.455.01.682.001.53-.022.943-.457.94-.979-.003-.53-.428-.958-.964-.98-.375-.016-.76.007-1.121-.077-.984-.229-1.658-1.127-1.633-2.107.025-1.015.714-1.849 1.711-2.071.044-.01.087-.024.154-.042"
                  transform="translate(-1239 -904) translate(1154 123) translate(31 773) translate(54 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
})`
  color: ${({ active, theme }) => (active ? theme.primary : '#abb2c8')};
`;

export const IconFlame = styled(({ active, ...props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M12.534 6.346l-.626-.508-.291.752c-.006.014-.565 1.43-1.425 1.172-.19-.057-.318-.167-.402-.347-.213-.457-.122-1.285.232-2.108.49-1.14.275-2.333-.62-3.452C8.772 1.068 8.016.592 7.984.572L7.068 0l.02 1.08c0 .02.02 1.938-1.4 2.642-.871.43-1.494 1.144-1.755 2.007-.244.808-.142 1.692.281 2.424.173.3.226.554.156.756-.1.288-.42.436-.517.475-1.016.407-1.68-.954-1.707-1.01l-.464-.99-.568.933C.385 9.515 0 10.894 0 12.304c0 4.205 3.397 7.632 7.591 7.681l.092.001c4.237 0 7.684-3.446 7.684-7.682 0-2.32-1.033-4.491-2.833-5.958zM5.28 16.412v-.007c-.005-.467.083-.967.265-1.492l1.444.422.015-.766c.022-1.174.684-1.895 1.213-2.284.136.59.347 1.116.64 1.594.332.543.648 1.004.939 1.42.187.268.293.673.29 1.11v.004c0 .641-.25 1.244-.703 1.698-.454.454-1.057.704-1.7.704-1.324 0-2.402-1.078-2.403-2.403zm5.628 1.546c.229-.476.35-1 .35-1.544.003-.683-.175-1.317-.501-1.785-.325-.465-.602-.874-.901-1.362-.334-.545-.534-1.188-.613-1.964l-.078-.758-.713.27c-.067.025-.67.263-1.29.825-.657.596-1.08 1.332-1.249 2.16l-1.047-.307-.207.472c-.374.854-.56 1.678-.55 2.45 0 .553.127 1.077.352 1.545-1.963-1.122-3.29-3.237-3.29-5.656 0-.88.178-1.745.517-2.545.052.057.108.115.167.172.717.69 1.582.882 2.435.54.576-.231 1.009-.661 1.188-1.18.127-.37.188-.968-.25-1.724-.265-.46-.327-.992-.174-1.498.167-.55.576-1.011 1.153-1.297C7.422 4.17 7.92 3.04 8.121 2.184c.128.126.26.268.383.425.602.763.75 1.515.441 2.235-.495 1.151-.576 2.298-.217 3.067.226.483.616.82 1.127.973.804.242 1.59-.04 2.215-.79.098-.118.184-.238.259-.353 1.193 1.213 1.866 2.84 1.866 4.563 0 2.417-1.325 4.531-3.287 5.654z"
                  transform="translate(-1497 -1027) translate(1158 241) translate(27 778) translate(312.005 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
})`
  color: ${({ active, theme }) => (active ? theme.primary : '#abb2c8')};
`;

interface IconProps {
  size?: string;
  color?: string;
  [attr: string]: any;
}

export const IconInfoCircle = styled(({ size, color, ...props }: IconProps) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 0C2.90927 0 0 2.93548 0 6.5C0 10.0907 2.90927 13 6.5 13C10.0645 13 13 10.0907 13 6.5C13 2.93548 10.0645 0 6.5 0ZM6.5 2.88306C7.10282 2.88306 7.60081 3.38105 7.60081 3.98387C7.60081 4.6129 7.10282 5.08468 6.5 5.08468C5.87097 5.08468 5.39919 4.6129 5.39919 3.98387C5.39919 3.38105 5.87097 2.88306 6.5 2.88306ZM7.96774 9.54032C7.96774 9.72379 7.81048 9.85484 7.65323 9.85484H5.34677C5.16331 9.85484 5.03226 9.72379 5.03226 9.54032V8.91129C5.03226 8.75403 5.16331 8.59677 5.34677 8.59677H5.66129V6.91935H5.34677C5.16331 6.91935 5.03226 6.78831 5.03226 6.60484V5.97581C5.03226 5.81855 5.16331 5.66129 5.34677 5.66129H7.02419C7.18145 5.66129 7.33871 5.81855 7.33871 5.97581V8.59677H7.65323C7.81048 8.59677 7.96774 8.75403 7.96774 8.91129V9.54032Z"
        fill="currentColor"
      />
    </svg>
  );
})`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  color: ${({ color, theme }) => color ?? theme.primary};
`;

export const IconQrcode = styled(({ size, color, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z"
        fill="currentColor"
      />
    </svg>
  );
})`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  color: ${({ color }) => color ?? '#abb2c8'};
`;
