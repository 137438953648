import { useHistory, useLocation } from 'react-router-dom';
import { useStores } from '../contexts/storesContext';
interface AuthModeProps {
  mode?: 'auth_market' | 'auth_game';
}
const useAuth: any = () => {
  const {
    root: { providerStore, dropdownStore, userStore },
  } = useStores();
  // const { address } = userStore.profile;
  const { account } = providerStore.providerStatus;
  const { accessToken, isSyncAuth } = userStore;
  const history = useHistory();
  const location = useLocation();

  function checkAuth({ mode }: AuthModeProps = { mode: 'auth_market' }) {
    // if (!address) {
    //   notificationModalStore.showNotification({
    //     text: 'Please login to perform this action.',
    //   });
    //   return;
    // }

    if (mode === 'auth_game') {
      if (!accessToken || !isSyncAuth) {
        history.push({
          pathname: '/login',
          state: { from: location },
        });
        return false;
      } else {
        return true;
      }
    }

    if (!account) {
      dropdownStore.toggleWalletDropdown();
      return false;
    }
    return true;
  }
  return checkAuth;
};
export default useAuth;
