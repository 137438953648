import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
// @ts-ignore
import PopupNotification from '../PopupNotification';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapped = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
  `};
  @media screen and (min-width: 767px) {
    display: flex;
  }
`;

const WrappedContent = styled.main`
  flex-grow: 1;
`;

const DefaultLayout = observer(
  ({ children }: { children: React.ReactNode }) => {
    const {
      root: { notificationStore, notificationModalStore },
    } = useStores();

    const {
      open,
      onClose,
      message,
      autoHideDuration,
      severity,
      anchorOrigin,
    } = notificationStore;

    const {
      message: messagePopup,
      desc,
      onClose: onClosePopup,
    } = notificationModalStore;

    return (
      <>
        <Wrapped>
          <WrappedContent>{children}</WrappedContent>
        </Wrapped>
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={onClose}
        >
          <Alert onClose={onClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
        <PopupNotification
          message={messagePopup}
          desc={desc}
          onClose={onClosePopup}
        />
      </>
    );
  }
);

export default DefaultLayout;
