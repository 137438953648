import { action, observable } from 'mobx';
import qs from 'query-string';
import { ITEM_TYPE_ID, TypeCategory } from '../constants';
import { ClearFilterProps, EnvironmentProps } from '../types';
import { ContractTypes } from './Provider';
import RootStore from './Root';
import { INIT_DATA_RARES, MAX_PRICE, MIN_PRICE } from './WlandStore';
export const MAX_LEVEL = 20;
export const MIN_LEVEL = 1;

export default class NftItemStore {
  public rootStore: RootStore;
  @observable public environments: EnvironmentProps[];
  @observable public itemId: string;
  @observable public sortBy: string;
  @observable public sale: string;
  @observable public page: number;
  @observable public yourOffers: boolean;
  @observable public yourOldOffers: boolean;
  @observable public isOwned: boolean;
  @observable public yourTotalLand: number;
  @observable public rangeLV: number[];
  @observable public rangePrice: Array<string | number>;
  @observable public checkedTypeIds: number[];
  @observable public filterByCategory: any;
  @observable public filterByCategoryData: any;
  @observable public rares: EnvironmentProps[];
  @observable public valueTab: number;
  @observable public categoryFilter: {
    pet: string;
    item: string;
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rares = INIT_DATA_RARES;
    this.environments = [
      {
        id: 'basic',
        name: 'basic',
        checked: false,
      },
      {
        id: 'forest',
        name: 'forest',
        checked: false,
      },
      {
        id: 'lake',
        name: 'lake',
        checked: false,
      },
      {
        id: 'sea',
        name: 'sea',
        checked: false,
      },
    ];
    this.itemId = '';
    this.sortBy = '';
    this.sale = 'all';
    this.valueTab = 0;
    this.page = 1;
    this.yourOffers = false;
    this.yourOldOffers = false;
    this.isOwned = false;
    this.yourTotalLand = 0;
    this.rangeLV = [MIN_LEVEL, MAX_LEVEL];
    this.rangePrice = [null, null];
    this.checkedTypeIds = [];
    const ARRAY_CATEGORY = Object.values(TypeCategory);
    const ARRAY_ITEM_TYPE = Object.values(ITEM_TYPE_ID);
    const filterCategoryData = {};
    ARRAY_CATEGORY.forEach(
      category =>
        (filterCategoryData[category] = ARRAY_ITEM_TYPE.filter(
          item => item.typeCategory === category
        ))
    );
    this.filterByCategoryData = filterCategoryData;
    this.filterByCategory = filterCategoryData;
    this.categoryFilter = {
      pet: 'all',
      item: 'all',
    };
  }

  @action public setEnvironments = (environments: EnvironmentProps[]) => {
    this.environments = environments;
    const indexChecked = environments.findIndex(item => item.checked === true);
    if (indexChecked !== -1) {
      this.resetFilterOffer();
    }
  };

  @action public setRares = (rares: EnvironmentProps[]) => {
    this.rares = rares;
    const indexChecked = rares.findIndex(item => item.checked === true);
    if (indexChecked !== -1) {
      this.resetFilterOffer();
    }
  };

  @action public setItemId = (itemId: string) => {
    this.itemId = itemId;
    if (itemId) {
      this.resetFilterOffer();
    }
  };

  @action public setSortBy = (sortBy: string) => {
    this.sortBy = sortBy;
    if (sortBy) {
      this.resetFilterOffer();
    }
  };

  @action public setSale = (sale: string) => {
    this.sale = sale;
    if (sale !== 'all') {
      this.resetFilterOffer();
    }
  };

  @action public setPage = (page: number) => {
    this.page = page;
  };

  @action public setYourOffers = (yourOffers: boolean) => {
    this.yourOffers = yourOffers;
    if (yourOffers) {
      this.clearFilter({ yourOffers, isOwned: false, yourOldOffers: false });
    }
  };

  @action public setYourOldOffers = (yourOldOffers: boolean) => {
    this.yourOldOffers = yourOldOffers;
    if (yourOldOffers) {
      this.clearFilter({ yourOldOffers, isOwned: false, yourOffers: false });
    }
  };

  @action public resetFilterOffer = () => {
    this.setYourOffers(false);
    this.setYourOldOffers(false);
    this.setPage(1);
  };

  @action public setOwned = (isOwned: boolean) => {
    this.isOwned = isOwned;
    if (isOwned) {
      this.resetFilterOffer();
    }
  };

  @action public setRangeLV = (rangeLV: number[]) => {
    this.rangeLV = rangeLV;
    if (rangeLV) {
      this.resetFilterOffer();
    }
  };

  @action public setRangePrice = (rangePrice: Array<string | number>) => {
    this.rangePrice = rangePrice;
    if (rangePrice[0] !== null || rangePrice[1] !== null) {
      this.resetFilterOffer();
    }
  };

  @action public setCheckedTypeIds = (checkedTypeIds: number[]) => {
    this.checkedTypeIds = checkedTypeIds || [];
  };

  @action public setValueTab = (value: number) => {
    this.valueTab = value;
  };

  @action public setCategoryFilter = (params: {
    pet: string;
    item: string;
  }) => {
    this.categoryFilter = params;
  };

  @action public setFilterByCategory = params => {
    this.filterByCategory = params;
  };

  @action public clearFilter = ({
    yourOffers,
    isOwned,
    yourOldOffers,
  }: ClearFilterProps) => {
    const data = this.environments.map(obj => ({ ...obj, checked: false }));
    this.rares = INIT_DATA_RARES;
    this.environments = data;
    this.itemId = '';
    this.sale = 'all';
    this.sortBy = '';
    this.page = 1;
    this.isOwned = isOwned || false;
    this.yourOffers = yourOffers || false;
    this.yourOldOffers = yourOldOffers || false;
    this.rangePrice = [null, null];
    this.checkedTypeIds = [];
    this.rangeLV = [MIN_LEVEL, MAX_LEVEL];
    this.categoryFilter = { pet: 'all', item: 'all' };
  };

  @action public syncQueryParamsToFilter = search => {
    const queryParams = new URLSearchParams(search);
    const pageParam = Number(queryParams.get('page'));
    const idParam = queryParams.get('id');
    const raresParam = queryParams.get('rares');
    const isOwnedParam = queryParams.get('isOwned');
    const yourOffersParam = queryParams.get('yourOffers');
    const yourOldOffersParam = queryParams.get('yourOldOffers');
    const sortByParam = queryParams.get('sortBy');
    const saleParam = queryParams.get('sale');
    const environmentsParam = queryParams.get('environments');
    const minPrice = queryParams.get('minPrice');
    const maxPrice = queryParams.get('maxPrice');
    const type = queryParams.get('type');
    const minLevel = queryParams.get('minLevel');
    const maxLevel = queryParams.get('maxLevel');
    if (pageParam && pageParam && this.page !== pageParam) {
      this.setPage(pageParam);
    }
    if (idParam) {
      this.setItemId(idParam);
    }
    if (isOwnedParam) {
      this.setOwned(!!isOwnedParam);
    }
    if (yourOffersParam) {
      this.setYourOffers(!!yourOffersParam);
    }
    if (yourOldOffersParam) {
      this.setYourOldOffers(!!yourOldOffersParam);
    }
    if (sortByParam) {
      this.setSortBy(sortByParam);
    }
    if (saleParam) {
      this.setSale(saleParam);
    }
    if (minPrice) {
      this.setRangePrice([minPrice, this.rangePrice[1]]);
    }
    if (maxPrice) {
      this.setRangePrice([this.rangePrice[0], maxPrice]);
    }
    if (environmentsParam) {
      const environmentsClone = this.environments.map(environment => {
        if (environmentsParam.indexOf(environment.id) !== -1) {
          environment.checked = true;
        }
        return environment;
      });
      this.setEnvironments(environmentsClone);
    }
    if (raresParam) {
      const raresClone = this.rares.map(environment => {
        if (raresParam.indexOf(environment.id) !== -1) {
          environment.checked = true;
        }
        return environment;
      });
      this.setRares(raresClone);
    }
    if (minLevel && Number(minLevel) !== MIN_LEVEL) {
      this.setRangeLV([Number(minLevel), this.rangeLV[1]]);
    }
    if (maxLevel && Number(maxLevel) !== MAX_LEVEL) {
      this.setRangeLV([this.rangeLV[0], Number(maxLevel)]);
    }
    if (type) {
      this.setCheckedTypeIds(type.split(',').map(Number));
    }
  };

  @action public syncFilterToQueryParams = history => {
    let filterParams: {
      page?: number;
      id?: string;
      isOwned?: boolean;
      yourOffers?: boolean;
      yourOldOffers?: boolean;
      sortBy?: string;
      sale?: string;
      rares?: string;
      environments?: string;
      type?: string;
      minPrice?: string | number;
      maxPrice?: string | number;
      minLevel?: string | number;
      maxLevel?: string | number;
    } = {};
    if (this.itemId) {
      filterParams = { ...filterParams, id: this.itemId };
    }
    if (this.page && this.page !== 1) {
      filterParams = { ...filterParams, page: this.page };
    }
    if (this.isOwned) {
      filterParams = { ...filterParams, isOwned: this.isOwned };
    }
    if (this.yourOffers) {
      filterParams = { ...filterParams, yourOffers: this.yourOffers };
    }
    if (this.yourOldOffers) {
      filterParams = { ...filterParams, yourOldOffers: this.yourOldOffers };
    }
    if (this.sortBy) {
      filterParams = { ...filterParams, sortBy: this.sortBy };
    }
    if (this.sale !== 'all') {
      filterParams = { ...filterParams, sale: this.sale };
    }
    if (this.rangePrice[0] !== null && this.rangePrice[0] !== MIN_PRICE) {
      filterParams = { ...filterParams, minPrice: this.rangePrice[0] };
    }
    if (this.rangePrice[1] !== null && this.rangePrice[1] !== MAX_PRICE) {
      filterParams = { ...filterParams, maxPrice: this.rangePrice[1] };
    }

    const environmentsSelected = this.getEnvironmentsSelected(
      this.environments
    );
    if (environmentsSelected.length !== 0) {
      filterParams = {
        ...filterParams,
        environments: environmentsSelected.toString(),
      };
    }
    if (this.checkedTypeIds.length !== 0) {
      filterParams = {
        ...filterParams,
        type: this.checkedTypeIds.toString(),
      };
    }
    const raresSelected = this.getRaresSelected(this.rares);
    if (raresSelected.length !== 0) {
      filterParams = {
        ...filterParams,
        rares: raresSelected.toString(),
      };
    }
    if (this.rangeLV[0] !== MIN_LEVEL) {
      filterParams = { ...filterParams, minLevel: this.rangeLV[0] };
    }

    if (this.rangeLV[1] !== MAX_LEVEL) {
      filterParams = { ...filterParams, maxLevel: this.rangeLV[1] };
    }

    history.replace({ search: qs.stringify(filterParams) });
  };

  public getRaresSelected = rares => {
    const raresSelected = [];
    if (Array.isArray(rares) && rares.length !== 0) {
      for (let k = 0; k < rares.length; k++) {
        if (rares[k].checked === true) {
          raresSelected.push(this.rares[k].name);
        }
      }
    }
    return raresSelected;
  };

  // public getEnvironments = () => {
  //   getEnvironments().then(res => {
  //     if (Array.isArray(res?.data?.wenvironmentEntities)) {
  //       const environments = res?.data?.wenvironmentEntities;
  //       const results = [];
  //       for (let k = 0; k < environments.length; k++) {
  //         const item = { ...environments[k], checked: false };
  //         results.push(item);
  //       }
  //       this.setEnvironments(results);
  //     }
  //   });
  // };

  public getEnvironmentsSelected = environments => {
    const environmentsSelected = [];
    if (Array.isArray(environments) && environments.length !== 0) {
      for (let k = 0; k < environments.length; k++) {
        if (environments[k].checked === true) {
          environmentsSelected.push(this.environments[k].name);
        }
      }
    }
    return environmentsSelected;
  };

  public getOwnerOf = async (itemId: number) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      const wLandContract = await providerStore.getContract(
        ContractTypes.wLand,
        contractMetadata.wLandContract
      );

      const data = await wLandContract.ownerOf(itemId);
      return data;
    } catch (e) {
      return false;
    }
  };
}
