import { MuiThemeProvider } from '@material-ui/core';

import { observer } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import React, { useContext, useEffect } from 'react';
import {
  HashRouter,
  Redirect,
  Route as DefaultRoute,
  Switch,
} from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import AppLayout from './components/layout/AppLayout';
import BlankLayout from './components/layout/BlankLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import ProfileLayout from './components/layout/ProfileLayout';
import PopupLogout from './components/PopupLogout';
import SnackBarWaitTx from './components/SnackBarWaitTx';
import Web3ReactManager from './components/Web3ReactManager';
import AuthContextProvider, { AuthContext } from './contexts/authContext';
import Activity from './pages/Activity';
import GameHistory from './pages/GameHistory';
import GameInventory from './pages/GameInventory';
import ItemDetail from './pages/Items/Detail';
import Items from './pages/Items';
import LandDetail from './pages/Lands/Detail';
import RentDetail from './pages/Rental/Detail';
import Lands from './pages/Lands';
import Rental from './pages/Rental';
import Login from './pages/Login';
import Gift from './pages/Gift';
import Mailbox from './pages/Mailbox';
// import DefaultLayout from './components/layout/DefaultLayout';
// import Maintenance from './pages/Maintenance';
// import NFTSale from './pages/NFTSale';
import Profile from './pages/Profile';
// import TicketDistributer from './pages/TicketDistributer';
// import TokenClaim from './pages/TokenClaim';
import WalletInventory from './pages/WalletInventory';
import SupportTicket from './pages/SupportTicket';
// import Whitelisted from './pages/Whitelisted';
// import WhitelistedIncubator from './pages/WhitelistedIncubator';
import NetworkUpdater from './state/network/updater';
import ThemeProvider, { GlobalStyle } from './theme/index';
import useMuiThemeOverrides from './theme/override';
import { RATE_FRAME_IMG } from './utils/land';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const Marginer = styled.div``;

const AppSell = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Updaters() {
  return <NetworkUpdater />;
}

function PrivateRoute({ layout: Layout = AppLayout, ...props }) {
  const { path, children, location } = props;
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <DefaultRoute
      exact={true}
      path={path}
      {...props}
      children={() => (
        <Layout>
          {isAuthenticated ? (
            children
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )}
        </Layout>
      )}
    />
  );
}

const Route = ({
  component: Component,
  layout: Layout = AppLayout,
  ...rest
}) => {
  return (
    <DefaultRoute
      {...rest}
      children={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const App = observer(() => {
  useEffect(() => {
    const imagesPreload = Object.values(RATE_FRAME_IMG);
    imagesPreload.forEach(image => {
      const newImage = new Image();
      newImage.src = image;
      Window[image] = newImage;
    });
  }, []);
  const renderViews = () => {
    return (
      <AppSell>
        <AuthContextProvider>
          <Switch>
            <PrivateRoute path="/profile/account" layout={ProfileLayout}>
              <Profile />
            </PrivateRoute>
            <PrivateRoute path="/profile/game-inventory" layout={ProfileLayout}>
              <GameInventory />
            </PrivateRoute>
            <PrivateRoute
              path="/profile/wallet-inventory"
              layout={ProfileLayout}
            >
              <WalletInventory />
            </PrivateRoute>
            <PrivateRoute path="/profile/activities" layout={ProfileLayout}>
              <Activity />
            </PrivateRoute>
            <PrivateRoute path="/profile/game-history" layout={ProfileLayout}>
              <GameHistory />
            </PrivateRoute>
            <PrivateRoute path="/profile/gift" layout={ProfileLayout}>
              <Gift />
            </PrivateRoute>
            <PrivateRoute path="/profile/mailbox" layout={ProfileLayout}>
              <Mailbox />
            </PrivateRoute>
            <Route
              exact={true}
              path="/game-history"
              component={GameHistory}
              layout={DefaultLayout}
            />
            <Route exact={true} path="/lands" component={Lands} />
            <Route exact={true} path="/lands/:landId" component={LandDetail} />
            <Route exact={true} path="/items" component={Items} />
            <Route exact={true} path="/items/:itemId" component={ItemDetail} />
            {/* <Route exact={true} path="/rental/lands" component={Rental} /> */}
            {/* <Route
              exact={true}
              path="/rental/lands/:landId"
              component={RentDetail}
            />
            <Route
              exact={true}
              path="/rental/contracts/:contractId"
              component={RentDetail}
            /> */}
            <Route path="/login" component={Login} layout={BlankLayout} />
            <Route
              path="/support-ticket"
              component={SupportTicket}
              layout={BlankLayout}
            />
            {/* <Route exact={true} path="/lottery" component={TicketDistributer} /> */}

            <Redirect exact={true} from="/profile" to="/profile/account" />
            {/* <Route exact={true} path="/token-claim" component={TokenClaim} />
            <Route exact={true} path="/nft-sale" component={NFTSale} />
            <Route
              exact={true}
              path="/nft-whitelist"
              component={WhitelistedIncubator}
            /> */}
            <Redirect from="/rental" to="/rental/lands" />
            <Redirect from="/" to="/lands" />

            {/* <Route
              path="/maintenance"
              component={Maintenance}
              layout={DefaultLayout}
            />
            <Redirect from="/" to="/maintenance" /> */}

            {/* <Route exact={true} path="/whitelisted" component={Whitelisted} /> */}
            {/* <Redirect from="/" to="/whitelisted" /> */}
            {/* <Redirect from="/" to="/land-sale" /> */}
          </Switch>
          <Marginer />
        </AuthContextProvider>
      </AppSell>
    );
  };

  return (
    <MuiThemeProvider theme={useMuiThemeOverrides(true)}>
      <ThemeProvider>
        <React.Fragment>
          <Web3ReactManager>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              content={(key, message) => (
                <SnackBarWaitTx id={key} message={message} />
              )}
              maxSnack={3}
            >
              <Updaters />
              <HashRouter>
                <AppWrapper>{renderViews()}</AppWrapper>
              </HashRouter>
            </SnackbarProvider>
          </Web3ReactManager>
          <GlobalStyle />
          <div id="snackbarhelper" />
          <PopupLogout />
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  );
});

export default App;
