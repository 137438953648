import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import useFetchLandData from '../../hooks/useFetchLandData';
import ActionList from './actionList';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledMainContent = styled.div`
  flex: 1;
  margin: 0 auto;
  padding: 0px 8px;
`;

const StyledFormControl = styled.div`
  margin: 0 10px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 1rem;
  justify-content: space-between;
  margin: 0 -10px 1rem;
`;

const StyledTotal = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

const LandTab = observer(() => {
  const {
    totalLands,
    listLands,
    page,
    loading,
    handleChangePage,
    limitItemByScreen,
  } = useFetchLandData({ modeOwned: true });

  return (
    <StyledContainer>
      <StyledMainContent>
        <StyledHeader>
          <StyledFormControl>
            <StyledTotal>{totalLands} lands</StyledTotal>
          </StyledFormControl>
        </StyledHeader>
        <ActionList
          listData={listLands}
          loading={loading}
          type="wland"
          page={page}
          onChangePage={handleChangePage}
          totalPage={Math.ceil(totalLands / limitItemByScreen)}
        />
      </StyledMainContent>
    </StyledContainer>
  );
});

export default LandTab;
