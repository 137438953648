import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useRef, useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const StyledWrapInput = styled.div`
  display: flex;
  align-items: center;
`;

const usernameRegex = /^[aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵzA-Z0-9]+(?:[ _.-][aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵzA-Z0-9]+)*$/;

const PopupEditAccount = observer(() => {
  const {
    root: { userStore },
  } = useStores();
  const { profile, openPopupEditAccount, setPopupEditName } = userStore;
  const [name, setName] = useState<string>(profile.nickname || '');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('isUserNameValid', value => {
      const res = usernameRegex.exec(value);
      if (!res) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule('isUserNameValid');
    };
  }, [name]);

  function handleClose() {
    setPopupEditName(false);
  }
  function handleChangeName(e) {
    setName(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    loginFormRef.current.isFormValid().then(res => {
      if (res) {
        setLoading(true);
        const type = 'name';
        userStore
          .updateProfile({ nickname: name }, type)
          .then(() => {
            setName('');
            setPopupEditName(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }

  const loginFormRef = useRef(null);

  return (
    <StyledModal open={openPopupEditAccount} onClose={handleClose}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={handleClose}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <h2>Update name account</h2>
          <ul>
            <li>
              Username can contain letters (a-z), numbers (0-9), characters(-_.)
              and space
            </li>
            <li>Username must end with and start with letter or number</li>
          </ul>
          <ValidatorForm
            onSubmit={handleSubmit}
            ref={loginFormRef}
            style={{ with: '100%' }}
          >
            <StyledWrapInput>
              <TextValidator
                validators={[
                  'required',
                  'minStringLength:4',
                  'maxStringLength:26',
                  'isUserNameValid',
                ]}
                errorMessages={[
                  'This field is required',
                  'Usernames must be at least 4 characters long',
                  'Usernames must be less than or equal to 26 characters',
                  'The username is not valid. Only characters [A-Z, a-z , 0-9, -_.] and space are  acceptable.',
                ]}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                disabled={loading}
                label="Name Account"
                autoFocus={true}
                value={name}
                onChange={handleChangeName}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ margin: '7px 0 0 10px' }}
              >
                Save
              </Button>
            </StyledWrapInput>
          </ValidatorForm>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default PopupEditAccount;
