import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { LoadingSpinner } from '../theme/components';
import { TokenParamProps, TokenProps } from '../types';
import { bnum, toWei } from '../utils/helpers';

enum ButtonState {
  NO_WALLET,
  UNLOCK,
  DEPOSIT,
}

const ButtonText = ['Connect Wallet', 'Approve', 'Deposit'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

const StyledWarning = styled.div`
  color: #fea500;
  line-height: 1.1;
  margin-top: 10px;
  font-size: 14px;
  text-algin: center;
`;

const PopupDeposit = observer(() => {
  const {
    root: {
      userStore,
      providerStore,
      assetStore,
      tokenStore,
      notificationStore,
      orderStore,
    },
  } = useStores();
  const metaData = providerStore.getContractMetaData();
  const TOKENS = assetStore.getTokensConfig();
  const ARRAY_TOKENS = Object.values(TOKENS);
  const [isLoading, setLoading] = useState<boolean>(false);
  const depositFormRef = useRef(null);
  const { popupDeposit, setPopupDeposit } = userStore;
  const { account } = providerStore.providerStatus;
  const [isApprove, setApprove] = useState(false);
  const isTokenAmount =
    TOKENS[popupDeposit.token].param === TokenParamProps.amount;
  const isWland = TOKENS[popupDeposit.token].value === TokenProps.WLAND;
  const isNFTItem = TOKENS[popupDeposit.token].value === TokenProps.NFTITEM;
  const isTokenNFT = isWland || isNFTItem;

  useEffect(() => {
    if (isTokenNFT) {
      orderStore
        .isApprovedForAll({
          contractAddress: metaData.assetStoreContract,
          type: TOKENS[popupDeposit.token].type,
          account,
        })
        .then(res => {
          setApprove(res);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData.assetStoreContract, account, popupDeposit.token, orderStore]);

  useEffect(() => {
    ValidatorForm.addValidationRule('amountToken', value => {
      return value === '' || bnum((value && value.toString()) || '0').gt(0);
    });
    return () => {
      ValidatorForm.removeValidationRule('amountToken');
    };
  }, []);

  const resetForm = useCallback(() => {
    setLoading(false);
    if (depositFormRef.current) {
      depositFormRef.current.resetValidations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (popupDeposit.open) {
      resetForm();
    }
  }, [popupDeposit.open, resetForm]);

  const userAllowance =
    isTokenAmount &&
    tokenStore.getAllowance(
      TOKENS[popupDeposit.token].contract,
      account,
      metaData.assetStoreContract
    );

  const getButtonState = (accountParam): ButtonState => {
    if (!accountParam) {
      return ButtonState.NO_WALLET;
    }
    if (isTokenNFT && !isApprove) {
      return ButtonState.UNLOCK;
    } else if (isTokenAmount) {
      const sufficientAllowance =
        userAllowance &&
        userAllowance.gte(bnum(toWei(popupDeposit.param as string) || 0));
      if (!sufficientAllowance) {
        return ButtonState.UNLOCK;
      }
    }
    return ButtonState.DEPOSIT;
  };

  const getButtonText = (buttonStateParam: ButtonState): string => {
    if (buttonStateParam === ButtonState.UNLOCK) {
      return `Approve ${TOKENS[popupDeposit.token].text}`;
    } else if (buttonStateParam === ButtonState.DEPOSIT) {
      return `Deposit ${TOKENS[popupDeposit.token].text}`;
    }
    return ButtonText[buttonStateParam];
  };

  function handleClose() {
    setPopupDeposit({ open: false, param: '' });
  }

  function handleChangeAmount(e) {
    setPopupDeposit({ param: e.target.value });
  }

  function handleChangeSelectToken(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    setPopupDeposit({ token: event.target.value as any });
  }

  function handleDeposit() {
    depositFormRef.current
      .isFormValid()
      .then(res => {
        if (res) {
          setLoading(true);
          return assetStore.depositByType(
            popupDeposit.token,
            popupDeposit.param
          );
        }
      })
      .then(res => {
        const { txResponse } = res;
        if (txResponse?.hash) {
          handleClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleApprove = () => {
    setLoading(true);
    tokenStore
      .approveMaxCallback(
        TOKENS[popupDeposit.token].contract,
        metaData.assetStoreContract
      )
      .catch(error => {
        const msg = error || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApproveNFT = () => {
    setLoading(true);
    orderStore
      .setApproveAll({
        contractAddress: metaData.assetStoreContract,
        state: true,
        account,
        type: TOKENS[popupDeposit.token].type,
      })
      .then(() => {
        orderStore
          .isApprovedForAll({
            contractAddress: metaData.assetStoreContract,
            type: TOKENS[popupDeposit.token].type,
            account,
          })
          .then(res => {
            setApprove(res);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buttonActionHandler = (buttonStateParam: ButtonState) => {
    switch (buttonStateParam) {
      case ButtonState.NO_WALLET:
        // dropdownStore.toggleWalletDropdown();
        break;
      case ButtonState.DEPOSIT:
        handleDeposit();
        break;
      case ButtonState.UNLOCK:
        if (isTokenNFT) {
          handleApproveNFT();
        } else {
          handleApprove();
        }
        break;
      default:
        throw new Error('Invalid button state');
    }
  };

  const buttonState = getButtonState(account);

  const handleSubmit = () => {
    buttonActionHandler(buttonState);
  };

  const labelParam = isTokenNFT ? 'Item Id' : 'Amount';

  return (
    <>
      <StyledModal open={popupDeposit.open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <h2>DEPOSIT</h2>
            <ValidatorForm
              onSubmit={handleSubmit}
              ref={depositFormRef}
              style={{ with: '100%' }}
            >
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="filter">Token</InputLabel>
                <Select
                  value={popupDeposit.token}
                  onChange={handleChangeSelectToken}
                  labelId="filter"
                  label={'Token'}
                  disabled={true}
                >
                  {ARRAY_TOKENS.map(tokenItem => (
                    <MenuItem
                      value={tokenItem.value}
                      key={tokenItem.value}
                      disabled={
                        TOKENS[tokenItem.value].value === TokenProps.WLAND ||
                        TOKENS[tokenItem.value].value === TokenProps.NFTITEM
                      }
                    >
                      <StyledToken>
                        <img src={tokenItem.logo} alt="" height="25px" />
                        {tokenItem.text}
                      </StyledToken>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true}>
                <TextValidator
                  validators={['required']}
                  errorMessages={['This field is required']}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  id="your-wallet"
                  label="Your Wallet"
                  name="your-wallet"
                  value={account || ''}
                  disabled={true}
                />
              </FormControl>
              <FormControl fullWidth={true}>
                <TextValidator
                  validators={['required']}
                  errorMessages={['This field is required']}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  id="to-wallet"
                  label="Contract Address"
                  name="to-wallet"
                  value={TOKENS[popupDeposit.token].contractDeposit}
                  disabled={true}
                />
              </FormControl>
              <FormControl fullWidth={true}>
                <TextValidator
                  validators={['required', 'amountToken']}
                  required={true}
                  fullWidth={true}
                  value={popupDeposit.param?.toString()}
                  margin="normal"
                  label={labelParam}
                  onChange={handleChangeAmount}
                  disabled={!popupDeposit.isEdit}
                  autoFocus={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {TOKENS[popupDeposit.token].text}
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  errorMessages={[
                    'This field is required',
                    `${labelParam} must be an integer greater than 0`,
                  ]}
                  type={isTokenNFT ? 'string' : 'number'}
                />
              </FormControl>
              {isWland && (
                <StyledWarning>
                  Warning: After deposit the land will be locked in 7days
                </StyledWarning>
              )}
              <Button
                style={{ marginTop: '10px', minHeight: '48px' }}
                disabled={isLoading || !popupDeposit.param}
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
              >
                {getButtonText(buttonState)}{' '}
                {isLoading ? <LoadingSpinner size={24} /> : null}
              </Button>
            </ValidatorForm>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    </>
  );
});

export default PopupDeposit;
