import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStores } from '../../contexts/storesContext';
import { NoDataText } from '../../components/Common';
import {
  ExpandMore as ExpandMoreIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from '@material-ui/core';
import { MailProps } from '../../types';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Clipboard as ClipboardIcon } from 'react-feather';
// import { copyToClipboard } from '../../utils/helpers';
import Tooltip from '../../components/Tooltip';
import useCopyClipboard from '../../hooks/useCopyClipboard';

const StyledBody = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 2rem;
  }
  form {
    width: 100%;
  }
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 28px 12px;
  background-color: ${({ theme }) => theme.cardBG};
  width: 100%;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 16px;
    flex-direction: column;
  `}
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.4em;
  font-weight: 500;
`;

const StyledAbout = styled.div`
  flex: 1;
  overflow: scroll-x;
`;

const StyleBodyAbout = styled.div`
  max-height: 500px;
  overflow: auto;
  p {
    margin-top: 0;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-height: 300px;
  `}
  border-left:  5px solid #86849b;
  padding-left: 1rem
  height: 100%;
  max-height: 600px;
  overflow: auto;
`;

const StyledMail = styled.div<{ isMobile?: boolean }>`
  flex: 1;
  padding: 6px 0;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
  &:hover {
    background: ${({ isMobile }) =>
      isMobile ? 'unset' : 'rgba(24, 26, 39, 0.3)'};
  }
`;

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const StyledDesc = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  margin-top: 10px;
`;

const StyledSender = styled.div`
  span {
    text-transform: uppercase;
  }
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
`;

const StyledDate = styled.div``;

const StyledWrapMail = styled.div`
  min-width: 450px;
  max-width: 40%;
  max-height: 600px;
  overflow: auto;
  padding-right: 2rem;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    min-width: 340px;
  `};
`;

const StyledRead = styled.span<{ isRead: boolean }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: ${({ theme, isRead }) =>
    !isRead ? theme.primary : 'transparent'};
  margin-top: 10px;
  margin-right: 10px;
`;
const StyledHeadMail = styled.div`
  padding-bottom: 3px;
  border-bottom: 2px solid #4b4f69;
`;

const StyledHistory = styled.div`
  width: 100%;
  margin-top: 1rem;
  max-width: 500px;
`;

const StyledTableContainer = styled(({ ...props }: any) => {
  return <TableContainer {...props} />;
})`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 12px;
  }
  margin-bottom: 1rem;
  white-space: nowrap;
  .number-decimal {
    color: #98a0b9;
  }
`;

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    background-color: ${({ theme }) => theme.cardBG};
  }
  &.MuiAccordion-root:before {
    background-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledContentCode = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

function FormatContent(props) {
  const [isCopied, setCopied] = useCopyClipboard();
  const { content } = props;
  const pattern = /(.+)\[([A-Za-z0-9-]+)\](.+)/gi;
  const results = pattern.exec(content);
  const onCopy = () => {
    // copyToClipboard(results[2])
    setCopied(results[2]);
  };

  if (results && results.length === 4) {
    return (
      <StyledContentCode>
        {results[1]}&nbsp;
        <Tooltip title={isCopied ? 'Copied' : `Click to copy`}>
          <FormControl>
            <OutlinedInput
              id="code"
              name="code"
              value={results[2]}
              readOnly={true}
              style={{ width: '200px' }}
              margin="none"
              onClick={onCopy}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    size="small"
                  >
                    <ClipboardIcon size="20" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Tooltip>
        &nbsp;{results[3]}
      </StyledContentCode>
    );
  }
  return <p>content</p>;
}

const Activity = observer(() => {
  const {
    root: { userStore },
  } = useStores();
  const { mailboxes, setMailboxes } = userStore;
  const [mailOpened, setMailOpened] = useState<MailProps>({} as MailProps);

  useEffect(() => {
    userStore.fetchMailbox().then(res => {
      setMailOpened(res[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReadMail = mail => {
    const { id } = mail;
    const mailboxesRead = mailboxes.map(item => {
      const itemClone = Object.assign({}, item);
      if (id === itemClone?.id && !itemClone?.read_flg) {
        itemClone.read_flg = true;
      }
      return itemClone;
    });
    setMailOpened({} as MailProps);
    setTimeout(() => {
      setMailOpened(mail);
    }, 200);
    setMailboxes(mailboxesRead);
    userStore.readMail(id);
  };

  return (
    <StyledBody>
      <h2>Mail Box</h2>

      {mailboxes && mailboxes.length !== 0 ? (
        isMobile ? (
          mailboxes.map((mail, index) => (
            <StyledAccordion
              key={index}
              style={{ width: '100%' }}
              onClick={() => onReadMail(mail)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledMail
                  style={{ marginBottom: '0', padding: 0 }}
                  isMobile={true}
                >
                  <StyledRead isRead={mail.read_flg}></StyledRead>
                  <div style={{ width: '100%' }}>
                    <StyledTitle>{mail.title}</StyledTitle>
                    <StyledDesc>
                      <StyledSender>
                        <span>{mail.sender_name}</span>
                      </StyledSender>
                      <StyledDate>
                        {mail.expiration_date && (
                          <StyledDate>
                            Expires in{' '}
                            {moment(mail.expiration_date).diff(
                              moment(),
                              'days'
                            )}{' '}
                            {moment(mail.expiration_date).diff(
                              moment(),
                              'days'
                            ) > 1
                              ? 'days'
                              : 'day'}
                          </StyledDate>
                        )}
                      </StyledDate>
                    </StyledDesc>
                  </div>
                </StyledMail>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ marginLeft: '1rem' }}>
                  <FormatContent content={mail?.content} />
                </div>
              </AccordionDetails>
            </StyledAccordion>
          ))
        ) : (
          <Card>
            <StyledWrapMail>
              {mailboxes.map((mail, index) => (
                <StyledMail key={index} onClick={() => onReadMail(mail)}>
                  <StyledRead isRead={mail.read_flg}></StyledRead>
                  <div style={{ width: '100%' }}>
                    <StyledTitle>{mail.title}</StyledTitle>
                    <StyledDesc>
                      <StyledSender>
                        <PersonIcon /> <span>{mail.sender_name}</span>
                      </StyledSender>
                      {mail.expiration_date && (
                        <StyledDate>
                          Expires in{' '}
                          {moment(mail.expiration_date).diff(moment(), 'days')}{' '}
                          {moment(mail.expiration_date).diff(moment(), 'days') >
                          1
                            ? 'days'
                            : 'day'}
                        </StyledDate>
                      )}
                    </StyledDesc>
                  </div>
                </StyledMail>
              ))}
            </StyledWrapMail>
            <StyledAbout>
              {mailOpened && mailOpened?.id && (
                <StyleBodyAbout className="scroll-custom">
                  <StyledHeadMail>
                    <StyledH3>{mailOpened?.title}</StyledH3>
                    <StyledSender>
                      Sender:&nbsp;<span> {mailOpened?.sender_name} </span>
                    </StyledSender>
                    <StyledSender>
                      Time/Date:&nbsp;
                      <span>
                        {moment(mailOpened?.sent_date).format(
                          'YYYY-MM-DD HH:mm'
                        )}{' '}
                      </span>
                    </StyledSender>
                  </StyledHeadMail>
                  <br />
                  <FormatContent content={mailOpened?.content} />
                  {mailOpened?.reward_datas && (
                    <StyledHistory>
                      <h4 style={{ marginBottom: '10px' }}>Rewards</h4>
                      <StyledTableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Code</TableCell>
                              <TableCell align="left">name</TableCell>
                              <TableCell align="left">Description</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mailOpened.reward_datas.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">{row.code}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                  {row.description}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    </StyledHistory>
                  )}
                </StyleBodyAbout>
              )}
            </StyledAbout>
          </Card>
        )
      ) : (
        <Card style={{ justifyContent: 'center' }}>
          <NoDataText />
        </Card>
      )}
    </StyledBody>
  );
});

export default Activity;
