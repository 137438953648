import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { ContractTypes } from '../../stores/Provider';
import { LoadingSpinner } from '../../theme/components';
import { toChecksum, isAddressEqual } from '../../utils/helpers';

enum ButtonState {
  NO_WALLET,
  TRANSFER,
}

const ButtonText = ['Connect Wallet', 'TRANSFER'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  form {
    width: 100%;
    max-width: 300px;
  }
`;

const PopupTransfer = observer(({ open, setOpen, data }) => {
  const [isLoading, setLoading] = useState(false);
  const {
    root: { providerStore, dropdownStore, notificationStore, transactionStore },
  } = useStores();
  const metaData = providerStore.getContractMetaData();
  const [toAddress, setToAddress] = useState<string>('');
  const account = providerStore.providerStatus.account;
  const isWland = isAddressEqual(data?.contractAddress, metaData.wLandContract);

  useEffect(() => {
    ValidatorForm.addValidationRule('toChecksum', value => {
      return toChecksum(toAddress);
    });
    return () => {
      ValidatorForm.removeValidationRule('toChecksum');
    };
  }, [toAddress]);

  const getButtonState = (accountParam): ButtonState => {
    if (!accountParam) {
      return ButtonState.NO_WALLET;
    }
    return ButtonState.TRANSFER;
  };

  const getButtonText = (buttonStateParam: ButtonState): string => {
    return ButtonText[buttonStateParam];
  };

  const resetForm = () => {
    setToAddress('');
    if (transferFormRef.current) {
      transferFormRef.current.resetValidations();
    }
  };

  function handleClose() {
    setOpen(false);
  }

  const handleTransfer = async () => {
    setLoading(true);
    try {
      const contractMetadata = providerStore.getContractMetaData();
      const contractType = isWland
        ? ContractTypes.wLand
        : ContractTypes.nftItem;
      const contractAddress = isWland
        ? contractMetadata.wLandContract
        : contractMetadata.wNFTContract;

      const result = await providerStore.sendTransaction(
        contractType,
        contractAddress,
        'transferFrom',
        [toChecksum(account), toChecksum(toAddress), data.itemId],
        {},
        'Transfer'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        setLoading(false);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse?.hash);
        resetForm();
        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const buttonActionHandler = (buttonStateParam: ButtonState) => {
    switch (buttonStateParam) {
      case ButtonState.NO_WALLET:
        dropdownStore.toggleWalletDropdown();
        break;
      case ButtonState.TRANSFER:
        handleTransfer();
        break;
      default:
        throw new Error('Invalid button state');
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    transferFormRef.current?.isFormValid().then(res => {
      if (res) {
        buttonActionHandler(buttonState);
      }
    });
  };

  const buttonState = getButtonState(account);
  function handleChangeToAddress(e) {
    setToAddress(e.target.value);
  }

  const transferFormRef = useRef(null);

  return (
    <StyledModal open={open} onClose={handleClose}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={handleClose}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <ValidatorForm onSubmit={onSubmit} ref={transferFormRef}>
            <h2>TRANSFER</h2>

            <p className={'price-title'}>To Address</p>
            <TextValidator
              value={toAddress}
              onChange={handleChangeToAddress}
              variant="outlined"
              validators={['toChecksum']}
              errorMessages={['Invalid address']}
              fullWidth={true}
              autoFocus={true}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={buttonState === ButtonState.NO_WALLET || isLoading}
              style={{ minHeight: '48px', marginTop: '1rem' }}
            >
              {getButtonText(buttonState)}{' '}
              {isLoading ? <LoadingSpinner size={24} /> : null}
            </Button>
          </ValidatorForm>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default PopupTransfer;
