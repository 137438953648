import { networkConnectors } from '../provider/networkConnectors';
import { TokenParamProps, TokenProps, TokenTypeProps } from '../types';
import { authRequest } from '../utils/authRequest';
import { fromWei, toWei } from '../utils/helpers';
import { ContractTypes } from './Provider';
import RootStore from './Root';

export default class AssetStore {
  public rootStore: RootStore;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  public depositByType = async (type, amount) => {
    const { assetStore } = this.rootStore;
    const TOKENS = assetStore.getTokensConfig();

    switch (type) {
      case TOKENS.WANA.value:
        return this.depositWana(toWei(amount).toString());
      case TOKENS.WAI.value:
        return this.depositWai(toWei(amount).toString());
      case TOKENS.WLAND.value:
        return this.depositLand(amount);
      case TOKENS.NFTITEM.value:
        return this.depositNftItem(amount);
      default:
        return Promise.reject({ message: 'Token invalid' });
    }
  };

  public depositWana = async amount => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.assetStore,
        contractMetadata.assetStoreContract,
        'depositWana',
        [amount],
        {},
        'Deposit Wana'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        const baseUrl = networkConnectors.getStatsUrl();
        authRequest
          .post(baseUrl + '/update-tx-deposit/wana', {
            amount: Number(fromWei(amount)),
            txHash: txResponse?.hash,
          })
          .catch(() => {});
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public depositWai = async amount => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.assetStore,
        contractMetadata.assetStoreContract,
        'depositWai',
        [amount],
        {},
        'Deposit Wai'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        const baseUrl = networkConnectors.getStatsUrl();
        authRequest
          .post(baseUrl + '/update-tx-deposit/wai', {
            amount: Number(fromWei(amount)),
            txHash: txResponse?.hash,
          })
          .catch(() => {});
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public depositLand = async itemId => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.assetStore,
        contractMetadata.assetStoreContract,
        'depositLand',
        [itemId],
        {},
        'Deposit Land'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        const baseUrl = networkConnectors.getStatsUrl();
        authRequest
          .post(baseUrl + '/update-tx-deposit/land', {
            itemIds: [Number(itemId)],
            txHash: txResponse?.hash,
          })
          .catch(() => {});
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public depositNftItem = async itemIds => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }

      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.nftItem,
        contractMetadata.wNFTContract,
        'deleteItems',
        [itemIds?.map(Number)],
        {},
        'Deposit NFT Item'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        const baseUrl = networkConnectors.getStatsUrl();
        authRequest
          .post(baseUrl + '/update-tx-deposit/nft', {
            itemIds: itemIds?.map(Number),
            txHash: txResponse?.hash,
          })
          .catch(() => {});
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public getTokensConfig = () => {
    const { providerStore } = this.rootStore;
    const metaData = providerStore.getContractMetaData();
    const tokenWithContract: {
      [key in TokenProps]: {
        value: TokenProps;
        text: string;
        logo: string;
        contract: string;
        contractDeposit: string;
        historyType: string;
        param: TokenParamProps;
        type?: TokenTypeProps;
      };
    } = {
      WANA: {
        value: TokenProps.WANA,
        text: 'WANA',
        logo: '/images/logo-token-wana.png',
        contract: metaData.wanaFarmContract,
        contractDeposit: metaData.assetStoreContract,
        historyType: 'wana',
        param: TokenParamProps.amount,
      },
      WAI: {
        value: TokenProps.WAI,
        text: 'WAI',
        logo: '/images/logo-token-wai.png',
        contract: metaData.waiTokenContract,
        contractDeposit: metaData.assetStoreContract,
        historyType: 'wai',
        param: TokenParamProps.amount,
      },
      WLAND: {
        value: TokenProps.WLAND,
        text: 'WLAND',
        logo: '/images/logo-token-land.png',
        contract: metaData.wLandContract,
        contractDeposit: metaData.assetStoreContract,
        historyType: 'land',
        param: TokenParamProps.itemIds,
        type: TokenTypeProps.wland,
      },
      NFTITEM: {
        value: TokenProps.NFTITEM,
        text: 'NFT ITEM',
        logo: '/images/logo-token-item.png',
        contract: metaData.wNFTContract,
        contractDeposit: metaData.wNFTContract,
        historyType: 'nft',
        param: TokenParamProps.itemIds,
        type: TokenTypeProps.nftItem,
      },
    };
    return tokenWithContract;
  };
}
