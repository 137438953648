// Stores
import { web3Window as window } from '../provider/Web3Window';
import { authRequest } from '../utils/authRequest';
import AssetStore from './AssetStore';
import BlockchainFetchStore from './BlockchainFetch';
import DropdownStore from './Dropdown';
import ErrorStore from './Error';
import NftItemStore from './NftItemStore';
import NotificationStore from './Notification';
import NotificationModalStore from './NotificationModal';
import OrderStore from './Order';
import ProviderStore from './Provider';
import TokenStore from './Token';
import TransactionStore from './Transaction';
import UserStore from './User';
import WithdrawStore from './WithdrawStore';
import WlandStore from './WlandStore';
import RentalStore from './Rental';

export default class RootStore {
  public providerStore: ProviderStore;
  public errorStore: ErrorStore;
  public dropdownStore: DropdownStore;
  public userStore: UserStore;
  public notificationStore: NotificationStore;
  public wlandStore: WlandStore;
  public nftItemStore: NftItemStore;
  public transactionStore: TransactionStore;
  public tokenStore: TokenStore;
  public orderStore: OrderStore;
  public blockchainFetchStore: BlockchainFetchStore;
  public notificationModalStore: NotificationModalStore;
  public assetStore: AssetStore;
  public withdrawStore: WithdrawStore;
  public rentalStore: RentalStore;

  constructor() {
    this.dropdownStore = new DropdownStore(this);
    this.errorStore = new ErrorStore(this);
    this.providerStore = new ProviderStore(this);
    this.notificationStore = new NotificationStore(this);
    this.wlandStore = new WlandStore(this);
    this.transactionStore = new TransactionStore(this);
    this.tokenStore = new TokenStore(this);
    this.orderStore = new OrderStore(this);
    this.blockchainFetchStore = new BlockchainFetchStore(this);
    this.notificationModalStore = new NotificationModalStore(this);
    this.nftItemStore = new NftItemStore(this);
    this.assetStore = new AssetStore(this);
    this.userStore = new UserStore(this);
    this.withdrawStore = new WithdrawStore(this);
    this.rentalStore = new RentalStore(this);

    authRequest.initStore(this);
    this.asyncSetup().catch(e => {
      // TODO: Add retry on these fetches
      throw new Error('Async Setup Failed ' + e);
    });
  }

  public async asyncSetup() {
    const windowEthereum = window.ethereum || window.BinanceChain;
    if (!windowEthereum) {
      await new Promise(resolve => {
        setTimeout(resolve, 500);
      });
    }
    await this.providerStore.loadWeb3();
    // Load on-chain data as soon as a provider is available
  }
}
