import { action, observable } from 'mobx';
import { networkConnectors } from '../provider/networkConnectors';
import { normalizeBalance } from '../utils/helpers';
import RootStore from './Root';

export default class BlockchainFetchStore {
  @observable public activeFetchLoop: any;
  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action public blockchainFetch(accountSwitchOverride?: boolean) {
    const { providerStore } = this.rootStore;
    const {
      active,
      activeChainId: chainId,
      library,
      account,
    } = providerStore.providerStatus;

    // TODO: need to reduce the number of requests
    if (
      active &&
      networkConnectors.isChainIdSupported() &&
      library &&
      account
    ) {
      library
        .getBlockNumber()
        .then(blockNumber => {
          const lastCheckedBlock = providerStore.getCurrentBlockNumber();
          const doFetch =
            blockNumber !== lastCheckedBlock || accountSwitchOverride;
          if (doFetch) {
            // console.log('[Fetch Loop] Fetch Blockchain Data', {
            //   lastCheckedBlock,
            //   blockNumber,
            //   chainId,
            //   account,
            // });

            // Using on-chain balances. These may change so need to be updated.
            // poolStore.fetchOnchainPools();

            // Set block number
            providerStore.setCurrentBlockNumber(blockNumber);

            // Get global blockchain data
            // None

            // Get user-specific blockchain data
            if (account) {
              providerStore
                .fetchUserBlockchainData(account)
                .then(results => {})
                .catch(e => {
                  console.log(e);
                });
            } else {
              // tokenStore.fetchOnChainTokenDecimals(
              //   tokenStore.getTrackedTokenAddresses()
              // );
            }
          }
        })
        .catch(error => {
          console.log('[Fetch Loop Failure]', {
            providerStore,
            forceFetch: accountSwitchOverride,
            chainId,
            account,
            library,
            error,
          });
          providerStore.setCurrentBlockNumber(undefined);
        });
      library
        .getGasPrice()
        .then(gasPrice => {
          providerStore.updateChainData({
            gasPrice: normalizeBalance(gasPrice, 9).toNumber(),
          });
        })
        .catch(() => {});
    } else {
      // console.log(`[BlockchainFetch] Aborting fetch. `, {
      //   active,
      //   chainId,
      // });
    }
  }
}
