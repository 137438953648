import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@material-ui/core';
import { Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { AutoColumn } from '../../components/Column';
import IconTypeCH from '../../components/SVG/IconTypeCH';
import IconTypeItem from '../../components/SVG/IconTypeItem';
import IconTypePet from '../../components/SVG/IconTypePet';
import {
  COLOR_ENVIRONMENT,
  COLOR_RARE,
  SIDEBAR_WITH,
  TypeCategory,
  TypeCategoryPet,
} from '../../constants';
import { useStores } from '../../contexts/storesContext';
import { MAX_LEVEL, MIN_LEVEL } from '../../stores/NftItemStore';
import { valuetext } from '../Lands/SidebarLand';
import TypeFilter from './TypeFilter';

const StyledTabFilter = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled.div`
  font-size: 20px;
`;

const StyledTagsFilter = styled.div``;
const StyledLabelTag = styled.div`
  font-size: 12px;
  color: #fbfbfb;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const StyledFilterTags = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
export const StyledFilterTag = styled.div`
  width: 50%;
  margin-bottom: 10px;
`;
const StyledSidebar = styled.div`
  width: ${SIDEBAR_WITH}px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  padding: 32px 20px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const StyledTag = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 4px;
  line-height: 18px;
  max-width: 76px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
`;

export const StyleAvatarType = styled.img`
  height: 100%;
  max-height: 40px;
  max-width: 40px;
  object-fit: contain;
`;

const IconComponents = {
  [TypeCategory.ITEM]: IconTypeItem,
  [TypeCategory.PET]: IconTypePet,
  [TypeCategory.CH]: IconTypeCH,
};

export function IconType(props) {
  // Correct! JSX type can be a capitalized variable.
  const SpecificIconType = IconComponents[props.type];
  return <SpecificIconType {...props} />;
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const CommonTabs = styled(props => (
  <Tabs classes={{ indicator: 'indicator' }} {...props} />
))`
  & .indicator {
    background-color: ${({ theme }) => theme.primary};
    height: 3px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

export function a11yProps(index) {
  return {
    id: `common-tab-${index}`,
    'aria-controls': `offer-detail-tabpanel-${index}`,
  };
}
export const StyledTabTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  flex-direction: column;
  img {
    margin-right: 10px;
    height: 29px;
  }
  white-space: nowrap;
`;

export const CommonTab = styled(({ index, ...props }) => (
  <Tab
    classes={{ selected: 'selected' }}
    id={`councilTab-tab-${index}`}
    aria-controls={`councilTab-tabpanel-${index}`}
    {...props}
  />
))`
  && {
    font-size: 1em;
    font-weight: 700;
    text-transform: none;
    min-width: unset;
  }
`;

const marks = [
  {
    value: MIN_LEVEL,
    label: MIN_LEVEL,
  },
  {
    value: MAX_LEVEL,
    label: MAX_LEVEL,
  },
];

const SidebarNftItem = observer(({ isOwned = false }) => {
  const {
    root: { nftItemStore },
  } = useStores();
  const {
    rares,
    setRares,
    checkedTypeIds,
    setCheckedTypeIds,
    environments,
    setEnvironments,
    itemId,
    setItemId,
    sale,
    setSale,
    sortBy,
    setSortBy,
    clearFilter,
    rangePrice,
    setRangePrice,
    valueTab,
    rangeLV,
    setRangeLV,
    categoryFilter,
  } = nftItemStore;

  const handleChecked = (id: string) => {
    const environmentsClone = [...environments];
    const index = environments.findIndex(obj => {
      return obj.id === id;
    });
    if (index !== -1) {
      environmentsClone[index].checked = !environmentsClone[index].checked;
    }
    setEnvironments(environmentsClone);
  };

  const handleCheckedRare = id => {
    const raresClone = [...rares];
    const index = rares.findIndex(obj => {
      return obj.id === id;
    });
    if (index !== -1) {
      raresClone[index].checked = !raresClone[index].checked;
    }
    setRares(raresClone);
  };

  const handleCheckedType = (
    event: React.ChangeEvent<HTMLInputElement>,
    typeId: number
  ) => {
    event.persist();
    const checkedTypeIdsClone = JSON.parse(JSON.stringify(checkedTypeIds));
    if (event.target.checked) {
      checkedTypeIdsClone.push(typeId);
    } else {
      const index = checkedTypeIdsClone.indexOf(typeId);
      if (index > -1) {
        checkedTypeIdsClone.splice(index, 1);
      }
    }
    setCheckedTypeIds(checkedTypeIdsClone);
  };

  function handleChainId(e: ChangeEvent<HTMLInputElement>) {
    setItemId(e.target.value);
  }

  const handleChangeSelectFilter = (event: ChangeEvent<{ value: unknown }>) => {
    setSortBy(event.target.value as string);
  };

  const handleChangeSale = (event: ChangeEvent<{ value: unknown }>) => {
    setSale(event.target.value as string);
  };

  const handleChainPriceMin = (e: { target: { value: string | number } }) => {
    setRangePrice([e.target.value, rangePrice[1]]);
  };

  const handleChainPriceMax = (e: { target: { value: string | number } }) => {
    setRangePrice([rangePrice[0], e.target.value]);
  };

  function onClearFilter() {
    clearFilter({ isOwned: isOwned });
  }

  const handleChangeRangeLV = (event, newValue) => {
    setRangeLV(newValue);
  };

  const isShowAdvancedFilter =
    valueTab === 1 &&
    (categoryFilter.pet === 'all' ||
      categoryFilter.pet === TypeCategoryPet.PET_CARE ||
      categoryFilter.pet === TypeCategoryPet.PET_BATTLE);

  return (
    <StyledSidebar className="scroll-custom">
      <StyledTabFilter>
        <StyledLabel>Filter</StyledLabel>
        <Button
          color="primary"
          onClick={onClearFilter}
          variant="outlined"
          style={{ height: '30px' }}
        >
          Clear filter
        </Button>
      </StyledTabFilter>
      <StyledTagsFilter>
        <TypeFilter handleChecked={handleCheckedType} />
        <AutoColumn gap="16px">
          <TextField
            value={itemId}
            type="number"
            label="ID"
            variant="outlined"
            onChange={handleChainId}
            fullWidth={true}
          />
          <FormControl variant="outlined">
            <InputLabel id="filter">Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={handleChangeSelectFilter}
              labelId="filter"
              label={'Sort By'}
            >
              <MenuItem value={'lowest-price'}>Lowest Price</MenuItem>
              <MenuItem value={'highest-price'}>Highest Price</MenuItem>
              <MenuItem
                value={'lowest-wai-remaining'}
                style={{ display: valueTab !== 0 ? 'none' : '' }}
              >
                Lowest WAI Remaining
              </MenuItem>
              <MenuItem
                value={'highest-wai-remaining'}
                style={{ display: valueTab !== 0 ? 'none' : '' }}
              >
                Highest WAI Remaining
              </MenuItem>
              <MenuItem
                value={'lowest-wai-max'}
                style={{ display: valueTab !== 0 ? 'none' : '' }}
              >
                Lowest WAI Max Reward
              </MenuItem>
              <MenuItem
                value={'highest-wai-max'}
                style={{ display: valueTab !== 0 ? 'none' : '' }}
              >
                Highest WAI Max Reward
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="filter-for-sale">Filter for sale</InputLabel>
            <Select
              value={sale}
              onChange={handleChangeSale}
              labelId="filter-for-sale"
              label={'Filter for sale'}
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'order'}>On Sale</MenuItem>
              <MenuItem value={'not-order'}>Not for sale</MenuItem>
            </Select>
          </FormControl>
        </AutoColumn>
        <br />
        <AutoColumn gap="16px">
          <StyledLabelTag>Price</StyledLabelTag>
          <TextField
            value={rangePrice[0] || ''}
            type="number"
            label="Min Price"
            variant="outlined"
            onChange={handleChainPriceMin}
            fullWidth={true}
          />
          <TextField
            value={rangePrice[1] || ''}
            type="number"
            label="Max Price"
            variant="outlined"
            onChange={handleChainPriceMax}
            fullWidth={true}
          />
          {isShowAdvancedFilter && (
            <>
              <StyledLabelTag>Rare</StyledLabelTag>
              <StyledFilterTags>
                {rares.map((obj, index) => {
                  return (
                    <StyledFilterTag key={obj.id + 'rares'}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={obj.checked ? obj.checked : false}
                            onChange={() => {
                              handleCheckedRare(obj.id);
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={
                          <StyledTag
                            color={COLOR_RARE[obj.id] ?? COLOR_RARE.default}
                          >
                            {obj.name}
                          </StyledTag>
                        }
                      />
                    </StyledFilterTag>
                  );
                })}
              </StyledFilterTags>
              <StyledLabelTag>Level</StyledLabelTag>
              <Slider
                style={{ width: 'calc(100% - 1px)' }}
                min={MIN_LEVEL}
                max={MAX_LEVEL}
                value={rangeLV}
                onChange={handleChangeRangeLV}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                marks={marks}
              />
            </>
          )}

          <StyledLabelTag>Environment</StyledLabelTag>
          <StyledFilterTags>
            {environments.map((obj, index) => {
              return (
                <StyledFilterTag key={obj.id + 'environments'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={obj.checked ? obj.checked : false}
                        onChange={() => {
                          handleChecked(obj.id);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <StyledTag
                        color={
                          COLOR_ENVIRONMENT[obj.id] ?? COLOR_ENVIRONMENT.default
                        }
                      >
                        {obj.name}
                      </StyledTag>
                    }
                  />
                </StyledFilterTag>
              );
            })}
          </StyledFilterTags>
        </AutoColumn>
      </StyledTagsFilter>
    </StyledSidebar>
  );
});

export default SidebarNftItem;
