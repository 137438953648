import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { LoadingSpinner } from '../../theme/components';
import {
  amountFormat,
  bnum,
  formatBalanceWithCommas,
  fromWei,
  isAddressEqual,
} from '../../utils/helpers';

enum ButtonState {
  NO_WALLET,
  UNLOCK,
  CREATE,
}

const ButtonText = ['Connect Wallet', 'Approve', 'BUY'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const StyledP = styled.p`
  color: #a1a6b6;
  text-align: center;
  span {
    color: #fff;
    font-weight: 500;
  }
  .number-decimal {
    color: #a1a6b6;
  }
`;

const PopupBuy = observer(
  ({ open, setOpen, data, handleCheckOwner, setOrderData }) => {
    const [isLoading, setLoading] = useState(false);
    const {
      root: { orderStore, providerStore, tokenStore },
    } = useStores();

    const account = providerStore.providerStatus.account;
    const metaData = providerStore.getContractMetaData();
    const inputToken = tokenStore.findTokenByAddress(metaData.wanaFarmContract);
    const isWland = isAddressEqual(
      data?.nftContractAddress,
      metaData.wLandContract
    );
    const isNftItem = isAddressEqual(
      data?.nftContractAddress,
      metaData.wNFTContract
    );
    const { landPercentConfigFee, itemPercentConfigFee } = orderStore;

    const percentFee = isWland ? landPercentConfigFee : itemPercentConfigFee;
    const userAllowance = tokenStore.getAllowance(
      metaData.wanaFarmContract,
      account,
      metaData.orderContract
    );

    const getButtonState = (accountParam): ButtonState => {
      if (!accountParam) {
        return ButtonState.NO_WALLET;
      }

      const sufficientAllowance =
        userAllowance && userAllowance.gte(bnum(data?.price || 0));
      if (!sufficientAllowance) {
        return ButtonState.UNLOCK;
      }
      return ButtonState.CREATE;
    };

    const getButtonText = (buttonStateParam: ButtonState): string => {
      if (buttonStateParam === ButtonState.UNLOCK) {
        return `Approve ${inputToken.symbol}`;
      }
      return ButtonText[buttonStateParam];
    };

    function handleClose() {
      setOpen(false);
    }

    function handleBuy() {
      setLoading(true);
      orderStore
        .executeOrder({
          contractAddress: data.nftContractAddress,
          itemId: data.itemId?.toString(),
          price: data.price?.toString(),
        })
        .then(() => {
          handleClose();
          setOrderData(false);
          if (handleCheckOwner) {
            handleCheckOwner();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const handleApprove = () => {
      setLoading(true);
      tokenStore
        .approveMaxCallback(metaData.wanaFarmContract, metaData.orderContract)
        .finally(() => {
          setLoading(false);
        });
    };

    const buttonActionHandler = (buttonStateParam: ButtonState) => {
      switch (buttonStateParam) {
        case ButtonState.NO_WALLET:
          // dropdownStore.toggleWalletDropdown();
          break;
        case ButtonState.CREATE:
          handleBuy();
          break;
        case ButtonState.UNLOCK:
          handleApprove();
          break;
        default:
          throw new Error('Invalid button state');
      }
    };

    const clickFormButton = buttonStateParam => {
      buttonActionHandler(buttonStateParam);
    };

    const buttonState = getButtonState(account);
    const textLabel = isWland ? 'Land' : isNftItem ? 'Item' : 'NFT';
    const fee = bnum(fromWei(data?.price || '0'))
      .multipliedBy(percentFee)
      .div(100);
    const priceWithFee = bnum(fromWei(data?.price || '0')).plus(fee) || '0';

    return (
      <StyledModal open={open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <h2>Buy {textLabel}</h2>
            <StyledP>
              The buyer will pay an extra{' '}
              <span>{formatBalanceWithCommas(percentFee)}%</span> market fee to
              total of{' '}
              <span>
                {formatBalanceWithCommas(fromWei(data?.price || '0'))} WANA
              </span>{' '}
              <br />
              Are you sure you want to buy this {textLabel} with{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {amountFormat(priceWithFee, 3)?.split('.')[0]}
                <span className="number-decimal">
                  {amountFormat(priceWithFee, 3)?.split('.')[1] &&
                    `.${amountFormat(priceWithFee, 3)?.split('.')[1]}`}{' '}
                </span>
                &nbsp;WANA
                {data?.price && tokenStore.priceWANA
                  ? `(~ $${amountFormat(
                      bnum(priceWithFee).multipliedBy(
                        bnum(tokenStore.priceWANA)
                      ),
                      0
                    )})?`
                  : ''}
              </span>
            </StyledP>
            <Button
              variant="contained"
              className="primary2"
              onClick={() => {
                clickFormButton(buttonState);
              }}
              disabled={isLoading}
              size="medium"
            >
              {getButtonText(buttonState)}{' '}
              {isLoading ? <LoadingSpinner size={24} /> : null}
            </Button>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    );
  }
);

export default PopupBuy;
