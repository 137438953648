import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { BigNumber } from '../utils/bignumber';
import { bnum, toWei } from '../utils/helpers';
import { ContractTypes } from './Provider';
import RootStore from './Root';

interface FeeConfigProps {
  listingFeeInBps: number;
  shareProfitInBps: number;
  shareProfitToBasePriceInBps: number;
}
export default class OrderStore {
  public rootStore: RootStore;
  @observable public feeConfig: FeeConfigProps;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.feeConfig = {
      listingFeeInBps: 0,
      shareProfitInBps: 50,
      shareProfitToBasePriceInBps: 200,
    };
  }

  @computed get landPercentConfigFee(): BigNumber | string {
    if (this.feeConfig) {
      const {
        listingFeeInBps,
        shareProfitInBps,
        shareProfitToBasePriceInBps,
      } = this.feeConfig;
      return bnum(listingFeeInBps)
        .plus(bnum(shareProfitInBps))
        .plus(bnum(shareProfitToBasePriceInBps))
        .dividedBy(100)
        .toFixed();
    }
    return '0';
  }
  @computed get itemPercentConfigFee(): BigNumber | string {
    if (this.feeConfig) {
      const { shareProfitInBps } = this.feeConfig;
      return bnum(shareProfitInBps)
        .dividedBy(100)
        .toFixed();
    }
    return '0';
  }

  @action public createOrder = async ({ contractAddress, itemId, amount }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'createOrder',
        [
          contractAddress,
          itemId,
          toWei(amount).toString(),
          moment()
            .add(10, 'years')
            .unix(),
        ],
        {},
        'Create Order'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        // notificationStore.showSuccessNotification('Create Order Success');
        await transactionStore.waitTx(txResponse?.hash);
      }

      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };
  @action public cancelOrder = async ({ contractAddress, itemId }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'cancelOrder',
        [contractAddress, itemId],
        {},
        'Cancel Order'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse?.hash);
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public setApproveAll = async ({
    contractAddress,
    account,
    state,
    type,
  }: {
    contractAddress: string;
    state: boolean;
    type: 'nftItem' | 'wland';
    account: string;
  }) => {
    try {
      const {
        providerStore,
        notificationStore,
        transactionStore,
      } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();

      let contractTypes = ContractTypes.wLand;
      let approveContractAddress = contractMetadata.wLandContract;
      if (type === 'nftItem') {
        contractTypes = ContractTypes.nftItem;
        approveContractAddress = contractMetadata.wNFTContract;
      }

      const result = await providerStore.sendTransaction(
        contractTypes,
        approveContractAddress,
        'setApprovalForAll',
        [contractAddress, state],
        {},
        'Approve NFT'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Approve Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public isApprovedForAll = async ({ contractAddress, account, type }) => {
    try {
      const { providerStore } = this.rootStore;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      let contractTypes = ContractTypes.wLand;
      let approveContractAddress = contractMetadata.wLandContract;
      if (type === 'nftItem') {
        contractTypes = ContractTypes.nftItem;
        approveContractAddress = contractMetadata.wNFTContract;
      }
      const contract = await providerStore.getContract(
        contractTypes,
        approveContractAddress
      );

      return contract.isApprovedForAll(account, contractAddress);
    } catch (e) {
      return false;
    }
  };

  public executeOrder = async ({
    contractAddress,
    itemId,
    price,
  }: {
    contractAddress: string;
    itemId: string;
    price: string;
  }) => {
    try {
      const {
        providerStore,
        notificationStore,
        transactionStore,
      } = this.rootStore;
      const { account } = providerStore.providerStatus;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'executeOrder',
        [contractAddress, itemId, price],
        {},
        'Buy'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse?.hash);
        // await txResponse.wait();
        // notificationStore.showSuccessNotification('Buy Success');
      }
      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public createOffer = async ({ contractAddress, itemId, amount }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'createOffer',
        [
          contractAddress,
          itemId,
          toWei(amount, 18).toString(),
          moment()
            .add(10, 'years')
            .unix(),
        ],
        {},
        'Create Offer'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Create offer success');
      }

      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public takeOffer = async ({ contractAddress, buyer, itemId, price }) => {
    try {
      const { providerStore, transactionStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'takeOffer',
        [contractAddress, buyer, itemId, price],
        {},
        'Take Offer'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse.hash);
        /// notificationStore.showSuccessNotification('Take Offer success');
      }

      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public checkOffer = async ({ contractAddress, itemId, account }) => {
    try {
      const { providerStore } = this.rootStore;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      const contractOffer = await providerStore.getContract(
        ContractTypes.orderContract,
        contractMetadata.orderContract
      );

      const data = await contractOffer.bidByBidder(
        contractAddress,
        itemId,
        account
      );
      return data ? data?.expiresAt.toString() !== '0' : null;
    } catch (e) {
      return false;
    }
  };

  public checkExistOrder = async ({ contractAddress, itemId, account }) => {
    try {
      const { providerStore } = this.rootStore;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      const contractOffer = await providerStore.getContract(
        ContractTypes.orderContract,
        contractMetadata.orderContract
      );

      const data = await contractOffer.orderExist(contractAddress, itemId);
      return data;
    } catch (e) {
      return false;
    }
  };

  public orderByItemId = async ({ contractAddress, itemId }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      const contractOffer = await providerStore.getContract(
        ContractTypes.orderContract,
        contractMetadata.orderContract
      );

      const data = await contractOffer.orderByItemId(contractAddress, itemId);
      return data;
    } catch (e) {
      return false;
    }
  };

  public cancelOffer = async ({ contractAddress, itemId }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.orderContract,
        'cancelOffer',
        [contractAddress, itemId],
        {},
        'Cancel Offer'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }

      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  public cancelOldOffer = async ({ contractAddress, itemId }) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      const contractMetadata = providerStore.getContractMetaData();
      const result = await providerStore.sendTransaction(
        ContractTypes.orderContract,
        contractMetadata.oldOrderContract,
        'cancelOffer',
        [contractAddress, itemId],
        {},
        'Cancel Old Offer'
      );
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        await transactionStore.waitTx(txResponse.hash);
        // notificationStore.showSuccessNotification('Cancel Offer Success');
      }

      return result;
    } catch (e) {
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  @action public fetchFeeConfigOnChain = async () => {
    try {
      const { providerStore } = this.rootStore;
      const contractMetadata = providerStore.getContractMetaData();
      const contractOffer = await providerStore.getContract(
        ContractTypes.orderContract,
        contractMetadata.orderContract
      );

      const {
        listingFeeInBps,
        shareProfitInBps,
        shareProfitToBasePriceInBps,
      } = await contractOffer.feeConfig();
      this.feeConfig = {
        listingFeeInBps,
        shareProfitInBps,
        shareProfitToBasePriceInBps,
      };
    } catch (e) {
      console.error(e);
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };
}
