import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { networkConnectors } from '../../provider/networkConnectors';
import { LoadingSpinner } from '../../theme/components';
import { apiRequest, getStatsErrorMessage } from '../../utils/apiRequest';

function validate_txhash(addr) {
  return /^0x([A-Fa-f0-9]{64})$/.test(addr);
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      whiteSpace: 'nowrap',
      height: '48px',
      borderRadius: '16px',
    },
    cardActions: {
      justifyContent: 'center',
      padding: '16px 24px 24px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

const StyledTitle = styled.h2`
  flex-grow: 1;
  padding-top: 0rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 28px;
  font-family: 'ChakraPetch';
  ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 22px;
    `}
`;

const Row = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
  gap: 1rem;
  margin: 1.2rem 0;
  .label {
    color: ${({ theme }) => theme.text2};
    text-align: left;
    font-weight: medium;
  }
  .value {
    font-weight: bold;
  }
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 16px;
    width: 100%;
  }
`;
const StyledCardContent = styled(CardContent)`
  padding: '32px 24px 16px';
`;
const StyledContainer = styled.div`
  margin: auto;
  text-align: center;
  max-width: 500px;
  padding: 1rem 1rem 1rem;
  min-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: calc(100vh - 159px);
  `}
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

const SupportTicket = observer(() => {
  const {
    root: { assetStore, notificationStore },
  } = useStores();
  const [pass, setPass] = useState('');
  const [txHash, setTxHash] = useState('');
  const TOKENS = assetStore.getTokensConfig();
  const ARRAY_TOKENS = Object.values({
    WANA: TOKENS.WANA,
    WAI: TOKENS.WAI,
    NFTITEM: TOKENS.NFTITEM,
    WLAND: TOKENS.WLAND,
  });

  const classes = useStyles();
  const isMounted = useRef(true);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>(TOKENS.NFTITEM.value);
  const statsApiUrl = networkConnectors.getStatsUrl();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function clickFormButton() {
    if (!validate_txhash(txHash)) {
      notificationStore.showErrorNotification('TxHash invalid');
      return;
    }
    setLoading(true);
    apiRequest
      .post(
        `${statsApiUrl}/handle-deposit-by-tx/${TOKENS[token].historyType}`,
        { txHash, password: pass }
      )
      .then(res => {
        const resMessage =
          token === TOKENS.NFTITEM.value
            ? res.data?.resultGame[0]?.c?.error
            : res.data?.resultGame?.c?.error;
        notificationStore.showSuccessNotification(
          resMessage || 'Deposit Successfully!'
        );
      })
      .catch(error => {
        const msg = getStatsErrorMessage(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChangeSelectToken(event: ChangeEvent<{ value: unknown }>) {
    setToken(event.target.value as string);
  }

  function onChangePass(event: ChangeEvent<{ value: unknown }>) {
    setPass(event.target.value as string);
  }

  function onChangeTxHash(event: ChangeEvent<{ value: unknown }>) {
    setTxHash(event.target.value as string);
  }

  return (
    <div>
      <Container>
        <StyledContainer className="scroll-custom">
          <StyledCard>
            <StyledCardContent>
              <StyledTitle>Deposit by TxHash</StyledTitle>
              <Row style={{ margin: '0' }}>
                <div className={'label'}>Type: </div>
                <FormControl variant="outlined">
                  <InputLabel id="filter">Type</InputLabel>
                  <Select
                    value={token}
                    onChange={handleChangeSelectToken}
                    labelId="filter"
                    label={'Token'}
                  >
                    {ARRAY_TOKENS.map(tokenItem => (
                      <MenuItem value={tokenItem.value} key={tokenItem.value}>
                        <StyledToken>
                          <img src={tokenItem.logo} alt="" height="25px" />
                          {tokenItem.text}
                        </StyledToken>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Row>
              <TextField
                value={pass}
                label="Password"
                variant="outlined"
                onChange={onChangePass}
                fullWidth
                style={{ margin: '0.5rem 0' }}
              />
              <TextField
                value={txHash}
                label="TxHash"
                variant="outlined"
                onChange={onChangeTxHash}
                fullWidth
                style={{ margin: '0.5rem 0' }}
              />
            </StyledCardContent>
            <CardActions className={classes.cardActions}>
              <Button
                onClick={() => {
                  clickFormButton();
                }}
                color="primary"
                variant="contained"
                className={classes.button}
                fullWidth={true}
                disabled={isLoading}
              >
                Deposit {isLoading ? <LoadingSpinner size={24} /> : null}
              </Button>
            </CardActions>
          </StyledCard>
        </StyledContainer>
      </Container>
    </div>
  );
});

export default SupportTicket;
