import React from 'react';

const IconTypePet = ({ isActive, style }) => {
  const color = isActive ? '#4dbb8d' : '#b3b6c2';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.88 25.43"
      style={style}
    >
      <title>Pet</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={color}
            d="M18.9,0a4.48,4.48,0,0,1,2.8,5.2,6.16,6.16,0,0,1-2,3.8,2.87,2.87,0,0,1-4.4-.7c-1.5-2.2-.7-6.1,1.4-7.7A6.71,6.71,0,0,1,18.2,0Z"
          />
          <path
            fill={color}
            d="M0,11.2A6.47,6.47,0,0,1,.4,9.9,2.39,2.39,0,0,1,3.8,8.6a5.33,5.33,0,0,1,2.4,6.1,2.42,2.42,0,0,1-3.6,1.4A5.22,5.22,0,0,1,.2,12.4c0-.1-.1-.3-.1-.4A2.49,2.49,0,0,1,0,11.2Z"
          />
          <path
            fill={color}
            d="M13.7,10.7a3.31,3.31,0,0,1,3,1.6c1,1.5,1.9,3,3,4.5.7,1,1.7,1.7,2.4,2.7a4,4,0,0,1,.8,3.9,2.62,2.62,0,0,1-3.1,2,33.54,33.54,0,0,1-4.4-.7,15.87,15.87,0,0,0-6.5.3,12.22,12.22,0,0,1-2.6.3,3.08,3.08,0,0,1-3-4.1A6.44,6.44,0,0,1,5.4,18a25.5,25.5,0,0,0,4.1-4.6,9.47,9.47,0,0,1,2-2,3.06,3.06,0,0,1,2.2-.7Z"
          />
          <path
            fill={color}
            d="M12.5,5.8a6,6,0,0,1-.8,2.9,2.83,2.83,0,0,1-4.1.8A5.52,5.52,0,0,1,5.4,5.8,5.5,5.5,0,0,1,5.9,2a2.81,2.81,0,0,1,4.5-.8A5.88,5.88,0,0,1,12.5,5.8Z"
          />
          <path
            fill={color}
            d="M20.6,13.2a5.31,5.31,0,0,1,2-4.2,2.81,2.81,0,0,1,2.6-.6,2.65,2.65,0,0,1,1.6,2.2,5.31,5.31,0,0,1-1.7,4.9,2.51,2.51,0,0,1-2.9.8,2.88,2.88,0,0,1-1.6-2.6Z"
          />
        </g>
      </g>
    </svg>
  );
};
export default IconTypePet;
