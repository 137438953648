import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { NetworkContextName } from './constants';
import { web3Window as window } from './provider/Web3Window';
import * as serviceWorker from './serviceWorker';
import getLibrary from './utils/getLibrary';
import { client } from './utils/subgraph';

// Sentry.init({
//   dsn:
//     'https://086a40f867b641b4b00e5ab8a98ce7ff@o1046747.ingest.sentry.io/6023633',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

const Root = (
  <ApolloProvider client={client}>
    <React.Suspense fallback="Loading...">
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
              <React.Fragment>
                <div style={{ textAlign: 'center', marginTop: '40vh' }}>
                  <div
                    style={{
                      fontSize: '24px',
                      marginBottom: '1rem',
                    }}
                  >
                    You have encountered an error
                  </div>
                  <button
                    style={{ minHeight: '32px' }}
                    onClick={() => {
                      resetError();
                    }}
                  >
                    Click here to reset!
                  </button>
                </div>
              </React.Fragment>
            )}
          >
            <App />
          </Sentry.ErrorBoundary>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </React.Suspense>
  </ApolloProvider>
);
ReactDOM.render(Root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
