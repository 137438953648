// import Menu from './Menu';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { themeProperties } from '../../theme';
import AddTokens from '../AddTokens/';
// @ts-ignore
import { ExternalText } from '../Common';
import { MENU_ITEMS } from '../layout/AppLayout';
import { RowBetween } from '../Row';
import Wallet from '../Wallet';

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: relative;
    margin-bottom: 1rem;
  `};
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${themeProperties.halfPaddingCard} 0 ${themeProperties.paddingCard};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    justify-content: center;
  `}
`;

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 8px;
  `}
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse;
  `};
`;

const SideNav = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`;

const BasicNavLink = styled(NavLink)`
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  font-weight: 500;
  padding: 4px 16px;
  margin: 0 6px;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #fff;
  &.active {
    height: 60px;
    background: #28293d;
  }
`;

const StyledLogo = styled.img`
  height: 40px;
  width: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 50px;
    width: 50px;
  `}
`;

const BasicUrl = styled.a<{ external?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  font-weight: 500;
  padding: 4px 16px;
  margin: 0 6px;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const StyledBtnProfile = styled(NavLink)`
  text-decoration: none;
  margin-left: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none
  `};
`;

// const StyledBeta = styled.div`
//   background: linear-gradient(
//     135deg,
//     ${({ theme }) => theme.secondary},
//     ${({ theme }) => theme.secondary1} 50%
//   );
//   background-size: 200% auto;
//   color: #181a26;
//   height: 40px;
//   padding: 8px 10px;
//   border: none;
//   position: relative;
//   z-index: 1;
//   font-weight: 400;
//   border-radius: 8px;
//   font-weight: bold;
//   margin: 0 8px;
//   &:before {
//     width: calc(100% - 2px);
//     height: calc(100% - 2px);
//     content: '';
//     position: absolute;
//     display: block;
//     top: 1px;
//     right: 1px;
//     z-index: -1;
//     border-radius: inherit;
//     background: #181a26;
//   }
//   span {
//     background: linear-gradient(135deg, #ffa051, #ff6628 50%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }
// `;

const Header = observer(({ toggles }: { toggles?: React.ReactNode }) => {
  const {
    root: { providerStore },
  } = useStores();

  const { account } = providerStore.providerStatus;

  return (
    <HeaderFrame>
      <RowBetween
        align={'center'}
        flexWrap={'wrap'}
        style={{ position: 'relative' }}
      >
        <HeaderLeft>
          {toggles ?? null}
          <Link to="/">
            <StyledLogo src={require('../../assets/images/logo.png')} alt="" />
          </Link>
          <SideNav>
            {MENU_ITEMS.map((menu: any) => {
              const attrs: any = {
                key: menu.key,
              };
              if (menu.href) {
                attrs.href = menu.href;
                attrs.target = '_blank';
              } else {
                attrs.to = menu.to;
                attrs.exact = menu.exact;
              }
              if (menu.href) {
                return (
                  <BasicUrl external={true} {...attrs}>
                    <ExternalText>{menu.key}</ExternalText>
                  </BasicUrl>
                );
              }
              return (
                <BasicNavLink {...attrs} key={menu.key}>
                  {menu.icon ?? null}
                  {menu.key}
                </BasicNavLink>
              );
            })}
          </SideNav>
          {/* <StyledBeta>
            <span>Beta V2</span>
          </StyledBeta> */}
        </HeaderLeft>
        <HeaderControls>
          <HeaderElementWrap>
            {!isMobile && <AddTokens tokenName={'WANA'} />}
            <Wallet />
            {account ? (
              <StyledBtnProfile to="/profile">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ minHeight: '40px' }}
                >
                  My Account
                </Button>
              </StyledBtnProfile>
            ) : null}
            {/* <Menu /> */}
          </HeaderElementWrap>
        </HeaderControls>

        {/*<TrackingGasPrice />*/}
      </RowBetween>
    </HeaderFrame>
  );
});

export default Header;
