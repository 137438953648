import { SnackbarProvider, withSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import SnackBarWaitTx from '../components/SnackBarWaitTx';

interface MessageProps {
  content: string;
  txHash: string | any;
}

export default {
  toast(msg: MessageProps) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      useEffect(() => {
        enqueueSnackbar(message, {
          persist: true,
        });
      }, [enqueueSnackbar, message]);
      return null;
    });

    const mountPoint = document.getElementById('snackbarhelper');

    ReactDOM.render(
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        content={(key, message) => (
          <SnackBarWaitTx id={key} message={message} />
        )}
        maxSnack={3}
      >
        <Display message={msg} />
      </SnackbarProvider>,
      mountPoint
    );
  },
};
