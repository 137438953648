import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStores } from '../../contexts/storesContext';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { LoadingSpinner } from '../../theme/components';
import { NoDataText } from '../../components/Common';
import {
  Button,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../../theme';

const StyledBody = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 2rem;
  }
  form {
    width: 100%;
  }
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 28px 24px;
  background-color: ${({ theme }) => theme.cardBG};
  max-width: 1000px;
  width: 100%;
  display: flex;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 16px;
    flex-direction: column;
  `}
`;

const StyledTableContainer = styled(({ ...props }: any) => {
  return <TableContainer {...props} />;
})`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 12px;
  }
  margin-bottom: 1rem;
  white-space: nowrap;
  .number-decimal {
    color: #98a0b9;
  }
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledAbout = styled.div`
  flex: 1;
`;

const StyledRedeem = styled.div`
  flex: 1;
`;

const StyledHistory = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 3rem;
`;

const StyleBodyAbout = styled.div`
  max-height: 200px;
  overflow: auto;
  p {
    margin-top: 0;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-height: 300px;
  `}
`;

const StyleDivide = styled.div`
  height: inherit;
  width: 1px;
  background: #595d79;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 1px;
    width: 100%;
  `}
`;

const Activity = observer(() => {
  const {
    root: { userStore, providerStore, notificationModalStore },
  } = useStores();

  const { account } = providerStore.providerStatus;
  const { historyRedeem, profile } = userStore;

  const [isLoading, setLoading] = useState<boolean>(false);
  const redeemFormRef = useRef(null);
  const [code, setCode] = useState(() => '');

  useEffect(() => {
    userStore.fetchHistoryRedeem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (!profile.email) {
      notificationModalStore.showNotification({
        text: (
          <div className="link-model">
            Please{' '}
            <a href={`${window.location.origin}/#/profile/account`}>
              {' '}
              set up your game account Email & Password
            </a>{' '}
            to perform this action.
          </div>
        ),
      });
      return;
    }
    redeemFormRef.current.isFormValid().then(res => {
      if (res) {
        setLoading(true);
        userStore
          .redeemCode(code)
          .then(() => {
            userStore.fetchHistoryRedeem();
            resetForm();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  function handleChangeCode(e) {
    setCode(e.target.value);
  }

  const resetForm = () => {
    setLoading(false);
    setCode('');
    if (redeemFormRef.current) {
      redeemFormRef.current.resetValidations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <StyledBody>
      <Card>
        <StyledRedeem>
          <h2 style={{ marginBottom: '1.5rem' }}>Redeem Code</h2>
          <ValidatorForm
            onSubmit={handleSubmit}
            ref={redeemFormRef}
            style={{ with: '100%' }}
          >
            <FormControl fullWidth={true}>
              <TextValidator
                validators={['required']}
                errorMessages={['This field is required']}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                id="your-wallet"
                label="Your Wallet"
                name="your-wallet"
                value={account || ''}
                disabled={true}
              />
              <TextValidator
                validators={['required']}
                errorMessages={['This field is required']}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                id="redemption-code"
                label="Redemption Code"
                name="redemption-code"
                value={code}
                onChange={handleChangeCode}
              />
            </FormControl>
            <Button
              style={{ marginTop: '10px', minHeight: '48px' }}
              disabled={isLoading}
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
            >
              Redeem
              {isLoading ? <LoadingSpinner size={24} /> : null}
            </Button>
          </ValidatorForm>
        </StyledRedeem>
        <StyleDivide></StyleDivide>
        <StyledAbout>
          <StyledH3>About Code Redemption</StyledH3>
          <StyleBodyAbout className="scroll-custom">
            <p>
              1. After redeeming a code, you will receive the redeemed item.
              Check{' '}
              <Link style={{ color: '#1cc48c' }} to="/profile/game-inventory">
                In-Game’s Inventory
              </Link>{' '}
              to see that you have received it.
            </p>
            <p>
              2. Pay attention to the redemption conditions and validity period
              of the redemption code. A code cannot be redeemed after it
              expires.{' '}
            </p>
            <p>3. Each redemption code can only be used once</p>
            <p>
              4. Once a code is redeemed, the redemption cannot be revoked. Make
              sure you log into the correct account before redeeming the code
            </p>
            <p>
              5. If you encounter any problems during the code redemption
              process, please create a{' '}
              <ExternalLink
                href={`https://support.wanakafarm.com/?address=${account}&category=18`}
              >
                Ticket
              </ExternalLink>{' '}
            </p>
          </StyleBodyAbout>
        </StyledAbout>
      </Card>

      <StyledHistory>
        <StyledH3>Redemption History</StyledH3>
        <StyledTableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="center">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyRedeem &&
                historyRedeem.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{row.code}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="center">
                      {moment(row.updatedDate).format('YYYY-MM-DD')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {!historyRedeem || (historyRedeem && historyRedeem.length === 0) ? (
          <NoDataText size="18px" />
        ) : null}
      </StyledHistory>
    </StyledBody>
  );
});

export default Activity;
