import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import styled from 'styled-components';

const WrapContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 35px 0px;
  > div:first-child {
    margin-right: 20px;
  }
`;

const ItemRow = styled.div`
  svg {
    width: 60px;
    height: 28px;
    margin: 4px 0px 0px 4px;
  }
`;

const ArrowPaginate = ({ page, onChange, size, limit, ...res }) => {
  if (page === 1 && size < limit) {
    return null;
  }
  return (
    <WrapContainer {...res}>
      <ItemRow>
        <Button
          variant="outlined"
          size="medium"
          disabled={page === 1}
          onClick={() => {
            onChange(page - 1);
          }}
        >
          <ArrowBackIcon />
        </Button>
      </ItemRow>
      <ItemRow>
        <Button
          variant="outlined"
          size="medium"
          disabled={size < limit}
          onClick={() => {
            onChange(page + 1);
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </ItemRow>
    </WrapContainer>
  );
};

export default ArrowPaginate;
