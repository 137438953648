import { Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { DEPOSIT_WITHDRAW_STATE, STEP_TRANSFER } from '../../constants';
import { BoxSpacingProps } from '../../theme/styled';

const Chip = styled(props => {
  const rest = { ...props };
  if (props.deleteIcon && !props.onDelete) {
    if (props.onClick) {
      rest.onDelete = props.onClick;
    } else {
      rest.onDelete = () => {};
    }
  }
  return <MuiChip {...rest} />;
})<MuiChipProps & BoxSpacingProps>`
  && {
    ${({ theme }) => theme.boxSpacing}
  }
`;

export default Chip;

interface ChipStatusProps {
  mr?: string;
  fontSize?: string;
  status?: string | number;
  statusGame?: string;
  stepProcess?: string;
  event?: string;
}

const ChipByStatusStyled = styled.div<{
  mr?: string;
  fontSize?: string;
  bg: string;
}>`
  display: inline-flex;
  align-items: center;
  margin-right: ${({ mr }) => mr ?? '4px'};
  background-color: ${({ theme, bg }) => bg || theme.borderColor};
  color: ${({ theme }) => theme.text1};
  border-radius: 34px;
  padding: 6px 16px 8px;
  height: 34px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: ChakraPetch;
  letter-spacing: 0.3px;
`;

function getColorByStatusHistoryGame(status: string): string {
  switch (status) {
    case 'nft_rollback':
    case 'wland_rollback':
    case 'wai_rollback':
    case 'wana_rollback':
    case 'build_rollback':
    case 'pet_rollback':
      return '#2dccff';
    case 'reward':
    case 'OK':
    case 'wana_deposit':
    case 'sell_item':
    case 'nft_item_deposit':
    case 'wland_deposit':
    case 'build_deposit':
    case 'pet_deposit':
    case 'wai_deposit':
    case 'owner_claim_wana':
      return '#1cc48c';
    case 'SPENDING':
    case 'wana_withdraw':
    case 'wai_withdraw':
    case 'buy_item':
    case 'nft_item_withdraw':
    case 'wland_withdraw':
    case 'game_action':
    case 'pool_regist':
    case 'slot_unlock':
    case 'build_withdraw':
    case 'pet_withdraw':
    case 'unlock_level_land':
    case 'teleport_land':
      return '#fea500';
    case 'NG':
      return '#fe383f';
    default:
      return '#3a3f50';
  }
}

function getTextByStatusHistoryGame(status: string): string {
  switch (status) {
    case 'unlock_level_land':
      return 'Unlock level land';
    case 'reward':
      return 'Reward';
    case 'owner_claim_wana':
      return 'Claim wana';
    case 'wai_deposit':
      return 'Deposit';
    case 'wai_rollback':
      return 'Wai rollback';
    case 'wai_withdraw':
      return 'Withdraw';
    case 'wana_rollback':
      return 'Wana rollback';
    case 'wana_deposit':
      return 'Deposit';
    case 'wana_withdraw':
      return 'Withdraw';
    case 'pet_withdraw':
      return 'Pet withdraw';
    case 'pet_deposit':
      return 'Pet deposit';
    case 'pet_rollback':
      return 'Pet rollback';
    case 'build_withdraw':
      return 'Build withdraw';
    case 'build_deposit':
      return 'Build deposit';
    case 'build_rollback':
      return 'Build rollback';
    case 'nft_rollback':
      return 'NFT rollback';
    case 'nft_item_withdraw':
      return 'Item withdraw';
    case 'nft_item_deposit':
      return 'Item deposit';
    case 'wland_deposit':
      return 'Land deposit';
    case 'wland_withdraw':
      return 'Land withdraw';
    case 'wland_rollback':
      return 'Land rollback';
    case 'buy_item':
      return 'Buy item';
    case 'sell_item':
      return 'Sell item';
    case 'OK':
      return 'Success';
    case 'NG':
      return 'Failed';
    case 'SPENDING':
      return 'Pending';
    case 'game_action':
      return 'Burn';
    case 'slot_unlock':
      return 'Slot unlock';
    case 'pool_regist':
      return 'Pool register';
    case 'teleport_land':
      return 'Teleport land';
    default:
      return status;
  }
}

function getColorByStatus(status: string): string {
  switch (status) {
    case 'orderFinished':
    case 'offerFinished':
    case 'wLandTransferred':
    case 'nftItemTransferred':
    case 'mint':
      return '#1cc48c';
    default:
      return '#3a3f50';
  }
}

function getColorByStatusHistory(
  statusGame: string,
  statusStep: string,
  event: string
): string {
  const isDeposit = event?.includes('Deposit') || event?.includes('Delete');

  if (isDeposit) {
    if (statusGame === DEPOSIT_WITHDRAW_STATE.SUCCESS) {
      return '#1cc48c';
    } else if (statusGame === DEPOSIT_WITHDRAW_STATE.PENDING) {
      return '#fea500';
    } else if (statusGame === DEPOSIT_WITHDRAW_STATE.FAILED) {
      return '#fe383f';
    } else {
      return '#3a3f50';
    }
  } else {
    if (statusStep === STEP_TRANSFER.SUCCESS) {
      return '#1cc48c';
    } else if (
      statusStep === STEP_TRANSFER.PENDING ||
      statusGame === DEPOSIT_WITHDRAW_STATE.PENDING ||
      statusStep === STEP_TRANSFER.PROCESSING ||
      statusStep === STEP_TRANSFER.GAME_DONE ||
      statusStep === STEP_TRANSFER.ROLLBACK
    ) {
      return '#fea500';
    } else if (
      statusStep === STEP_TRANSFER.FAILED ||
      statusGame === STEP_TRANSFER.FAILED
    ) {
      return '#fe383f';
    } else {
      return '#3a3f50';
    }
  }
}

function getTextByStatusHistory(
  statusGame: string,
  statusStep: string,
  event: string
): string {
  const isDeposit = event?.includes('Deposit') || event?.includes('Delete');
  if (isDeposit) {
    if (statusGame === DEPOSIT_WITHDRAW_STATE.SUCCESS) {
      return 'Success';
    } else if (statusGame === DEPOSIT_WITHDRAW_STATE.PENDING) {
      return 'Pending';
    } else if (statusGame === DEPOSIT_WITHDRAW_STATE.FAILED) {
      return 'Failed';
    } else {
      return statusGame;
    }
  } else {
    if (statusStep === STEP_TRANSFER.SUCCESS) {
      return 'Success';
    } else if (
      statusStep === STEP_TRANSFER.PENDING ||
      statusGame === DEPOSIT_WITHDRAW_STATE.PENDING ||
      statusStep === STEP_TRANSFER.PROCESSING ||
      statusStep === STEP_TRANSFER.GAME_DONE
    ) {
      return 'Pending';
    } else if (statusStep === STEP_TRANSFER.PROCESSING) {
      return 'Processing';
    } else if (
      statusStep === STEP_TRANSFER.FAILED ||
      statusGame === STEP_TRANSFER.FAILED
    ) {
      return 'Failed';
    } else {
      return statusStep;
    }
  }
}

function getColorByEventHistory(status: string): string {
  if (status && status?.includes('Withdraw')) {
    return '#fea500';
  } else if (
    status &&
    (status?.includes('Deposit') || status?.includes('Delete'))
  ) {
    return '#1cc48c';
  } else {
    return '#3a3f50';
  }
}

function getTextByStatus(status: string): string {
  switch (status) {
    case 'wLandTransferred':
    case 'nftItemTransferred':
      return 'Transferred';
    case 'orderFinished':
    case 'offerFinished':
      return 'Sold';
    case 'mint':
      return 'Mint';
    default:
      return status;
  }
}

export const ChipByStatus = ({ mr, fontSize, status }: ChipStatusProps) => {
  return (
    <ChipByStatusStyled
      mr={mr}
      fontSize={fontSize}
      bg={getColorByStatus(status as string)}
    >
      {getTextByStatus(status as string)}
    </ChipByStatusStyled>
  );
};

export const ChipByStatusHistory = ({
  mr,
  fontSize,
  statusGame,
  stepProcess,
  event,
}: ChipStatusProps) => {
  return (
    <ChipByStatusStyled
      mr={mr}
      fontSize={fontSize}
      bg={getColorByStatusHistory(statusGame, stepProcess, event)}
    >
      {getTextByStatusHistory(statusGame, stepProcess, event)}
    </ChipByStatusStyled>
  );
};

export const ChipByEventHistory = ({
  mr,
  fontSize,
  status,
}: ChipStatusProps) => {
  return (
    <ChipByStatusStyled
      mr={mr}
      fontSize={fontSize}
      bg={getColorByEventHistory(status as string)}
    >
      {status}
    </ChipByStatusStyled>
  );
};

export const ChipByStatusHistoryGame = ({
  mr,
  fontSize,
  status,
}: ChipStatusProps) => {
  return (
    <ChipByStatusStyled
      mr={mr}
      fontSize={fontSize}
      bg={getColorByStatusHistoryGame(status as string)}
    >
      {getTextByStatusHistoryGame(status as string)}
    </ChipByStatusStyled>
  );
};
