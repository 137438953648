import { Button } from '@material-ui/core';
import { NavigateBeforeOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container, Row, TagID } from '../../components/Common';
import HistoryOffer from '../../components/Details/HistoryOffer';
import PopupBuy from '../../components/Details/PopupBuy';
import PopupOffer from '../../components/Details/PopupOffer';
import PopupOrder from '../../components/Details/PopupOrder';
import PopupTransfer from '../../components/Details/PopupTransfer';
import { ITEM_TYPE_ID, TIME_FETCH_DETAIL } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useAuth from '../../hooks/useAuth';
import { NftMetaDataProps, OrderProps } from '../../types';
import GA from '../../utils/ga';
import {
  amountFormat,
  bnum,
  fromWei,
  isAddressEqual,
} from '../../utils/helpers';
import { getColorByEnvironment } from '../../utils/land';
import { getItemById } from '../../utils/subgraph';
import AttributeData from './AttributeData';

const Left = styled.div`
  flex-basis: 40%;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
  `}
`;
const Right = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  width: 100%;
`;

const StyledWrapContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin: auto;
  `}
`;

const TextButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledHeaderBar = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
    align-items: flex-end;
  `}
`;

const StyledPrice = styled.div`
  font-size: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1.4;
  .highlight {
    font-weight: 500;
    font-size: 22px;
  }
  .value {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
  }
  .number-decimal {
    color: #98a0b9;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-end;
    .highlight {
      font-size: 18px;
    }
    .value {
      font-size: 18px;
    }
  `}
`;

const StyledContentItem = styled.div<{ isPedestal?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 270px;
  ${({ isPedestal }) =>
    isPedestal
      ? css`
          flex-direction: column;
          img.main-image {
            max-width: 160px;
          }
        `
      : css`
          img.main-image {
            max-width: 60%;
            padding: 20px 20px;
          }
        `}
`;

const StyledName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 28px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  min-height: 37px;
`;

const StyledWrapPrice = styled.div`
  margin-left: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 0;
  `}
`;
const StyledLabel = styled.span`
  color: #a6a7ad;
`;

const StyledContainerNFT = styled.div``;

const StyledWrapBox = styled.div<{ hideBg?: string; bg: boolean }>`
  ${({ hideBg, bg }) =>
    hideBg
      ? css``
      : css`
          background: ${bg
            ? `url('${require('../../assets/images/bg-nft-item.png')}') center no-repeat`
            : 'none'};
          background-size: contain;
        `}
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: flex-start;
  `}
`;
const StyledTopButton = styled.div`
  margin-left: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 12px;
  `}
`;

const Pedestal = styled.img`
  z-index: -1;
  max-width: 300px;
  margin-top: -32px;
  position: relative;
`;

interface OrderData extends OrderProps {
  nftContractAddress: string;
}
const DATA_INIT = {
  id: 0,
  itemId: 0,
  metaDataUrl: '',
  owner: '',
  name: '',
  description: '',
  isActive: false,
  salePrice: '',
  activeOrder: {
    id: 0,
    orderId: '',
    itemId: 0,
    owner: '',
    buyer: '',
    price: '',
    status: '',
  },
  environment: '',
  image: '',
  typeId: 0,
  typeName: '',
  category: '',
  weight: 0,
  quality: 0,
  quantityCanClaim: 0,
  maxWaiCanClaim: 0,
  maxWaiReward: 0,
  onSale: false,
  saleAt: 0,
  level: 0,
  contractAddress: '',
  rare: '',
};

const NftItemDetail = observer(() => {
  const {
    root: { providerStore, orderStore, nftItemStore, tokenStore },
  } = useStores();
  const { account } = providerStore.providerStatus;
  const history = useHistory();
  const { itemId } = useParams<{ itemId: any }>();
  const [isOffer, setOffer] = useState<boolean>(false);
  const [isOwner, setOwner] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderData>(null);
  const [openPopupOffer, setPopupOffer] = useState<boolean>(false);
  const [openPopupOrder, setPopupOrder] = useState<boolean>(false);
  const [openPopupBuy, setPopupBuy] = useState<boolean>(false);
  const [openTransfer, setPopupTransfer] = useState<boolean>(false);
  const [nftItemDetail, setNftItemDetail] = useState<NftMetaDataProps>(
    DATA_INIT
  );
  const isMounted = useRef(true);
  const checkAuth = useAuth();
  const metaDataContract = providerStore.getContractMetaData();
  const itemWithData = ITEM_TYPE_ID[nftItemDetail?.typeId];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    tokenStore.fetchAllowancesData(
      account,
      [metaDataContract.wanaFarmContract],
      metaDataContract.orderContract
    );
  }, [
    account,
    metaDataContract.orderContract,
    metaDataContract.wanaFarmContract,
    tokenStore,
  ]);

  useEffect(() => {
    GA.mountedPage({
      page: 'item-detail',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDetail = useCallback(() => {
    if (!itemId) {
      return;
    }
    getItemById(itemId)
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setNftItemDetail({
          ...res,
          contractAddress: metaDataContract.wNFTContract,
        });
        setOwner(isAddressEqual(res.owner, account));
        if (res.onSale) {
          setOrderData({
            ...res.activeOrder,
            nftContractAddress: metaDataContract.wNFTContract,
            itemId,
            price: res.salePrice,
          });
        } else {
          setOrderData(null);
        }
      })
      .catch(e => {
        setNftItemDetail(DATA_INIT);
      });
  }, [account, itemId, metaDataContract.wNFTContract]);

  const handleCheckOffer = useCallback(() => {
    if (!itemId || isOwner) {
      return;
    }
    orderStore
      .checkOffer({
        contractAddress: metaDataContract.wNFTContract,
        itemId,
        account,
      })
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setOffer(res);
      })
      .catch(() => {
        setOffer(false);
      });
  }, [itemId, isOwner, orderStore, metaDataContract.wNFTContract, account]);

  const handleCheckOwner = useCallback(() => {
    nftItemStore.getOwnerOf(itemId).then(res => {
      setOwner(isAddressEqual(res, account));
    });
  }, [account, itemId, nftItemStore]);

  const handleCheckExistOrder = useCallback(() => {
    orderStore
      .checkExistOrder({
        contractAddress: metaDataContract.wNFTContract,
        itemId,
        account,
      })
      .then(() => {
        orderStore
          .orderByItemId({
            contractAddress: metaDataContract.wNFTContract,
            itemId,
          })
          .then(res => {
            setOrderData(res);
          });
      });
  }, [orderStore, metaDataContract.wNFTContract, itemId, account]);

  useEffect(() => {
    let handler;
    if (itemId) {
      handleGetDetail();
      handler = setInterval(handleGetDetail, TIME_FETCH_DETAIL);
    } else {
      clearInterval(handler);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [handleGetDetail, itemId]);

  useEffect(() => {
    handleCheckOffer();
  }, [account, handleCheckOffer, nftItemDetail.owner]);

  const handleMakeOffer = e => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupOffer(true);
  };

  const handleCreateOrder = () => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupOrder(true);
  };

  const handleBuy = () => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupBuy(true);
  };

  function handleCancelOrder() {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    orderStore
      .cancelOrder({
        contractAddress: metaDataContract.wNFTContract,
        itemId,
      })
      .then(() => {
        handleCheckExistOrder();
      });
  }

  const dataNftItem = {
    itemId,
    contractAddress: metaDataContract.wNFTContract,
    salePrice: nftItemDetail.salePrice,
  };

  return (
    <Container>
      <Row>
        <Top>
          <TextButton onClick={() => history.goBack()}>
            <NavigateBeforeOutlined />
            Back
          </TextButton>
          <StyledHeaderBar>
            <StyledWrapPrice>
              {orderData && !!BigInt(orderData.price || '0x00') ? (
                <>
                  <StyledPrice>
                    <StyledLabel>Order Price: </StyledLabel>&nbsp;
                    <img
                      src="/images/logo-token-wana.png"
                      alt=""
                      width="25px"
                    />
                    &nbsp;
                    {orderData?.price ? (
                      <>
                        <span className="highlight">
                          {
                            amountFormat(
                              fromWei(orderData.price || '0'),
                              3
                            )?.split('.')[0]
                          }
                        </span>{' '}
                        <span className="highlight number-decimal">
                          {amountFormat(
                            fromWei(orderData.price || '0'),
                            3
                          )?.split('.')[1] &&
                            `.${
                              amountFormat(
                                fromWei(orderData.price || '0'),
                                3
                              )?.split('.')[1]
                            }`}
                        </span>
                      </>
                    ) : (
                      '__'
                    )}
                    <span>&nbsp;WANA</span>
                  </StyledPrice>
                  <div style={{ color: '#98a0b9' }}>
                    {orderData?.price && tokenStore.priceWANA
                      ? `≈ $${amountFormat(
                          bnum(fromWei(orderData?.price || '0')).multipliedBy(
                            bnum(tokenStore.priceWANA)
                          ),
                          0
                        )}`
                      : ''}
                  </div>
                </>
              ) : null}
            </StyledWrapPrice>
            <div>
              {isOwner ? (
                <StyledTopButton>
                  {orderData && !!BigInt(orderData.price || '0x00') ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCancelOrder}
                      style={{ marginRight: '12px' }}
                      size="medium"
                    >
                      CANCEL LISTING
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreateOrder}
                      size="medium"
                    >
                      LIST ON SALE
                    </Button>
                  )}
                </StyledTopButton>
              ) : (
                <StyledTopButton>
                  {orderData && orderData.price ? (
                    <Button
                      variant="contained"
                      className="primary2"
                      onClick={handleBuy}
                      style={{ marginRight: '12px' }}
                      size="medium"
                    >
                      BUY
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMakeOffer}
                    size="medium"
                  >
                    MAKE OFFER
                  </Button>
                </StyledTopButton>
              )}
            </div>
          </StyledHeaderBar>
        </Top>
      </Row>
      <StyledWrapContent>
        <Left>
          <TagID color={getColorByEnvironment(nftItemDetail?.environment)}>
            # {itemId}
          </TagID>
          <StyledName>{nftItemDetail?.name}</StyledName>
          <StyledContainerNFT>
            <StyledWrapBox
              hideBg={itemWithData?.pedestal}
              bg={itemWithData?.background}
            >
              <StyledContentItem isPedestal={itemWithData?.pedestal}>
                <img
                  src={itemWithData?.imageDetail}
                  alt=""
                  className="main-image"
                />
                {itemWithData?.pedestal && (
                  <Pedestal src={itemWithData?.pedestal} alt="" />
                )}
              </StyledContentItem>
            </StyledWrapBox>
          </StyledContainerNFT>
        </Left>
        <Right>
          <AttributeData
            nftItemDetail={nftItemDetail}
            setPopupTransfer={setPopupTransfer}
            isOwner={isOwner}
          />
        </Right>
      </StyledWrapContent>
      {/*  */}
      <HistoryOffer
        category="nftItem"
        data={nftItemDetail}
        handleCheckOffer={handleCheckOffer}
        isOffer={isOffer}
      />
      <PopupOffer
        open={openPopupOffer}
        setOpen={setPopupOffer}
        data={dataNftItem}
        handleCheckOffer={handleCheckOffer}
        type={'nftItem'}
      />
      <PopupOrder
        open={openPopupOrder}
        setOpen={setPopupOrder}
        data={dataNftItem}
        handleCheckExistOrder={handleCheckExistOrder}
        type={'nftItem'}
      />
      <PopupBuy
        open={openPopupBuy}
        setOpen={setPopupBuy}
        data={orderData}
        handleCheckOwner={handleCheckOwner}
        setOrderData={setOrderData}
      />
      <PopupTransfer
        open={openTransfer}
        setOpen={setPopupTransfer}
        data={nftItemDetail}
      />
    </Container>
  );
});

export default NftItemDetail;
