import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
// @ts-ignore
import MetamaskIcon from '../../assets/images/metamask.png';
// @ts-ignore
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { useStores } from '../../contexts/storesContext';
import usePrevious from '../../hooks/usePrevious';
import {
  injected,
  SUPPORTED_WALLETS,
  walletLink,
} from '../../provider/connectors';
import { web3Window as window } from '../../provider/Web3Window';
import { themeProperties } from '../../theme';
import { setupNetwork } from '../../utils/wallet';
import AccountDetails from '../AccountDetails';
import Modal from '../Modal';
import Option from './Option';
import PendingView from './PendingView';

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props =>
    props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit'};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const ContentWrapper = styled.div`
  padding: ${themeProperties.paddingCard};
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

// const Blurb = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   margin-top: 2rem;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     margin: 1rem;
//     font-size: 12px;
//   `};
// `;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`;

const HoverText = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
};

const WalletModal = observer(() => {
  const {
    root: { dropdownStore },
  } = useStores();
  // important that these are destructed from the account-specific web3-react context
  const {
    active,
    account,
    connector,
    activate,
    error,
    setError,
  } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | WalletConnectConnector | undefined
  >();
  const [pendingError, setPendingError] = useState<boolean>();

  const walletModalOpen = dropdownStore.walletDropdownVisible;
  const toggleWalletModal = dropdownStore.toggleWalletDropdown;

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (
    _connector: AbstractConnector | undefined,
    nameOption?: string
  ) => {
    Object.keys(SUPPORTED_WALLETS).map(key => {
      if (_connector === SUPPORTED_WALLETS[key].connector) {
        return SUPPORTED_WALLETS[key].name;
      }
      return true;
    });
    setPendingWallet(_connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      _connector instanceof WalletConnectConnector &&
      _connector.walletConnectProvider?.connected
    ) {
      await (_connector as WalletConnectConnector).close();
    }
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.connected
    ) {
      await (connector as WalletConnectConnector)?.close();
    }
    localStorage.removeItem('walletconnect');
    if (_connector) {
      activate(_connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          if (_connector === injected) {
            const hasSetup = await setupNetwork();
            if (hasSetup) {
              activate(_connector);
            }
          } else {
            activate(_connector); // a little janky...can't use setError because the connector isn't set
          }
        } else {
          if (_connector instanceof WalletConnectConnector) {
            await (_connector as WalletConnectConnector)?.close();
            _connector.walletConnectProvider = undefined;
          }
          setPendingError(true);
        }
      });
    }
  };
  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum?.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <Option
              onClick={() => {
                option.connector !== connector &&
                  !option.href &&
                  tryActivation(option.connector, option.name);
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={require('../../assets/images/' + option.iconName)}
            />
          );
        }
        return null;
      }
      // Trust Wallet check installed
      if (option.name === 'Trust Wallet' && !window.ethereum?.isTrust) {
        return (
          <Option
            id={`connect-${key}`}
            key={key}
            color={option.color}
            link={option.downloadLink.desktop}
            header={`Install ${option.name}`}
            subheader={null} // use option.descriptio to bring back multi-line
            icon={require('../../assets/images/' + option.iconName)}
          />
        );
      }
      // overwrite injected when needed
      if (option.connector === injected || option.connector === walletLink) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Metamask'}
                subheader={null}
                link={'https://metamask.io/'}
                icon={MetamaskIcon}
              />
            );
          } else if (option.name === 'Coinbase Wallet') {
            return (
              <Option
                id={`connect-${key}`}
                onClick={() => {
                  tryActivation(option.connector, option.name);
                }}
                key={key}
                active={option.connector === connector}
                color={option.color}
                link={option.href}
                header={`Install ${option.name}`}
                subheader={null} // use option.descriptio to bring back multi-line
                icon={require('../../assets/images/' + option.iconName)}
              />
            );
          } else {
            return null;
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null;
        }
      }
      // console.log({ option: option.connector, connector: connector });
      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector, option.name);
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} // use option.descriptio to bring back multi-line
            icon={require('../../assets/images/' + option.iconName)}
          />
        )
      );
    });
  }

  function getModalContent() {
    if (error) {
      return (
        <UpperSection>
          <CloseIcon onClick={handleClose}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network'
              : 'Error connecting'}
          </HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <h5>
                Please connect to the appropriate Binance Smart Chain network.
              </h5>
            ) : (
              'Error connecting. Try refreshing the page.'
            )}
          </ContentWrapper>
        </UpperSection>
      );
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      );
    }
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow>
            <HoverText
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
            >
              Back
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <HoverText>Connect to a wallet</HoverText>
          </HeaderRow>
        )}
        <ContentWrapper>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
          {/*{walletView !== WALLET_VIEWS.PENDING && (*/}
          {/*  <Blurb>*/}
          {/*    <span>New to Ethereum? &nbsp;</span>{' '}*/}
          {/*    <ExternalLink href="https://ethereum.org/wallets/">*/}
          {/*      Learn more about wallets*/}
          {/*    </ExternalLink>*/}
          {/*  </Blurb>*/}
          {/*)}*/}
        </ContentWrapper>
      </UpperSection>
    );
  }

  const handleClose = async () => {
    if (
      connector &&
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.connected
    ) {
      await (connector as any).close();
      // localStorage.removeItem('walletName');
      setError(null);
    }
    toggleWalletModal();
  };

  return (
    <Modal
      isOpen={walletModalOpen}
      onDismiss={handleClose}
      minHeight={false}
      maxHeight={90}
    >
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  );
});

export default WalletModal;
