import { Button, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { LoadingSpinner } from '../../theme/components';
import { BigNumber } from '../../utils/bignumber';
import {
  amountFormat,
  bnum,
  denormalizedBalance,
  formatBalanceWithCommas,
  fromWei,
  toWei,
} from '../../utils/helpers';

enum ButtonState {
  DISABLED,
  NO_WALLET,
  UNLOCK,
  CREATE,
}

const ButtonText = ['Create', 'Connect Wallet', 'Approve', 'Create'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  text-align: center;
`;
const StyledBalance = styled.div`
  margin: 1rem 0;
  color: #a1a6b6;
  span {
    color: #fff;
    font-weight: 500;
  }
`;

const StyledWarning = styled.div`
  color: #fea500;
  text-align: center;
  line-height: 1.1;
  margin-top: 10px;
  font-size: 14px;
`;

const PopupOffer = observer(
  ({ open, setOpen, data, handleCheckOffer, type }) => {
    const [amount, setAmount] = useState<number>(0);
    const [isLoading, setLoading] = useState(false);
    const [floorPrice, setFloorPrice] = useState<string>('0');
    const {
      root: { orderStore, tokenStore, providerStore, wlandStore },
    } = useStores();

    const { contractAddress, itemId } = data;

    const metaData = providerStore.getContractMetaData();
    const account = providerStore.providerStatus.account;
    const inputToken = tokenStore.findTokenByAddress(metaData.wanaFarmContract);
    const inputAmount = amount;

    const offerFormRef = useRef(null);
    const isWLand = type === 'wland';

    useEffect(() => {
      if (!isWLand || !itemId) {
        return;
      }
      wlandStore.getLandDataByID(itemId).then(res => {
        const { salePrice } = res;
        setFloorPrice(BigInt(salePrice || '0x00').toString());
      });
    }, [isWLand, itemId, wlandStore]);

    useEffect(() => {
      ValidatorForm.addValidationRule('priceFloor', value => {
        return !bnum(toWei((value && value.toString()) || '0')).lte(
          bnum(floorPrice)
        );
      });
      return () => {
        ValidatorForm.removeValidationRule('priceFloor');
      };
    }, [floorPrice]);

    const getButtonState = (
      account,
      userAllowance: BigNumber | undefined
    ): ButtonState => {
      if (
        bnum(toWei((amount && amount.toString()) || '0')).lte(bnum(floorPrice))
      ) {
        return ButtonState.DISABLED;
      }
      if (!account) {
        return ButtonState.NO_WALLET;
      }

      if (!userAllowance) {
        return ButtonState.UNLOCK;
      }
      const sufficientAllowance =
        userAllowance &&
        userAllowance.gte(
          denormalizedBalance(bnum(inputAmount || 0), inputToken.decimals)
        );
      if (!sufficientAllowance) {
        return ButtonState.UNLOCK;
      }
      return ButtonState.CREATE;
    };
    const getButtonText = (buttonState: ButtonState): string => {
      if (buttonState === ButtonState.UNLOCK) {
        return `Approve ${inputToken.symbol}`;
      }
      return ButtonText[buttonState];
    };

    function handleClose() {
      setOpen(false);
      setAmount(0);
    }

    function handleChangeAmount(e) {
      setAmount(e.target.value);
    }

    function handleOffer() {
      setLoading(true);
      orderStore
        .createOffer({
          contractAddress,
          itemId,
          amount,
        })
        .then(() => {
          handleClose();
          // tslint:disable-next-line: no-unused-expression
          if (handleCheckOffer) {
            handleCheckOffer();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const userAllowance = tokenStore.getAllowance(
      metaData.wanaFarmContract,
      account,
      metaData.orderContract
    );

    const handleApprove = () => {
      setLoading(true);
      tokenStore
        .approveMaxCallback(metaData.wanaFarmContract, metaData.orderContract)
        .finally(() => {
          setLoading(false);
        });
    };

    const buttonActionHandler = (buttonState: ButtonState) => {
      switch (buttonState) {
        case ButtonState.NO_WALLET:
          break;
        case ButtonState.CREATE:
          handleOffer();
          break;
        case ButtonState.UNLOCK:
          handleApprove();
          break;
        default:
          throw new Error('Invalid button state');
      }
    };

    const buttonState = getButtonState(account, userAllowance);
    const balance = tokenStore.getWANABalance;

    const handleSubmit = e => {
      e.preventDefault();
      offerFormRef.current?.isFormValid().then(res => {
        if (res) {
          buttonActionHandler(buttonState);
        }
      });
    };
    const isWland = type === 'wland';

    return (
      <StyledModal open={open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <ValidatorForm onSubmit={handleSubmit} ref={offerFormRef}>
              <h2>MAKE AN OFFER</h2>
              <p className={'price-title'}>PRICE</p>
              <TextValidator
                autoFocus={true}
                value={amount}
                onChange={handleChangeAmount}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">WANA</InputAdornment>
                  ),
                }}
                variant="outlined"
                validators={['priceFloor']}
                errorMessages={['Price should be greater than Floor Price']}
                type="number"
              />
              <StyledBalance>
                {amount !== 0 && amount > 0 && (
                  <div>
                    Price in USD:{' '}
                    <span>
                      {amountFormat(
                        bnum(amount).multipliedBy(bnum(tokenStore.priceWANA)),
                        0
                      )}{' '}
                      USD
                    </span>
                  </div>
                )}
                {isWland && (
                  <div>
                    Floor Price:{' '}
                    <span>
                      {floorPrice
                        ? `${formatBalanceWithCommas(fromWei(floorPrice))} WANA`
                        : '__'}
                    </span>
                  </div>
                )}
                <div>
                  Current balance:{' '}
                  <span>{formatBalanceWithCommas(balance || '0')} WANA</span>
                </div>

                <StyledWarning>
                  Warning: Your WANA will be locked temporarily. It will be
                  returned right after you cancel the offer.
                </StyledWarning>
              </StyledBalance>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={buttonState === ButtonState.DISABLED || isLoading}
                style={{ minHeight: '48px' }}
              >
                {getButtonText(buttonState)}{' '}
                {isLoading ? <LoadingSpinner size={24} /> : null}
              </Button>
            </ValidatorForm>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    );
  }
);

export default PopupOffer;
