import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIDEBAR_WITH, TIME_FETCH_LIST } from '../constants';
import { useStores } from '../contexts/storesContext';
import { MAX_LEVEL, MIN_LEVEL } from '../stores/NftItemStore';
import { MAX_PRICE, MIN_PRICE } from '../stores/WlandStore';
import { getItems, getOffersOld, LIMIT_HOME_ITEMS } from '../utils/subgraph';
import { TypeCategory } from '../constants';

interface fetchItemDataProps {
  modeOwned: boolean;
  noInitFilter?: boolean;
}

export default function useFetchItemData({
  modeOwned = false,
  initFilter = false,
}): fetchItemDataProps {
  const {
    root: { nftItemStore, providerStore },
  } = useStores();
  const { account } = providerStore.providerStatus;
  const {
    valueTab,
    rares,
    environments,
    itemId,
    sortBy,
    sale,
    page,
    isOwned,
    yourOffers,
    rangePrice,
    checkedTypeIds,
    rangeLV,
    filterByCategory,
    yourOldOffers,
    setYourOldOffers,
    getRaresSelected,
    setOwned,
    setPage,
    setYourOffers,
    syncFilterToQueryParams,
    syncQueryParamsToFilter,
    getEnvironmentsSelected,
    clearFilter,
  } = nftItemStore;
  const [listItems, setListNftItems] = useState([]);
  const [totalNftItems, setTotalNftItems] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const itemRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [noInitFilter, setInitFilter] = useState(initFilter);

  useEffect(() => {
    clearFilter({
      yourOffers: false,
      isOwned: modeOwned,
      yourOldOffers: false,
    });
    setPage(1);
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let limitItemByScreen =
    Math.floor((window.screen.width - SIDEBAR_WITH - 64 + 16) / 236) * 3;
  if (window.screen.width < 1213) {
    limitItemByScreen = LIMIT_HOME_ITEMS - 3;
  } else if (window.screen.width < 1600) {
    limitItemByScreen =
      Math.floor((window.screen.width - SIDEBAR_WITH - 64 + 8) / 224) * 3;
  }

  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }
  // const preValueTab = usePrevious(valueTab);

  const fetchData = useCallback(async () => {
    const where: any = { isActive: true };
    const environmentsSelected = getEnvironmentsSelected(environments);
    const raresSelected = getRaresSelected(rares);

    // order by
    if (sortBy === 'lowest-price') {
      where.orderByPrice = 'asc';
    } else if (sortBy === 'highest-price') {
      where.orderByPrice = 'desc';
    } else if (sortBy === 'lowest-wai-remaining') {
      where.orderByMaxWaiCanClaim = 'asc';
    } else if (sortBy === 'highest-wai-remaining') {
      where.orderByMaxWaiCanClaim = 'desc';
    } else if (sortBy === 'lowest-wai-max') {
      where.orderByMaxWaiReward = 'asc';
    } else if (sortBy === 'highest-wai-max') {
      where.orderByMaxWaiReward = 'desc';
    }

    // order
    if (sale === 'order') {
      where.onSale = true;
    } else if (sale === 'not-order') {
      where.onSale = false;
    }

    // itemId
    if (itemId) {
      where.id = Number(itemId);
    }

    // environment
    if (environmentsSelected.length > 0) {
      where.environment = environmentsSelected;
    }

    // rare
    if (raresSelected.length > 0) {
      where.rare = raresSelected;
    }

    // level
    if (rangeLV[0] !== MIN_LEVEL || rangeLV[1] !== MAX_LEVEL) {
      where.level = [Number(rangeLV[0]), Number(rangeLV[1])];
    }
    // type
    if (noInitFilter) {
      if (checkedTypeIds.length > 0) {
        setInitFilter(false);
        where.typeIds = checkedTypeIds;
      }
    } else if (checkedTypeIds.length > 0) {
      where.typeIds = checkedTypeIds;
    } else {
      where.typeIds = filterByCategory[Object.keys(TypeCategory)[valueTab]].map(
        item => item.typeId
      );
    }

    // owner
    if (isOwned) {
      where.yourOwned = account && account.toLowerCase();
    }

    // price
    if (
      (rangePrice[0] !== null && rangePrice[0] !== MIN_PRICE) ||
      (rangePrice[1] !== null && rangePrice[1] !== MAX_PRICE)
    ) {
      where.rangePrice = [
        Number(rangePrice[0]) || MIN_PRICE,
        Number(rangePrice[1] || MAX_PRICE),
      ];
    }

    try {
      if (!isMounted.current) {
        return;
      }
      let whereMore, results, total;
      if (yourOffers) {
        whereMore = { yourOffer: account };
      } else {
        whereMore = where;
      }
      if (yourOldOffers) {
        const res = await getOffersOld({
          limit: 1000,
          category: 'nftItem',
          page,
          buyer: account,
        });

        results = res.results;
        total = res.total;
      } else {
        const res = await getItems({
          first: limitItemByScreen,
          skip: (page - 1) * limitItemByScreen,
          whereMore,
        });
        results = res.results;
        total = res.total;
      }

      if (!isMounted.current) {
        return;
      }
      if (_.isEmpty[results]) {
        setListNftItems([]);
        setLoading(false);
        return;
      }

      setTotalNftItems(total);
      setListNftItems(results);
      setLoading(false);
      return;
    } catch (error) {
      setLoading(false);
      setListNftItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getEnvironmentsSelected,
    environments,
    getRaresSelected,
    rares,
    sortBy,
    sale,
    itemId,
    rangeLV,
    valueTab,
    checkedTypeIds,
    isOwned,
    rangePrice,
    filterByCategory,
    account,
    yourOffers,
    yourOldOffers,
    limitItemByScreen,
    page,
  ]);

  useEffect(() => {
    setLoading(true);
    setListNftItems([]);
    const handler = setTimeout(fetchData, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchData]);

  useEffect(() => {
    const handler = setInterval(() => {
      fetchData();
    }, TIME_FETCH_LIST);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchData]);

  useEffect(() => {
    syncQueryParamsToFilter(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncQueryParamsToFilter]);

  useEffect(() => {
    syncFilterToQueryParams(history);
  }, [
    history,
    syncFilterToQueryParams,
    sortBy,
    sale,
    itemId,
    isOwned,
    yourOffers,
    yourOldOffers,
    environments,
    checkedTypeIds,
    page,
    rangePrice,
  ]);

  const handleChangeOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOwned(event.target.checked);
  };

  const handleChangeOffers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYourOffers(event.target.checked);
  };

  const handleChangeOldOffers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setYourOldOffers(event.target.checked);
  };

  const handleChangePage = (event, pageNumber: number) => {
    setPage(pageNumber);
  };

  return {
    account,
    itemRef,
    totalNftItems,
    isOwned,
    handleChangeOwner,
    yourOffers,
    yourOldOffers,
    handleChangeOffers,
    handleChangeOldOffers,
    listItems,
    page,
    loading,
    handleChangePage,
    limitItemByScreen,
  };
}
