import { action, observable } from 'mobx';
import RootStore from './Root';

export default class NotificationStore {
  @observable public open: boolean;
  @observable public message: string;
  @observable public status: string;
  @observable public desc: string;

  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.open = null;
    this.message = '';
    this.status = '';
    this.desc = '';
  }
  @action public showNotification = message => {
    this.message = message.text;
    this.desc = message.desc;
    // this.status = message.color || 'info'
  };
  @action public onClose = () => {
    this.message = null;
    this.desc = null;
    this.status = null;
  };
}
