import { Contract as EthersProjectContract } from '@ethersproject/contracts';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
// import * as Sentry from '@sentry/react';
import { ethers } from 'ethers';
import { action, computed, observable } from 'mobx';
import { ChainId } from '../constants';
import MiniRpcProvider from '../provider/MiniRpcProvider';
import { networkConnectors } from '../provider/networkConnectors';
import UncheckedJsonRpcSigner from '../provider/UncheckedJsonRpcSigner';
import { web3Window as window } from '../provider/Web3Window';
import RootStore from '../stores/Root';
import { calculateGasMargin, toChecksum } from '../utils';
import { EtherBigNumber } from '../utils/bignumber';
import { BigNumber } from '../utils/bignumber';
import { isTxReverted } from '../utils/helpers';
import snackbarHelper from '../utils/snackbarHelper';
import { ActionResponse, sendAction } from './actions/actions';
export interface ChainData {
  currentBlockNumber: number;
  gasPrice?: number;
}

enum ERRORS {
  UntrackedChainId = 'Attempting to access data for untracked chainId',
  ContextNotFound = 'Specified context name note stored',
  BlockchainActionNoAccount = 'Attempting to do blockchain transaction with no account',
  BlockchainActionNoChainId = 'Attempting to do blockchain transaction with no chainId',
  BlockchainActionNoResponse = 'No error or response received from blockchain action',
  NoWeb3 = 'Error Loading Web3',
}
export enum ContractTypes {
  gameitems = 'Gameitems',
  nftItem = 'NftItem',
  orderContract = 'OrderContract',
  waiToken = 'WaiToken',
  wanankaFarm = 'WanankaFarm',
  wLand = 'WLand',
  MultiCall = 'MultiCall',
  Token = 'Token',
  LandClaim = 'LandClaim',
  LandSale = 'LandSale',
  NFTSale = 'NFTSale',
  PancakePair = 'PancakePair',
  assetStore = 'AssetStore',
  tokenClaim = 'TokenClaim',
  withdrawOperator = 'WithdrawOperator',
  TicketDistributer = 'TicketDistributer',
  NftClaim = 'NftClaim',
}
// type ChainDataMap = ObservableMap<number, ChainData>;
export const schema = {
  Gameitems: require('../abi/GameItems.json').abi,
  NftItem: require('../abi/NftItem.json').abi,
  OrderContract: require('../abi/OrderContract.json').abi,
  WaiToken: require('../abi/WaiToken.json').abi,
  WanankaFarm: require('../abi/WanakaFarm.json').abi,
  WLand: require('../abi/WLand.json').abi,
  MultiCall: require('../abi/MultiCall.json').abi,
  Token: require('../abi/Token.json'),
  LandClaim: require('../abi/LandClaim.json').abi,
  LandSale: require('../abi/LandSale.json').abi,
  NFTSale: require('../abi/NFTSale.json').abi,
  PancakePair: require('../abi/IPancakePair.json').abi,
  AssetStore: require('../abi/AssetStore.json').abi,
  TokenClaim: require('../abi/TokenClaim.json').abi,
  WithdrawOperator: require('../abi/WithdrawOperator.json').abi,
  TicketDistributer: require('../abi/TicketDistributer.json').abi,
  NftClaim: require('../abi/NftClaim.json').abi,
};

export interface TokenMetadata {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  precision?: number;
  isSupported?: boolean;
  allowance?: BigNumber;
  balanceRatio?: BigNumber;
  chainId?: ChainId;
}
export interface ProviderStatus {
  activeChainId: number;
  account: string;
  library: any;
  active: boolean;
  injectedLoaded: boolean;
  injectedActive: boolean;
  injectedChainId: number;
  injectedWeb3: any;
  backUpLoaded: boolean;
  backUpWeb3: any;
  activeProvider: any;
  web3Provider: any;
  error: Error;
}

export default class ProviderStore {
  @observable public chainData: ChainData;
  @observable public providerStatus: ProviderStatus;
  @observable public countFetchUserBlockchainData: number;
  @observable public initializedProvider: boolean;
  @observable public gasPrice: number;
  @observable public navigator: any;
  public estimatedBlocksPerDay: number;
  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.chainData = { currentBlockNumber: -1 } as ChainData;
    this.providerStatus = {} as ProviderStatus;
    this.providerStatus.active = false;
    this.providerStatus.injectedLoaded = false;
    this.providerStatus.injectedActive = false;
    this.providerStatus.backUpLoaded = false;
    this.providerStatus.activeProvider = null;
    this.providerStatus.web3Provider = null;
    this.initializedProvider = false;
    this.countFetchUserBlockchainData = 0;
    this.estimatedBlocksPerDay = 6500;

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
  }

  @computed get currentBlockNumber(): number {
    return this.chainData.currentBlockNumber;
  }

  @computed get activeChainId(): number {
    return this.providerStatus.activeChainId;
  }

  public getCurrentBlockNumber(): number {
    return this.chainData.currentBlockNumber;
  }

  public async loadWeb3Modal(): Promise<void> {
    // let provider = await this.web3Modal.connect();
    console.log(`[Provider] Web3Modal`);
    // if (provider) await this.loadWeb3(provider);
  }

  @action public setCurrentBlockNumber(blockNumber): void {
    this.chainData.currentBlockNumber = blockNumber;
  }
  @action public updateChainData = (data: {
    currentBlockNumber?: number;
    gasPrice?: number;
  }) => {
    this.chainData = Object.assign({}, this.chainData, data);
  };

  @action public setNavigator(v) {
    this.navigator = v;
  }
  @action public setAccount(account): void {
    // const { userStore } = this.rootStore;
    // const changedAccount = !isAddressEqual(
    //   account,
    //   this.providerStatus.account
    // );
    // if (changedAccount && account) {
    // }
    this.providerStatus.account = account;
    // if (changedAccount && account) {
    //   const { userStore } = this.rootStore;
    //   const { handleLoginWallet } = userStore;
    //   const authData = getCookie('auth_data')
    //     ? JSON.parse(getCookie('auth_data'))
    //     : {};
    //   if (
    //     !authData?.accessToken &&
    //     localStorage.getItem('process_login') === 'true'
    //   ) {
    //     localStorage.removeItem('process_login');
    //     handleLoginWallet()
    //       .then(() => {})
    //       .catch(() => {});
    //     // setTimeout(() => {
    //     //   handleLoginWallet
    //     // }, 1000);
    //   }
    // }
  }

  @action public setActiveChainId = (chainId): void => {
    const {
      blockchainFetchStore,
      transactionStore,
      orderStore,
    } = this.rootStore;
    networkConnectors.setCurrentChainId(chainId);
    const changedNetwork = chainId !== this.providerStatus.activeChainId;
    this.providerStatus = Object.assign({}, this.providerStatus, {
      activeChainId: chainId,
    });
    if (changedNetwork && this.providerStatus.account) {
      blockchainFetchStore.blockchainFetch(false);
      orderStore.fetchFeeConfigOnChain();
      transactionStore.loadTxRecords();
      // TODO: load subgraph
    }
  };

  // account is optional
  public getProviderOrSigner(library, account) {
    console.debug('[getProviderOrSigner', {
      library,
      account,
      signer: library.getSigner(account),
    });

    return account
      ? new UncheckedJsonRpcSigner(library.getSigner(account))
      : library;
  }

  public getSigner() {
    return this.getProviderOrSigner(
      this.providerStatus.library,
      this.providerStatus.account
    );
  }

  public getWeb3Provider(): Web3Provider | undefined {
    if (this.providerStatus.activeProvider) {
      const web3Provider = new Web3Provider(
        this.providerStatus.activeProvider,
        'any'
      );
      web3Provider.pollingInterval = 15000;
      return web3Provider;
    }
    return undefined;
  }

  @action public sendTransaction = async (
    contractType: ContractTypes,
    contractAddress: string,
    action: string,
    params: any[],
    _overrides?: any,
    summary?: string
  ): Promise<ActionResponse> => {
    const { activeChainId: chainId, account } = this.providerStatus;
    const { transactionStore } = this.rootStore;
    const overrides = _overrides || {};

    if (!account) {
      throw new Error(ERRORS.BlockchainActionNoAccount);
    }

    if (!chainId) {
      throw new Error(ERRORS.BlockchainActionNoChainId);
    }
    // console.log('abi-' + contractType, this.getContractAbiByType(contractType));
    const contract = this.getContract(contractType, contractAddress, account);

    // if (!overrides.gasLimit) {
    //   const gasEstimate = await this.estimateSafeGas(
    //     contractType,
    //     contractAddress,
    //     action,
    //     params,
    //     overrides
    //   );
    //   if (gasEstimate?.gas) {
    //     overrides.gasLimit = gasEstimate?.gas;
    //   }
    // }

    const response = await sendAction({
      contract,
      action,
      sender: account,
      data: params,
      overrides,
    });

    const { error, txResponse } = response;

    if (error) {
      console.warn('[Send Transaction Error', error);
      if (error?.code !== 4001) {
        console.debug(
          '[@debug sendAction]',
          JSON.stringify({
            method: action,
            address: contractAddress,
            sender: account,
            args: params,
            overrides: {
              value: overrides?.value?.toString(),
              gasLimit: overrides?.gasLimit?.toString(),
            },
            error: error?.message,
          })
        );
      }
      // Sentry.captureException(error?.message);
    } else if (txResponse) {
      snackbarHelper.toast({ content: summary, txHash: txResponse?.hash });
      transactionStore.addTransactionRecord(account, txResponse, summary);
    } else {
      throw new Error(ERRORS.BlockchainActionNoResponse);
    }

    return response;
  };

  @action public sendTransactionWithEstimatedGas = async (
    contractType: ContractTypes,
    contractAddress: string,
    action: string,
    params: any[],
    overrides?: any,
    summary?: string
  ): Promise<ActionResponse> => {
    const safeGasEstimate: {
      gas?: EtherBigNumber;
      error?: Error;
    } = await this.estimateSafeGas(
      contractType,
      contractAddress,
      action,
      params,
      overrides
    );

    if (!EtherBigNumber.isBigNumber(safeGasEstimate?.gas)) {
      let errorMessage: string = 'This transaction would fail.';
      if (safeGasEstimate?.error) {
        errorMessage = safeGasEstimate.error?.message;
      }
      console.error(errorMessage);
      return { error: new Error(errorMessage) } as ActionResponse;
    } else {
      overrides.gasLimit = safeGasEstimate.gas;

      try {
        return this.sendTransaction(
          contractType,
          contractAddress,
          action,
          params,
          overrides,
          summary
        );
      } catch (e) {
        if (!e || isTxReverted(e)) {
          return e;
        }
        return {
          error: new Error('Oops, something went wrong'),
        } as ActionResponse;
      }
    }
  };

  @action public handleNetworkChanged = async (
    networkId: string | number
  ): Promise<void> => {
    console.log(
      `[Provider] Network change: ${networkId} ${this.providerStatus.active}`
    );
    // network change could mean switching from injected to backup or vice-versa
    if (this.providerStatus.active) {
      await this.loadWeb3();
      const { blockchainFetchStore, orderStore } = this.rootStore;
      blockchainFetchStore.blockchainFetch(true);
      orderStore.fetchFeeConfigOnChain();
    }
  };

  @action public handleClose = async (): Promise<void> => {
    console.log(`[Provider] HandleClose() ${this.providerStatus.active}`);
    if (this.providerStatus.active) {
      await this.loadWeb3();
    }
  };

  @action public handleAccountsChanged = (accounts: string[]): void => {
    console.log(`[Provider] Accounts changed`);
    if (accounts.length === 0) {
      this.handleClose();
    } else {
      const { blockchainFetchStore, orderStore } = this.rootStore;
      this.setAccount(accounts[0]);
      blockchainFetchStore.blockchainFetch(true);
      orderStore.fetchFeeConfigOnChain();
    }
  };

  @action public loadProvider = async provider => {
    try {
      // remove any old listeners
      if (
        this.providerStatus.activeProvider &&
        this.providerStatus.activeProvider.on
      ) {
        console.log(`[Provider] Removing Old Listeners`);
        this.providerStatus.activeProvider.removeListener(
          'chainChanged',
          this.handleNetworkChanged
        );
        this.providerStatus.activeProvider.removeListener(
          'accountsChanged',
          this.handleAccountsChanged
        );
        this.providerStatus.activeProvider.removeListener(
          'close',
          this.handleClose
        );
        this.providerStatus.activeProvider.removeListener(
          'networkChanged',
          this.handleNetworkChanged
        );
      }

      if (this.providerStatus.library && this.providerStatus.library.close) {
        console.log(`[Provider] Closing Old Library.`);
        await this.providerStatus.library.close();
      }

      const web3 = new ethers.providers.Web3Provider(provider);

      if ((provider as any).isMetaMask) {
        console.log(`[Provider] MetaMask Auto Refresh Off`);
        (provider as any).autoRefreshOnNetworkChange = false;
      }

      if (provider.on) {
        console.log(`[Provider] Subscribing Listeners`);
        provider.on('chainChanged', this.handleNetworkChanged); // For now assume network/chain ids are same thing as only rare case when they don't match
        provider.on('accountsChanged', this.handleAccountsChanged);
        provider.on('disconnect', this.handleClose);
        provider.on('chainChanged', this.handleNetworkChanged);
      }

      const network = await web3.getNetwork();
      const accounts = await web3.listAccounts();
      let account = null;
      if (accounts.length > 0) {
        account = accounts[0];
      }
      this.providerStatus.injectedLoaded = true;
      this.providerStatus.injectedChainId = network.chainId;
      this.providerStatus.injectedWeb3 = web3;
      this.providerStatus.activeProvider = provider;
      const web3Provider = new Web3Provider(provider, 'any');
      web3Provider.pollingInterval = 15000;
      this.providerStatus.web3Provider = web3Provider;
      this.providerStatus.library = web3;
      this.setAccount(account);
      console.log(`[Provider] Injected provider loaded.`);
    } catch (err) {
      console.error(`[Provider] Injected Error`, err);
      this.providerStatus.injectedLoaded = false;
      this.providerStatus.injectedChainId = null;
      this.setAccount(null);
      this.providerStatus.library = null;
      this.providerStatus.active = false;
      this.providerStatus.activeProvider = null;
      this.providerStatus.web3Provider = null;
    }
  };

  @action public loadWeb3 = async (provider = null) => {
    /*
    Handles loading web3 provider.
    Injected web3 loaded and active if chain Id matches.
    Backup web3 loaded and active if no injected or injected chain Id not correct.
    */
    const windowEthereum = window.ethereum || window.BinanceChain;
    if (provider === null && windowEthereum) {
      console.log(`[Provider] Loading Injected Provider`);
      await this.loadProvider(windowEthereum);
    } else if (provider) {
      console.log(`[Provider] Loading Provider`);
      await this.loadProvider(provider);
    }

    // If no injected provider or inject provider is wrong chain fall back to Infura
    if (
      !this.providerStatus.injectedLoaded ||
      !networkConnectors.isChainIdSupported(this.providerStatus.injectedChainId)
    ) {
      console.log(
        `[Provider] Reverting To Backup Provider.`,
        this.providerStatus
      );
      try {
        const networkUrl = networkConnectors.getBackupUrl();
        const web3 = new ethers.providers.JsonRpcProvider(networkUrl);
        const network = await web3.getNetwork();
        this.providerStatus.injectedActive = false;
        this.providerStatus.backUpLoaded = true;
        this.setActiveChainId(network.chainId);
        this.setAccount(null);
        this.providerStatus.backUpWeb3 = web3;
        this.providerStatus.library = web3;
        this.providerStatus.activeProvider = 'backup';
        const provider = new MiniRpcProvider(network.chainId, networkUrl);
        const web3Provider = new Web3Provider(
          (provider as unknown) as ExternalProvider,
          'any'
        );
        web3Provider.pollingInterval = 15000;
        this.providerStatus.web3Provider = web3Provider;
        console.log(`[Provider] BackUp Provider Loaded & Active`);
      } catch (err) {
        console.error(`[Provider] loadWeb3 BackUp Error`, err);
        this.providerStatus.injectedActive = false;
        this.providerStatus.backUpLoaded = false;
        this.setActiveChainId(-1);
        this.setAccount(null);
        this.providerStatus.backUpWeb3 = null;
        this.providerStatus.library = null;
        this.providerStatus.active = true; // false;
        this.providerStatus.error = new Error(ERRORS.NoWeb3);
        this.providerStatus.activeProvider = null;
        this.providerStatus.web3Provider = null;
        return;
      }
    } else {
      console.log(`[Provider] Injected provider active.`);
      this.providerStatus.library = this.providerStatus.injectedWeb3;
      this.setActiveChainId(this.providerStatus.injectedChainId);
      // Only fetch if not first page load as could be change of provider
      this.providerStatus.injectedActive = true;
    }

    this.providerStatus.active = true;
    this.initializedProvider = true;
    console.log(`[Provider] Provider Active.`, this.providerStatus);
  };

  public getContractAbiByType = (type: ContractTypes) => {
    return schema[type];
  };

  public getContract(
    type: ContractTypes | any[],
    address: string,
    signerAccount?: string
  ): ethers.Contract | undefined {
    if (!address) {
      return undefined;
    }
    const { library } = this.providerStatus;
    const abi = Array.isArray(type) ? type : schema[type];
    if (signerAccount) {
      return new ethers.Contract(
        address,
        abi,
        this.getProviderOrSigner(library, signerAccount)
      );
    }

    return new ethers.Contract(address, abi, library);
  }

  public getContractV5(
    type: ContractTypes | any[],
    address: string,
    signerAccount?: string
  ): EthersProjectContract {
    const { web3Provider } = this.providerStatus;
    const abi = Array.isArray(type) ? type : schema[type];

    const providerOrSigner = signerAccount
      ? web3Provider.getSigner(signerAccount).connectUnchecked()
      : web3Provider;

    return new EthersProjectContract(address, abi, providerOrSigner as any);
  }

  public estimateSafeGas = (
    type: ContractTypes | any[],
    contractAddress: string,
    method: string,
    args: any[],
    overrides?: any
  ): Promise<{ gas?: EtherBigNumber; error?: Error }> => {
    console.log(`[@estimate: ${method}]`, contractAddress, args, overrides);
    const { account } = this.providerStatus;
    const contract = this.getContractV5(type, contractAddress, account);
    if (!contract.estimateGas[method]) {
      return undefined;
    }
    return contract.estimateGas[method](...args, overrides || {})
      .then(gas => {
        return { gas: calculateGasMargin(gas.toString()) };
      })
      .catch(error => {
        if (!contract.callStatic) {
          console.debug(
            `estimateGas failed`,
            contractAddress,
            method,
            args,
            error
          );
          return { error };
        }
        console.debug(
          'Gas estimate failed, trying eth_call to extract error',
          contractAddress,
          method,
          args,
          `value: ${overrides?.value?.toString()}`
        );

        return contract.callStatic[method](...args, overrides || {})
          .then(result => {
            console.debug(
              'Unexpected successful call after failed estimate gas',
              error,
              result
            );
            return {
              error: new Error(
                'Unexpected issue with estimating the gas. Please try again.'
              ),
            };
          })
          .catch(callError => {
            console.error(
              'Call threw error',
              contractAddress,
              method,
              args,
              callError
            );
            const errorReason =
              callError.reason || callError.data?.message || callError.message;
            // Sentry.captureException(errorReason);
            return { error: errorReason };
          });
      });
  };

  public getContractMetaData = () => {
    const contracts = networkConnectors.getContracts(
      this.providerStatus.activeChainId
    );
    const multiCall = networkConnectors.getMultiAddress(
      this.providerStatus.activeChainId
    );
    const { tokens: _tokens } = networkConnectors.getAssets();
    const tokens = { ...(_tokens || {}) };

    const contractMetadata = {
      tokens: [] as TokenMetadata[],
      wanaFarmContract: contracts.WanaFarmContract,
      waiTokenContract: contracts.WaiTokenContract,
      wLandContract: contracts.WLandContract,
      wNFTContract: contracts.WNFTContract,
      wFTContract: contracts.WFTContract,
      orderContract: contracts.OrderContract,
      oldOrderContract: contracts.OldOrderContract,
      multiCallContract: multiCall,
      ClaimLandContract: contracts.ClaimLandContract,
      LandSaleContract: contracts.LandSaleContract,
      NFTSaleContract: contracts.NFTSaleContract,
      assetStoreContract: contracts.AssetStoreContract,
      TokenClaimWAIContract: contracts.TokenClaimWAIContract,
      TokenClaimWANAContract: contracts.TokenClaimWANAContract,
      WithdrawOperatorContract: contracts.WithdrawOperatorContract,
      TicketDistributerContract: contracts.TicketDistributerContract,
    };
    const tokensObjId: { [address: string]: 1 } = {};

    Object.keys(tokens).forEach(tokenAddress => {
      const token = tokens[tokenAddress];
      const { address, symbol, name, precision } = token;
      if (tokensObjId[tokenAddress?.toLowerCase()]) {
        return;
      }
      tokensObjId[tokenAddress?.toLowerCase()] = 1;
      contractMetadata.tokens.push({
        address: toChecksum(address),
        symbol,
        name,
        decimals: token.decimals || 18,
        precision,
        isSupported: true,
        allowance: new BigNumber(0),
      });
    });
    return contractMetadata;
  };

  @action public fetchUserBlockchainData = async (account: string) => {
    const { tokenStore, transactionStore } = this.rootStore;

    // console.debug('[Provider] fetchUserBlockchainData', {
    //   account,
    // });
    transactionStore.checkPendingTransactions(account);
    await tokenStore.fetchBalancerTokenData(
      account,
      tokenStore.getTrackedTokenAddresses()
    );

    await tokenStore.fetchBalancerTokenERC721Data(
      account,
      tokenStore.getTrackedToken721Addresses()
    );

    await tokenStore.getUSDPriceWana();

    this.countFetchUserBlockchainData = this.countFetchUserBlockchainData + 1;
  };
}
