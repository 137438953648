import React from 'react';

const IconTypeCH = ({ isActive, style }) => {
  const color = isActive ? '#4dbb8d' : '#b3b6c2';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.69 28.43"
      style={style}
    >
      <title>CH</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={color}
            d="M11.66.33a4.18,4.18,0,0,0,4,.7c2.5-.8,3.3-.4,4.3,2.1.1.3.2.5.3.8.8,2.2,1.2,2.5,3.6,2.6h1.9c.9,0,1.7.2,1.9,1.2s-.6,1.3-1.3,1.7a29.94,29.94,0,0,1-22,1.8,18,18,0,0,1-3.3-1,2.65,2.65,0,0,1-.6-.5,1.68,1.68,0,0,1,.9-2.8h0a9.42,9.42,0,0,1,6.6.4,7.69,7.69,0,0,0,5.6.19c-2.2-.39-4.5-.89-7-1.3.9-2.3,1.3-4.4,3-5.8A1.68,1.68,0,0,1,11.66.33Z"
          />
          <path
            fill={color}
            d="M6.76,12.52a25,25,0,0,0,14.1-.39c1.2,3.8-.9,9-4.3,10.8a5.68,5.68,0,0,1-6.9-1.21C7.06,19.22,6.36,16.12,6.76,12.52Z"
          />
          <path
            fill={color}
            d="M17.56,23.62c.4.9.6,1.5.9,2.3-1.4.8-2.7,1.7-4,2.4a1.72,1.72,0,0,1-1.4-.1c-1.3-.6-2.5-1.4-3.9-2.2.3-.8.6-1.5,1-2.4C12.56,25.93,15.06,26,17.56,23.62Z"
          />
        </g>
      </g>
    </svg>
  );
};
export default IconTypeCH;
