import { Info } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { BoxSpacingProps } from '../../theme/styled';
import { Link } from 'react-router-dom';

const ExternalTextWrapper = styled.span`
  display: flex;
  align-items: flex-start;
`;

const ArrowOpenInNew = styled.span`
  font-size: 11px;
  font-family: system-ui;
`;

interface ExternalTextProps {
  children: React.ReactNode;
  // as?: string | React.ReactElement | React.ReactFragment;
  [attr: string]: any;
}

export function ExternalText({ children, ...props }: ExternalTextProps) {
  return (
    <ExternalTextWrapper {...props}>
      {children}
      <ArrowOpenInNew>↗</ArrowOpenInNew>
    </ExternalTextWrapper>
  );
}

export const DottedSpacer = styled.div`
  flex: 1;
  height: 1px;
  /*
  background-image: ${({ theme }) =>
    theme.dark
      ? `
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0.15) 20%,
      rgba(255, 255, 255, 0) 0%
    )
  `
      : `
    linear-gradient(
      to right,
      rgba(0, 0, 0, 0.15) 20%,
      rgba(0, 0, 0, 0) 0%
    )
  `};
  background-position: center bottom;
  background-size: 0.5rem 0.0625rem;
  background-repeat: repeat-x;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  */
  position: relative;
  top: -0.25rem;
  margin-top: auto;
`;

export const InfoIcon = props => {
  return <Info {...props} />;
};

export const LineBreak = styled.div<BoxSpacingProps>`
  ${({ theme }) => theme.boxSpacing}
  height: 1px;
  background-color: ${({ theme }) => theme.borderColor};
`;

export const Container = styled.div`
  max-width: 1156px;
  padding: 1.5rem 12px;
  width: 100%;
  margin: auto;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const NoDataText = styled(({ children, ...props }) => {
  return <div {...props}>{children || 'No data to display'}</div>;
})`
  text-align: center;
  color: #bebfc4;
  font-weight: 500;
  font-size: ${({ size }) => size || '16px'};
`;

interface TagIDProps {
  children: React.ReactNode;
  color: string;
  status?: boolean;
  to?: string | number;
  style?: any;
}

export const TagID = styled(({ children, to, ...props }: TagIDProps) => {
  if (to) {
    return (
      <div {...props}>
        <Link
          to={{
            pathname: to as string,
          }}
        >
          {children}
        </Link>
      </div>
    );
  }
  return <div {...props}>{children}</div>;
})`
  background-color: ${({ color }) => color || '#73757b'};
  padding: ${({ status }) => (status ? '4px 8px 4px' : '2px 8px 2px')};
  border-radius: 4px;
  color: #fafafa;
  font-size: 14px;
  line-height: 16px;
  min-width: 50px;
  font-weight: 500;
  max-width: fit-content;
  white-space: nowrap;
  a {
    color: inherit;
    text-decoration: none;
  }
`;
