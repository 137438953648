import { CONTRACTS } from '../../configs/addresses';
import { ChainId } from '../../constants';

export interface TokenInfo {
  address: string;
  name?: string;
  symbol: string;
  decimals: number;
  image?: string;
}

export interface ProtocolToken {
  [symbol: string]: TokenInfo;
}

export const PROTOCOL_TOKENS: { [chainId in ChainId]?: ProtocolToken } = {
  [ChainId.CHAPEL]: {
    WANA: {
      address: CONTRACTS[ChainId.CHAPEL].WanaFarmContract,
      name: 'WANA',
      symbol: 'WANA',
      decimals: 18,
      image: '/images/logo-token-wana.png',
    },
    WAI: {
      address: CONTRACTS[ChainId.CHAPEL].WaiTokenContract,
      name: 'WAI',
      symbol: 'WAI',
      decimals: 18,
      image: '/images/logo-token-wai.png',
    },
    LAND: {
      address: CONTRACTS[ChainId.CHAPEL].WLandContract,
      name: 'LAND',
      symbol: 'LAND',
      decimals: 0,
      image: '/images/logo-token-land.png',
    },
    ITEM: {
      address: CONTRACTS[ChainId.CHAPEL].WNFTContract,
      name: 'ITEM',
      symbol: 'ITEM',
      decimals: 0,
      image: '/images/logo-token-item.png',
    },
  },
  [ChainId.MAINNET]: {
    WANA: {
      address: CONTRACTS[ChainId.MAINNET].WanaFarmContract,
      name: 'Wanaka Farm',
      symbol: 'WANA',
      decimals: 18,
      image: '/images/logo-token-wana.png',
    },
    WAI: {
      address: CONTRACTS[ChainId.MAINNET].WaiTokenContract,
      name: 'WAI',
      symbol: 'WAI',
      decimals: 18,
      image: '/images/logo-token-wai.png',
    },
    LAND: {
      address: CONTRACTS[ChainId.MAINNET].WLandContract,
      name: 'LAND',
      symbol: 'LAND',
      decimals: 0,
      image: '/images/logo-token-land.png',
    },
    ITEM: {
      address: CONTRACTS[ChainId.MAINNET].WNFTContract,
      name: 'ITEM',
      symbol: 'ITEM',
      decimals: 0,
      image: '/images/logo-token-item.png',
    },
  },
};

export const WHITELISTED_TOKENS: {
  [chainId in ChainId]?: { tokens: any; untrusted: string[] };
} = {
  [ChainId.MAINNET]: require('./registry.homestead.json'),
  [ChainId.CHAPEL]: require('./registry.chapel.json'),
};
