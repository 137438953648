import {
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TIME_FETCH_OFFER, TIME_FETCH_SALE } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useAuth from '../../hooks/useAuth';
import useInterval from '../../hooks/useInterval';
import { getEtherscanLink } from '../../utils';
import {
  amountFormat,
  blockToNiceTime,
  bnum,
  fromWei,
  isAddressEqual,
  truncateAddress,
  niceTime,
} from '../../utils/helpers';
import {
  getOfferHistory,
  getOfferOldHistory,
  getSaleHistory,
  LIMIT_HISTORY_ITEMS,
} from '../../utils/subgraph';
import ArrowPaginate from '../ArrowPaginate';
import { ChipByStatus } from '../Chip';
import PopupTakeOffer from './PopupTakeOffer';
import { Container, Row, NoDataText } from '../../components/Common';

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const StyledTableContainer = styled(({ ...props }: any) => {
  return <TableContainer {...props} />;
})`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 12px;
  }
  margin-bottom: 1rem;
  white-space: nowrap;
  .number-decimal {
    color: #98a0b9;
  }
`;

const StyledWrapTabs = styled.div`
  width: 100%;
`;

const CommonTabs = styled(props => (
  <Tabs classes={{ indicator: 'indicator' }} {...props} />
))`
  & .indicator {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const CommonTab = styled(({ index, ...props }) => (
  <Tab
    classes={{ selected: 'selected' }}
    id={`councilTab-tab-${index}`}
    aria-controls={`councilTab-tabpanel-${index}`}
    {...props}
  />
))`
  && {
    font-size: 1em;
    font-weight: 700;
    text-transform: none;
    min-width: unset;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.offer-active {
    background: #494d65;
  }
`;
const StyledTableCellAction = styled(TableCell)`
  && {
    padding: 7px 16px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`offer-detail-tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `common-tab-${index}`,
    'aria-controls': `offer-detail-tabpanel-${index}`,
  };
}

const HistoryOffer = observer(
  ({ category, data, handleCheckOffer, isOffer }) => {
    const {
      root: { providerStore, orderStore, tokenStore },
    } = useStores();
    const [isOpenTakeOffer, setPopupTakeOffer] = useState<boolean>(false);
    const [isOldOffer, setOldOffer] = useState<boolean>(false);
    const [valueTab, setValueTab] = useState(0);
    const [historyOffers, setHistoryOffers] = useState([]);
    const [historyOldOffers, setHistoryOldOffers] = useState([]);
    const [oldHistoryPage, setOldHistoryPage] = useState(1);
    const [historySales, setHistorySales] = useState([]);
    const [dataOffer, setDataOffer] = useState({});
    const [salePage, setSalePage] = useState(1);
    const [historyPage, setHistoryPage] = useState(1);
    const checkAuth = useAuth();
    const { account } = providerStore.providerStatus;
    const { currentBlockNumber } = providerStore;
    const { itemId, id, owner } = data;

    function handleChangeTab(e, value) {
      setValueTab(value);
    }

    function onTakeOffer(row) {
      const isAuth = checkAuth();
      if (!isAuth) {
        return;
      }
      setDataOffer(row);
      setPopupTakeOffer(true);
    }

    function getHistoryOffer() {
      if (!id) {
        return;
      }
      getOfferHistory({ itemId, category, page: historyPage })
        .then(res => {
          const { results } = res;
          setHistoryOffers(results);
        })
        .catch(() => {
          setHistoryOffers([]);
        });
    }

    function getHistoryOldOffer() {
      if (!itemId) {
        return;
      }
      getOfferOldHistory({
        itemId,
        category,
        size: 100,
        page: oldHistoryPage,
        account,
      })
        .then(res => {
          const results = res;
          setHistoryOldOffers(results);
          if (results.length > 0) {
            setOldOffer(true);
          }
        })
        .catch(() => {
          setHistoryOldOffers([]);
        });
    }

    function getHistorySale() {
      if (!id) {
        return;
      }

      getSaleHistory({ itemId, page: salePage, category })
        .then(res => {
          const { results } = res;
          setHistorySales(results);
        })
        .catch(() => {
          setHistorySales([]);
        });
    }

    useEffect(() => {
      getHistoryOffer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, historyPage]);

    useEffect(() => {
      getHistorySale();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, salePage]);

    useEffect(() => {
      getHistoryOldOffer();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, oldHistoryPage]);

    useInterval(
      getHistoryOffer,
      itemId && id && valueTab === 0 ? TIME_FETCH_OFFER : null,
      false
    );
    useInterval(
      getHistorySale,
      itemId && id && valueTab === 1 ? TIME_FETCH_SALE : null,
      false
    );

    useInterval(
      getHistoryOldOffer,
      itemId && id && valueTab === 3 ? TIME_FETCH_OFFER : null,
      false
    );

    const isOwner = isAddressEqual(providerStore.providerStatus.account, owner);

    function handleChangePageSale(page) {
      setSalePage(page);
    }

    function handleChangeHistoryPage(page) {
      setHistoryPage(page);
    }

    function handleChangeOldHistoryPage(page) {
      setOldHistoryPage(page);
    }

    const handleCancelOffer = contractAddress => {
      const isAuth = checkAuth();
      if (!isAuth) {
        return;
      }
      orderStore
        .cancelOffer({
          contractAddress,
          itemId,
        })
        .then(() => {
          handleCheckOffer();
        });
    };

    const handleCancelOldOffer = contractAddress => {
      const isAuth = checkAuth();
      if (!isAuth) {
        return;
      }

      orderStore
        .cancelOldOffer({
          contractAddress,
          itemId,
        })
        .then(() => {
          setOldOffer(false);
        });
    };
    const showAction = isOffer || isOwner;

    return (
      <Container>
        <Row>
          <StyledWrapTabs>
            <CommonTabs
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="tabs"
              classes={{ indicator: 'indicator' }}
            >
              <CommonTab label={<Title>List offers</Title>} {...a11yProps(0)} />
              <CommonTab
                label={<Title>Sale history</Title>}
                {...a11yProps(1)}
              />
              <CommonTab
                label={<Title>Old Offer(s) List </Title>}
                {...a11yProps(3)}
              />
            </CommonTabs>

            <TabPanel value={valueTab} index={0}>
              <div style={{ width: '100%' }}>
                <StyledTableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">TxHash</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center">Time</TableCell>
                        {showAction ? (
                          <TableCell align="right">Action</TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyOffers.map((row, index) => (
                        <StyledTableRow
                          key={index}
                          className={
                            isAddressEqual(account, row.buyer)
                              ? 'offer-active'
                              : null
                          }
                        >
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.txHash,
                                'transaction'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.txHash || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.buyer,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.buyer || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.seller,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.seller || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              {
                                amountFormat(
                                  fromWei(row.price || '0'),
                                  3
                                )?.split('.')[0]
                              }
                              <span className="number-decimal">
                                {amountFormat(
                                  fromWei(row.price || '0'),
                                  3
                                )?.split('.')[1] &&
                                  `.${
                                    amountFormat(
                                      fromWei(row.price || '0'),
                                      3
                                    )?.split('.')[1]
                                  }`}{' '}
                              </span>
                              &nbsp;WANA
                            </div>
                            <div style={{ color: '#98a0b9' }}>
                              {row.price && tokenStore.priceWANA
                                ? `≈ $${amountFormat(
                                    bnum(
                                      fromWei(row.price || '0')
                                    ).multipliedBy(bnum(tokenStore.priceWANA)),
                                    0
                                  )}`
                                : ''}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {blockToNiceTime(
                              currentBlockNumber,
                              row.blockNumber
                            )}
                          </TableCell>
                          {showAction ? (
                            <StyledTableCellAction align="right">
                              {isOwner &&
                              row.status === 'open' &&
                              !isAddressEqual(account, row.buyer) ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    onTakeOffer(row);
                                  }}
                                  size="small"
                                >
                                  Take Offer
                                </Button>
                              ) : isAddressEqual(account, row.buyer) &&
                                row.status === 'open' ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleCancelOffer(data.contractAddress)
                                  }
                                  size="small"
                                >
                                  CANCEL OFFER
                                </Button>
                              ) : null}
                            </StyledTableCellAction>
                          ) : null}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                {!historyOffers ||
                (historyOffers && historyOffers.length === 0) ? (
                  <NoDataText styled={{ fontSize: '16px' }} />
                ) : null}
                <ArrowPaginate
                  page={historyPage}
                  onChange={handleChangeHistoryPage}
                  size={historyOffers.length}
                  limit={LIMIT_HISTORY_ITEMS}
                />
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <div style={{ width: '100%', padding: '10px' }}>
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">TxHash</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center">Time</TableCell>
                        <TableCell align="center">Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historySales.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.txHash,
                                'transaction'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.txHash || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.from,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.from || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.to,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.to || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            {row.price &&
                            row.type !== 'wLandTransferred' &&
                            row.type !== 'nftItemTransferred' ? (
                              <>
                                <div>
                                  {
                                    amountFormat(
                                      fromWei(row.price || '0'),
                                      3
                                    )?.split('.')[0]
                                  }
                                  <span className="number-decimal">
                                    {amountFormat(
                                      fromWei(row.price || '0'),
                                      3
                                    )?.split('.')[1] &&
                                      `.${
                                        amountFormat(
                                          fromWei(row.price || '0'),
                                          3
                                        )?.split('.')[1]
                                      }`}{' '}
                                  </span>
                                  &nbsp;WANA
                                </div>
                                <div style={{ color: '#98a0b9' }}>
                                  {row.price && tokenStore.priceWANA
                                    ? `≈ $${amountFormat(
                                        bnum(
                                          fromWei(row.price || '0')
                                        ).multipliedBy(
                                          bnum(tokenStore.priceWANA)
                                        ),
                                        0
                                      )} `
                                    : ''}
                                </div>
                              </>
                            ) : (
                              '__'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {blockToNiceTime(
                              currentBlockNumber,
                              row.blockNumber
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <ChipByStatus status={row.type} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                {!historySales ||
                (historySales && historySales.length === 0) ? (
                  <NoDataText styled={{ fontSize: '16px' }} />
                ) : null}
                <ArrowPaginate
                  page={salePage}
                  onChange={handleChangePageSale}
                  size={historySales.length}
                  limit={LIMIT_HISTORY_ITEMS}
                />
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <div style={{ width: '100%' }}>
                <StyledTableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">TxHash</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center">Time</TableCell>
                        {showAction || isOldOffer ? (
                          <TableCell align="right">Action</TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyOldOffers.map((row, index) => (
                        <StyledTableRow
                          key={index}
                          className={
                            isAddressEqual(account, row.buyer)
                              ? 'offer-active'
                              : null
                          }
                        >
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.txHash,
                                'transaction'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.txHash || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.buyer,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.buyer || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={getEtherscanLink(
                                undefined,
                                row.seller,
                                'address'
                              )}
                              target={'_blank'}
                              style={{ color: '#fff' }}
                            >
                              {truncateAddress(row.seller || '')}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              {
                                amountFormat(
                                  fromWei(row.price || '0'),
                                  3
                                )?.split('.')[0]
                              }
                              <span className="number-decimal">
                                {amountFormat(
                                  fromWei(row.price || '0'),
                                  3
                                )?.split('.')[1] &&
                                  `.${
                                    amountFormat(
                                      fromWei(row.price || '0'),
                                      3
                                    )?.split('.')[1]
                                  }`}{' '}
                              </span>
                              &nbsp;WANA
                            </div>
                            <div style={{ color: '#98a0b9' }}>
                              {row.price && tokenStore.priceWANA
                                ? `≈ $${amountFormat(
                                    bnum(
                                      fromWei(row.price || '0')
                                    ).multipliedBy(bnum(tokenStore.priceWANA)),
                                    0
                                  )}`
                                : ''}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {niceTime(row.createdAt)}
                          </TableCell>
                          {showAction || isOldOffer ? (
                            <StyledTableCellAction align="right">
                              {isAddressEqual(account, row.buyer) &&
                              row.status === 'open' ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleCancelOldOffer(row?.nftAddress)
                                  }
                                  size="small"
                                >
                                  CANCEL OFFER
                                </Button>
                              ) : null}
                            </StyledTableCellAction>
                          ) : null}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                {!historyOldOffers ||
                (historyOldOffers && historyOldOffers.length === 0) ? (
                  <NoDataText styled={{ fontSize: '16px' }} />
                ) : null}
                <ArrowPaginate
                  page={historyPage}
                  onChange={handleChangeOldHistoryPage}
                  size={historyOldOffers.length}
                  limit={LIMIT_HISTORY_ITEMS}
                />
              </div>
            </TabPanel>
          </StyledWrapTabs>
        </Row>
        <PopupTakeOffer
          open={isOpenTakeOffer}
          setOpen={setPopupTakeOffer}
          dataOffer={dataOffer}
          dataNft={data}
          type={category}
        />
      </Container>
    );
  }
);

export default HistoryOffer;
