import { Button, IconButton, Modal } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';

import { CloseRounded } from '@material-ui/icons';

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledNotice = styled.p`
  text-align: center;
  color: #ccc;
  span {
    color: #fff;
  }
`;
const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
`;

const PopupConfirmEmail = ({ onConfirm, loading, onClose, open, email }) => {
  const [code, setCode] = useState<number | ''>('');
  const verifyFormRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    verifyFormRef.current.isFormValid().then(res => {
      if (res) {
        onConfirm(code);
      }
    });
  }
  function handleChangeCode(e) {
    setCode(e.target.value);
  }

  function handelClose() {
    onClose();
  }

  useEffect(() => {
    if (open) {
      setCode('');
    }
  }, [open]);

  return (
    <StyledModal open={open} onClose={handelClose}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={handelClose}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <h2>Verify Code</h2>
          <ValidatorForm
            onSubmit={handleSubmit}
            ref={verifyFormRef}
            style={{ with: '100%' }}
          >
            <StyledNotice>
              We have sent you a verify code to this email <br />
              <span>{email}.</span> <br /> Please check then enter to this box
              below:
              <br />
            </StyledNotice>
            <StyledWrap>
              <TextValidator
                validators={['required', 'minStringLength:6']}
                errorMessages={[
                  'This field is required',
                  'This field must be 6 digit',
                ]}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                label="Code"
                type="number"
                inputProps={{ maxLength: 6 }}
                value={code}
                onChange={handleChangeCode}
              />
              <div>
                <Button
                  style={{
                    marginLeft: '10px',
                    marginTop: '9px',
                    minHeight: '48px',
                  }}
                  disabled={loading}
                  type="submit"
                  fullWidth={true}
                  color="primary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </div>
            </StyledWrap>
          </ValidatorForm>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
};

export default PopupConfirmEmail;
