import React from 'react';

const IconTypeItem = ({ isActive, style }) => {
  const color = isActive ? '#4dbb8d' : '#b3b6c2';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.4 25.72"
      style={style}
    >
      <title>Item</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={color}
            d="M10.93,8.23c.1-1.3.1-2.3.2-3.3s.3-1.1,1.1-.6c-.3,1.3-.7,2.8-1,4,.2,0,0,0,.3-.1.7-.3,1.3-.5,2-.8a14.12,14.12,0,0,1,2.71-.9,5.29,5.29,0,0,1,5.6,3.9,12.61,12.61,0,0,1,.3,6.4,20.81,20.81,0,0,1-2.61,6.4,4.49,4.49,0,0,1-5,2.4,21.69,21.69,0,0,0-7.19,0,3.89,3.89,0,0,1-3.81-1.7,17.28,17.28,0,0,1-3.19-7.6,11.42,11.42,0,0,1,.09-5.8c.91-2.8,3.1-4.5,5.81-4.2a9,9,0,0,1,2.6.9A20,20,0,0,0,10.93,8.23Z"
          />
          <path
            fill={color}
            d="M2.63,0c4.1-.3,7.6,1.9,7.4,5.9-1.2-.1-2.4-.2-3.6-.4a3.34,3.34,0,0,1-2.7-1.7A8.37,8.37,0,0,1,2.63,0Z"
          />
          <path
            fill={color}
            d="M20.13,2.13A4,4,0,0,1,17.52,5c-1,.3-2,.5-2.89.8-.71.2-1.3.4-2,.6A5.68,5.68,0,0,1,20.13,2.13Z"
          />
        </g>
      </g>
    </svg>
  );
};
export default IconTypeItem;
