import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { networkConnectors } from '../provider/networkConnectors';
import {
  NftMetaDataProps,
  OfferProps,
  SaleHistoryProps,
  WlandMetaDataProps,
} from '../types';
import { apiRequest } from '../utils/apiRequest';

export const LIMIT_HISTORY_ITEMS = 5;
export const LIMIT_HOME_ITEMS = 18;

export const client = new ApolloClient({
  uri: networkConnectors.getSubgraphUrl(),
  cache: new InMemoryCache({}),
});

export const clientLottery = new ApolloClient({
  uri: networkConnectors.getSubgraphLotteryUrl(),
  cache: new InMemoryCache({}),
});

const ORDER_ENTITY = {
  id: true,
  orderId: true,
  itemId: true,
  owner: true,
  buyer: true,
  price: true,
  status: true,
};

const WLAND_ENTITY = {
  id: true,
  itemId: true,
  name: true,
  salePrice: true,
  level: true,
  environment: true,
  owner: true,
  image: true,
  metaDataUrl: true,
  birth: true,
  description: true,
  activeOrder: ORDER_ENTITY,
  isPacked: true,
  rare: true,
  season: true,
  increaseMutantRate: true,
  timeReduce: true,
  onSale: true,
};

const ITEM_ENTITY = {
  id: true,
  itemId: true,
  metaDataUrl: true,
  owner: true,
  name: true,
  description: true,
  isActive: true,
  salePrice: true,
  activeOrder: ORDER_ENTITY,
  environment: true,
  image: true,
  typeId: true,
  typeName: true,
  category: true,
  weight: true,
  quality: true,
  quantityCanClaim: true,
  maxWaiCanClaim: true,
  maxWaiReward: true,
  onSale: true,
  saleAt: true,
  level: true,
  rare: true,
  maxHealthBalance: true,
  maxSatietyBalance: true,
  maxEnergyBalance: true,
  weightBonusRate: true,
  qualityBonusRate: true,
  maxSlots: true,
};

const OFFER_ENTITY = {
  id: true,
  offerId: true,
  category: true,
  txHash: true,
  price: true,
  nftAddress: true,
  status: true,
  buyer: true,
  seller: true,
  timestamp: true,
  blockNumber: true,
};

const SALE_ENTITY = {
  id: true,
  txHash: true,
  type: true,
  category: true,
  itemId: true,
  quantity: true,
  price: true,
  from: true,
  to: true,
  timestamp: true,
  blockNumber: true,
};

// const OFFER_ENTITY_V1 = {
//   id: true,
//   offerId: true,
//   category: true,
//   nft: {
//     id: true,
//     itemId: true,
//     owner: {
//       id: true,
//     },
//     tokenURI: true,
//     activeOrder: {
//       price: true,
//     },
//     wland: {
//       itemId: true,
//       salePrice: true,
//       environment: {
//         id: true,
//         name: true,
//       },
//     },
//     nftItem: {
//       itemId: true,
//       salePrice: true,
//       environment: {
//         id: true,
//         name: true,
//       },
//     },
//     contractAddress: true,
//   },
//   txHash: true,
//   price: true,
//   nftAddress: true,
//   status: true,
//   buyer: true,
//   seller: true,
//   createdAt: true,
// };

export const getAttributeByName = (name: string, attributes: any[]): any => {
  if (!name || !(Array.isArray(attributes) && attributes.length !== 0)) {
    return null;
  }
  return attributes?.find(
    attribute => attribute?.trait_type?.toLowerCase() === name.toLowerCase()
  );
};

export function getLands({
  first = LIMIT_HOME_ITEMS,
  skip = 0,
  whereMore,
}): Promise<{ total: number; results: WlandMetaDataProps[] }> {
  const page = skip / first + 1;
  const landsQuery = {
    query: {
      wlands: {
        __args: {
          page,
          size: first,
          ...whereMore,
        },
        total: true,
        results: WLAND_ENTITY,
      },
    },
  };
  // console.log('landsQuery', landsQuery);
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(landsQuery)),
    })
    .then(res => {
      if (res.data?.wlands) {
        const {
          data: {
            wlands: { total, results },
          },
        } = res;
        return {
          total,
          results,
        };
      }
      return {
        total: 0,
        results: [],
      };
    })
    .catch(err => {
      console.error('Error getLands: ', err);
      throw err;
    });
}

// export function getLandsV1({
//   first = LIMIT_HOME_ITEMS,
//   skip = 0,
//   orderBy = 'itemId',
//   orderDirection = 'decs',
//   whereMore,
// }): Promise<{ landTotal: number; nftentities: NftEntityProps[] | any }> {
//   const landsQuery = {
//     query: {
//       countStores: {
//         wlandTotal: true,
//       },
//       nftentities: {
//         __args: {
//           where: {
//             itemId_gt: 0,
//             isActive: true,
//             category: 'wland',
//             ...whereMore,
//           },
//           first,
//           skip,
//           orderBy,
//           orderDirection,
//         },
//         offers: {
//           buyer: true,
//         },
//         id: true,
//         category: true,
//         name: true,
//         image: true,
//         tokenURI: true,
//         activeOrder: {
//           id: true,
//           category: true,
//           price: true,
//           status: true,
//           createdAt: true,
//         },
//         wland: WLAND_ENTITY,
//       },
//     },
//   };
//   // console.log('landsQuery', landsQuery);
//   return clientV1
//     .query({
//       fetchPolicy: 'no-cache',
//       query: gql(jsonToGraphQLQuery(landsQuery)),
//     })
//     .then(res => {
//       if (res?.data && res.data?.countStores[0] && res.data.nftentities) {
//         return {
//           landTotal: res.data.countStores[0].wlandTotal,
//           nftentities: res.data.nftentities,
//         };
//       }
//       return {
//         landTotal: 0,
//         nftentities: [],
//       };
//     })
//     .catch(err => {
//       console.error('Error getLands: ', err);
//       throw err;
//     });
// }
export async function getListMetaData(nftEntities): Promise<any> {
  const promisees = [];
  nftEntities.forEach(item => {
    promisees.push(apiRequest.get(item.tokenURI).catch(() => ({})));
  });
  try {
    const listMetaData = await Promise.all(promisees);
    const nftEntitiesWithMetaData = listMetaData.map((metaData, index) => ({
      ...nftEntities[index],
      metaData,
      visible: !!metaData.id,
    }));
    return nftEntitiesWithMetaData;
  } catch (error) {
    return nftEntities;
  }
}

export function getOffersOld({
  category,
  limit = LIMIT_HISTORY_ITEMS,
  page,
  buyer,
}): Promise<OfferProps[] | any> {
  let historyQuery = {};
  if (category === 'wland') {
    historyQuery = {
      query: {
        wlandsOld: {
          __args: {
            yourOffer: buyer && buyer.toLowerCase(),
            page,
            size: limit,
          },
          total: true,
          results: WLAND_ENTITY,
        },
      },
    };
  } else {
    historyQuery = {
      query: {
        nftsOld: {
          __args: {
            yourOffer: buyer && buyer.toLowerCase(),
            page,
            size: limit,
          },
          total: true,
          results: ITEM_ENTITY,
        },
      },
    };
  }

  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(historyQuery)),
    })
    .then(res => {
      if (category === 'wland')
        return res?.data?.wlandsOld ? res.data.wlandsOld : [];
      return res?.data?.nftsOld ? res.data.nftsOld : [];
    })
    .catch(err => {
      console.error('Error getOffers: ', err);
      throw err;
    });
}

export async function getLandByItemId(landId): Promise<WlandMetaDataProps> {
  try {
    const { results } = await getLands({
      first: 1,
      skip: 0,
      whereMore: { id: Number(landId) },
    });
    return results && results[0];
  } catch (error) {
    console.error('Error getLandById: ', error);
    throw error;
  }
}

export function getItems({
  first = LIMIT_HOME_ITEMS,
  skip = 0,
  whereMore,
}): Promise<{ total: number; results: NftMetaDataProps[] | [] }> {
  const page = skip / first + 1;
  const itemsQuery = {
    query: {
      nfts: {
        __args: {
          ...whereMore,
          page,
          size: first,
        },
        total: true,
        results: ITEM_ENTITY,
      },
    },
  };

  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(itemsQuery)),
    })
    .then(res => {
      if (res.data?.nfts) {
        const {
          data: {
            nfts: { total, results },
          },
        } = res;
        return {
          total,
          results,
        };
      }
      return {
        total: 0,
        results: [],
      };
    })
    .catch(err => {
      console.error('Error getItems: ', err);
      throw err;
    });
}

export async function getItemById(itemId): Promise<NftMetaDataProps> {
  try {
    const { results } = await getItems({
      first: 1,
      skip: 0,
      whereMore: { id: Number(itemId) },
    });
    return results && results[0];
  } catch (error) {
    console.error('Error getItemById: ', error);
    throw error;
  }
}

export function getOfferHistory({
  itemId,
  category,
  size = LIMIT_HISTORY_ITEMS,
  page,
}): Promise<{ total: number; results: OfferProps[] }> {
  const historyQuery = {
    query: {
      offers: {
        __args: {
          itemId,
          category: category && category.toLowerCase(),
          page,
          size,
        },
        total: true,
        results: OFFER_ENTITY,
      },
    },
  };

  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(historyQuery)),
    })
    .then(res => {
      if (res.data?.offers) {
        const {
          data: {
            offers: { total, results },
          },
        } = res;
        return { total, results };
      }
      return {
        total: 0,
        results: [],
      };
    })
    .catch(err => {
      console.error('Error getOfferHistory: ', err);
      throw err;
    });
}

export function getOfferOldHistory({
  itemId,
  category,
  page,
  size,
  account = '',
}): Promise<OfferProps[] | any> {
  const historyQuery = {
    query: {
      offersOld: {
        __args: {
          itemId,
          category: category && category.toLowerCase(),
          page,
          size,
          address: account,
        },
        total: true,
        results: OFFER_ENTITY,
      },
    },
  };

  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(historyQuery)),
    })
    .then(res => {
      return res.data?.offersOld?.results || [];
    })
    .catch(err => {
      console.error('Error getOfferHistory: ', err);
      throw err;
    });
}

export function getSaleHistory({
  itemId,
  size = LIMIT_HISTORY_ITEMS,
  page,
  category,
}): Promise<{ total: number; results: SaleHistoryProps[] }> {
  const query = {
    query: {
      saleHistories: {
        __args: {
          itemId,
          category: category && category.toLowerCase(),
          size,
          page,
        },
        total: true,
        results: SALE_ENTITY,
      },
    },
  };

  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(query)),
    })
    .then(res => {
      if (res.data?.saleHistories) {
        const {
          data: {
            saleHistories: { total, results },
          },
        } = res;
        return { total, results };
      }
      return {
        total: 0,
        results: [],
      };
    })
    .catch(err => {
      console.error('Error getSaleHistory: ', err);
      throw err;
    });
}

export function getPrizesTicketByCycle({ cycle, whereMore }): Promise<any[]> {
  const prizesQuery = {
    query: {
      prizes: {
        __args: {
          where: {
            isActived: true,
            cycle,
            ...whereMore,
          },
        },
        number: true,
        cycle: true,
        index: true,
      },
    },
  };

  return clientLottery
    .query({
      fetchPolicy: 'no-cache',
      query: gql(jsonToGraphQLQuery(prizesQuery)),
    })
    .then(res => {
      return res?.data?.prizes ? res.data.prizes : [];
    })
    .catch(err => {
      console.error('Error checkTicketBuyable: ', err);
      throw err;
    });
}
