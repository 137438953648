import { CONTRACTS, MULTICALL_ADDRESSES } from '../configs/addresses';
import {
  PROTOCOL_TOKENS,
  ProtocolToken,
  WHITELISTED_TOKENS,
} from '../configs/tokens';
import { ChainId } from '../constants';
import { chainNameById } from './connectors';
import {
  API_URLS,
  CONTRACT_PRICE_WAI,
  CONTRACT_PRICE_WANA,
  DISTRIBUTOR_URLS,
  GAME_API_URLS,
  GAME_SEVER_URLS,
  RENT_API_URLS,
  RPC_URLS,
  SUBGRAPH_LOTTERY_URLS,
  SUBGRAPH_URLS,
  SUPPORTED_CHAINS,
  WHITELIST_URLS,
} from './connectors';

export interface PoolFilterTokens {
  id: string;
  symbol: string;
  address: string | string[];
}

const supportedChains = SUPPORTED_CHAINS;
const defaultChainId = Number(
  process.env.REACT_APP_SUPPORTED_NETWORK_ID || supportedChains[0]
);

class NetworkConnectorsClass {
  public currentChainId: number;

  constructor() {
    this.currentChainId = defaultChainId;
  }

  public getDefaultChainId(): number {
    return defaultChainId;
  }

  public getCurrentChainId(): number {
    return this.currentChainId;
  }

  public setCurrentChainId(value: number): void {
    this.currentChainId = Math.max(value, supportedChains[0]);
  }
  public toValidChainId(chainId?: ChainId): number {
    return chainId ? chainId : this.currentChainId;
  }

  public isChainIdSupported(chainId?: number): boolean {
    return supportedChains.indexOf(chainId ?? this.currentChainId) >= 0;
  }

  // tslint:disable-next-line: no-unnecessary-initializer
  public getSupportedChainName(chainId = undefined): string {
    return chainNameById[chainId ?? this.currentChainId];
  }

  public getBackupUrl(chainId?: number): string {
    return RPC_URLS[this.toValidChainId(chainId)];
  }

  public getSubgraphUrl(): string | undefined {
    return SUBGRAPH_URLS[this.currentChainId];
  }

  public getSubgraphLotteryUrl(): string | undefined {
    return SUBGRAPH_LOTTERY_URLS[this.currentChainId];
  }

  public getStatsUrl(chainId?: number): string {
    return API_URLS[this.toValidChainId(chainId)] || '';
  }

  public getGameApiUrl(chainId?: number): string {
    return GAME_API_URLS[this.toValidChainId(chainId)] || '';
  }

  public getRentApiUrl(chainId?: number): string {
    return RENT_API_URLS[this.toValidChainId(chainId)] || '';
  }

  public getContracts(chainId?: ChainId) {
    return CONTRACTS[this.toValidChainId(chainId)] || {};
  }

  public getDistributorAbi() {
    return DISTRIBUTOR_URLS[this.currentChainId];
  }

  public getWhitelistAbi() {
    return WHITELIST_URLS[this.currentChainId];
  }

  public getMultiAddress(chainId?: ChainId): string {
    return MULTICALL_ADDRESSES[this.toValidChainId(chainId)] || {};
  }

  public getGameServerApi(chainId?: ChainId): string {
    return GAME_SEVER_URLS[this.toValidChainId(chainId)] || {};
  }

  public getContractPriceWANA(
    chainId?: ChainId
  ): {
    PairUsdtNative: string;
    USDT: {
      address: string;
      decimals: string;
      symbol: string;
    };
  } {
    return CONTRACT_PRICE_WANA[this.toValidChainId(chainId)] || {};
  }

  public getContractPriceWAI(
    chainId?: ChainId
  ): {
    PairUsdtNative: string;
    USDT: {
      address: string;
      decimals: string;
      symbol: string;
    };
  } {
    return CONTRACT_PRICE_WAI[this.toValidChainId(chainId)] || {};
  }

  public getMainToken() {
    const isValid =
      this.currentChainId &&
      PROTOCOL_TOKENS &&
      PROTOCOL_TOKENS[this.currentChainId] &&
      Object.keys(PROTOCOL_TOKENS[this.currentChainId]).length > 0;
    if (!isValid) {
      return {};
    }

    const tokens = PROTOCOL_TOKENS[this.currentChainId];
    return tokens.WANA;
  }
  public getProtocolTokens(chainId?: ChainId): ProtocolToken {
    return (
      PROTOCOL_TOKENS[this.toValidChainId(chainId)] || ({} as ProtocolToken)
    );
  }

  public getAssets() {
    const { tokens = {}, untrusted = [] } =
      (this.currentChainId && WHITELISTED_TOKENS[this.currentChainId]) ||
      ({} as any);
    return { tokens, untrusted };
  }
}

export const networkConnectors = new NetworkConnectorsClass();
