import {
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItemText,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  AccountBox,
  HelpOutline,
  Storage,
  Redeem,
  MailOutline as MailIcon,
} from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAlert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Archive, Edit, Home, LogOut, Package } from 'react-feather';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PopupDeposit from '../../components/PopupDeposit';
import PopupNotification from '../../components/PopupNotification';
import PopupWaitConfirm from '../../components/PopupWaitConfirm';
import PopupWithdraw from '../../components/PopupWithdraw';
import { useStores } from '../../contexts/storesContext';
import { getCookie } from '../../utils/helpers';
import { ExternalText } from '../Common';
import Header from '../Header';
import PopupEditAccount from '../PopupEditAccount';
import { AppBar, ListItem, ListItemIcon } from './styled';

const StyledDrawer = styled(Drawer)``;
const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    drawerPaper: {
      width: drawerWidth,
      top: 0,
      backgroundColor: '#28293d',
      height: '100vh',
      padding: '24px 16px 16px',
      [theme.breakpoints.up('md')]: {
        top: '60px',
        height: 'calc(100vh - 60px)',
        padding: '0 16px',
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapped = styled.div`
  margin-top: 60px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0;
  `};
  @media screen and (min-width: 960px) {
    display: flex;
  }
`;

const WrappedContent = styled.main`
  flex: 1;
  padding: 24px 32px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 24px 1rem;
    height: 100%;
  `};
`;

const StyledWrapDrawer = styled.div`
  flex-basis: ${drawerWidth}px;
`;

const StyledList = styled(List)`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const MENU_ITEMS_MOBILE = [
  {
    key: 'Home',
    to: '/',
    icon: (
      <div style={{ marginRight: '5px' }}>
        <Home />
      </div>
    ),
    exact: true,
  },
  {
    key: 'Account',
    to: '/profile/account',
    icon: <AccountBox />,
    exact: true,
  },
  // {
  //   key: 'In-Game’s Inventory',
  //   to: '/profile/game-inventory',
  //   icon: <Archive />,
  //   exact: true,
  // },
  {
    key: 'Wallet’s Inventory',
    to: '/profile/wallet-inventory',
    icon: <Package />,
    exact: true,
  },
  {
    key: 'Activity',
    to: '/profile/activities',
    icon: <HistoryIcon />,
    exact: true,
  },
  // {
  //   key: 'In-Game’s History',
  //   to: '/profile/game-history',
  //   icon: <Storage />,
  //   exact: true,
  // },
  // {
  //   key: 'Redeem code',
  //   to: '/profile/gift',
  //   icon: <Redeem />,
  //   exact: true,
  // },
  // {
  //   key: 'Mailbox',
  //   to: '/profile/mailbox',
  //   icon: <MailIcon style={{ fontSize: 24 }} />,
  //   exact: true,
  // },
  {
    key: 'Support',
    href: 'https://support.wanakafarm.com/',
    icon: <HelpOutline />,
    exact: true,
  },
];

export const MENU_ITEMS = [
  {
    key: 'Account',
    to: '/profile/account',
    icon: <AccountBox style={{ fontSize: 24 }} />,
    exact: true,
  },
  // {
  //   key: 'In-Game’s Inventory',
  //   to: '/profile/game-inventory',
  //   icon: <Archive />,
  //   exact: true,
  // },
  {
    key: 'Wallet’s Inventory',
    to: '/profile/wallet-inventory',
    icon: <Package />,
    exact: true,
  },
  {
    key: 'Activity',
    to: '/profile/activities',
    icon: <HistoryIcon style={{ fontSize: 24 }} />,
    exact: true,
  },
  // {
  //   key: 'In-Game’s History',
  //   to: '/profile/game-history',
  //   icon: <Storage style={{ fontSize: 24 }} />,
  //   exact: true,
  // },
  // {
  //   key: 'Redeem code',
  //   to: '/profile/gift',
  //   icon: <Redeem style={{ fontSize: 24 }} />,
  //   exact: true,
  // },
  // {
  //   key: 'Mailbox',
  //   to: '/profile/mailbox',
  //   icon: <MailIcon style={{ fontSize: 24 }} />,
  //   exact: true,
  // },
  {
    key: 'Support',
    href: 'https://support.wanakafarm.com/',
    icon: <HelpOutline style={{ fontSize: 24 }} />,
    exact: true,
  },
];

const StyledMenuFooter = styled.div`
  overflow: hidden;
  max-height: 150px;
  border-top: 2px solid rgba(255, 255, 255, 0.12);
  padding: 16px 0;
`;

const StyledAccount = styled.div`
  border-radius: 4px;
  border: 1px solid #3a3f50;
  padding: 12px 12px;
  margin: 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfileLayout = observer(
  ({ children }: { children: React.ReactNode }) => {
    const {
      root: { notificationStore, userStore, notificationModalStore },
    } = useStores();

    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { setPopupEditName } = userStore;
    const {
      message: messagePopup,
      desc,
      onClose: onClosePopup,
    } = notificationModalStore;

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const drawer = (
      <StyledList className="scroll-custom">
        <Hidden mdUp={true} implementation="css">
          {MENU_ITEMS_MOBILE.map((menu: any) => {
            // const hasSub = Array.isArray(menu.items) && menu.items.length;
            // const activated = activatedMenu[menu.key];
            const attrs: any = {};
            if (menu.href) {
              attrs.component = 'a';
              attrs.href = menu.href;
              attrs.target = '_blank';
            } else {
              attrs.component = NavLink;
              attrs.to = menu.to;
              attrs.exact = menu.exact;
            }
            return (
              <React.Fragment key={menu.key}>
                <ListItem
                  button={true}
                  {...attrs}
                  style={{ marginBottom: '1rem' }}
                >
                  <ListItemIcon>{menu.icon || null}</ListItemIcon>
                  <ListItemText
                    primary={
                      menu.href ? (
                        <ExternalText>{menu.key}</ExternalText>
                      ) : (
                        menu.key
                      )
                    }
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        </Hidden>
        <Hidden smDown={true} implementation="css">
          {MENU_ITEMS.map((menu: any) => {
            const attrs: any = {};
            if (menu.href) {
              attrs.component = 'a';
              attrs.href = menu.href;
              attrs.target = '_blank';
            } else {
              attrs.component = NavLink;
              attrs.to = menu.to;
              attrs.exact = menu.exact;
            }
            return (
              <React.Fragment key={menu.key}>
                <ListItem
                  button={true}
                  {...attrs}
                  style={{ marginBottom: '1rem' }}
                >
                  <ListItemIcon>{menu.icon || null}</ListItemIcon>
                  <ListItemText
                    primary={
                      menu.href ? (
                        <ExternalText>{menu.key}</ExternalText>
                      ) : (
                        menu.key
                      )
                    }
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        </Hidden>
      </StyledList>
    );

    const container =
      window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
      try {
        const authData = getCookie('auth_data')
          ? JSON.parse(getCookie('auth_data'))
          : '';
        const { accessToken } = authData;
        if (accessToken && !userStore.profile.address) {
          userStore.reLoginByAccessToken(accessToken);
        }
      } catch (e) {
        console.error('Error reLoginByAccessToken: ', e);
        // do nothing
      }
    }, [userStore]);

    const { profile } = userStore;
    const {
      open,
      onClose,
      message,
      autoHideDuration,
      severity,
      anchorOrigin,
    } = notificationStore;
    const nickName = profile.nickname || 'NONAME';
    return (
      <>
        <AppBar
          open={open}
          position={'relative'}
          color={'transparent'}
          elevation={0}
        >
          <Header
            toggles={
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            }
          />
        </AppBar>

        <Wrapped>
          <StyledWrapDrawer>
            <Hidden mdUp={true} implementation="css">
              <StyledDrawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <StyledAccount>
                  {nickName}{' '}
                  <IconButton onClick={() => setPopupEditName(true)}>
                    <Edit />
                  </IconButton>
                </StyledAccount>
                {drawer}
                <StyledMenuFooter>
                  <Button
                    size="medium"
                    style={{ color: '#fe383f' }}
                    onClick={userStore.handleLogout}
                  >
                    <LogOut style={{ marginRight: '10px' }} /> Logout
                  </Button>
                </StyledMenuFooter>
              </StyledDrawer>
            </Hidden>
            <Hidden smDown={true} implementation="css">
              <StyledDrawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open={true}
              >
                <StyledAccount>
                  {nickName}{' '}
                  <IconButton onClick={() => setPopupEditName(true)}>
                    <Edit />
                  </IconButton>
                </StyledAccount>
                {drawer}
                <StyledMenuFooter>
                  <Button
                    size="medium"
                    style={{ color: '#fe383f' }}
                    onClick={userStore.handleLogout}
                  >
                    <LogOut style={{ marginRight: '10px' }} /> Logout
                  </Button>
                </StyledMenuFooter>
              </StyledDrawer>
            </Hidden>
          </StyledWrapDrawer>
          <WrappedContent>{children}</WrappedContent>
        </Wrapped>
        {/*  */}
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={onClose}
        >
          <Alert onClose={onClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
        <PopupNotification
          message={messagePopup}
          desc={desc}
          onClose={onClosePopup}
        />
        <PopupEditAccount />
        <PopupDeposit />
        <PopupWithdraw />
        <PopupWaitConfirm />
      </>
    );
  }
);

export default ProfileLayout;
