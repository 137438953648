export enum ChainId {
  MAINNET = 56,
  CHAPEL = 97, // TESTNET
}

export const NetworkContextName = 'NETWORK';

export const COLOR_ENVIRONMENT = {
  basic: '#ff9800',
  forest: '#8bc34a',
  lake: '#02BAA3',
  sea: '#0CAAE2',
  default: '#ff9800',
};

export const COLOR_SEASON = {
  Spring: '#0c7926',
  Summer: '#cc2427',
  Autumn: '#f47a04',
  Winter: '#0a73ca',
  default: '#696969',
};

export const COLOR_RARE = {
  Common: '#6293a9',
  Uncommon: '#3caad5',
  Rare: '#3785a1',
  Mythical: '#892b8e',
  Legendary: '#493a86',
  Immortal: '#dfa000',
  default: '#696969',
};

export const SIDEBAR_WITH = 270;

export const SEASON = ['spring', 'summer', 'autumn', 'winter'];

interface ItemTypeIdProps {
  [key: number]: {
    category: string;
    name: string;
    typeId: number;
    typeCategory: TypeCategory;
    image: string;
    imageDetail: string;
    pedestal?: any;
    avatar: string;
    background?: boolean;
    deposable: boolean;
  };
}

export enum TypeCategory {
  ITEM = 'ITEM',
  PET = 'PET',
  CH = 'CH',
}

export enum TypeCategoryPet {
  PET_NORMAL = 'pet-normal',
  PET_CARE = 'pet-care',
  PET_SUPPORT = 'pet-support',
  PET_BATTLE = 'pet-battle',
}

export enum TypeCategoryItem {
  GROWING_ITEM = 'growing-item',
  BUILDING_ITEM = 'building-item',
  PET_EGG = 'pet-egg',
}

const BASE_IMAGE_NFT_URL = '/images/nft-items';

export const ITEM_TYPE_ID: ItemTypeIdProps = {
  1: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Ice Cabbage',
    typeId: 1,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/1.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/1.png`,
    image: `${BASE_IMAGE_NFT_URL}/1.png`,
    background: true,
    deposable: true,
  },
  2: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Steeve Apple',
    typeId: 2,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/2.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/2.png`,
    image: `${BASE_IMAGE_NFT_URL}/2.png`,
    background: true,
    deposable: true,
  },
  3: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Hello Cow',
    typeId: 3,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/3.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/3.png`,
    image: `${BASE_IMAGE_NFT_URL}/3.png`,
    background: true,
    deposable: true,
  },
  4: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Coin Fish',
    typeId: 4,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/4.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/4.png`,
    image: `${BASE_IMAGE_NFT_URL}/4.png`,
    background: true,
    deposable: true,
  },
  121: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Wheat',
    typeId: 121,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/121-v1.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/121-v1.png`,
    image: `${BASE_IMAGE_NFT_URL}/121-v1.png`,
    background: true,
    deposable: true,
  },
  122: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Mango',
    typeId: 122,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/122.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/122.png`,
    image: `${BASE_IMAGE_NFT_URL}/122.png`,
    background: true,
    deposable: true,
  },
  123: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Pig',
    typeId: 123,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/123.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/123.png`,
    image: `${BASE_IMAGE_NFT_URL}/123.png`,
    background: true,
    deposable: true,
  },
  124: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Carp',
    typeId: 124,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/124-v1.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/124-v1.png`,
    image: `${BASE_IMAGE_NFT_URL}/124-v1.png`,
    background: true,
    deposable: true,
  },
  32: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'DuckDao',
    typeId: 32,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/32.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/32.png`,
    image: `${BASE_IMAGE_NFT_URL}/32.png`,
    deposable: true,
  },
  33: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Brawlio88',
    typeId: 33,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/33.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/33.png`,
    image: `${BASE_IMAGE_NFT_URL}/33.png`,
    deposable: true,
  },
  34: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Corgi',
    typeId: 34,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/34.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/34.png`,
    image: `${BASE_IMAGE_NFT_URL}/34.png`,
    deposable: true,
  },
  35: {
    category: 'nftitem',
    name: 'Taika',
    typeId: 35,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/35.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/35.png`,
    image: `${BASE_IMAGE_NFT_URL}/35.png`,
    deposable: true,
  },
  // 36: {
  //   category: 'nftitem',
  //   name: 'Michael',
  //   typeId: 36,
  //   typeCategory: TypeCategory.CH,
  //   pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
  //   avatar: `${BASE_IMAGE_NFT_URL}/avt/michael.png`,
  //   imageDetail: `${BASE_IMAGE_NFT_URL}/michael.png`,
  //   image: `${BASE_IMAGE_NFT_URL}/36.png`
  // },
  37: {
    category: 'nftitem',
    name: 'Brian',
    typeId: 37,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/37.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/37.png`,
    image: `${BASE_IMAGE_NFT_URL}/37.png`,
    deposable: true,
  },
  38: {
    category: 'nftitem',
    name: 'Lily',
    typeId: 38,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/38.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/38.png`,
    image: `${BASE_IMAGE_NFT_URL}/38.png`,
    deposable: true,
  },
  39: {
    category: 'nftitem',
    name: 'Daisy',
    typeId: 39,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/39.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/39.png`,
    image: `${BASE_IMAGE_NFT_URL}/39.png`,
    deposable: true,
  },
  40: {
    category: 'nftitem',
    name: 'Christine',
    typeId: 40,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/40.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/40.png`,
    image: `${BASE_IMAGE_NFT_URL}/40.png`,
    deposable: true,
  },
  73: {
    category: TypeCategoryPet.PET_SUPPORT,
    name: 'Bedlam',
    typeId: 73,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/73.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/73.png`,
    image: `${BASE_IMAGE_NFT_URL}/73.png`,
    deposable: true,
  },
  72: {
    category: TypeCategoryPet.PET_SUPPORT,
    name: 'Paimoon',
    typeId: 72,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/72.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/72.png`,
    image: `${BASE_IMAGE_NFT_URL}/72.png`,
    deposable: true,
  },
  74: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Zackson',
    typeId: 74,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/74.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/74.png`,
    image: `${BASE_IMAGE_NFT_URL}/74.png`,
    deposable: true,
  },
  70: {
    category: 'nftitem',
    name: 'Matt Gailer',
    typeId: 70,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/70.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/70.png`,
    image: `${BASE_IMAGE_NFT_URL}/70.png`,
    deposable: true,
  },
  71: {
    category: 'nftitem',
    name: 'Scarlet',
    typeId: 71,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/71.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/71.png`,
    image: `${BASE_IMAGE_NFT_URL}/71.png`,
    deposable: true,
  },
  91: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Reindeer',
    typeId: 91,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/91.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/91.png`,
    image: `${BASE_IMAGE_NFT_URL}/91.png`,
    deposable: true,
  },
  92: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Mace soldier',
    typeId: 92,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/92.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/92.png`,
    image: `${BASE_IMAGE_NFT_URL}/92.png`,
    deposable: true,
  },
  93: {
    category: TypeCategoryPet.PET_NORMAL,
    name: 'Santa',
    typeId: 93,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/93.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/93.png`,
    image: `${BASE_IMAGE_NFT_URL}/93.png`,
    deposable: true,
  },
  95: {
    category: 'nftitem',
    name: 'Tim Santa',
    typeId: 95,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/95.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/95.png`,
    image: `${BASE_IMAGE_NFT_URL}/95.png`,
    deposable: true,
  },
  96: {
    category: 'nftitem',
    name: 'Mariah Claus',
    typeId: 96,
    typeCategory: TypeCategory.CH,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/96.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/96.png`,
    image: `${BASE_IMAGE_NFT_URL}/96.png`,
    deposable: true,
  },
  97: {
    category: TypeCategoryItem.PET_EGG,
    name: 'Rolly Egg',
    typeId: 97,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/97.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/97.png`,
    image: `${BASE_IMAGE_NFT_URL}/97.png`,
    deposable: true,
  },
  98: {
    category: TypeCategoryItem.PET_EGG,
    name: 'Talpa Egg',
    typeId: 98,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/98.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/98.png`,
    image: `${BASE_IMAGE_NFT_URL}/98.png`,
    deposable: true,
  },
  // 99: {
  //   category: 'pet',
  //   name: 'Argen Egg',
  //   typeId: 99,
  //   typeCategory: TypeCategory.ITEM,
  //   avatar: `${BASE_IMAGE_NFT_URL}/99.png`,
  //   imageDetail: `${BASE_IMAGE_NFT_URL}/99.png`,
  //   image: `${BASE_IMAGE_NFT_URL}/99.png`,
  //   deposable: true,
  // },
  // 100: {
  //   category: 'pet',
  //   name: 'Pridey Egg',
  //   typeId: 100,
  //   typeCategory: TypeCategory.ITEM,
  //   avatar: `${BASE_IMAGE_NFT_URL}/100.png`,
  //   imageDetail: `${BASE_IMAGE_NFT_URL}/100.png`,
  //   image: `${BASE_IMAGE_NFT_URL}/100.png`,
  //   deposable: true,
  // },
  // 101: {
  //   category: 'pet',
  //   name: 'Hekwig Egg',
  //   typeId: 101,
  //   typeCategory: TypeCategory.ITEM,
  //   avatar: `${BASE_IMAGE_NFT_URL}/101.png`,
  //   imageDetail: `${BASE_IMAGE_NFT_URL}/101.png`,
  //   image: `${BASE_IMAGE_NFT_URL}/101.png`,
  //   deposable: true,
  // },
  // 102: {
  //   category: 'pet',
  //   name: 'Bubba Egg',
  //   typeId: 102,
  //   typeCategory: TypeCategory.ITEM,
  //   avatar: `${BASE_IMAGE_NFT_URL}/102.png`,
  //   imageDetail: `${BASE_IMAGE_NFT_URL}/102.png`,
  //   image: `${BASE_IMAGE_NFT_URL}/102.png`,
  //   deposable: true,
  // },
  115: {
    category: TypeCategoryItem.BUILDING_ITEM,
    name: 'Incubator',
    typeId: 115,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/115.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/115.png`,
    image: `${BASE_IMAGE_NFT_URL}/115.png`,
    deposable: true,
  },
  144: {
    category: TypeCategoryPet.PET_CARE,
    name: 'Rolly',
    typeId: 144,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/144-v1.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/144-v1.png`,
    image: `${BASE_IMAGE_NFT_URL}/144.png`,
    deposable: true,
  },
  145: {
    category: TypeCategoryPet.PET_CARE,
    name: 'Talpa',
    typeId: 145,
    typeCategory: TypeCategory.PET,
    pedestal: `${BASE_IMAGE_NFT_URL}/bg-pet-ch.png`,
    avatar: `${BASE_IMAGE_NFT_URL}/detail/145-v1.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/detail/145-v1.png`,
    image: `${BASE_IMAGE_NFT_URL}/145.png`,
    deposable: true,
  },
  149: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Torch Corn',
    typeId: 149,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/avt/149.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/149.png`,
    image: `${BASE_IMAGE_NFT_URL}/149.png`,
    background: true,
    deposable: true,
  },
  150: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Thing Orange',
    typeId: 150,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/150.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/150.png`,
    image: `${BASE_IMAGE_NFT_URL}/150.png`,
    background: true,
    deposable: true,
  },
  151: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Invisible Goat',
    typeId: 151,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/151.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/151.png`,
    image: `${BASE_IMAGE_NFT_URL}/151.png`,
    background: true,
    deposable: true,
  },
  152: {
    category: TypeCategoryItem.GROWING_ITEM,
    name: 'Mister Plaice',
    typeId: 152,
    typeCategory: TypeCategory.ITEM,
    avatar: `${BASE_IMAGE_NFT_URL}/152.png`,
    imageDetail: `${BASE_IMAGE_NFT_URL}/152.png`,
    image: `${BASE_IMAGE_NFT_URL}/152.png`,
    background: true,
    deposable: true,
  },
};

export const DEPOSIT_WITHDRAW_STATE = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
};

export const STEP_TRANSFER = {
  GAME_DONE: 'WithdrawGameDone',
  PENDING: 'PendingTransfer',
  PROCESSING: 'ProcessingTransfer',
  SUCCESS: 'FinishedTransfer',
  FAILED: 'FailedTransfer',
  ROLLBACK: 'Rollback',
};

export const TIME_FETCH_LIST = 60000;
export const TIME_FETCH_DETAIL = 30000;
export const TIME_FETCH_SALE = 30000;
export const TIME_FETCH_OFFER = 30000;
export const TIME_FETCH_BALANCE_GAME = 30000;

export enum CELL_LAND_STATUS {
  NONE = 0,
  PLOWED = 1,
  HARVESTED = 2,
  STALL = 3,
}

export enum CELL_LAND_TYPE {
  POND = 0,
  GRASS = 1,
  SEA = 2,
  FORVEGETABLE = 3,
  FORTREE = 4,
  FORANIMAL = 5,
  FORFISH = 6,
  OTHER = 7,
  RENTVEGETABLE = 8,
  RENTTREE = 9,
  RENTANIMAL = 10,
  RENTFISH = 11,
}

export const ID_CELL_LAND_TYPE = {
  0: 'POND',
  1: 'GRASS',
  2: 'SEA',
  3: 'FORVEGETABLE',
  4: 'FORTREE',
  5: 'FORANIMAL',
  6: 'FORFISH',
  7: 'OTHER',
  8: 'RENTVEGETABLE',
  9: 'RENTTREE',
  10: 'RENTANIMAL',
  11: 'RENTFISH',
};

export const STATUS_RENT = {
  // 0: {
  //   color1: '#bbb',
  //   color2: '#444545',
  //   text: 'LOCK',
  // },
  // 1: {
  //   color1: '#dfaf26',
  //   color2: '#88532b',
  //   text: 'UNLOCK',
  // },
  2: {
    color1: '#e65e45',
    color2: '#b53026',
    text: 'FOR RENT',
    value: 2,
    viewMode: ['is-owned'],
  },
  3: {
    color1: '#57a348',
    color2: '#1a674d',
    text: 'RENTING',
    value: 3,
    viewMode: ['is-owned', 'your-rented'],
  },
  4: {
    color1: '#0d5798',
    color2: '#2e3285',
    text: 'EXPIRED',
    value: 4,
    viewMode: ['is-owned', 'your-rented'],
  },
  5: {
    color1: '#dfaf26',
    color2: '#88532b',
    text: 'DRAFT',
    value: 5,
    viewMode: ['is-owned'],
  },
};
