import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { LoadingSpinner } from '../../theme/components';
import { amountFormat, bnum, fromWei } from '../../utils/helpers';

enum ButtonState {
  NO_WALLET,
  UNLOCK,
  CREATE,
}

const ButtonText = ['Connect Wallet', 'Approve', 'Confirm'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const StyledP = styled.p`
  color: #a1a6b6;
  text-align: center;
  span {
    color: #fff;
    font-weight: 500;
  }
  .number-decimal {
    color: #a1a6b6;
  }
`;

const PopupTakeOffer = observer(
  ({ open, setOpen, dataOffer, dataNft, type }) => {
    const [isLoading, setLoading] = useState(false);
    const [isApprove, setApprove] = useState(false);
    const {
      root: { orderStore, providerStore, tokenStore },
    } = useStores();

    const metaData = providerStore.getContractMetaData();
    const { account } = providerStore.providerStatus;

    const getButtonState = (account, isApprove: boolean): ButtonState => {
      if (!account) {
        return ButtonState.NO_WALLET;
      }
      if (!isApprove) {
        return ButtonState.UNLOCK;
      }
      return ButtonState.CREATE;
    };

    const getButtonText = (buttonState: ButtonState): string => {
      if (buttonState === ButtonState.UNLOCK) {
        return `Approve NFT`;
      }
      return ButtonText[buttonState];
    };

    useEffect(() => {
      orderStore
        .isApprovedForAll({
          contractAddress: metaData.orderContract,
          account,
          type,
        })
        .then(res => {
          setApprove(res);
        });
    }, [metaData.orderContract, orderStore, dataNft.itemId, type, account]);

    function handleClose() {
      setOpen(false);
    }

    function handleTakeOffer() {
      setLoading(true);
      orderStore
        .takeOffer({
          contractAddress: dataNft.contractAddress,
          buyer: dataOffer.buyer,
          itemId: dataNft.itemId,
          price: dataOffer.price,
        })
        .then(() => {
          handleClose();
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const handleApprove = () => {
      setLoading(true);
      orderStore
        .setApproveAll({
          contractAddress: metaData.orderContract,
          state: true,
          type,
          account,
        })
        .then(() => {
          orderStore
            .isApprovedForAll({
              contractAddress: metaData.orderContract,
              type,
              account,
            })
            .then(res => {
              setApprove(res);
            });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const buttonActionHandler = (buttonState: ButtonState) => {
      switch (buttonState) {
        case ButtonState.NO_WALLET:
          // dropdownStore.toggleWalletDropdown();
          break;
        case ButtonState.CREATE:
          handleTakeOffer();
          break;
        case ButtonState.UNLOCK:
          handleApprove();
          break;
        default:
          throw new Error('Invalid button state');
      }
    };

    const clickFormButton = buttonState => {
      buttonActionHandler(buttonState);
    };

    const buttonState = getButtonState(account, isApprove);
    const text = type === 'wland' ? 'Land' : 'NFT Item';
    return (
      <StyledModal open={open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <h2>CONFIRM TAKE OFFER</h2>
            <StyledP>
              Are you sure you want to sell this {text} with{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {
                  amountFormat(fromWei(dataOffer?.price || '0'), 3)?.split(
                    '.'
                  )[0]
                }
                <span className="number-decimal">
                  {amountFormat(fromWei(dataOffer?.price || '0'), 3)?.split(
                    '.'
                  )[1] &&
                    `.${
                      amountFormat(fromWei(dataOffer?.price || '0'), 3)?.split(
                        '.'
                      )[1]
                    }`}{' '}
                </span>
                &nbsp;WANA
                {dataOffer?.price && tokenStore.priceWANA
                  ? ` (~ $${amountFormat(
                      bnum(fromWei(dataOffer?.price || '0')).multipliedBy(
                        bnum(tokenStore.priceWANA)
                      ),
                      0
                    )})?`
                  : ''}
              </span>
            </StyledP>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                clickFormButton(buttonState);
              }}
              disabled={isLoading}
              style={{ minHeight: '48px' }}
            >
              {getButtonText(buttonState)}{' '}
              {isLoading ? <LoadingSpinner size={24} /> : null}
            </Button>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    );
  }
);

export default PopupTakeOffer;
