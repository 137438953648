import { action, observable } from 'mobx';
import { networkConnectors } from '../provider/networkConnectors';
import { NftMetaDataProps, WlandMetaDataProps } from '../types';
import { TokenProps, MailProps } from '../types';
import {
  apiRequest,
  getStatsErrorMessage,
  getStatsErrorMessageGame,
} from '../utils/apiRequest';
import { authRequest } from '../utils/authRequest';
import { deleteCookie, setCookie } from '../utils/helpers';
import RootStore from './Root';

export interface ProfileProps {
  email?: string;
  address?: string;
  id?: string;
  nickname?: string;
  nonce?: string;
  is2FAEnabled?: boolean;
}

export interface BalancesProps {
  waiBalance?: number;
  wanaBalance?: number;
  wland?: WlandMetaDataProps[];
  nftitem?: {
    data: NftMetaDataProps[];
    totalPage: number;
  };
  pageSize?: number;
  totalPage?: number;
}

export interface DepositWithdrawProps {
  open?: boolean;
  token?: TokenProps | string | '';
  param?: number | '' | string[] | [];
  isEdit?: boolean;
  typeId?: number;
}

export interface RedeemProps {
  code: string;
  description: string;
  type: string;
  updatedDate: string;
  validatedTo: string;
}

export default class UserStore {
  @observable public accessToken: string;
  @observable public isSyncAuth: boolean;
  @observable public walletDropdownVisible: boolean;
  @observable public profile: ProfileProps;
  @observable public openPopupEditAccount: boolean;
  @observable public popupDeposit: DepositWithdrawProps;
  @observable public balances: BalancesProps;
  @observable public historyDepositWithdraw: any[];
  @observable public historyGame: { records: any[]; totalPage: number };
  @observable public historyRedeem: RedeemProps[];
  @observable public mailboxes: MailProps[];

  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    const { assetStore } = this.rootStore;
    const TOKENS = assetStore.getTokensConfig();
    const ARRAY_TOKENS = Object.values(TOKENS);
    this.accessToken = '';
    this.isSyncAuth = false;
    this.walletDropdownVisible = false;
    this.profile = {
      email: '',
      address: '',
      id: undefined,
      nickname: '',
      nonce: '',
      is2FAEnabled: undefined,
    };
    this.openPopupEditAccount = false;
    this.popupDeposit = {
      open: false,
      token: ARRAY_TOKENS[0].value,
      param: '',
      isEdit: true,
    };
    this.balances = {
      waiBalance: 0,
      wanaBalance: 0,
      wland: [],
      nftitem: {
        data: [],
        totalPage: 0,
      },
    };
    this.historyDepositWithdraw = [];
    this.historyGame = {
      records: [],
      totalPage: 1,
    };
    this.mailboxes = [];
  }

  @action public setPopupEditName = v => {
    this.openPopupEditAccount = v;
  };

  @action public setPopupDeposit = (depositParams: DepositWithdrawProps) => {
    this.popupDeposit = Object.assign({}, this.popupDeposit, depositParams);
  };

  public getUserNonce = async address => {
    const baseUrl = networkConnectors.getStatsUrl();
    if (!address) {
      return {};
    }

    return apiRequest
      .get(baseUrl + '/user/get-nonce', { address })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        console.error('Error getUserNonce: ', e);
        return {};
      });
  };

  public registerByAddress = async address => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    if (!address) {
      return {};
    }

    return apiRequest
      .post(baseUrl + '/user/register-by-address', { address })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        console.error('Error registerByAddress: ', e);
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public handleLoginWallet = async () => {
    const { providerStore } = this.rootStore;
    const { notificationStore } = this.rootStore;
    const signer = providerStore.getSigner();
    const { account } = providerStore.providerStatus;
    let { nonce } = await this.getUserNonce(account);
    if (!nonce) {
      const data = await this.registerByAddress(account);
      nonce = data.none;
    }
    const signatureHash = await signer.signMessage(nonce);
    const baseUrl = networkConnectors.getStatsUrl();

    return apiRequest
      .post(baseUrl + '/user/login-metamask', {
        signature: signatureHash,
        address: account,
      })
      .then(res => {
        const { data } = res;
        this.profile = data;
        this.accessToken = data?.accessToken;
        const authData = {
          address: data?.address,
          email: data?.email,
          accessToken: data?.accessToken,
          refreshToken: data?.refreshToken,
        };
        setCookie('auth_data', JSON.stringify(authData), 1);
        // TODO: integrate to the game api v2
        // this.handleSyncAuthGame();
        if (data?.address) {
          this.getBalance();
        }
        notificationStore.showSuccessNotification(res.message || 'Success');
        return data;
      })
      .catch(e => {
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public handleLoginAccount = async ({ email, password }) => {
    const baseUrl = networkConnectors.getStatsUrl();
    const { notificationStore } = this.rootStore;

    return apiRequest
      .post(baseUrl + '/user/login', {
        email,
        password,
      })
      .then(res => {
        const { data } = res;
        const authData = {
          email: data?.email,
          address: data?.address,
          accessToken: data?.accessToken,
          refreshToken: data?.refreshToken,
        };
        this.profile = data;
        this.accessToken = data?.accessToken;
        setCookie('auth_data', JSON.stringify(authData), 1);
        // TODO: integrate to the game api v2
        // this.handleSyncAuthGame();
        if (data?.address) {
          this.getBalance();
        }
        notificationStore.showSuccessNotification(res.message || 'Success');
        return data;
      })
      .catch(e => {
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public handleSyncAuthGame = async () => {
    const gameUrl = networkConnectors.getGameApiUrl();
    const { notificationStore } = this.rootStore;

    return authRequest
      .post(gameUrl + '/v1/user/sync_auth', {})
      .then(res => {
        const { c } = res;
        if (c.code === 'S000') {
          this.isSyncAuth = true;
        }
      })
      .catch(e => {
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public handleRegister = async ({ email, password }) => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();

    return apiRequest
      .post(baseUrl + '/user/register', {
        email,
        password,
      })
      .then(res => {
        notificationStore.showSuccessNotification(res?.message || 'Success');
        return res.data;
      })
      .catch(e => {
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public getProfile = () => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();

    return authRequest
      .post(baseUrl + '/user/profile', {})
      .then(res => {
        this.profile = res.data;
        return res.data;
      })
      .catch(err => {
        const message = getStatsErrorMessage(err);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw err;
      });
  };

  @action public updateProfile = async (params, type) => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    const apiUrl = type === 'pass' ? '/user/update-password' : '/user/edit';
    try {
      const res = await authRequest.put(baseUrl + apiUrl, params);
      notificationStore.showSuccessNotification(res?.message || 'Success');
      this.getProfile();
      if (type.includes('email')) {
        await this.getNewToken();
      }
      return res;
    } catch (e) {
      const message = getStatsErrorMessage(e);
      notificationStore.showErrorNotification(
        message || 'Something went wrong'
      );
      throw e;
    }
  };

  @action public reLoginByAccessToken = async accessToken => {
    const { notificationStore } = this.rootStore;
    try {
      await this.confirmAccessToken(accessToken);
      this.accessToken = accessToken;
      // TODO: integrate to the game api v2
      // this.handleSyncAuthGame();
      const profile = await this.getProfile();
      if (profile.address) {
        this.getBalance();
      }
      return accessToken;
    } catch (error) {
      deleteCookie('auth_data');
      const message = getStatsErrorMessage(error);
      notificationStore.showErrorNotification(
        message || 'Something went wrong'
      );
      // throw new Error(message);
    }
  };

  @action public confirmAccessToken = async token => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();

    return authRequest
      .post(baseUrl + `/user/check-token`, {
        token,
      })
      .then(res => {
        return res.data;
      })
      .catch(e => {
        const message = getStatsErrorMessage(e);
        notificationStore.showErrorNotification(
          message || 'Something went wrong'
        );
        throw e;
      });
  };

  @action public getBalance = async () => {
    const baseUrl = networkConnectors.getStatsUrl();
    try {
      const { data } = await authRequest.get(
        baseUrl + '/user/ingame_balance',
        {}
      );
      const { waiBalance, wanaBalance } = data;
      this.balances = Object.assign({}, this.balances, {
        waiBalance: waiBalance || 0,
        wanaBalance: wanaBalance || 0,
      });
      return data;
    } catch (e) {
      throw e;
      // throw new Error(message);
    }
  };

  @action public getBalanceNftItem = async ({ size, page }) => {
    const baseUrl = networkConnectors.getStatsUrl();
    try {
      const { data } = await authRequest.get(baseUrl + '/nft_list', {
        size,
        page,
      });
      const { nftitem, totalPage } = data?.d;
      this.balances = Object.assign({}, this.balances, {
        nftitem: {
          data: nftitem,
          totalPage,
        },
      });
      return data?.d;
    } catch (e) {
      throw e;
      // throw new Error(message);
    }
  };

  @action public getBalanceWland = async () => {
    const baseUrl = networkConnectors.getGameApiUrl();
    try {
      const {
        d: { wland },
      } = await authRequest.get(baseUrl + '/v1/wallet/wland_list', {});
      this.balances = Object.assign({}, this.balances, {
        wland,
      });
      return wland;
    } catch (e) {
      throw e;
      // throw new Error(message);
    }
  };

  @action public getHistoryByType = async ({
    type,
    page = 1,
    size = 5,
    typeStatus,
  }: {
    type: 'wana' | 'wai' | 'land';
    page?: number;
    size?: number;
    typeStatus: 'success' | 'waiting';
  }) => {
    const baseUrl = networkConnectors.getStatsUrl();
    let urlByType;
    if (typeStatus === 'success') {
      urlByType = baseUrl + `/history/${type}`;
    } else {
      urlByType = baseUrl + `/list-pending-withdraw/${type}`;
    }
    try {
      const { data } = await authRequest.get(urlByType, {
        page,
        size,
      });
      this.historyDepositWithdraw = data || [];
      return data;
    } catch (e) {
      this.historyDepositWithdraw = [];
      throw e;
    }
  };

  @action public getHistoryGameByType = async ({
    type,
    address,
    page = 1,
    size = 10,
    startTime,
    endTime,
  }: {
    type: 'wana' | 'wai' | 'nft';
    address: string;
    page?: number;
    size?: number;
    startTime: number;
    endTime: number;
  }) => {
    const baseUrl = networkConnectors.getStatsUrl();

    try {
      const { records, totalPage } = await apiRequest.get(
        baseUrl + `/ingame-history/${type}/${address}`,
        {
          page,
          size,
          startTime,
          endTime,
        }
      );
      this.historyGame = {
        records,
        totalPage,
      };
      return records;
    } catch (e) {
      this.historyGame = {
        records: [],
        totalPage: 1,
      };
      throw e;
    }
  };

  public getCodeVerifyWithdraw = async () => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    try {
      const { data } = await authRequest.post(
        baseUrl + '/email/get-verify-code-withdraw',
        {}
      );
      return data;
    } catch (e) {
      const message = getStatsErrorMessage(e);
      notificationStore.showErrorNotification(
        message || 'Something went wrong'
      );
      throw e;
      // throw new Error(message);
    }
  };

  @action public getNewToken = async () => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    try {
      const { data } = await authRequest.post(
        baseUrl + '/user/get-new-token',
        {}
      );
      const { address, email, accessToken, refreshToken } = data;
      this.accessToken = accessToken;
      const authData = {
        address,
        email,
        accessToken,
        refreshToken,
      };
      setCookie('auth_data', JSON.stringify(authData), 1);
      // TODO: integrate to the game api v2
      // this.handleSyncAuthGame();
      return data;
    } catch (e) {
      const message =
        e?.response?.data?.data?.c?.error || getStatsErrorMessage(e);
      notificationStore.showErrorNotification(
        message || 'Something went wrong'
      );
      this.handleLogout();
      throw e;
      // throw new Error(message);
    }
  };

  @action public resetAuthenticator = codeVerify => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    return authRequest
      .post(baseUrl + '/reset-2fa', { codeVerify: Number(codeVerify) })
      .then(res => {
        this.profile.is2FAEnabled = false;
        notificationStore.showSuccessNotification(res.message || 'Success');
        return res;
      })
      .catch(error => {
        const msg = getStatsErrorMessage(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        return error;
      });
  };

  @action public setUpAuthenticator = code => {
    const { notificationStore } = this.rootStore;
    const baseUrl = networkConnectors.getStatsUrl();
    return authRequest
      .post(baseUrl + '/verify-enable-2fa', { otpToken: code })
      .then(res => {
        this.profile.is2FAEnabled = true;
        notificationStore.showSuccessNotification(res.message || 'Success');
        return res;
      })
      .catch(error => {
        const msg = getStatsErrorMessage(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        return error;
      });
  };

  public redeemCode = code => {
    const { notificationStore } = this.rootStore;
    const gameUrl = networkConnectors.getGameApiUrl();
    return authRequest
      .post(gameUrl + '/v1/voucher/redeem_code', { d: { code } })
      .then(res => {
        notificationStore.showSuccessNotification(
          res.message || 'Redeem Success'
        );
        return res;
      })
      .catch(error => {
        const msg = getStatsErrorMessageGame(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        return error;
      });
  };

  @action public fetchHistoryRedeem = () => {
    const { notificationStore } = this.rootStore;
    const gameUrl = networkConnectors.getGameApiUrl();
    return authRequest
      .post(gameUrl + '/v1/voucher/history', {})
      .then(res => {
        const { c } = res;
        if (c.code === 'S000' && res.d) {
          this.historyRedeem = Object.values(res.d).flat() as RedeemProps[];
        }
        return res;
      })
      .catch(error => {
        const msg = getStatsErrorMessageGame(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        return error;
      });
  };

  @action public fetchMailbox = () => {
    const { notificationStore } = this.rootStore;
    const gameUrl = networkConnectors.getGameApiUrl();
    return authRequest
      .post(gameUrl + '/v1/marketplace/mail_list', {})
      .then(res => {
        const { c } = res;
        if (c.code === 'S000' && res.d) {
          this.mailboxes = (res.d.list_mails as MailProps[]) || [];
          return res.d.list_mails;
        }
        throw Error('Something went wrong');
      })
      .catch(error => {
        const msg = getStatsErrorMessageGame(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        return error;
      });
  };

  public readMail = mailId => {
    const gameUrl = networkConnectors.getGameApiUrl();
    return authRequest
      .post(gameUrl + '/v1/marketplace/mail_content', {
        c: {},
        d: {
          mail_id: mailId,
        },
      })
      .then(res => {})
      .catch(error => {
        return error;
      });
  };

  @action public setMailboxes = (mailboxes: MailProps[]) => {
    this.mailboxes = mailboxes;
  };
  @action public handleLogout = () => {
    deleteCookie('auth_data');
    this.profile = {};
    this.accessToken = null;
    this.isSyncAuth = false;
  };
}
