import { Button, Collapse, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Copy } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PopupConfirmEmail from '../../components/PopupConfirmEmail';
import PopupSetUp2FA from '../../components/PopupSetUp2FA';
import PopupUpdateEmail from '../../components/PopupUpdateEmail';
import QuestionHelper from '../../components/QuestionHelper';
import Tooltip from '../../components/Tooltip';
import { TIME_FETCH_BALANCE_GAME } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import useInterval from '../../hooks/useInterval';
import { networkConnectors } from '../../provider/networkConnectors';
import { ExternalLink } from '../../theme';
import { getEtherscanLink } from '../../utils';
import { getStatsErrorMessage } from '../../utils/apiRequest';
import { authRequest } from '../../utils/authRequest';
import GA from '../../utils/ga';
import { amountFormat, bnum, truncateAddress } from '../../utils/helpers';

const ProfileStyled = styled.div`
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label {
    color: #acacac;
  }
`;
const Card = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 28px 24px;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 16px;
  `}
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 280px;
  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
    background: rgba(17, 19, 27, 0.7);
    border-radius: 6px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;
    `}
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: unset;
    width: calc(100% - 12px);
  `}
`;
const StyledButton = styled(Button)`
  &.MuiButton-root {
    text-transform: capitalize;
  }
  &.MuiButton-root.gradient1 {
    background-image: linear-gradient(
      to right,
      #f5d505 0%,
      #1cc48c 51%,
      #f5d505 100%
    );
  }
  &.MuiButton-root.gradient2 {
    background-image: linear-gradient(
      to right,
      #02ed25 0%,
      #1cc48c 51%,
      #02ed25 100%
    );
  }
  &.MuiButton-root.gradient3 {
    background-image: linear-gradient(
      to right,
      #f5d505 0%,
      #fea500 51%,
      #f5d505 100%
    );
  }
  &.MuiButton-root.gradient4 {
    background-image: linear-gradient(
      to right,
      #02ed25 0%,
      #fea500 51%,
      #02ed25 100%
    );
  }
  &.MuiButton-root.gradient {
    transition: 0.5s;
    background-size: 200% auto;
    display: block;
  }

  &.MuiButton-root:hover {
    background-position: right center; /* change the direction of the change here */
    text-decoration: none;
  }
`;

const StyledHeaderAccount = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
  h2 {
    margin: 0;
    color: #fafafa;
  }
`;

const StyledRow = styled.div`
  margin: 0 -6px;
  & > div {
    margin: 6px;
  }
  button {
    min-height: 48px;
    margin: 6px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: calc(100% - 12px);
    `}
  }
`;

const StyleTitle = styled.h3`
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 20px;
  color: #fafafa;
`;

const StyledTitle2 = styled.h4`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`;

const StyleBalance = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  span {
    font-weight: 500;
  }
  color: ${({ color }) => color || '#F5D505'};
  img {
    margin-left: 5px;
    height: 20px;
  }
`;

const StylePoint = styled.div<{ color?: string }>`
  margin-bottom: 5px;
  span {
    font-weight: 500;
  }
  img {
    margin-left: 5px;
    height: 20px;
  }
`;

const StyledToken = styled.div<{ color?: string }>`
  border: 1px solid ${({ color }) => color || '#F5D505'};
  border-radius: 4px;
  padding: 4px 4px 4px 6px;
  background-color: rgb(24, 26, 38, 0.6);
  font-weight: 500;
  color: ${({ color }) => color || '#F5D505'};
  display: flex;
  align-items: center;
  min-width: 82px;
  width: max-content;
`;

const StyledAddress = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `}
  .address-value {
    margin-left: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weigh: 500;
  }
`;
const StyleCardHeader = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: revert;
`;

const StyledBox = styled.div``;

const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    color: #181a26;
    background-color: #f39c12;
  }
  &.MuiAlert-root .MuiAlert-icon {
    color: #181a26;
  }
`;

const StyledWrapButton = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledDetailPoint = styled.div`
  margin-top: 8px;
`;

const StyleWrapPoint = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
`;

const StyledTotalPoint = styled.div`
  font-size: 18px;
  span {
    font-size: 18px;
    color: #f5d505;
    font-weight: 500;
  }
  &.rank-point span {
    font-size: 16px;
    font-weight: 400;
  }

  &.rank-point .rank {
    color: #fafafa;
    font-weight: 500;
  }
  .rank.GOLD {
    color: #f5d505;
  }
  .rank.SLIVER {
    color: #d8d8d8;
  }
  .rank.BRONZE {
    color: #cd7f32;
  }
`;

const Profile = observer(() => {
  const [openUpdateEmailPass, setUpdateEmailPass] = useState(false);
  const {
    root: {
      userStore,
      tokenStore,
      providerStore,
      dropdownStore,
      notificationModalStore,
      notificationStore,
      withdrawStore,
    },
  } = useStores();
  // const balanceFormatted = formatBalanceWithCommas(
  //   tokenStore.getWANABalance || ''
  // );
  // const balanceWaitFormatted = formatBalanceWithCommas(
  //   tokenStore.getWAIBalance || ''
  // );
  const [typePopup, setTypePopup] = useState(null);
  const [data2FA, setData2FA] = useState<{ open: boolean; data: any }>({
    open: false,
    data: null,
  });
  const { account } = providerStore.providerStatus;
  const contractMetaData = providerStore.getContractMetaData();
  const [isCopied, setCopied] = useCopyClipboard();
  const [openConfirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    balances,
    profile,
    setPopupEditName,
    setPopupDeposit,
    getBalance,
    resetAuthenticator,
  } = userStore;
  const { setPopupWithdraw } = withdrawStore;
  const { email, address, is2FAEnabled } = userStore.profile;
  const history = useHistory();
  const location = useLocation();
  const baseUrl = networkConnectors.getStatsUrl();
  const [open, setOpen] = React.useState(
    !window.localStorage.getItem('dontShowWarningWithdraw')
  );
  const [stakingPoint, setStakingPoint] = React.useState({
    pointWana: 0,
    pointLand: 0,
    totalPoint: 0,
    stakingSyncBlock: 0,
    landSyncBlock: 0,
  });
  const { currentBlockNumber } = providerStore;

  useEffect(() => {
    GA.mountedPage({
      page: 'profile',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeposit = useCallback(
    (token = 'WANA') => {
      if (!account) {
        dropdownStore.toggleWalletDropdown();
        return;
      }
      if (!address) {
        history.push('/login');
        return;
      }
      if (!email) {
        notificationModalStore.showNotification({
          text: 'Please Setup Email Password to perform this action.',
        });
        return;
      }
      setPopupDeposit({ open: true, isEdit: true, token });
    },
    [
      account,
      address,
      dropdownStore,
      email,
      history,
      notificationModalStore,
      setPopupDeposit,
    ]
  );

  const onWithdraw = useCallback(
    (token = 'WANA') => {
      if (!account) {
        dropdownStore.toggleWalletDropdown();
        return;
      }
      if (!address) {
        history.push('/login');
        return;
      }
      if (!email) {
        notificationModalStore.showNotification({
          text: 'Please Setup Email Password to perform this action.',
        });
        return;
      }
      if (!is2FAEnabled) {
        notificationModalStore.showNotification({
          text: 'Please Setup Authenticator to perform this action.',
        });
        return;
      }
      setPopupWithdraw({
        open: true,
        isEdit: true,
        token,
      });
    },
    [
      account,
      address,
      dropdownStore,
      email,
      history,
      is2FAEnabled,
      notificationModalStore,
      setPopupWithdraw,
    ]
  );

  useEffect(() => {
    let handler;
    if (location.hash === '#deposit') {
      handler = setTimeout(() => {
        // onDeposit();
        history.replace('/profile/account');
      }, 800);
    } else if (location.hash === '#withdraw') {
      handler = setTimeout(() => {
        onWithdraw();
        history.replace('/profile/account');
      }, 800);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [history, location.hash, onDeposit, onWithdraw]);

  function getWanakaPoint() {
    if (!address) {
      return;
    }
    authRequest
      .get(`https://score-api.wanakafarm.tech/api/score/${address}`, {})
      .then(res => {
        setStakingPoint(res.data);
      })
      .catch(() => {
        setStakingPoint({
          pointWana: 0,
          pointLand: 0,
          totalPoint: 0,
          stakingSyncBlock: 0,
          landSyncBlock: 0,
        });
      });
  }

  useEffect(() => {
    tokenStore.fetchAllowancesData(
      account,
      [contractMetaData.wanaFarmContract, contractMetaData.waiTokenContract],
      contractMetaData.assetStoreContract
    );
    tokenStore.getUSDPriceWai();
  }, [
    account,
    contractMetaData.assetStoreContract,
    contractMetaData.waiTokenContract,
    contractMetaData.wanaFarmContract,
    tokenStore,
  ]);

  useInterval(getBalance, address ? TIME_FETCH_BALANCE_GAME : null, false);
  useInterval(getWanakaPoint, address ? TIME_FETCH_BALANCE_GAME : null, true);
  const setUpAuthenticator = () => {
    if (!email) {
      notificationModalStore.showNotification({
        text: 'Please Setup Email Password to perform this action.',
      });
      return;
    }
    setData2FA({ open: true, data: null });
  };

  function handleSetEmailPass(type: string) {
    if (!address) {
      history.push('/login');
      return;
    }
    setTypePopup(type);
    setUpdateEmailPass(true);
  }

  const openPopupConfirm = () => {
    setLoading(true);
    authRequest
      .post(baseUrl + '/verify-code-reset-2fa', {})
      .then(res => {
        setConfirm(true);
      })
      .catch(error => {
        const msg = getStatsErrorMessage(error) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleCloseConfirm() {
    setConfirm(false);
  }

  function onConfirmResetAuthenticator(code) {
    setLoading(true);
    resetAuthenticator(code)
      .then(() => {
        handleCloseConfirm();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const totalPoint =
    Number(stakingPoint?.totalPoint) +
    (tokenStore.getLANDBalance * 1500 || 0) +
    (Number(tokenStore.getWANABalance) || 0);

  let rank = '';
  if (totalPoint > 100000) {
    rank = 'GOLD';
  } else if (totalPoint > 60000) {
    rank = 'SLIVER';
  } else if (totalPoint > 20000) {
    rank = 'BRONZE';
  }

  return (
    <ProfileStyled>
      <Collapse in={open}>
        <StyledAlert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                window.localStorage.setItem('dontShowWarningWithdraw', 'true');
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          You can find unexecuted withdraw transactions in "Activity" with type
          "WAITING"
        </StyledAlert>
      </Collapse>
      <StyledBox>
        <div>
          <StyledHeaderAccount>
            <h2>Account Info</h2>
          </StyledHeaderAccount>
          <StyleCardHeader>
            <Card>
              <StyleTitle>Available in Your Wallet:</StyleTitle>
              <StyleBalance>
                <StyledToken>
                  WANA <img src="/images/logo-token-wana.png" alt="" />
                </StyledToken>
                &nbsp;:&nbsp;
                <span>
                  {amountFormat(tokenStore.getWANABalance, 2)}
                  {tokenStore.getWANABalance && tokenStore.priceWANA
                    ? ` (~ ${amountFormat(
                        bnum(tokenStore.getWANABalance).multipliedBy(
                          bnum(tokenStore.priceWANA)
                        ),
                        0
                      )} USD)`
                    : ''}{' '}
                </span>
              </StyleBalance>
              <StyleBalance color="#02ED25">
                <StyledToken color="#02ED25">
                  WAI&nbsp; <img src="/images/logo-token-wai.png" alt="" />
                </StyledToken>
                &nbsp;:&nbsp;
                <span>
                  {amountFormat(tokenStore.getWAIBalance, 2)}
                  {tokenStore.getWAIBalance && tokenStore.priceWAI
                    ? ` (~ ${amountFormat(
                        bnum(tokenStore.getWAIBalance).multipliedBy(
                          bnum(tokenStore.priceWAI)
                        ),
                        0
                      )} USD)`
                    : ''}{' '}
                </span>
              </StyleBalance>
              <StyledWrapButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  style={{ margin: '12px 12px 0 0' }}
                  onClick={() => onDeposit('WANA')}
                  size="small"
                  className="gradient1 gradient"
                >
                  Deposit WANA
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  style={{ margin: '12px 12px 0 0' }}
                  onClick={() => onDeposit('WAI')}
                  size="small"
                >
                  Deposit WAI
                </StyledButton>
              </StyledWrapButton>
            </Card>
            <Card>
              <StyleTitle>Balance In Game:</StyleTitle>
              <StyleBalance>
                <StyledToken>
                  WANA <img src="/images/logo-token-wana.png" alt="" />{' '}
                </StyledToken>
                &nbsp;:&nbsp;
                <span>
                  {amountFormat(balances?.wanaBalance, 2)}
                  {balances?.wanaBalance && tokenStore.priceWANA
                    ? ` (~ ${amountFormat(
                        bnum(balances?.wanaBalance).multipliedBy(
                          bnum(tokenStore.priceWANA)
                        ),
                        0
                      )} USD)`
                    : ''}{' '}
                </span>
              </StyleBalance>
              <StyleBalance color="#02ED25">
                <StyledToken color="#02ED25">
                  WAI&nbsp;
                  <img src="/images/logo-token-wai.png" alt="" />{' '}
                </StyledToken>
                &nbsp;:&nbsp;
                <span>
                  {amountFormat(balances?.waiBalance, 2)}
                  {balances?.waiBalance && tokenStore.priceWAI
                    ? ` (~ ${amountFormat(
                        bnum(balances?.waiBalance).multipliedBy(
                          bnum(tokenStore.priceWAI)
                        ),
                        0
                      )} USD)`
                    : ''}{' '}
                </span>
              </StyleBalance>

              <StyledWrapButton>
                <StyledButton
                  variant="contained"
                  className="primary2 gradient3 gradient"
                  onClick={() => {
                    onWithdraw('WANA');
                  }}
                  style={{ margin: '12px 12px 0 0' }}
                  size="small"
                >
                  Withdraw WANA
                </StyledButton>
                <StyledButton
                  variant="contained"
                  className="primary2"
                  onClick={() => {
                    onWithdraw('WAI');
                  }}
                  style={{ margin: '12px 12px 0 0' }}
                  size="small"
                >
                  Withdraw WAI
                </StyledButton>
              </StyledWrapButton>
              <hr style={{ margin: '12px 0 12px' }} />
              <StyledAddress className="text-truncate">
                Account Address:{' '}
                <span className="address-value">
                  <Tooltip title={isCopied ? 'Copied' : 'Click to copy'}>
                    <span>
                      {truncateAddress(profile?.address || '')} &nbsp;
                      <Copy
                        size={'16'}
                        onClick={() => setCopied(profile?.address)}
                      />
                    </span>
                  </Tooltip>
                </span>
              </StyledAddress>
            </Card>
            <Card>
              <StyleTitle>
                WANAKA Point:{' '}
                <QuestionHelper text="When have change(stake, deposit, withdraw, ...) will be lost some minutes to sync point" />
              </StyleTitle>
              <StyleWrapPoint>
                <StylePoint>
                  <StyledToken>
                    WANA <img src="/images/logo-token-wana.png" alt="" />{' '}
                  </StyledToken>
                  <StyledDetailPoint>
                    <div>
                      <span className="label">In Wallet:</span>&nbsp;
                      <span>{amountFormat(tokenStore.getWANABalance, 2)}</span>
                    </div>
                    <div>
                      <span className="label">Staking:</span>&nbsp;
                      <span>
                        {amountFormat(stakingPoint?.pointWana, 2) || '0'}
                      </span>
                    </div>
                    <div>
                      <span className="label">Snapshot:</span>{' '}
                      <a
                        href={getEtherscanLink(
                          undefined,
                          stakingPoint?.stakingSyncBlock,
                          'block'
                        )}
                        target={'_blank'}
                        style={{ color: '#fff' }}
                      >
                        {stakingPoint?.stakingSyncBlock}
                      </a>
                    </div>
                  </StyledDetailPoint>
                </StylePoint>
                <StylePoint>
                  <StyledToken color="#02ED25">
                    LAND&nbsp;
                    <img src="/images/logo-token-land.png" alt="" />{' '}
                  </StyledToken>
                  <StyledDetailPoint>
                    <div>
                      <span className="label">In Wallet:</span>&nbsp;
                      <span>{tokenStore.getLANDBalance * 1500}</span>
                    </div>
                    <div>
                      <span className="label">In Game:</span>&nbsp;
                      <span>
                        {amountFormat(stakingPoint?.pointLand, 2) || '0'}
                      </span>
                    </div>
                    <div>
                      <span className="label">Snapshot:</span>{' '}
                      <a
                        href={getEtherscanLink(
                          undefined,
                          stakingPoint?.landSyncBlock,
                          'block'
                        )}
                        target={'_blank'}
                        style={{ color: '#fff' }}
                      >
                        {stakingPoint?.landSyncBlock}
                      </a>
                    </div>
                  </StyledDetailPoint>
                </StylePoint>
              </StyleWrapPoint>
              <hr />
              <div>
                <span className="label">Current block:</span>{' '}
                <a
                  href={getEtherscanLink(
                    undefined,
                    currentBlockNumber,
                    'block'
                  )}
                  target={'_blank'}
                  style={{ color: '#fff' }}
                >
                  {currentBlockNumber}
                </a>
              </div>
              {totalPoint ? (
                <StyledTotalPoint className="rank-point">
                  <span className="label">Rank: </span>
                  <span className={`rank ${rank}`}>{rank || 'NO RANK'}</span>
                </StyledTotalPoint>
              ) : null}
              <StyledTotalPoint>
                Total Point: <span>{amountFormat(totalPoint, 2)}</span>
              </StyledTotalPoint>
              <ExternalLink
                href={'https://blog.wanakafarm.com/dao-system-wanaka-point-wp/'}
              >
                <span style={{ marginLeft: '4px' }}>Details</span>
              </ExternalLink>
            </Card>
          </StyleCardHeader>
        </div>

        <div>
          {profile?.email ? (
            <>
              <StyledHeaderAccount>
                <h2>Account Settings</h2>
              </StyledHeaderAccount>

              <Card>
                <StyledTitle2>Name</StyledTitle2>
                <StyledRow>
                  <StyledTextField
                    variant="outlined"
                    value={profile.nickname || ''}
                    disabled={true}
                  />
                  <StyledButton
                    variant="outlined"
                    onClick={() => {
                      setPopupEditName(true);
                    }}
                  >
                    Change Name
                  </StyledButton>
                </StyledRow>
                <StyledTitle2>Email</StyledTitle2>
                <StyledRow>
                  <StyledTextField
                    variant="outlined"
                    value={profile.email || ''}
                    disabled={true}
                  />
                  <StyledButton
                    variant="outlined"
                    onClick={() => handleSetEmailPass('email')}
                  >
                    Change Email
                  </StyledButton>
                </StyledRow>
                <StyledTitle2>Password</StyledTitle2>
                <div>
                  <StyledButton
                    variant="outlined"
                    style={{ minHeight: '48px', marginTop: '6px' }}
                    onClick={() => handleSetEmailPass('pass')}
                  >
                    Change Password
                  </StyledButton>
                </div>
              </Card>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSetEmailPass('email-pass')}
              style={{ marginTop: '16px' }}
            >
              Setup Email Password
            </Button>
          )}
          {profile?.is2FAEnabled ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={openPopupConfirm}
                disabled={loading}
                style={{ marginTop: '16px' }}
              >
                Reset Authenticator
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
                onClick={setUpAuthenticator}
              >
                Setup Authenticator
              </Button>
            </div>
          )}
        </div>
      </StyledBox>

      <PopupConfirmEmail
        open={openConfirm}
        onClose={handleCloseConfirm}
        loading={loading}
        onConfirm={onConfirmResetAuthenticator}
        email={email || userStore.profile.email}
      />
      <PopupUpdateEmail
        type={typePopup}
        open={openUpdateEmailPass}
        setOpen={setUpdateEmailPass}
      />
      <PopupSetUp2FA data={data2FA} setOpen={setData2FA} />
    </ProfileStyled>
  );
});

export default Profile;
