import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import ArrowPaginate from '../../components/ArrowPaginate';
import { ChipByEventHistory, ChipByStatusHistory } from '../../components/Chip';
import CountdownTimer from '../../components/CountdownTimer';
import Tooltip from '../../components/Tooltip';
import { ITEM_TYPE_ID } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useInterval from '../../hooks/useInterval';
import { TokenParamProps, TokenProps } from '../../types';
import { getEtherscanLink } from '../../utils';
import GA from '../../utils/ga';
import {
  bnum,
  dateTimeFormat,
  numberFormat,
  truncateAddress,
} from '../../utils/helpers';
import { LIMIT_HISTORY_ITEMS } from '../../utils/subgraph';
import { NoDataText } from '../../components/Common';

const StyledBody = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

const StyledTableContainer = styled(({ ...props }: any) => {
  return <TableContainer {...props} />;
})`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 12px;
  }
  margin-bottom: 1rem;
  white-space: nowrap;
  .number-decimal {
    color: #98a0b9;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.offer-active {
    background: #494d65;
  }
`;

const StyledSelectToken = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  & > div {
    margin-right: 10px;
  }
  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 25px;
  }
`;

const StyledCountdownTimer = styled(CountdownTimer)`
  font-family: ChakraPetch, 'UTM Colossalis', sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.primary3};
`;

const Activity = observer(() => {
  const {
    root: { userStore, assetStore, withdrawStore },
  } = useStores();
  const { resendContractData } = withdrawStore;
  const TOKENS = assetStore.getTokensConfig();
  const ARRAY_TOKENS = Object.values(TOKENS);
  const ARRAY_TYPE = [
    { value: 'success', text: 'SUCCESS' },
    { value: 'waiting', text: 'WAITING' },
  ];
  const [historyPage, setHistoryPage] = useState(1);
  const [token, setToken] = useState<string>(ARRAY_TOKENS[0].value);
  const [type, setType] = useState<string>(ARRAY_TYPE[1].value);
  const { accessToken, historyDepositWithdraw, getHistoryByType } = userStore;
  const isWaiting = type === 'waiting';
  const isWAI = TOKENS[token].value === TokenProps.WAI;
  const isTokenAmount = TOKENS[token].param === TokenParamProps.amount;

  function handleChangeDepositPage(page) {
    setHistoryPage(page);
  }

  useEffect(() => {
    setHistoryPage(1);
  }, [token]);

  useEffect(() => {
    GA.mountedPage({
      page: 'activity',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHistory = useCallback(() => {
    getHistoryByType({
      type: TOKENS[token].historyType || 'wana',
      page: historyPage,
      size: LIMIT_HISTORY_ITEMS * 2,
      typeStatus: type as undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHistoryByType, historyPage, token, type]);

  useEffect(() => {
    getHistory();
  }, [accessToken, getHistory, historyPage, token]);

  useInterval(getHistory, accessToken ? 30000 : null, false);

  function handleChangeSelectToken(event: ChangeEvent<{ value: unknown }>) {
    setToken(event.target.value as string);
  }

  function handleChangeSelectType(event: ChangeEvent<{ value: unknown }>) {
    setType(event.target.value as string);
  }

  const onResendTransaction = row => {
    GA.sendEvent({ event: 'resend-transaction', label: token });
    resendContractData(
      row.dataWithdraw,
      row.id,
      TOKENS[token].historyType,
      TOKENS[token].value
    ).then(() => {
      getHistory();
    });
  };

  const currentTime = new Date().getTime() / 1000;

  return (
    <StyledBody>
      <h2>Deposit & Withdraw History</h2>
      <StyledSelectToken>
        <FormControl variant="outlined">
          <InputLabel id="filter">Token</InputLabel>
          <Select
            value={token}
            onChange={handleChangeSelectToken}
            labelId="filter"
            label={'Token'}
          >
            {ARRAY_TOKENS.map(tokenItem => (
              <MenuItem value={tokenItem.value} key={tokenItem.value}>
                <StyledToken>
                  <img src={tokenItem.logo} alt="" height="25px" />
                  {tokenItem.text}
                </StyledToken>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="filter">Type</InputLabel>
          <Select
            value={type}
            onChange={handleChangeSelectType}
            labelId="filter"
            label={'Type'}
          >
            {ARRAY_TYPE.map(tokenItem => (
              <MenuItem value={tokenItem.value} key={tokenItem.value}>
                <StyledToken>{tokenItem.text}</StyledToken>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledSelectToken>
      <div style={{ width: '100%' }}>
        <StyledTableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {!isWaiting && <TableCell align="center">TxHash</TableCell>}
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Event</TableCell>
                {isWaiting && token === 'NFTITEM' && (
                  <TableCell align="center">Type Item</TableCell>
                )}
                {isWAI && (
                  <TableCell align="center" className="text-capitalize">
                    Origin Amount
                  </TableCell>
                )}
                {isWAI && <TableCell align="center">Fee</TableCell>}
                <TableCell align="center" className="text-capitalize">
                  {isWAI ? 'Receipt Amount' : TOKENS[token].param}
                </TableCell>
                {!isWaiting && <TableCell align="center">Status</TableCell>}
                <TableCell align="center">
                  {isWaiting ? 'Expired time' : 'Time'}
                </TableCell>
                {isWaiting && <TableCell align="center">Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyDepositWithdraw &&
                historyDepositWithdraw.map((row, index) => (
                  <StyledTableRow key={index}>
                    {!isWaiting && (
                      <TableCell align="center">
                        <a
                          href={getEtherscanLink(
                            undefined,
                            row.txHash,
                            'transaction'
                          )}
                          target={'_blank'}
                          style={{ color: '#fff' }}
                        >
                          {truncateAddress(row.txHash || '')}
                        </a>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {moment(row.createdAt).format('YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      <ChipByEventHistory status={row.event} />
                    </TableCell>
                    {isWAI && (
                      <TableCell align="center">
                        {row?.amountOrigin
                          ? numberFormat(row?.amountOrigin, 3)
                          : numberFormat(row?.amount, 3)}
                      </TableCell>
                    )}
                    {isWAI && (
                      <TableCell align="center">
                        {row.fee
                          ? bnum(row.fee)
                              .multipliedBy(100)
                              .toFixed(2, 1)
                          : 0}
                        %
                      </TableCell>
                    )}
                    {isWaiting && token === 'NFTITEM' && (
                      <TableCell align="center">
                        {ITEM_TYPE_ID[row.typeIdNft]?.name || ''}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {isTokenAmount
                        ? numberFormat(row[TOKENS[token].param], 3)
                        : row[TOKENS[token].param]}
                    </TableCell>
                    {!isWaiting && (
                      <TableCell align="center">
                        <ChipByStatusHistory
                          statusGame={row.updateToGame}
                          stepProcess={row.stepProcess}
                          event={row.event}
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {isWaiting && row.dataWithdraw?.deadline ? (
                        currentTime < row.dataWithdraw?.deadline ? (
                          <StyledCountdownTimer
                            daysInHours={true}
                            endTime={row.dataWithdraw?.deadline}
                            onComplete={() => {
                              // onClose(null, null);
                            }}
                          />
                        ) : (
                          'Expired'
                        )
                      ) : (
                        dateTimeFormat(row.createdAt)
                      )}
                    </TableCell>
                    {isWaiting && (
                      <TableCell align="center">
                        <Tooltip
                          title={
                            currentTime >= row.dataWithdraw?.deadline
                              ? 'The token is locked due to the expiration of time'
                              : ''
                          }
                          placement={'top'}
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              className="primary2"
                              disabled={
                                currentTime >= row.dataWithdraw?.deadline
                              }
                              onClick={() => {
                                onResendTransaction(row);
                              }}
                            >
                              Claim
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {!historyDepositWithdraw ||
        (historyDepositWithdraw && historyDepositWithdraw.length === 0) ? (
          <NoDataText />
        ) : null}
        <ArrowPaginate
          page={historyPage}
          onChange={handleChangeDepositPage}
          size={historyDepositWithdraw.length}
          limit={LIMIT_HISTORY_ITEMS * 2}
          style={{ marginBottom: 0 }}
        />
      </div>
    </StyledBody>
  );
});

export default Activity;
