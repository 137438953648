import { ChainId } from '../constants';

export const DEV_CONTRACTS = {
  WanaFarmContract: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
  WaiTokenContract: '0xcd2d09fa9d425682085bc94ed0c7bada6cabcdba',
  WLandContract: '0x20de5503dd2e7554d1b12fa027132a8447ae6a55',
  WNFTContract: '0x82919e77f9b76da6881e2085292f9e1fcbefbb18',
  WFTContract: '0xb2759823412c6ebbd7844ac7c26118312762dee8',
  OrderContract: '0x9d8C1745785351B733bBA3937C5E34cB700cb5a4',
  OldOrderContract: '0x4a96aa4dbf1e8e12873063f5798abe17a64bc5d7',
  AssetStoreContract: '0x03215b2446c13e6ffe35a55ad19329377d9fae76',
  ClaimLandContract: '',
  LandSaleContract: '0x0248341f06F22c651aB9eFD0893ED9e23C6Ba2C2',
  NFTSaleContract: '0x097F3Ace5170B1644C04b2788567a4e9af397204',
  TokenClaimWAIContract: '',
  TokenClaimWANAContract: '',
  WithdrawOperatorContract: '0xe17c650cded7af4ead0c2e5d0ab9da4d08489717',
  TicketDistributerContract: '0x2091FB10c75E242ddDe1E50480a6dab24931645f',
};

export const STAGING_CONTRACTS = {
  WanaFarmContract: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
  WaiTokenContract: '0xcd2d09fa9d425682085bc94ed0c7bada6cabcdba',
  WLandContract: '0x14db978488903bd03c7a3a7e363060ccc76acd3b',
  WNFTContract: '0x3352518d651791cb233a888057fbf30aa6d39c93',
  WFTContract: '',
  OrderContract: '0x5fe49b887fC56734322eF6d32730ea7Fe2Be7Ff5',
  OldOrderContract: '0xFE1Aca23A7a0DF3302DB88bDc39f59c577abc195',
  AssetStoreContract: '0x9e21e6069b012b47f0cfd307cbc8b32d5bfd660c',
  ClaimLandContract: '0x60f602bDE38eFBaa8aE66b8E00f8F9a867b9aecb',
  LandSaleContract: '0x519A6efA00bd478383D798A76769f03528D7E524',
  NFTSaleContract: '0x547EB4dAdC288975C60003bCc16f2acBCF250Db6',
  TokenClaimWAIContract: '0x288eE36145e696bb7a236402934363a53be36407',
  TokenClaimWANAContract: '',
  WithdrawOperatorContract: '0x9304de08389dcd07febeab9dffdb8ad382d4d486',
  TicketDistributerContract: '0x137ad01f26acc2ee0a3e48d7c9b07e513ed27eed',
};
export const CONTRACTS: { [chainId in ChainId]?: any } = {
  [ChainId.MAINNET]: {
    WanaFarmContract: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
    WaiTokenContract: '0x934c9198582bf2631128c5d4b051aacef9a6224f',
    WLandContract: '0x12F299cB26452b428017340E79F79662aC8D73ef',
    WNFTContract: '0xf2397580372f8df900e45127ccea1faf7b9df68e',
    WFTContract: '',
    OrderContract: '0x931317f6503378c16E2E88f6565b971899Cb6Bee',
    OldOrderContract: '0xFE1Aca23A7a0DF3302DB88bDc39f59c577abc195',
    ClaimLandContract: '0x30C2B948CCb4B603587ad3C91C5Acc708Cd311aA',
    LandSaleContract: '0x358Cbc5383B214858C2CFd55d0F4f64FB6B8bc6c',
    NFTSaleContract: '0xD9F6957f625e376900c51a3A4DaBa16093d3a4a3',
    AssetStoreContract: '0x164664fcf89f3b722bcba6f02f2c9e3b9081c2a1',
    TokenClaimWAIContract: '0xa590318Ce049b2D45207D2350CF1629Ae1E8653d',
    TokenClaimWANAContract: '0x11bF55Eb05116Eb899024Bf742c1EaAD632467c6',
    WithdrawOperatorContract: '0x6297509f8009118a59358c86a78b96b21da48ecd',
    TicketDistributerContract: '0xfE5ac5e907685c694d08d50b9b89ACefA2c5Df6D',
  },
  [ChainId.CHAPEL]:
    process.env.REACT_APP_ENV === 'staging' ? STAGING_CONTRACTS : DEV_CONTRACTS,
};

console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);

export const MULTICALL_ADDRESSES: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  [ChainId.CHAPEL]: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
};
