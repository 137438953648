import React from 'react';
import styled from 'styled-components';
import Tooltip from '../../components/Tooltip';
import { getEtherscanLink } from '../../utils';
import { bnum, isAddressEqual } from '../../utils/helpers';
import { networkConnectors } from '../../provider/networkConnectors';

const Card = styled.div`
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px 12px;
  `}
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;
const LabelAttribute = styled.div`
  font-weight: 700;
  color: #1dc48c;
`;

const StyledStats = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  margin: -12px;
  align-items: end;
`;

const StyledWrapStat = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '33.33333%'};
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 50%;
    padding: 10px 4px;
  `}
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  max-width: calc(100% - 45px);
`;

const StyledLinkExtend = styled.a`
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIcon = styled.div`
  margin-right: 8px;
  width: 30px;
  min-width: 30px;
  height: 35px;
  text-align: center;
  img {
    height: 100%;
    max-height: 35px;
    max-width: 30px;
    object-fit: contain;
  }
`;

const StyledAddress = styled.div`
  display: flex;
  padding: 20px 10px 10px;
  width: 100%;
  align-items: center;
`;

const StyledIconTransfer = styled.img`
  width: 26px;
  margin-left: 16px;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 16px;
  `}
`;

const StyledAddressOwner = styled.div`
  display: flex;
  align-items: center;
`;

const AttributeData = ({ landDetail, setPopupTransfer, isOwner }) => {
  const { owner } = landDetail;
  const numSeasons = landDetail.season?.split(',')?.length;
  const contracts = networkConnectors.getContracts(56);

  function openPopupTransfer() {
    setPopupTransfer(true);
  }

  return (
    <div>
      <Title>About</Title>
      <Card>
        <StyledStats>
          <StyledWrapStat width="50%">
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-land.png')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Name</LabelAttribute>
              <div>{landDetail?.name || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat width="50%">
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-environment.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Environment</LabelAttribute>
              <div>{landDetail?.environment || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
        </StyledStats>
        <StyledStats>
          <StyledAddress>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-main.png')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Owner</LabelAttribute>
              <StyledAddressOwner>
                {owner ? (
                  <StyledLinkExtend
                    href={getEtherscanLink(undefined, owner, 'address')}
                    target="_blank"
                  >
                    {isAddressEqual(owner, contracts.AssetStoreContract) ? (
                      <span style={{ textDecoration: 'underline' }}>
                        Asset Store Contract
                      </span>
                    ) : (
                      owner
                    )}
                  </StyledLinkExtend>
                ) : (
                  '__'
                )}

                {isOwner && (
                  <Tooltip title={`Transfer NFT`}>
                    <StyledIconTransfer
                      src={require('../../assets/images/icon-transfer.png')}
                      alt=""
                      onClick={openPopupTransfer}
                    />
                  </Tooltip>
                )}
              </StyledAddressOwner>
            </StyledStat>
          </StyledAddress>
        </StyledStats>
      </Card>
      <Title style={{ marginTop: '10px' }}>Stats</Title>
      <Card>
        <StyledStats>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-rare.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Rare</LabelAttribute>
              <div>{landDetail.rare || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-birth.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Birth</LabelAttribute>
              <div>{landDetail.birth || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-season.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Season</LabelAttribute>
              <div>
                {numSeasons || numSeasons === 0
                  ? numSeasons === 1
                    ? `${numSeasons} season`
                    : `${numSeasons} seasons`
                  : '__'}
              </div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-level.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Level</LabelAttribute>
              <div>{landDetail.level || '__'}</div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-mutation.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Increase Mutant Rate</LabelAttribute>
              <div>
                {landDetail?.increaseMutantRate
                  ? `${bnum(landDetail?.increaseMutantRate?.toString())
                      .multipliedBy(100)
                      .toFixed()}%`
                  : '0%'}
              </div>
            </StyledStat>
          </StyledWrapStat>
          <StyledWrapStat>
            <StyledIcon>
              <img
                src={require('../../assets/images/market/icon-time-reduce.svg')}
                alt=""
              />
            </StyledIcon>
            <StyledStat>
              <LabelAttribute>Time Reduce</LabelAttribute>
              <div>
                {landDetail?.timeReduce
                  ? `${bnum(landDetail?.timeReduce?.toString())
                      .multipliedBy(100)
                      .toFixed()}%`
                  : '0%'}
              </div>
            </StyledStat>
          </StyledWrapStat>
        </StyledStats>
      </Card>
      <br />
      <Card>
        <LabelAttribute>Description</LabelAttribute>
        <div>{landDetail?.description}</div>
      </Card>
    </div>
  );
};

export default AttributeData;
