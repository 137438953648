import { Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import YourListItem from '../../components/items/yourListItem';
import YourListLand from '../../components/lands/yourListLand';
import GA from '../../utils/ga';

const WalletInventoryStyled = styled.div`
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyleCardHeader = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: revert;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CommonTabs = styled(props => (
  <Tabs classes={{ indicator: 'indicator' }} {...props} />
))`
  & .indicator {
    background-color: ${({ theme }) => theme.primary};
    height: 3px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

function a11yProps(index) {
  return {
    id: `common-tab-${index}`,
    'aria-controls': `offer-detail-tabpanel-${index}`,
  };
}

const StyledTabTitle = styled.div`
  display: flex;
  padding: 10px 6px;
  align-items: center;
  font-weight: bold;
  img {
    margin-right: 10px;
    height: 29px;
  }
  white-space: nowrap;
`;
const CommonTab = styled(({ index, ...props }) => (
  <Tab
    classes={{ selected: 'selected' }}
    id={`councilTab-tab-${index}`}
    aria-controls={`councilTab-tabpanel-${index}`}
    {...props}
  />
))`
  && {
    font-size: 1em;
    font-weight: 700;
    text-transform: none;
    min-width: unset;
  }
`;

const WalletInventory = observer(() => {
  const [valueTab, setValueTab] = useState(0);

  useEffect(() => {
    GA.mountedPage({
      page: 'wallet-inventory',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeTab(e, value) {
    setValueTab(value);
  }
  return (
    <WalletInventoryStyled>
      <StyleCardHeader>
        <CommonTabs
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="tabs"
          classes={{ indicator: 'indicator' }}
        >
          <CommonTab
            label={
              <StyledTabTitle>
                <img src={require('../../assets/images/tab-land.png')} alt="" />
                Lands
              </StyledTabTitle>
            }
            {...a11yProps(0)}
          />
          <CommonTab
            label={
              <StyledTabTitle>
                <img src={require('../../assets/images/tab-item.png')} alt="" />
                Items
              </StyledTabTitle>
            }
            {...a11yProps(1)}
          />
        </CommonTabs>
        <TabPanel value={valueTab} index={0}>
          <YourListLand />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <YourListItem />
        </TabPanel>
      </StyleCardHeader>
    </WalletInventoryStyled>
  );
});

export default WalletInventory;
