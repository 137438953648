import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { AutoColumn } from '../../components/Column';
import {
  COLOR_ENVIRONMENT,
  COLOR_RARE,
  COLOR_SEASON,
  SIDEBAR_WITH,
} from '../../constants';
import { useStores } from '../../contexts/storesContext';
import { MAX_LEVEL, MIN_LEVEL } from '../../stores/WlandStore';

const StyledTabFilter = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled.div`
  font-size: 20px;
`;

const StyledTagsFilter = styled.div``;
const StyledLabelTag = styled.div`
  font-size: 12px;
  color: #fbfbfb;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
`;

const StyledFilterTags = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const StyledFilterTag = styled.div`
  width: 50%;
  margin-bottom: 10px;
`;
const StyledSidebar = styled.div`
  width: ${SIDEBAR_WITH}px;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  padding: 32px 21px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `}
`;

const StyledTag = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 4px;
  line-height: 18px;
  max-width: 76px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
`;

export function valuetext(value) {
  return `${value} lv`;
}

const marks = [
  {
    value: MIN_LEVEL,
    label: MIN_LEVEL,
  },
  {
    value: MAX_LEVEL,
    label: MAX_LEVEL,
  },
];

const SidebarLand = observer(() => {
  const {
    root: { wlandStore },
  } = useStores();
  const {
    environments,
    setEnvironments,
    rares,
    setRares,
    seasons,
    setSeasons,
    itemId,
    setItemId,
    sale,
    setSale,
    sortBy,
    setSortBy,
    clearFilter,
    rangePrice,
    setRangePrice,
    rangeLV,
    setRangeLV,
    statusPacked,
    setPacked,
  } = wlandStore;

  const handleCheckedEnvironment = (id: string) => {
    const environmentsClone = [...environments];
    const index = environments.findIndex(obj => {
      return obj.id === id;
    });
    if (index !== -1) {
      environmentsClone[index].checked = !environmentsClone[index].checked;
    }
    setEnvironments(environmentsClone);
  };

  const handleCheckedRare = id => {
    const raresClone = [...rares];
    const index = rares.findIndex(obj => {
      return obj.id === id;
    });
    if (index !== -1) {
      raresClone[index].checked = !raresClone[index].checked;
    }
    setRares(raresClone);
  };

  const handleCheckedSeason = id => {
    const seasonsClone = [...seasons];
    const index = seasons.findIndex(obj => {
      return obj.id === id;
    });
    if (index !== -1) {
      seasonsClone[index].checked = !seasonsClone[index].checked;
    }
    setSeasons(seasonsClone);
  };

  function handleChainId(e: ChangeEvent<HTMLInputElement>) {
    setItemId(e.target.value);
  }

  const handleChangeSelectFilter = (event: ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    if (value?.includes('mutant-rate') || value?.includes('mutant-rate')) {
      setPacked('false');
    }
    setSortBy(event.target.value as string);
  };

  const handleChangeSale = (event: ChangeEvent<{ value: unknown }>) => {
    setSale(event.target.value as string);
  };

  const handleChainPriceMin = e => {
    setRangePrice([e.target.value, rangePrice[1]]);
  };

  const handleChainPriceMax = e => {
    setRangePrice([rangePrice[0], e.target.value]);
  };

  const handleChangeRangeLV = (event, newValue) => {
    setRangeLV(newValue);
  };

  const handleChangePacked = (event: ChangeEvent<{ value: unknown }>) => {
    setPacked(event.target.value as string);
  };

  return (
    <StyledSidebar className="scroll-custom">
      <StyledTabFilter>
        <StyledLabel>Filter</StyledLabel>
        <Button
          color="primary"
          onClick={clearFilter}
          variant="outlined"
          style={{ height: '30px' }}
        >
          Clear filter
        </Button>
      </StyledTabFilter>
      <StyledTagsFilter>
        <AutoColumn gap="16px">
          <TextField
            value={itemId}
            type="number"
            label="ID"
            variant="outlined"
            onChange={handleChainId}
            fullWidth={true}
          />
          <FormControl variant="outlined">
            <InputLabel id="filter">Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={handleChangeSelectFilter}
              labelId="filter"
              label={'Sort By'}
            >
              <MenuItem value={'lowest-price'}>Lowest Price</MenuItem>
              <MenuItem value={'highest-price'}>Highest Price</MenuItem>
              <MenuItem value={'lowest-increase-mutant-rate'}>
                Lowest Mutant Rate
              </MenuItem>
              <MenuItem value={'highest-increase-mutant-rate'}>
                Highest Mutant Rate
              </MenuItem>
              <MenuItem value={'lowest-time-reduce'}>
                Lowest Time Reduce
              </MenuItem>
              <MenuItem value={'highest-time-reduce'}>
                Highest Time Reduce
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="filter-for-sale">Filter for sale</InputLabel>
            <Select
              value={sale}
              onChange={handleChangeSale}
              labelId="filter-for-sale"
              label={'Filter for sale'}
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'order'}>On Sale</MenuItem>
              <MenuItem value={'not-order'}>Not for sale</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel id="filter-for-sale">Status</InputLabel>
            <Select
              value={statusPacked}
              onChange={handleChangePacked}
              labelId="filter-for-sale"
              label={'Status'}
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'true'}>Packed</MenuItem>
              <MenuItem value={'false'}>Unpacked</MenuItem>
            </Select>
          </FormControl>
        </AutoColumn>
        <br />
        <AutoColumn gap="16px">
          <StyledLabelTag>Price</StyledLabelTag>
          <TextField
            value={rangePrice[0] || ''}
            type="number"
            label="Min Price"
            variant="outlined"
            onChange={handleChainPriceMin}
            fullWidth={true}
          />
          <TextField
            value={rangePrice[1] || ''}
            type="number"
            label="Max Price"
            variant="outlined"
            onChange={handleChainPriceMax}
            fullWidth={true}
          />
          <StyledLabelTag>Rare</StyledLabelTag>
          <StyledFilterTags>
            {rares.map((obj, index) => {
              return (
                <StyledFilterTag key={obj.id + 'rares'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={obj.checked ? obj.checked : false}
                        onChange={() => {
                          handleCheckedRare(obj.id);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <StyledTag
                        color={COLOR_RARE[obj.id] ?? COLOR_RARE.default}
                      >
                        {obj.name}
                      </StyledTag>
                    }
                  />
                </StyledFilterTag>
              );
            })}
          </StyledFilterTags>

          <StyledLabelTag>Season</StyledLabelTag>
          <StyledFilterTags>
            {seasons.map((obj, index) => {
              return (
                <StyledFilterTag key={obj.id + 'seasons'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={obj.checked ? obj.checked : false}
                        onChange={() => {
                          handleCheckedSeason(obj.id);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <StyledTag
                        color={COLOR_SEASON[obj.id] ?? COLOR_SEASON.default}
                      >
                        {obj.name}
                      </StyledTag>
                    }
                  />
                </StyledFilterTag>
              );
            })}
          </StyledFilterTags>

          <StyledLabelTag>Level</StyledLabelTag>
          <Slider
            min={MIN_LEVEL}
            max={MAX_LEVEL}
            value={rangeLV}
            onChange={handleChangeRangeLV}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            marks={marks}
          />
          <StyledLabelTag>Environment</StyledLabelTag>
          <StyledFilterTags>
            {environments.map((obj, index) => {
              return (
                <StyledFilterTag key={obj.id + 'environments'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={obj.checked ? obj.checked : false}
                        onChange={() => {
                          handleCheckedEnvironment(obj.id);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <StyledTag
                        color={
                          COLOR_ENVIRONMENT[obj.id] ?? COLOR_ENVIRONMENT.default
                        }
                      >
                        {obj.name}
                      </StyledTag>
                    }
                  />
                </StyledFilterTag>
              );
            })}
          </StyledFilterTags>
        </AutoColumn>
      </StyledTagsFilter>
    </StyledSidebar>
  );
});

export default SidebarLand;
