import { css } from '@emotion/react';
import React from 'react';
import HashLoader from 'react-spinners/HashLoader';
import styled from 'styled-components';

const WrapContainer = styled.div`
  position: relative;
  text-align: center;
`;

const override = css`
  display: block;
  margin: 0 auto;
  padding: 50px 0 30px;
`;

// const Itemrow = styled.div`
//   border: 1px solid #fff;
//   border-radius: 5px;
//   svg {
//     width: 60px;
//     height: 28px;
//     margin: 4px 0px 0px 0px;
//   }
// `;

const Spinner = ({ loading }) => {
  return (
    <WrapContainer>
      <HashLoader css={override} color={'#ccc'} loading={loading} size={60} />
    </WrapContainer>
  );
};

export default Spinner;
