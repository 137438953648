import { Button } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TagID } from '../../components/Common';
import {
  ITEM_TYPE_ID,
  TypeCategory,
  TypeCategoryItem,
  TypeCategoryPet,
} from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useAuth from '../../hooks/useAuth';
import { NftMetaDataProps } from '../../types';
import {
  abbreviateNumber,
  amountFormat,
  bnum,
  fromWei,
} from '../../utils/helpers';
import { getColorByEnvironment } from '../../utils/land';

const StyledItem = styled(Link)`
  text-decoration: none;
  color: unset;
  width: 220px;
  min-height: 315px;
  height: calc(100% - 16px);
  margin: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  @media screen and (max-width: 1600px) {
    width: 208px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 16px;
  padding: 0 1rem;
  min-height: 41px;
`;

const StyledOrderPrice = styled.div`
  line-height: 1.3px;
  display: flex;
  align-items: center;
  font-weight: 500;
  width: max-content;
  //margin: auto;
  span {
    font-size: 18px;
  }
  .number-decimal {
    font-size: 16px;
    color: #98a0b9;
  }
`;

const StyledWrapPrice = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 120px;
  align-items: flex-end;
`;

const StyledContentCenter = styled.div<{ isTypeItem: boolean; bg: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 24px;
  ${({ isTypeItem, bg }) =>
    isTypeItem &&
    css`
      background: ${bg
        ? `url('${require('../../assets/images/bg-nft-item.png')}') center no-repeat`
        : 'none'};
    `}
  margin: 0 1rem 0.5rem;
  background-size: contain;
  img {
    max-width: 90%;
    padding: 6px 6px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 68%;
    `};
  }
`;

const StyledBottom = styled.div`
  padding: 0 12px 12px;
`;

const StyledName = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 0 12px 6px;
`;

const StyleWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const StyledWrapStat = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  max-width: ${({ width }) => width || '100px'};
  font-weight: 600;
  &:first-child {
    margin-right: 12px;
  }
`;

const StyledStat = styled.div``;

const StyledIcon = styled.div`
  margin-right: 5px;
  min-height: 29px;
  display: flex;
  align-items: center;
  img {
    height: 24px;
    object-fit: contain;
  }
`;

interface ItemsProps {
  item: NftMetaDataProps;
}

const Items = observer(({ item }: ItemsProps) => {
  const {
    root: { tokenStore, orderStore, providerStore },
  } = useStores();
  const checkAuth = useAuth();
  const metaDataContract = providerStore.getContractMetaData();
  const handleCancelOffer = (
    contractAddress: string,
    itemId: string | number
  ) => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    orderStore.cancelOffer({
      contractAddress,
      itemId,
    });
  };

  const isDeposited =
    item?.owner === '0x0000000000000000000000000000000000000000';
  const isTypeItem =
    ITEM_TYPE_ID[item.typeId]?.typeCategory === TypeCategory.ITEM;
  const isCategoryItem =
    ITEM_TYPE_ID[item?.typeId]?.category === TypeCategoryItem.GROWING_ITEM;
  const isCategoryPetCare =
    ITEM_TYPE_ID[item?.typeId]?.category === TypeCategoryPet.PET_CARE;
  return (
    <StyledItem
      to={!isDeposited && `/items/${item.itemId}`}
      style={{ cursor: isDeposited ? 'unset' : '' }}
    >
      <StyledHeader>
        <TagID color={getColorByEnvironment(item.environment)}>
          #{item.itemId}
        </TagID>
        {isDeposited ? (
          <TagID color={'#1cc48c'} style={{ margin: 0 }}>
            Deposited
          </TagID>
        ) : (
          <StyledWrapPrice>
            <StyledOrderPrice>
              <ShoppingCartIcon
                style={{ fontSize: '20px', marginRight: '3px' }}
              />
              {item.onSale ? (
                <>
                  <span>
                    {
                      abbreviateNumber(
                        amountFormat(fromWei(item.salePrice || '0'), 3),
                        0
                      )?.split('.')[0]
                    }{' '}
                  </span>
                  <span className="number-decimal">
                    {abbreviateNumber(
                      amountFormat(fromWei(item.salePrice || '0'), 3),
                      0
                    )?.split('.')[1] &&
                      `.${
                        abbreviateNumber(
                          amountFormat(fromWei(item.salePrice || '0'), 3),
                          0
                        )?.split('.')[1]
                      }`}{' '}
                  </span>
                </>
              ) : (
                '__'
              )}
            </StyledOrderPrice>
            <div style={{ color: '#98a0b9' }}>
              {item.onSale && tokenStore.priceWANA
                ? `≈ $${amountFormat(
                    bnum(fromWei(item.salePrice || '0')).multipliedBy(
                      bnum(tokenStore.priceWANA)
                    ),
                    0
                  )}`
                : ''}
            </div>
          </StyledWrapPrice>
        )}
      </StyledHeader>

      <StyleWrap>
        <StyledContentCenter
          isTypeItem={isTypeItem}
          bg={ITEM_TYPE_ID[item?.typeId]?.background}
        >
          <img src={ITEM_TYPE_ID[item?.typeId]?.image || item.image} alt="" />
        </StyledContentCenter>
      </StyleWrap>
      <StyledName className="text-truncate">{item.name}</StyledName>
      <StyledBottom>
        {isDeposited ? (
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleCancelOffer(metaDataContract.wNFTContract, item?.itemId)
              }
              size="small"
            >
              Cancel Offer
            </Button>
          </div>
        ) : (
          <StyledStats>
            {isTypeItem && isCategoryItem && (
              <>
                <StyledWrapStat className="text-truncate" width="30%">
                  <StyledIcon>
                    <img
                      src={require('../../assets/images/market/icon-wai.png')}
                      alt=""
                    />
                  </StyledIcon>
                  {/* <StyledStat>
                  <div>
                  </div>
                </StyledStat> */}
                </StyledWrapStat>
                <StyledWrapStat className="text-truncate" width="70%">
                  <StyledStat>
                    <div>
                      {item.quantityCanClaim?.toString() || '__'}/
                      {item.maxWaiCanClaim?.toString() || '__'}(
                      {item.maxWaiReward?.toString() || '__'})
                    </div>
                  </StyledStat>
                </StyledWrapStat>
              </>
            )}
            {isCategoryPetCare && (
              <>
                <StyledWrapStat>
                  <StyledIcon>
                    <img
                      src={require('../../assets/images/market/icon-rare.svg')}
                      alt=""
                    />
                  </StyledIcon>
                  <StyledStat>
                    <div>{item.rare || '__'}</div>
                  </StyledStat>
                </StyledWrapStat>
                <StyledWrapStat>
                  <StyledIcon>
                    <img
                      src={require('../../assets/images/market/icon-level.svg')}
                      alt=""
                    />
                  </StyledIcon>
                  <StyledStat>
                    <div>{item.level || '__'}</div>
                  </StyledStat>
                </StyledWrapStat>
              </>
            )}
          </StyledStats>
        )}
      </StyledBottom>
    </StyledItem>
  );
});

export default Items;
