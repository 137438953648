import { Button } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TagID } from '../../components/Common';
import {
  ITEM_TYPE_ID,
  TypeCategory,
  TypeCategoryItem,
  TypeCategoryPet,
} from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useAuth from '../../hooks/useAuth';
import { NftMetaDataProps } from '../../types';
import {
  abbreviateNumber,
  amountFormat,
  bnum,
  fromWei,
} from '../../utils/helpers';
import { getColorByEnvironment } from '../../utils/land';
import { getAttributeByName } from '../../utils/subgraph';

const StyledWrapStat = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '33.33%'};
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 50%;
    padding: 10px 4px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    padding: 5px 4px;
  `}
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  max-width: calc(100% - 45px);
`;

const StyledIcon = styled.div`
  margin-right: 8px;
  width: 30px;
  min-width: 30px;
  height: 35px;
  text-align: center;
  img {
    height: 100%;
    max-height: 35px;
    max-width: 30px;
    object-fit: contain;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-height: 32px;
      max-width: 28px;
    `}
  }
`;

const LabelAttribute = styled.div`
  font-weight: 700;
  color: #1dc48c;
  font-size: 16px;
  @media screen and (max-width: 1500px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyledItem = styled.div`
  text-decoration: none;
  color: unset;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 12px 12px;
  `};
`;

const StyledItemWithLink = styled(Link)`
  text-decoration: none;
  color: unset;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
  padding: 0 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 12px 12px;
  `};
`;

const StyledOrderPrice = styled.div`
  line-height: 1.3px;
  font-weight: 500;
  span {
    font-size: 20px;
  }
  .number-decimal {
    font-size: 18px;
    color: #98a0b9;
  }
  @media screen and (max-width: 1500px) {
    span {
      font-size: 18px;
    }
    .number-decimal {
      font-size: 16px;
    }
  }
`;

const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 12%;
  padding: 10px;
  min-height: 165px;
  height: auto;
  max-width: 155px;
  @media screen and (max-width: 1700px) {
    min-height: 130px;
  }
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
    padding: 10px 4px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    min-width: 155px;
  }
`;

const StyledHeaderInfo = styled.div`
  display: grid;
  grid-gap: 10px;
  height: max-content;
  width: 14%;
  padding: 10px;
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
    padding: 10px 4px;
  }
`;

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  @media screen and (max-width: 1439px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    order: 2;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const StyledBottom = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 14%;
  padding: 10px;
  white-space: nowrap;
  @media screen and (max-width: 1439px) {
    width: 33%;
  }
  @media screen and (max-width: 960px) {
    width: 50%;
    padding: 10px 4px;
    order: 3;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
`;

const StyledImageItem = styled.div<{ bg: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bg }) =>
    bg
      ? `url('${require('../../assets/images/bg-nft-item.png')}') center no-repeat`
      : 'none'};
  background-size: contain;
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    padding: 6px;
  }
`;

const StyledImageCommon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    height: auto;
    width: 100%;
  }
`;

const StyleName = styled.div``;

interface ViewListItemProps {
  item: NftMetaDataProps;
  type: 'nftItem' | 'metaData';
  actionNode?: React.ReactNode;
}

const ListItem = observer(({ item, type = 'nftItem', actionNode }) => {
  const {
    root: { tokenStore, orderStore, providerStore },
  } = useStores();
  const checkAuth = useAuth();
  const metaDataContract = providerStore.getContractMetaData();

  const handleCancelOffer = (
    contractAddress: string,
    itemId: string | number
  ) => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    orderStore.cancelOffer({
      contractAddress,
      itemId,
    });
  };

  const isDataWallet = type === 'nftItem';

  const itemId = item?.itemId;
  const gameId = item?.id;
  const isDeposited =
    item?.owner === '0x0000000000000000000000000000000000000000';
  const isTypeItem =
    ITEM_TYPE_ID[item.typeId]?.typeCategory === TypeCategory.ITEM;
  const isCategoryItem =
    ITEM_TYPE_ID[item?.typeId]?.category === TypeCategoryItem.GROWING_ITEM;
  const isCategoryBuilding =
    ITEM_TYPE_ID[item?.typeId]?.category === TypeCategoryItem.BUILDING_ITEM;
  const isCategoryPetCare =
    ITEM_TYPE_ID[item?.typeId]?.category === TypeCategoryPet.PET_CARE;
  if (item && item?.attributes) {
    item.quality = getAttributeByName('Quality', item?.attributes)?.value;
    // item.qualityBonusRate = getAttributeByName('QualityBonusRate', item?.attributes)?.value;
    item.weight = getAttributeByName('Weight', item?.attributes)?.value;
    item.category = getAttributeByName('Category', item?.attributes)?.value;
    item.maxSlots = getAttributeByName('MaxSlots', item?.attributes)?.value;
    item.maxHealthBalance = getAttributeByName(
      'MaxHealthBalance',
      item?.attributes
    )?.value;
    item.maxSatietyBalance = getAttributeByName(
      'MaxSatietyBalance',
      item?.attributes
    )?.value;
    item.maxEnergyBalance = getAttributeByName(
      'MaxEnergyBalance',
      item?.attributes
    )?.value;
    item.level = getAttributeByName('Level', item?.attributes)?.value;
    item.rare = getAttributeByName('Rare', item?.attributes)?.value;

    item.maxWaiReward = getAttributeByName(
      'MaxWaiReward',
      item?.attributes
    )?.value;
    item.maxWaiCanClaim = getAttributeByName(
      'MaxWaiCanClaim',
      item?.attributes
    )?.value;
    item.quantityCanClaim = getAttributeByName(
      'QuantityCanClaim',
      item?.attributes
    )?.value;
  }
  return (
    <>
      <StyledHeader>
        {isTypeItem && isCategoryItem ? (
          <StyledImageItem bg={ITEM_TYPE_ID[item?.typeId]?.background}>
            <img
              src={ITEM_TYPE_ID[item?.typeId]?.image || item?.image}
              alt=""
            />
          </StyledImageItem>
        ) : (
          <StyledImageCommon>
            <img
              src={ITEM_TYPE_ID[item?.typeId]?.image || item?.image}
              alt=""
            />
          </StyledImageCommon>
        )}
      </StyledHeader>
      <StyledHeaderInfo>
        {isDeposited && (
          <TagID color={'#1cc48c'} style={{ margin: 0 }}>
            Deposited
          </TagID>
        )}
        <TagID
          color={getColorByEnvironment(item?.environment)}
          to={itemId && actionNode && `/items/${itemId}`}
        >
          {itemId ? `#${itemId}` : `#G-${gameId}`}
        </TagID>
        <StyleName className="text-truncate">{item?.name}</StyleName>
        {isDataWallet && actionNode && (
          <Link style={{ color: '#1cc48c' }} to={`/items/${item?.itemId}`}>
            View
          </Link>
        )}
      </StyledHeaderInfo>

      <StyledStats>
        {isTypeItem && isCategoryItem && (
          <>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-level.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Quality {item.quality}</LabelAttribute>
                <div>{amountFormat(item.quality?.toString(), 2) || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-kg.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Weight</LabelAttribute>
                <div>{amountFormat(item.weight?.toString(), 2) || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-category.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Category</LabelAttribute>
                <div>{item.category || '__'}</div>
              </StyledStat>
            </StyledWrapStat>

            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-wai.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Max Reward</LabelAttribute>
                <div>{item.maxWaiReward?.toString() || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-wai.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Remaining</LabelAttribute>
                <div>{item.maxWaiCanClaim?.toString() || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-wai.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Claimable</LabelAttribute>
                <div>{item.quantityCanClaim?.toString() || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
          </>
        )}
        {isCategoryBuilding && (
          <>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-level.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Level</LabelAttribute>
                <div>{item.level || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-category.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Category</LabelAttribute>
                <div>{item.category || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
          </>
        )}
        {isCategoryPetCare && (
          <>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-rare.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Rare</LabelAttribute>
                <div>{item.rare || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-level.svg')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Level</LabelAttribute>
                <div>{item.level || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-slot.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Max slot</LabelAttribute>
                <div>{item.maxSlots || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-health.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Max Health</LabelAttribute>
                <div>{item.maxHealthBalance || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-satiety.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Max Satiety</LabelAttribute>
                <div>{item.maxSatietyBalance || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
            <StyledWrapStat>
              <StyledIcon>
                <img
                  src={require('../../assets/images/market/icon-energy.png')}
                  alt=""
                />
              </StyledIcon>
              <StyledStat>
                <LabelAttribute>Max Energy</LabelAttribute>
                <div>{item.maxEnergyBalance || '__'}</div>
              </StyledStat>
            </StyledWrapStat>
          </>
        )}
      </StyledStats>

      {isDataWallet && (
        <StyledBottom>
          <StyledOrderPrice>
            <ShoppingCartIcon
              style={{ fontSize: '20px', marginRight: '3px' }}
            />
            {item.onSale ? (
              <>
                <span>
                  {
                    abbreviateNumber(
                      amountFormat(fromWei(item.salePrice || '0'), 3),
                      0
                    )?.split('.')[0]
                  }{' '}
                </span>
                <span className="number-decimal">
                  {abbreviateNumber(
                    amountFormat(fromWei(item.salePrice || '0'), 3),
                    0
                  )?.split('.')[1] &&
                    `.${
                      abbreviateNumber(
                        amountFormat(fromWei(item.salePrice || '0'), 3),
                        0
                      )?.split('.')[1]
                    }`}{' '}
                </span>
              </>
            ) : (
              '__'
            )}
          </StyledOrderPrice>
          <div style={{ color: '#98a0b9' }}>
            {item.onSale && tokenStore.priceWANA
              ? `≈ $${amountFormat(
                  bnum(fromWei(item.salePrice || '0')).multipliedBy(
                    bnum(tokenStore.priceWANA)
                  ),
                  0
                )}`
              : ''}
          </div>
        </StyledBottom>
      )}
      {actionNode}
      {isDeposited && (
        <div style={{ textAlign: 'center', padding: '10px 10px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              handleCancelOffer(metaDataContract.wNFTContract, item?.itemId)
            }
            size="small"
          >
            Cancel Offer
          </Button>
        </div>
      )}
    </>
  );
});

const ViewListItem = ({ item, type, actionNode }: ViewListItemProps) => {
  const isDeposited =
    item?.owner === '0x0000000000000000000000000000000000000000';

  return actionNode ? (
    <StyledItem>
      <ListItem item={item} actionNode={actionNode} type={type} />
    </StyledItem>
  ) : (
    <StyledItemWithLink
      to={!isDeposited && `/items/${item.itemId}`}
      style={{ cursor: isDeposited ? 'unset' : '' }}
    >
      <ListItem item={item} actionNode={actionNode} type={type} />
    </StyledItemWithLink>
  );
};

export default ViewListItem;
