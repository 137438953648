import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import useFetchItemData from '../../hooks/useFetchItemData';
import SidebarItem from '../../pages/Items/SidebarItem';
import ActionList from './actionList';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledMainContent = styled.div`
  flex: 1;
  margin: 0 auto;
  padding: 0px 8px;
`;

const StyledFormControl = styled.div`
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 1rem;
  justify-content: space-between;
  margin: 0 -10px 1rem;
`;

const StyledTotal = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

const StyledMenuFilter = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${({ theme }) => theme.cardBG};
  position: absolute;
  z-index: 2;
  right: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  animation: fade-in 0.3s forwards;
  .fade-in {
    animation: fade-in 0.3s forwards;
  }
  .fade-out {
    animation: fade-out 0.3s forwards;
  }
`;

// let first = true;

const LandTab = observer(() => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleClick = event => {
    setOpen(oldState => !oldState);
  };

  const {
    totalNftItems,
    listItems,
    page,
    loading,
    handleChangePage,
    limitItemByScreen,
  } = useFetchItemData({ modeOwned: true, initFilter: true });

  return (
    <StyledContainer>
      <StyledMainContent>
        <StyledHeader>
          <StyledFormControl>
            <StyledTotal>{totalNftItems} items</StyledTotal>
            <div>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                onClick={handleClick}
                size="small"
              >
                Filters
              </Button>
              <StyledMenuFilter open={isOpen}>
                <SidebarItem isOwned={true} />
              </StyledMenuFilter>
            </div>
          </StyledFormControl>
        </StyledHeader>
        <ActionList
          listData={listItems}
          loading={loading}
          type="nftItem"
          page={page}
          onChangePage={handleChangePage}
          totalPage={Math.ceil(totalNftItems / limitItemByScreen)}
        />
      </StyledMainContent>
    </StyledContainer>
  );
});

export default LandTab;
