import { action, observable } from 'mobx';
import RootStore from './Root';

export default class DropdownStore {
  @observable public walletDropdownVisible: boolean;
  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.walletDropdownVisible = false;
  }

  @action public toggleWalletDropdown = () => {
    this.walletDropdownVisible = !this.walletDropdownVisible;
  };

  @action public setWalletDropdownVisible = (visible: boolean) => {
    this.walletDropdownVisible = visible;
  };
}
