import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ITEM_TYPE_ID } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import { NftMetaDataProps, TokenProps } from '../../types';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import ViewListItem from './viewListItem';
import { getAttributeByName } from '../../utils/subgraph';
import { NoDataText } from '../Common';

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: calc(100vh - 540px);
  `};
`;

const StyledAction = styled.div`
  text-align: right;
  width: 10%;
  margin-left: auto;
  padding: 10px;
  @media screen and (max-width: 1439px) {
    width: 33%;
    text-align: left;
  }
  @media screen and (max-width: 960px) {
    padding: 10px 4px;
    width: 50%;
    order: 1;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    text-transform: capitalize;
  }
`;

const StyledHeaderAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

interface ActionListProps {
  listData: NftMetaDataProps[];
  loading?: boolean;
  type: 'nftItem' | 'metaData';
  page: number;
  totalPage: number;
  onChangePage: any;
}

const ActionList = observer(
  ({
    listData,
    loading,
    type,
    page,
    onChangePage,
    totalPage,
  }: ActionListProps) => {
    const {
      root: {
        providerStore,
        dropdownStore,
        userStore,
        notificationStore,
        withdrawStore,
      },
    } = useStores();
    const { account } = providerStore.providerStatus;
    const { setPopupDeposit, profile } = userStore;
    const { setPopupWithdraw } = withdrawStore;
    const [listChecked, setListChecked] = useState([]);
    const [typeId, setTypeId] = useState<number>(0);
    const isDataWallet = type === 'nftItem';

    const onDeposit = () => {
      if (!account) {
        dropdownStore.toggleWalletDropdown();
        return;
      }
      setPopupDeposit({
        open: true,
        isEdit: false,
        param: listChecked,
        token: TokenProps.NFTITEM,
      });
      setListChecked([]);
    };

    const onWithdraw = () => {
      if (!profile.address) {
        notificationStore.showErrorNotification('Please login again');
        return;
      }
      setPopupWithdraw({
        open: true,
        isEdit: false,
        typeId,
        param: listChecked,
        token: TokenProps.NFTITEM,
      });
      setListChecked([]);
    };

    const onChangeChecked = (
      event: React.ChangeEvent<HTMLInputElement>,
      item: any
    ) => {
      event.persist();
      const metaData = item;
      const id = isDataWallet ? metaData.itemId : metaData.id;
      setListChecked(dataChecked => {
        if (event.target.checked) {
          const dataCheckedClone = JSON.parse(JSON.stringify(dataChecked));
          if (
            isDataWallet &&
            ITEM_TYPE_ID[metaData?.typeId] &&
            !ITEM_TYPE_ID[metaData.typeId]?.deposable
          ) {
            notificationStore.showNotification(
              `The ${
                ITEM_TYPE_ID[metaData.typeId]?.name
              } is temporarily unable to deposit`,
              'warning'
            );
            return JSON.parse(JSON.stringify(dataChecked));
          }
          if (isDataWallet) {
            // is type deposit
            dataChecked.push(id);
            return JSON.parse(JSON.stringify(dataChecked));
          }
          // withdraw nft in-game
          dataChecked.push(id);
          setTypeId(metaData.typeId);

          if (typeId && metaData.typeId !== typeId) {
            notificationStore.showNotification(
              'Please select only items of the same type',
              'info'
            );
            return [id];
          }
          if (dataCheckedClone.length >= 10) {
            notificationStore.showNotification(
              'The maximum quantity of items per withdrawal is 10',
              'warning'
            );
            return JSON.parse(JSON.stringify(dataCheckedClone)) || [];
          }
        } else {
          const index = dataChecked.indexOf(id);
          if (index > -1) {
            dataChecked.splice(index, 1);
          }
          if (dataChecked.length === 0) {
            setTypeId(0);
          }
        }
        return JSON.parse(JSON.stringify(dataChecked)) || [];
      });
    };

    const isDataEmpty = _.isEmpty(listData);
    return (
      <>
        <StyledHeaderAction>
          {isDataWallet && !isDataEmpty ? (
            <StyledButton
              variant="contained"
              color="primary"
              onClick={onDeposit}
              disabled={_.isEmpty(listChecked)}
            >
              Deposit Selected Items
            </StyledButton>
          ) : !isDataEmpty ? (
            <StyledButton
              variant="contained"
              color="primary"
              className="primary2"
              onClick={onWithdraw}
              disabled={_.isEmpty(listChecked)}
            >
              Withdraw Selected Items
            </StyledButton>
          ) : null}
        </StyledHeaderAction>

        <StyledItems>
          {listData?.map((item, index) => (
            <ViewListItem
              item={item}
              key={index}
              type={type}
              actionNode={
                <StyledAction>
                  <Tooltip
                    title={
                      (ITEM_TYPE_ID[item?.typeId] &&
                      !ITEM_TYPE_ID[item?.typeId]?.deposable
                        ? `The ${
                            ITEM_TYPE_ID[item.typeId]?.name
                          } is temporarily unable to deposit`
                        : '') ||
                      (!getAttributeByName('Withdrawable', item?.attributes)
                        ?.value && !isDataWallet
                        ? 'Please put the item in inventory to withdraw'
                        : '')
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={listChecked.includes(
                            item?.itemId || item?.id
                          )}
                          onChange={e => onChangeChecked(e, item)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      disabled={
                        (ITEM_TYPE_ID[item?.typeId] &&
                          !ITEM_TYPE_ID[item?.typeId]?.deposable) ||
                        (!getAttributeByName('Withdrawable', item?.attributes)
                          ?.value &&
                          !isDataWallet)
                      }
                      label={isDataWallet ? 'Deposit' : 'Withdraw'}
                    />
                  </Tooltip>
                </StyledAction>
              }
            />
          ))}
          <div>
            <Spinner loading={loading} />
            {isDataEmpty && !loading ? (
              <NoDataText size="18px" />
            ) : (
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
                variant="outlined"
                color="primary"
                size="large"
                page={page}
                count={totalPage}
                onChange={onChangePage}
              />
            )}
          </div>
        </StyledItems>
      </>
    );
  }
);

export default ActionList;
