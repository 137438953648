import {
  Button,
  // Checkbox,
  FormControl,
  // FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { LoadingSpinner } from '../theme/components';
import { TokenParamProps, TokenProps } from '../types';
import { abbreviateNumber, bnum, numberFormat } from '../utils/helpers';
// import CountdownTimer from './CountdownTimer';
import PopupConfirm from './PopupConfirm';

enum ButtonState {
  NO_WALLET,
  WITHDRAW,
}

const ButtonText = ['Connect Wallet', 'Withdraw'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  max-height: calc(100vh - 30px);
  overflow: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
  .no-wrap {
    white-space: nowrap;
    min-width: 80px;
  }
  .MuiFormHelperText-contained {
    margin-left: 10px;
    margin-right: 0;
  }
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

// const StyledCountdownTimer = styled(CountdownTimer)`
//   font-family: ChakraPetch, 'UTM Colossalis', sans-serif;
//   font-size: 20px;
//   line-height: 1;
//   margin: 0 15px;
//   font-weight: bold;
//   color: ${({ theme }) => theme.primary3};
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     font-size: 18px;
//   `}
// `;

const StyledEndAdornment = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBottom = styled.div<{ columns }>`
  display: grid;
  grid-gap: 1rem;
  margin-top: 10px;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
`;

const StyledWarning = styled.div`
  line-height: 1.2;
  font-size: 14px;
  text-algin: center;
  color: #bebfc5;
  span {
    color: #fea500;
    font-weight: 500;
    fontsize: 16px;
  }
`;

const StyledWarningHelp = styled.span`
  color: #fea500;
`;

const MAX_WITHDRAW = {
  WANA: 500,
  WAI: 25000,
};

const MIN_WITHDRAW = {
  WANA: 500,
  WAI: 5000,
};

const PopupWithdraw = observer(() => {
  const {
    root: { userStore, assetStore, tokenStore, withdrawStore },
  } = useStores();
  const TOKENS = assetStore.getTokensConfig();
  const ARRAY_TOKENS = Object.values(TOKENS);
  const [isLoading, setLoading] = useState<boolean>(false);
  // const [isLoadingCode, setLoadingCode] = useState<boolean>(false);
  const [code, setCode] = useState<number | ''>('');
  // const [isWithdrawAll, setWithdrawAll] = useState<boolean>(false);
  // const [timeCodeExpired, setTimeCodeExpired] = useState<number>(
  //   moment()
  //     .add(4, 'm')
  //     .unix()
  // );
  const withdrawFormRef = useRef(null);
  const {
    // profile,
    balances,
    getBalance,
  } = userStore;
  const { address } = userStore.profile;
  const {
    popupWithdraw,
    feeWithdraw,
    getFeeWithdrawWai,
    handleContractWithdraw,
    callApiWithdraw,
    setPopupWithdraw,
    setPopupConfirm,
  } = withdrawStore;
  const [openConfirm, setConfirm] = useState(false);
  // const [hasGetCode, setGetCode] = useState<boolean>(false);
  const isTokenAmount =
    TOKENS[popupWithdraw.token].param === TokenParamProps.amount;
  const isWland = TOKENS[popupWithdraw.token].value === TokenProps.WLAND;
  const isNFTItem = TOKENS[popupWithdraw.token].value === TokenProps.NFTITEM;
  const isTokenNFT = isNFTItem || isWland;
  const isWANA = TOKENS[popupWithdraw.token].value === TokenProps.WANA;
  const isWAI = TOKENS[popupWithdraw.token].value === TokenProps.WAI;

  useEffect(() => {
    ValidatorForm.addValidationRule('amountToken', value => {
      return value === '' || bnum((value && value.toString()) || '0').gt(0);
    });
    return () => {
      ValidatorForm.removeValidationRule('amountToken');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isWAI && popupWithdraw.open) {
      getFeeWithdrawWai();
    }
  }, [getFeeWithdrawWai, isWAI, popupWithdraw.open]);

  const resetForm = useCallback(() => {
    setCode('');
    setLoading(false);
    // setWithdrawAll(false);
    if (withdrawFormRef.current) {
      withdrawFormRef.current.resetValidations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (popupWithdraw.open) {
      resetForm();
    }
  }, [popupWithdraw.open, resetForm]);

  const getButtonState = (accountParam): ButtonState => {
    if (!accountParam) {
      return ButtonState.NO_WALLET;
    }
    return ButtonState.WITHDRAW;
  };

  const getButtonText = (buttonStateParam: ButtonState): string => {
    return ButtonText[buttonStateParam];
  };

  function handleClose() {
    setPopupWithdraw({ open: false, param: '' });
  }

  function handleChangeAmount(e) {
    setPopupWithdraw({ param: e.target.value });
    if (code) {
      setCode('');
    }
  }

  function handleChangeCode(e) {
    setCode(e.target.value);
    // if(e.target.value?.toString()?.length >= 6 && popupWithdraw.param?.toString()) {
    //   handleWithdraw()
    // }
  }

  function handleChangeSelectToken(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    setPopupWithdraw({ token: event.target.value as any });
  }

  const hasFeeWithdrawWai = isWAI && feeWithdraw.wai.feePercent;

  function handleWithdraw(confirmWai = false) {
    withdrawFormRef.current
      .isFormValid()
      .then(res => {
        if (hasFeeWithdrawWai && !confirmWai) {
          setConfirm(true);
          return false;
        }
        if (res) {
          setLoading(true);
          setConfirm(false);
          return callApiWithdraw(code);
        } else {
          Promise.reject('Check Your Withdraw Form');
        }
      })
      .then(res => {
        if (!res) {
          return;
        }
        getFeeWithdrawWai();
        resetForm();
        getBalance();
        const { result2, id } = res;
        handleContractWithdraw(
          result2,
          id,
          TOKENS[popupWithdraw.token].historyType,
          TOKENS[popupWithdraw.token].value
        );
        setPopupConfirm(true);
        if (result2) {
          handleClose();
        }
      })
      .catch(err => {
        console.error('Error handleWithdraw: ', err);
        setConfirm(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // function onChangeStateWithdraw(event: React.ChangeEvent<HTMLInputElement>) {
  //   setWithdrawAll(event.target.checked);
  // }

  const buttonState = getButtonState(address);

  // const getCode = () => {
  //   setLoadingCode(true);
  //   getCodeVerifyWithdraw()
  //     .then(res => {
  //       const { ttl } = res;
  //       setTimeCodeExpired(
  //         moment()
  //           .add(ttl, 'seconds')
  //           .unix()
  //       );
  //       notificationStore.showSuccessNotification(
  //         'Open the email and find the verification code'
  //       );
  //       setGetCode(true);
  //     })
  //     .finally(() => {
  //       setLoadingCode(false);
  //     });
  // };

  // const renderer = ({ hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     return null;
  //   } else {
  //     return <span>{hours * 60 * 60 + minutes * 60 + seconds} s</span>;
  //   }
  // };

  const onMaxBalance = () => {
    if (TOKENS[popupWithdraw.token].value === 'WANA') {
      if (
        bnum(balances.wanaBalance)
          .multipliedBy(bnum(tokenStore.priceWANA))
          .gt(MAX_WITHDRAW.WANA)
      ) {
        tokenStore.getUSDPriceWana().then(price => {
          setPopupWithdraw({
            param: numberFormat(bnum(490).dividedBy(price), 1),
          });
        });
      } else {
        setPopupWithdraw({ param: balances.wanaBalance });
      }
    } else if (TOKENS[popupWithdraw.token].value === 'WAI') {
      if (bnum(balances.waiBalance).gt(MAX_WITHDRAW.WAI)) {
        setPopupWithdraw({
          param: MAX_WITHDRAW.WAI,
        });
      } else {
        setPopupWithdraw({ param: balances.waiBalance });
      }
    }
  };

  const labelParam = isTokenNFT ? 'Item Id' : 'Amount';

  const buttonActionHandler = (buttonStateParam: ButtonState) => {
    switch (buttonStateParam) {
      case ButtonState.NO_WALLET:
        // dropdownStore.toggleWalletDropdown();
        break;
      case ButtonState.WITHDRAW:
        handleWithdraw();
        break;
      default:
        throw new Error('Invalid button state');
    }
  };
  const handleSubmit = () => {
    buttonActionHandler(buttonState);
  };

  function onConfirm() {
    handleWithdraw(true);
  }

  return (
    <>
      <StyledModal open={popupWithdraw.open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <h2>WITHDRAW</h2>
            <ValidatorForm
              onSubmit={handleSubmit}
              ref={withdrawFormRef}
              style={{ with: '100%' }}
            >
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="filter">Token</InputLabel>
                <Select
                  value={popupWithdraw.token}
                  onChange={handleChangeSelectToken}
                  labelId="filter"
                  label={'Token'}
                  disabled={true}
                >
                  {ARRAY_TOKENS.map(tokenItem => (
                    <MenuItem
                      value={tokenItem.value}
                      key={tokenItem.value}
                      disabled={
                        TOKENS[tokenItem.value].value === TokenProps.WLAND ||
                        TOKENS[tokenItem.value].value === TokenProps.NFTITEM
                      }
                    >
                      <StyledToken>
                        <img src={tokenItem.logo} alt="" height="25px" />
                        {tokenItem.text}
                      </StyledToken>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth={true}>
                <TextValidator
                  validators={['required']}
                  errorMessages={['This field is required']}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  id="your-wallet"
                  label="Your Wallet"
                  name="your-wallet"
                  value={address}
                  disabled={true}
                />
              </FormControl>
              {/* {isWland && (
                <StyledCheckbox>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWithdrawAll}
                        onChange={onChangeStateWithdraw}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Withdraw All"
                  />
                </StyledCheckbox>
              )} */}

              {isTokenNFT ? (
                <FormControl fullWidth={true}>
                  <TextValidator
                    validators={['required']}
                    required={true}
                    fullWidth={true}
                    value={popupWithdraw.param?.toString()}
                    margin="normal"
                    label={labelParam}
                    onChange={handleChangeAmount}
                    disabled={!popupWithdraw.isEdit}
                    autoFocus={true}
                    variant="outlined"
                    errorMessages={['This field is required']}
                    type={isTokenNFT ? 'string' : 'number'}
                  />
                </FormControl>
              ) : (
                <FormControl fullWidth={true}>
                  <TextValidator
                    validators={['required', 'amountToken']}
                    required={true}
                    fullWidth={true}
                    value={popupWithdraw.param?.toString()}
                    margin="normal"
                    label={labelParam}
                    onChange={handleChangeAmount}
                    disabled={!popupWithdraw.isEdit}
                    autoFocus={true}
                    helperText={
                      <StyledWarningHelp>
                        {isTokenAmount
                          ? `${
                              isWANA
                                ? `The max ${
                                    popupWithdraw.token
                                  } you can withdraw each 24hrs is ${
                                    MAX_WITHDRAW[popupWithdraw.token]
                                  }$ equivalent`
                                : `The amount you can withdraw each 14 days is ${abbreviateNumber(
                                    MIN_WITHDRAW[popupWithdraw.token]
                                  )} minimum and ${abbreviateNumber(
                                    MAX_WITHDRAW[popupWithdraw.token]
                                  )} maximum`
                            }`
                          : ''}
                      </StyledWarningHelp>
                    }
                    InputProps={{
                      endAdornment: (
                        <StyledEndAdornment>
                          {isTokenAmount && (
                            <Button
                              variant="outlined"
                              color="primary"
                              className="primary2"
                              size="small"
                              onClick={onMaxBalance}
                            >
                              Max
                            </Button>
                          )}
                          <InputAdornment position="end">
                            {TOKENS[popupWithdraw.token].text}
                          </InputAdornment>
                        </StyledEndAdornment>
                      ),
                    }}
                    variant="outlined"
                    errorMessages={[
                      'This field is required',
                      `${labelParam} must be an integer greater than 0`,
                    ]}
                    type={isTokenNFT ? 'string' : 'number'}
                  />
                </FormControl>
              )}

              <FormControl fullWidth={true}>
                <TextValidator
                  validators={['required', 'minStringLength:6']}
                  errorMessages={[
                    'This field is required',
                    'This field must be 6 digit',
                  ]}
                  required={true}
                  fullWidth={true}
                  value={code}
                  autoFocus={isTokenNFT}
                  margin="normal"
                  label="Google authenticator code"
                  onChange={handleChangeCode}
                  helperText={'Enter the 6-digit code you see in the app.'}
                  InputProps={
                    {
                      // endAdornment: (
                      //   <Button
                      //     disabled={isLoadingCode || hasGetCode}
                      //     variant="contained"
                      //     color="primary"
                      //     onClick={getCode}
                      //     size="small"
                      //     className="no-wrap"
                      //   >
                      //     {hasGetCode ? (
                      //       <StyledCountdownTimer
                      //         daysInHours={true}
                      //         endTime={timeCodeExpired}
                      //         renderer={renderer}
                      //         onComplete={() => {
                      //           setGetCode(false);
                      //         }}
                      //       />
                      //     ) : (
                      //       'Send'
                      //     )}
                      //     {isLoadingCode ? <LoadingSpinner size={24} /> : null}
                      //   </Button>
                      // ),
                    }
                  }
                  variant="outlined"
                  type="number"
                />
              </FormControl>
              <div>
                {isWAI && feeWithdraw?.wai?.current ? (
                  <StyledWarning>
                    <span>
                      Withdrawal fee:{' '}
                      {bnum(feeWithdraw?.wai?.feePercent)
                        .multipliedBy(100)
                        .toFixed(2, 1)}
                      %
                    </span>
                  </StyledWarning>
                ) : (
                  isTokenAmount && (
                    <StyledWarning>
                      <span>Withdrawal fee: 0%</span>
                    </StyledWarning>
                  )
                )}
              </div>
              <StyledBottom columns="1">
                <Button
                  style={{ minHeight: '48px' }}
                  disabled={isLoading}
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  className="primary2"
                >
                  {getButtonText(buttonState)}{' '}
                  {isLoading ? <LoadingSpinner size={24} /> : null}
                </Button>
              </StyledBottom>
            </ValidatorForm>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
      <PopupConfirm
        open={openConfirm}
        onClose={() => {
          setConfirm(false);
        }}
        loading={isLoading}
        title={'Confirm withdraw'}
        onConfirm={onConfirm}
      >
        <StyledWarning style={{ fontSize: '15px' }}>
          In past days, you already withdrew Wai at{' '}
          <span>
            {moment
              .unix(feeWithdraw?.wai?.current / 1000)
              .format('YYYY/MM/DD HH:mm')}
          </span>
          . Please wait until{' '}
          <span>
            {moment
              .unix(feeWithdraw?.wai?.nextTime / 1000)
              .format('YYYY/MM/DD HH:mm')}
          </span>
          &nbsp; for next turn or we will charge{' '}
          <span>
            {bnum(feeWithdraw?.wai?.feePercent)
              .multipliedBy(100)
              .toFixed(2, 1)}
            % (~{' '}
            {bnum(popupWithdraw.param?.toString())
              .multipliedBy(feeWithdraw?.wai?.feePercent)
              .toFixed(2, 1)}{' '}
            WAI)
          </span>{' '}
          in this transaction. Do you want to continue?
        </StyledWarning>
      </PopupConfirm>
    </>
  );
});

export default PopupWithdraw;
