import { Button, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { LoadingSpinner } from '../../theme/components';
import {
  amountFormat,
  bnum,
  formatBalanceWithCommas,
  fromWei,
  toWei,
} from '../../utils/helpers';

enum ButtonState {
  DISABLED,
  NO_WALLET,
  UNLOCK,
  CREATE,
}
const ButtonText = ['Confirm', 'Connect Wallet', 'Approve', 'Confirm'];

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  .price-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  text-align: center;
`;

const StyledAction = styled.div`
  margin-top: 1rem;
  text-align: center;
  button {
    margin-top: 1rem;
  }
`;

const StyledPrice = styled.div`
  margin-top: 1rem;
  color: #a1a6b6;
  span {
    color: #fff;
    font-weight: 500;
  }
`;

const StyledDesc = styled.div`
  margin-top: 0.5rem;
  color: #a1a6b6;
  span {
    color: ${({ theme }) => theme.secondary};
    font-weight: 500;
  }
`;

const PopupOrder = observer(
  ({ open, setOpen, data, handleCheckExistOrder, type }) => {
    const [amount, setAmount] = useState<number>(0);
    const [isApprove, setApprove] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [floorPrice, setFloorPrice] = useState<string>('0');
    const {
      root: { orderStore, providerStore, tokenStore, wlandStore },
    } = useStores();
    const { account } = providerStore.providerStatus;
    const { landPercentConfigFee, itemPercentConfigFee } = orderStore;
    const metaData = providerStore.getContractMetaData();
    const orderFormRef = useRef(null);
    const { contractAddress, itemId } = data;
    const isLandOffer = type === 'wland';
    useEffect(() => {
      if (!isLandOffer || !itemId) {
        return;
      }
      wlandStore.getLandDataByID(itemId).then(res => {
        const { salePrice } = res;
        setFloorPrice(BigInt(salePrice || '0x00').toString());
      });
    }, [isLandOffer, itemId, wlandStore]);

    const percentFee = isLandOffer
      ? landPercentConfigFee
      : itemPercentConfigFee;

    const errorMessagePrice = isLandOffer
      ? 'Price must be number greater than Floor Price'
      : 'Price must be a number greater than 0';

    useEffect(() => {
      ValidatorForm.addValidationRule('priceFloor', value => {
        return !bnum(toWei((value && value.toString()) || '0')).lte(
          bnum(floorPrice)
        );
      });
      return () => {
        ValidatorForm.removeValidationRule('priceFloor');
      };
    }, [floorPrice]);

    const resetForm = () => {
      setAmount(0);
      if (orderFormRef.current) {
        orderFormRef.current.resetValidations();
      }
    };

    function handleClose() {
      setAmount(0);
      setOpen(false);
    }

    function handleChangeAmount(e) {
      setAmount(e.target.value);
    }

    const getButtonState = (
      amountParam,
      accountParam,
      isApproveParam: boolean
    ): ButtonState => {
      if (
        bnum(toWei((amountParam && amountParam.toString()) || '0')).lte(
          bnum(floorPrice)
        )
      ) {
        return ButtonState.DISABLED;
      }
      if (!accountParam) {
        return ButtonState.NO_WALLET;
      }
      if (!isApproveParam) {
        return ButtonState.UNLOCK;
      }
      return ButtonState.CREATE;
    };

    useEffect(() => {
      orderStore
        .isApprovedForAll({
          contractAddress: metaData.orderContract,
          type,
          account,
        })
        .then(res => {
          setApprove(res);
        });
    }, [metaData.orderContract, orderStore, type, account]);

    const buttonActionHandler = (buttonStateParam: ButtonState) => {
      switch (buttonStateParam) {
        case ButtonState.NO_WALLET:
          // dropdownStore.toggleWalletDropdown();
          break;
        case ButtonState.CREATE:
          handleOrder();
          break;
        case ButtonState.UNLOCK:
          handleApprove();
          break;
        default:
          throw new Error('Invalid button state');
      }
    };

    const handleApprove = () => {
      setLoading(true);
      orderStore
        .setApproveAll({
          contractAddress: metaData.orderContract,
          state: true,
          account,
          type,
        })
        .then(() => {
          orderStore
            .isApprovedForAll({
              contractAddress: metaData.orderContract,
              type,
              account,
            })
            .then(res => {
              setApprove(res);
            });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    function handleOrder() {
      setLoading(true);
      orderStore
        .createOrder({
          contractAddress,
          itemId,
          amount,
        })
        .then(() => {
          if (handleCheckExistOrder) {
            handleCheckExistOrder();
          }
          resetForm();
          setOpen(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const getButtonText = (buttonStateParam: ButtonState): string => {
      if (buttonStateParam === ButtonState.UNLOCK) {
        return `Approve NFT`;
      }
      return ButtonText[buttonStateParam];
    };

    const buttonState = getButtonState(amount, account, isApprove);

    const handleSubmit = e => {
      e.preventDefault();
      orderFormRef.current?.isFormValid().then(res => {
        if (res) {
          buttonActionHandler(buttonState);
        }
      });
    };
    return (
      <StyledModal open={open} onClose={handleClose}>
        <ModalWrapContent>
          <StyledClose>
            <IconButton onClick={handleClose}>
              <StyledCloseRounded />
            </IconButton>
          </StyledClose>
          <StyledBody>
            <ValidatorForm onSubmit={handleSubmit} ref={orderFormRef}>
              <h2>CREATE ORDER</h2>
              <p className={'price-title'}>PRICE</p>
              <TextValidator
                id="outlined-adornment-weight"
                value={amount}
                autoFocus={true}
                onChange={handleChangeAmount}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">WANA</InputAdornment>
                  ),
                }}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                validators={['priceFloor']}
                errorMessages={[errorMessagePrice]}
                type="number"
                variant="outlined"
              />
              <StyledAction>
                {isLandOffer && (
                  <StyledPrice>
                    Floor Price:{' '}
                    <span>
                      {floorPrice
                        ? `${formatBalanceWithCommas(fromWei(floorPrice))} WANA`
                        : '__'}
                    </span>
                  </StyledPrice>
                )}

                {bnum(amount || 0).isGreaterThan(0) ? (
                  <StyledDesc>
                    The buyer will pay an extra{' '}
                    <span>{formatBalanceWithCommas(percentFee)}%</span> market
                    fee to total of{' '}
                    <span>{formatBalanceWithCommas(amount)} WANA</span>{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {tokenStore.priceWANA
                        ? `(~ $${amountFormat(
                            bnum(amount).multipliedBy(
                              bnum(tokenStore.priceWANA)
                            ),
                            0
                          )})`
                        : ''}
                    </span>
                  </StyledDesc>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading || buttonState === ButtonState.DISABLED}
                >
                  {getButtonText(buttonState)}{' '}
                  {isLoading ? <LoadingSpinner size={24} /> : null}
                </Button>
              </StyledAction>
            </ValidatorForm>
          </StyledBody>
        </ModalWrapContent>
      </StyledModal>
    );
  }
);

export default PopupOrder;
