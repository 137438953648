import { Button, IconButton, Modal, TextField } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Copy as CopyIcon } from 'react-feather';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { networkConnectors } from '../provider/networkConnectors';
import { getStatsErrorMessage } from '../utils/apiRequest';
import { authRequest } from '../utils/authRequest';
import Tooltip from './Tooltip';

const StyledIconButton = styled(IconButton)<{ disabled?: boolean }>`
  && {
    margin-left: ${({ theme }) => theme.spacing.half};
    padding: 6px;
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  }
`;
const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 460px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  a {
    color: #4285f4;
  }
  ul {
    padding-left: 1rem;
  }
`;

const StyledWrapButton = styled.div`
  margin-top: 1rem;
  text-align: right;
`;

const StyledQrImage = styled.div`
  text-align: center;
  min-height: 173px;
`;

const StyledSecret = styled.div`
  display: flex;
`;
interface PopupProp {
  data: {
    open: boolean;
    data: any;
  };
  setOpen: any;
}
const PopupSetUp2FA = observer(({ data, setOpen }: PopupProp) => {
  const {
    root: { notificationStore, userStore },
  } = useStores();

  const [stepProcess, setStepProcess] = useState<string>('1');
  const [towFAData, setTowFAData] = useState({
    secret: '',
    QRCodeImage: '',
  });
  const verifyFormRef = useRef(null);
  const [code, setCode] = useState<number | ''>('');
  const baseUrl = networkConnectors.getStatsUrl();
  const { setUpAuthenticator } = userStore;
  const [copied, setCopied] = useState<boolean>(false);

  function handleSubmit(e) {
    e.preventDefault();
    verifyFormRef.current.isFormValid().then(result => {
      if (result) {
        setUpAuthenticator(code).then(() => {
          onClose();
        });
      }
    });
  }

  const onClose = () => {
    setStepProcess('1');
    setOpen({ ...data, open: false });
  };
  const onBack = () => {
    setStepProcess('1');
  };
  const onNext = () => {
    setStepProcess('2');
  };
  const onEnterSecret = () => {
    setStepProcess('3');
  };
  function handleChangeCode(e) {
    setCode(e.target.value);
  }

  useEffect(() => {
    if (data.open) {
      authRequest
        .post(baseUrl + '/enable-2fa', {})
        .then(res => {
          setTowFAData(res.data);
        })
        .catch(error => {
          const msg = getStatsErrorMessage(error) || 'Something went wrong';
          notificationStore.showErrorNotification(msg);
        });
    }
  }, [baseUrl, data.open, notificationStore]);

  // const onPrev = () => {
  //   setStepProcess('1')
  // }
  const handleCopy = useCallback(() => {
    copy(towFAData?.secret);
    setCopied(true);
  }, [towFAData.secret]);

  return (
    <StyledModal open={data.open} onClose={onClose} disableEscapeKeyDown={true}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={onClose}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>

        <StyledBody>
          {stepProcess === '1' && (
            <div>
              <h2>Set up Authenticator</h2>
              <ul>
                <li>
                  Get the Authenticator App from the{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
                  >
                    App Store
                  </a>{' '}
                  or{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  >
                    Google Play
                  </a>
                  .
                </li>
                <li>
                  In the App select <b>Set up account.</b>
                </li>
                <li>
                  Choose <b>Scan barcode.</b>
                </li>
              </ul>
              <StyledQrImage>
                {towFAData?.QRCodeImage ? (
                  <>
                    <div>
                      <img src={towFAData?.QRCodeImage} width={130} alt="" />
                    </div>
                    <Button
                      size="small"
                      onClick={onEnterSecret}
                      color="primary"
                    >
                      Can't scan it?
                    </Button>
                  </>
                ) : (
                  'Loading QR image...'
                )}
              </StyledQrImage>
              <StyledWrapButton>
                <Button
                  variant="contained"
                  onClick={onClose}
                  color="primary"
                  className="primary2"
                  style={{ margin: '0 5px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onNext}
                  color="primary"
                  style={{ margin: '0 5px' }}
                >
                  Next
                </Button>
              </StyledWrapButton>
            </div>
          )}
          {stepProcess === '2' && (
            <div>
              <h2>Set up Authenticator</h2>
              <div>Enter the 6-digit code you see in the app.</div>
              <ValidatorForm
                onSubmit={handleSubmit}
                ref={verifyFormRef}
                style={{ with: '100%' }}
              >
                <TextValidator
                  validators={['required', 'minStringLength:6']}
                  errorMessages={[
                    'This field is required',
                    'This field must be 6 digit',
                  ]}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth={true}
                  label="Code"
                  type="number"
                  inputProps={{ maxLength: 6 }}
                  value={code}
                  onChange={handleChangeCode}
                />
              </ValidatorForm>
              <StyledWrapButton>
                <Button
                  variant="contained"
                  onClick={onClose}
                  color="primary"
                  className="primary2"
                  style={{ margin: '0 5px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  color="primary"
                  style={{ margin: '0 5px' }}
                >
                  Verify
                </Button>
              </StyledWrapButton>
            </div>
          )}
          {stepProcess === '3' && (
            <div>
              <h2>Can't scan the barcode?</h2>
              <ul style={{ listStyleType: 'decimal' }}>
                <li>
                  Tap <b>Menu</b>, then <b>Set up account</b>.
                </li>
                <li>
                  Tap <b>Enter provided key.</b>
                </li>
                <li>
                  Enter your email address and this key:
                  <StyledSecret>
                    <TextField value={towFAData?.secret} disabled={true} />
                    <Tooltip
                      title={copied ? 'Copied' : 'CopyToClipboard'}
                      placement={'top'}
                    >
                      <StyledIconButton
                        disabled={!towFAData?.secret}
                        aria-label="copy"
                        onClick={handleCopy}
                        onMouseLeave={() => setCopied(false)}
                      >
                        <CopyIcon color={'#1cc48c'} size={20} />
                      </StyledIconButton>
                    </Tooltip>
                  </StyledSecret>
                </li>
                <li>
                  Make sure <b>Time based</b> is turned on, and tap <b>Add</b>{' '}
                  to finish.
                </li>
              </ul>
              <StyledWrapButton>
                <Button
                  variant="contained"
                  onClick={onBack}
                  color="primary"
                  className="primary2"
                  style={{ margin: '0 5px' }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={onNext}
                  color="primary"
                  style={{ margin: '0 5px' }}
                >
                  Next
                </Button>
              </StyledWrapButton>
            </div>
          )}
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default PopupSetUp2FA;
