import MomentUtils from '@date-io/moment';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ChipByStatusHistoryGame } from '../../components/Chip';
import { useStores } from '../../contexts/storesContext';
import { TokenProps } from '../../types';
import { getEtherscanLink } from '../../utils';
import { numberFormat, truncateAddress } from '../../utils/helpers';
import { NoDataText } from '../../components/Common';

const StyledBody = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 2rem;
  }
  form {
    width: 100%;
  }
`;

const StyledToken = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;

const StyledTableContainer = styled(({ ...props }: any) => {
  return <TableContainer {...props} />;
})`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.cardBG};
    border-radius: 12px;
  }
  margin-bottom: 1rem;
  white-space: nowrap;
  .number-decimal {
    color: #98a0b9;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.offer-active {
    background: #494d65;
  }
`;
const StyledSelectToken = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
  `};
  .MuiFormControl-root {
    margin-bottom: 1rem;
    min-height: 45px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%
    `};
  }
  .MuiOutlinedInput-adornedEnd {
    height: 100%;
  }

  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 25px;
  }
`;

const StyledDateFilter = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-auto-flow: row;
    width: 100%;
  `};
`;

const Activity = observer(() => {
  const {
    root: { userStore },
  } = useStores();
  const TOKENS = {
    WAI: {
      value: 'WAI',
      text: 'WAI',
      logo: '/images/logo-token-wai.png',
      historyType: 'wai',
    },
    WANA: {
      value: 'WANA',
      text: 'WANA',
      logo: '/images/logo-token-wana.png',
      historyType: 'wana',
    },
    NFT: {
      value: 'NFT',
      text: 'NFT ITEM',
      logo: '/images/logo-token-item.png',
      historyType: 'nft',
    },
  };
  const ARRAY_TOKENS = Object.values(TOKENS);
  const [page, setPage] = useState(1);
  const [token, setToken] = useState<string>(ARRAY_TOKENS[0].value);
  const { historyGame, getHistoryGameByType, profile } = userStore;
  const isWAI = TOKENS[token].value === TokenProps.WAI;
  const isNFT = TOKENS[token].value === 'NFT';
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [startTime, setStartTime] = React.useState(
    moment().subtract(7, 'days')
  );
  const [endTime, setEndTime] = React.useState(moment());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const sizeQuery = Number(queryParams.get('size'));
  const addressQuery = queryParams.get('address');
  const pageQuery = Number(queryParams.get('page'));
  const tokenQuery = queryParams.get('token');

  useEffect(() => {
    const addressWallet = addressQuery || profile.address;
    if (addressWallet) {
      getHistoryGameByType({
        type: TOKENS[token].historyType || 'wana',
        address: addressWallet,
        page,
        size: rowsPerPage,
        startTime: startTime?.unix(),
        endTime: endTime?.unix(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.address, page, rowsPerPage, startTime, endTime, token]);

  function handleChangeSelectToken(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    setToken(event.target.value as string);
    setPage(1);
  }

  useEffect(() => {
    if (pageQuery) {
      setPage(pageQuery);
    }
    if (sizeQuery) {
      setRowsPerPage(sizeQuery);
    }
    if (tokenQuery) {
      setToken(tokenQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuery, sizeQuery]);

  useEffect(() => {
    let filterParams = {};
    if (rowsPerPage) {
      filterParams = { ...filterParams, size: rowsPerPage };
    }
    if (page && page !== 1) {
      filterParams = { ...filterParams, page };
    }
    if (addressQuery) {
      filterParams = { ...filterParams, address: addressQuery };
    }
    if (token) {
      filterParams = { ...filterParams, token };
    }
    history.replace({ search: qs.stringify(filterParams) });
  }, [addressQuery, history, page, rowsPerPage, token]);

  function handleChangeStartTime(value) {
    setStartTime(value);
  }

  function handleChangeEndTime(value) {
    setEndTime(value);
  }

  return (
    <StyledBody>
      <h2>In-Game’s History</h2>
      <StyledSelectToken>
        <FormControl variant="outlined">
          <InputLabel id="filter">Token</InputLabel>
          <Select
            value={token}
            onChange={handleChangeSelectToken}
            labelId="filter"
            label={'Token'}
          >
            {ARRAY_TOKENS.map(tokenItem => (
              <MenuItem value={tokenItem.value} key={tokenItem.value}>
                <StyledToken>
                  <img src={tokenItem.logo} alt="" height="25px" />
                  {tokenItem.text}
                </StyledToken>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StyledDateFilter>
            <KeyboardDateTimePicker
              label="Start date"
              value={startTime}
              onChange={handleChangeStartTime}
              format="yyyy/MM/DD HH:mm"
              disableFuture={true}
              inputVariant="outlined"
            />
            <KeyboardDateTimePicker
              label="End date"
              value={endTime}
              onChange={handleChangeEndTime}
              format="yyyy/MM/DD HH:mm"
              disableFuture={true}
              inputVariant="outlined"
            />
          </StyledDateFilter>
        </MuiPickersUtilsProvider>
      </StyledSelectToken>

      <div style={{ width: '100%' }}>
        <StyledTableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {!isWAI && <TableCell align="center">Tx</TableCell>}
                <TableCell align="center">Date</TableCell>
                {!isNFT && <TableCell align="center">Amount</TableCell>}
                {isNFT && <TableCell align="center">Item Game Id</TableCell>}
                {isNFT && <TableCell align="center">NFT Id</TableCell>}
                {!isNFT && (
                  <TableCell align="center">Current Balance</TableCell>
                )}
                <TableCell align="center">Type</TableCell>
                {!isWAI && <TableCell align="center">Status</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyGame?.records &&
                historyGame?.records.map((row, index) => (
                  <StyledTableRow key={index}>
                    {!isWAI && (
                      <TableCell align="center">
                        {row?.tx?.slice(0, 2) === '0x' && (
                          <a
                            href={getEtherscanLink(
                              undefined,
                              row?.tx,
                              'transaction'
                            )}
                            target={'_blank'}
                            style={{ color: '#fff' }}
                          >
                            {truncateAddress(row?.tx || '')}
                          </a>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {moment(row?.createdDate)
                        .utc()
                        .format('YYYY-MM-DD HH:mm')}{' '}
                      UTC <br />
                      <span
                        style={{
                          fontSize: '70%',
                          fontStyle: 'Italic',
                          color: 'gray',
                        }}
                      >
                        ({moment(row?.createdDate).format('YYYY-MM-DD HH:mm')}{' '}
                        Local)
                      </span>
                    </TableCell>
                    {!isNFT && (
                      <TableCell align="center">
                        {numberFormat(row?.amount, 3)}
                      </TableCell>
                    )}
                    {isNFT && (
                      <TableCell align="center">{row?.itemIds}</TableCell>
                    )}
                    {isNFT && (
                      <TableCell align="center">{row?.nftItemIds}</TableCell>
                    )}
                    {!isNFT && (
                      <TableCell align="center">
                        {numberFormat(row?.currentBalance, 3)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <ChipByStatusHistoryGame status={row?.txType} />
                    </TableCell>
                    {!isWAI && (
                      <TableCell align="center">
                        <ChipByStatusHistoryGame status={row?.status} />
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {!historyGame?.records ||
        (historyGame?.records && historyGame?.records.length === 0) ? (
          <NoDataText />
        ) : (
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
            variant="outlined"
            color="primary"
            size="large"
            page={page}
            count={historyGame?.totalPage}
            onChange={handleChangePage}
          />
        )}
        {/* <ArrowPaginate
          page={page}
          onChange={handleChangeDepositPage}
          size={historyGameByPage.length}
          limit={LIMIT_HISTORY_ITEMS * 2}
          style={{ marginBottom: 0 }}
        /> */}
      </div>
    </StyledBody>
  );
});

export default Activity;
