import { Button, IconButton, Modal, TextField } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { isAddressEqual, truncateAddress } from '../utils/helpers';

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  p {
    color: #a1a6b6;
    margin-bottom: 2rem;
    line-height: 20px;
    text-align: center;
  }
`;

const StyledWrapButton = styled.div`
  margin-top: 2rem;
`;

const StyledWrapAddress = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.div`
  margin-bottom: 10px;
`;
const PopupLogout = observer(() => {
  const {
    root: { userStore, providerStore },
  } = useStores();
  const { address } = userStore.profile;
  const { account } = providerStore.providerStatus;
  const [openLogout, setOpenLogout] = useState(false);

  function onClose(event, reason) {
    // handleLogout();
    if (reason !== 'backdropClick') {
      setOpenLogout(false);
    }
  }

  useEffect(() => {
    if (
      providerStore.providerStatus.account &&
      userStore.profile.address &&
      !isAddressEqual(
        providerStore.providerStatus.account,
        userStore.profile.address
      )
    ) {
      setOpenLogout(true);
    } else {
      setOpenLogout(false);
    }
  }, [userStore.profile.address, providerStore.providerStatus.account]);

  function handleLogout() {
    userStore.handleLogout();
    setOpenLogout(false);
  }

  return (
    <StyledModal
      open={openLogout}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={() => handleLogout()}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <p>
            The WanaFarm account you've just logged into doesn't match your
            current Connected Wallet. Please open your Wallet and switch to the
            corresponding wallet.
          </p>
          <StyledWrapAddress>
            <div>
              <StyledLabel>Account’s Address</StyledLabel>
              <div>
                <TextField
                  variant="outlined"
                  disabled={true}
                  value={truncateAddress(address)}
                />
              </div>
            </div>
            <SwapHorizIcon style={{ margin: '0 5px' }} />
            <div>
              <StyledLabel>Connected Address</StyledLabel>
              <div>
                <TextField
                  variant="outlined"
                  disabled={true}
                  value={truncateAddress(account?.toLowerCase())}
                />
              </div>
            </div>
          </StyledWrapAddress>
          <StyledWrapButton>
            <Button
              variant="contained"
              onClick={handleLogout}
              color="secondary"
              style={{ margin: '0 5px' }}
            >
              Logout
            </Button>
          </StyledWrapButton>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default PopupLogout;
