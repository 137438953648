import { Interface } from 'ethers/utils';
import { action, observable } from 'mobx';
import NftItem from '../abi/NftItem.json';
import { networkConnectors } from '../provider/networkConnectors';
import { getStatsErrorMessage } from '../utils/apiRequest';
import { authRequest } from '../utils/authRequest';
import { ContractTypes } from './Provider';
import RootStore from './Root';
import { DepositWithdrawProps } from './User';

interface DataWithdraw {
  account: string;
  actionID: number;
  amount: string;
  deadline: number;
  signature: string;
}

interface FeeWithdrawProps {
  wai: {
    feePercent: number;
    current: number;
    nextTime: number;
  };
}

export default class WithdrawStore {
  public rootStore: RootStore;
  @observable public openConfirm: boolean;
  @observable public dataContractWithdraw: DataWithdraw;
  @observable public popupWithdraw: DepositWithdrawProps;
  @observable public loadingWithdraw: boolean;
  @observable public idDBWithdraw: number;
  @observable public feeWithdraw: FeeWithdrawProps;

  constructor(rootStore) {
    this.rootStore = rootStore;
    const { assetStore } = this.rootStore;
    const TOKENS = assetStore.getTokensConfig();
    const ARRAY_TOKENS = Object.values(TOKENS);
    this.openConfirm = false;
    this.idDBWithdraw = 0;
    this.dataContractWithdraw = {
      account: '',
      actionID: 0,
      amount: '',
      deadline: 0,
      signature: '',
    };
    this.popupWithdraw = {
      open: false,
      token: ARRAY_TOKENS[0].value,
      param: '',
      isEdit: true,
      typeId: 0,
    };
    this.loadingWithdraw = false;
    this.feeWithdraw = {
      wai: {
        feePercent: 0,
        current: 0,
        nextTime: 0,
      },
    };
  }

  public sendContractWithdraw = (data, typeToken) => {
    try {
      if (!data.account) {
        return Promise.reject({ message: 'Account invalid' });
      }
      const { assetStore, providerStore } = this.rootStore;
      const TOKENS = assetStore.getTokensConfig();
      const contractMetadata = providerStore.getContractMetaData();
      let params = {
        contractType: ContractTypes.withdrawOperator,
        contractMetadata: contractMetadata.WithdrawOperatorContract,
        method: '',
        data,
        override: {},
        message: '',
      };
      switch (typeToken) {
        case TOKENS.WANA.value:
          params = {
            ...params,
            method: 'withdrawWana',
            message: 'Withdraw Wana',
          };
          break;
        case TOKENS.WAI.value:
          params = {
            ...params,
            method: 'withdrawWai',
            message: 'Withdraw Wai',
          };
          break;
        case TOKENS.WLAND.value:
          params = {
            ...params,
            method: 'withdrawLand',
            message: 'Withdraw Land',
          };
          break;
        case TOKENS.NFTITEM.value:
          params = {
            ...params,
            method: 'withdrawNft',
            message: 'Withdraw Nft',
          };
          break;
        default:
          return Promise.reject({ message: 'Token invalid' });
      }
      return providerStore.sendTransaction(
        params.contractType,
        params.contractMetadata,
        params.method,
        [params.data],
        params.override,
        params.message
      );
    } catch (error) {
      throw error;
    }
  };

  public handleContractWithdraw = async (data, id, type, typeToken) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      const { notificationStore, transactionStore } = this.rootStore;
      if (!account) {
        return Promise.reject('[Error] Login first');
      }
      this.setDataContractWithdraw(data);
      const result = await this.sendContractWithdraw(data, typeToken);
      const { txResponse, error } = result;
      if (error) {
        const msg =
          error?.data?.message || error?.message || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw error;
      } else if (txResponse && txResponse?.hash) {
        this.loadingWithdraw = true;
        await transactionStore.waitTx(txResponse.hash);
        // await this.updateWithdrawSuccess(type, {
        //   id,
        //   txHash: txResponse?.hash,
        // }).catch(() => {});
        this.loadingWithdraw = false;
        this.setDataContractWithdraw({
          account: '',
          actionID: 0,
          amount: '',
          deadline: 0,
          signature: '',
        });
        this.setPopupConfirm(false);
      }
      return result;
    } catch (e) {
      this.loadingWithdraw = false;
      return Promise.reject(`Failed to read data - ${e}`);
    }
  };

  @action public setPopupWithdraw = (withdrawParams: DepositWithdrawProps) => {
    this.popupWithdraw = Object.assign({}, this.popupWithdraw, withdrawParams);
  };

  @action public setPopupConfirm = (value: boolean) => {
    this.openConfirm = value;
  };

  @action public setDataContractWithdraw = (value: DataWithdraw) => {
    this.dataContractWithdraw = value;
  };

  public resendContractData = (data, id, type, typeToken) => {
    return this.handleContractWithdraw(data, id, type, typeToken);
  };

  @action public callApiWithdraw = async codeVerify => {
    const { assetStore, notificationStore } = this.rootStore;
    const TOKENS = assetStore.getTokensConfig();
    const baseUrl = networkConnectors.getStatsUrl();
    const type = TOKENS[this.popupWithdraw.token].value;
    const typeId = this.popupWithdraw.typeId;
    const param = this.popupWithdraw.param;
    let paramsWithdraw;
    let urlWithdraw;
    switch (type) {
      case TOKENS.WANA.value:
        paramsWithdraw = { amount: Number(param), codeVerify };
        urlWithdraw = baseUrl + '/withdraw-v2/wana';
        break;
      case TOKENS.WAI.value:
        paramsWithdraw = { amount: Number(param), codeVerify };
        urlWithdraw = baseUrl + '/withdraw-v2/wai';
        break;
      case TOKENS.WLAND.value:
        paramsWithdraw = { itemId: Number(param), codeVerify };
        urlWithdraw = baseUrl + '/withdraw-v2/land';
        break;
      case TOKENS.NFTITEM.value:
        let paramItemIds = param as number[];
        if (typeof param === 'string') {
          paramItemIds = param.split(',').map(Number);
        }
        paramsWithdraw = { itemIds: paramItemIds, typeId, codeVerify };
        urlWithdraw = baseUrl + '/withdraw-v2/nft';
        break;
      default:
        return Promise.reject({ message: 'type invalid' });
    }

    try {
      this.idDBWithdraw = 0;
      const { data } = await authRequest.post(urlWithdraw, paramsWithdraw);
      this.idDBWithdraw = data.id;
      notificationStore.showNotification(
        data?.message ||
          'Please find and confirm the transaction in your Wallet',
        'warning'
      );
      return data;
    } catch (e) {
      const message =
        e?.response?.data?.data?.c?.error || getStatsErrorMessage(e);
      notificationStore.showErrorNotification(
        message || 'Something went wrong'
      );
      throw e;
      // throw new Error(message);
    }
  };

  public getFeeWithdrawWai = async () => {
    try {
      const baseUrl = networkConnectors.getStatsUrl();
      const { data } = await authRequest.post(
        baseUrl + '/withdraw-v2-fee/wai',
        {}
      );
      const { feePercent, current, nextTime } = data;
      this.feeWithdraw.wai = {
        feePercent,
        current,
        nextTime,
      };
    } catch (error) {
      return 0;
    }
  };
  @action public updateWithdrawSuccess = async (type, params) => {
    // const baseUrl = networkConnectors.getStatsUrl();
    // const { txHash } = params;
    // try {
    //   const { providerStore,
    //     // notificationStore
    //   } = this.rootStore;
    //   const library = providerStore.providerStatus.library;
    //   library
    //     .getTransactionReceipt(txHash)
    //     .then(async receipt => {
    //       const { status } =  receipt
    //       const { logs } = receipt
    //       if (type === 'nft') {
    //         params.nftIds = await this.decodeLogNft({data: logs[3]?.data, topics: logs[3]?.topics}).then((res) => res?.values?.nftIds?.map(Number))
    //       }
    //       if (status) {
    //         const { data } = await authRequest.post(
    //           baseUrl + `/result-withdraw-v2/${type}`,
    //           params
    //         )
    //         // notificationStore.showSuccessNotification(
    //         //   data?.message || 'Send request to withdraw successfully'
    //         // );
    //         return data;
    //       }
    //     })
    //     .catch(() => {});
    // } catch (e) {
    //   throw e;
    // }
  };

  public decodeLogNft = async logs => {
    try {
      const iface = new Interface(NftItem.abi);
      return iface.parseLog(logs);
    } catch (e) {
      console.log('decodeLogNft', e.toString());
      return null;
    }
  };
}
