import { action, computed, observable } from 'mobx';
import { CELL_LAND_STATUS, ID_CELL_LAND_TYPE } from '../constants';
import { networkConnectors } from '../provider/networkConnectors';
import { DataLandContractProps } from '../types';
import { getStatsErrorMessageGame } from '../utils/apiRequest';
import { authRequest } from '../utils/authRequest';
import { isAddressEqual } from '../utils/helpers';
import RootStore from './Root';

const ROW_CELL = 17;
const COL_CEL = 17;

export const CELL_INIT = Array.from({ length: ROW_CELL }, (v, k) =>
  Array.from({ length: COL_CEL }, () => false)
);

export default class RentalStore {
  @observable public openPopupRent: boolean;
  @observable public landCells: boolean[][];
  @observable public landCellsInit: boolean[][];
  @observable public dataDetail: DataLandContractProps;
  @observable public keyRefresh: number;

  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.openPopupRent = false;
    this.landCells = CELL_INIT;
    this.landCellsInit = CELL_INIT;
    this.dataDetail = {};
    this.keyRefresh = 0;
  }

  @computed get objectAttributes(): any {
    const objectAttributes = {};
    this.dataDetail?.attributes?.forEach(attr => {
      objectAttributes[attr.trait_type] = attr.value;
    });
    return objectAttributes;
  }

  @computed get isOwner(): any {
    const { userStore } = this.rootStore;
    const { address } = userStore.profile;
    return isAddressEqual(address, this.objectAttributes.AddressWallet);
  }

  @action public setPopupRent = (value: boolean) => {
    this.openPopupRent = value;
  };

  @action public setKeyRefresh = (value: number) => {
    this.keyRefresh = value;
  };

  @action public setLandCells = (value: boolean[][]) => {
    this.landCells = value;
  };

  @action public getDetailLandContract = ({
    landId,
    contractId,
  }: {
    landId?: number | string;
    contractId?: number | string;
  }) => {
    const gameApi = networkConnectors.getRentApiUrl();
    if (!landId && !contractId) {
      return;
    }
    let baseApi = '';
    let baseParams = { d: {} };
    if (landId) {
      baseApi = gameApi + '/v1/rent_land/owner_land_detail';
      baseParams = { d: { land_id: landId } };
    } else if (contractId) {
      baseApi = gameApi + '/v1/rent_land/contract_rent_info';
      baseParams = { d: { contract_id: contractId } };
    }
    return authRequest
      .post(baseApi, baseParams)
      .then(res => {
        const dataResponse = res.c.code === 'S000' ? res?.d?.data : {};
        this.dataDetail = dataResponse;
        const landCellsClone = JSON.parse(JSON.stringify(this.landCells));
        landCellsClone.forEach((row, indexRow) =>
          row.forEach((col, indexCol) => {
            if (
              dataResponse?.cell_list[this.convertIndex(indexRow, indexCol)]
                .contract_id === dataResponse?.id
            ) {
              landCellsClone[indexRow][indexCol] = true;
            }
          })
        );
        this.landCells = landCellsClone;
        this.landCellsInit = landCellsClone;
        return res;
      })
      .catch(e => {
        throw e;
      });
  };

  public convertIndex = (index1, index2) => {
    return index1 * ROW_CELL + index2;
  };

  public convertLandTypeId = id => {
    if (!id && id !== 0) {
      return ID_CELL_LAND_TYPE[1];
    }
    return ID_CELL_LAND_TYPE[id] || ID_CELL_LAND_TYPE[1];
  };

  @action public onReset = () => {
    this.landCells = this.landCellsInit;
  };

  public callApiForRent = dataRent => {
    const { notificationStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    const listCells = this.formatCellListToSend(dataRent);
    return authRequest
      .post(gameApi + '/v1/rent_land/owner_create_contract', {
        d: {
          land_id: dataRent.landId,
          rent_duration: dataRent.duration,
          rent_cost: dataRent?.price?.totalPrice,
          pond_qty: dataRent?.slotNumber?.pondSlot,
          grass_qty: dataRent?.slotNumber?.landSlot,
          rent_slots:
            dataRent?.slotNumber?.pondSlot + dataRent?.slotNumber?.landSlot,
          list_cells: listCells,
          auto_extend_flg: dataRent.landOwnerRenewal,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification(
            'Create Contract Successfully'
          );
          this.landCells = CELL_INIT;
          this.getDetailLandContract({ landId: dataRent.landId }).then(() => {
            this.keyRefresh += 1;
          });
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiRent = dataRent => {
    const { notificationStore, userStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    return authRequest
      .post(gameApi + '/v1/rent_land/renter_rent', {
        d: {
          contract_id: dataRent.contractId,
          auto_extend_flg: dataRent.renterRenewal,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification('Rent Successfully');
          this.getDetailLandContract({ contractId: dataRent.contractId });
          userStore.getBalance();
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiSwitchEditMode = contractId => {
    const { notificationStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    return authRequest
      .post(gameApi + '/v1/rent_land/owner_draft_contract', {
        d: {
          contract_id: contractId,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification('Successfully');
          this.getDetailLandContract({ contractId });
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiDeleteContract = contractId => {
    const { notificationStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    return authRequest
      .post(gameApi + '/v1/rent_land/owner_delete_slot', {
        d: {
          contract_id: contractId,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification(
            'Delete contract Successfully'
          );
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiUpdateContract = dataRent => {
    const { notificationStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    const listCells = this.formatCellListToSend(dataRent);
    return authRequest
      .post(gameApi + '/v1/rent_land/owner_edit_contract', {
        d: {
          contract_id: +dataRent.contractId,
          rent_duration: dataRent.duration,
          rent_cost: dataRent?.price?.totalPrice,
          pond_qty: dataRent?.slotNumber?.pondSlot,
          grass_qty: dataRent?.slotNumber?.landSlot,
          rent_slots:
            dataRent?.slotNumber?.pondSlot + dataRent?.slotNumber?.landSlot,
          list_cells: listCells,
          auto_extend_flg: dataRent.landOwnerRenewal,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification(
            'Update Contract Successfully'
          );
          this.getDetailLandContract({ contractId: dataRent.contractId });
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiUpdateRenewal = (dataRent, isRented) => {
    const { notificationStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    return authRequest
      .post(gameApi + '/v1/rent_land/auto_extend', {
        d: {
          contract_id: +dataRent.contractId,
          auto_renewly_flag: isRented
            ? dataRent.renterRenewal
            : dataRent.landOwnerRenewal,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification(
            'Update Contract Successfully'
          );
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public callApiClaim = dataRent => {
    const { notificationStore, userStore } = this.rootStore;
    const gameApi = networkConnectors.getRentApiUrl();
    return authRequest
      .post(gameApi + '/v1/rent_land/owner_claim_wana', {
        d: {
          contract_id: +dataRent?.contractId,
        },
      })
      .then(res => {
        if (res?.c?.code === 'S000') {
          notificationStore.showSuccessNotification('Claim Successfully');
          userStore.getBalance();
          return res?.d;
        } else {
          throw Error(res.c.error);
        }
      })
      .catch(errors => {
        const msg = getStatsErrorMessageGame(errors) || 'Something went wrong';
        notificationStore.showErrorNotification(msg);
        throw Error(msg);
      });
  };

  public formatCellListToSend = ({ landCells, cellList, contractId }) => {
    const listCells = [];
    if (!cellList) {
      return [];
    }
    landCells.forEach((row, index1) => {
      row.forEach((col, index2) => {
        if (!col) {
          return;
        }
        if (
          !this.checkCellAvailableForRent({
            cell: cellList[this.convertIndex(index1, index2)],
            contractId,
          })
        ) {
          return;
        }
        listCells.push({ posX: index1, posY: index2 });
      });
    });
    return listCells;
  };

  @action public clearUIData = () => {
    this.landCells = CELL_INIT;
    this.landCellsInit = CELL_INIT;
    this.dataDetail = {};
  };

  public checkCellAvailableForRent = ({ cell, contractId }) => {
    if (!cell) {
      return false;
    }
    // on create contract
    if (cell?.contract_id === 0 && cell?.status !== CELL_LAND_STATUS.NONE) {
      return false;
    }
    // on edit contract
    if (cell?.contract_id !== 0 && cell?.contract_id !== +contractId) {
      return false;
    }
    return true;
  };
}
