import { action, observable } from 'mobx';
import qs from 'query-string';
import { ClearFilterProps, EnvironmentProps } from '../types';
import { ContractTypes } from './Provider';
import RootStore from './Root';

export const MAX_LEVEL = 19;
export const MIN_LEVEL = 1;

export const MAX_SLOT_NUMBER = 82;
export const MIN_SLOT_NUMBER = 1;

export const MAX_PRICE = 999999999;
export const MIN_PRICE = 0;

export const MAX_DURATION = 20;
export const MIN_DURATION = 1;

const INIT_DATA_ENVIRONMENTS = [
  {
    id: 'basic',
    name: 'basic',
    checked: false,
  },
  {
    id: 'forest',
    name: 'forest',
    checked: false,
  },
  {
    id: 'lake',
    name: 'lake',
    checked: false,
  },
  {
    id: 'sea',
    name: 'sea',
    checked: false,
  },
];

export const INIT_DATA_RARES = [
  {
    id: 'Common',
    name: 'Common',
    checked: false,
  },
  {
    id: 'Uncommon',
    name: 'Uncommon',
    checked: false,
  },
  {
    id: 'Rare',
    name: 'Rare',
    checked: false,
  },
  {
    id: 'Legendary',
    name: 'Legendary',
    checked: false,
  },
  {
    id: 'Mythical',
    name: 'Mythical',
    checked: false,
  },
  {
    id: 'Immortal',
    name: 'Immortal',
    checked: false,
  },
];

const INIT_DATA_SEASONS = [
  {
    id: 'Spring',
    name: 'Spring',
    checked: false,
  },
  {
    id: 'Summer',
    name: 'Summer',
    checked: false,
  },
  {
    id: 'Autumn',
    name: 'Autumn',
    checked: false,
  },
  {
    id: 'Winter',
    name: 'Winter',
    checked: false,
  },
];
export default class WlandStore {
  public rootStore: RootStore;
  @observable public environments: EnvironmentProps[];
  @observable public rares: EnvironmentProps[];
  @observable public seasons: EnvironmentProps[];
  @observable public itemId: string;
  @observable public sortBy: string;
  @observable public status: number;
  @observable public sale: string;
  @observable public page: number;
  @observable public yourOffers: boolean;
  @observable public yourOldOffers: boolean;
  @observable public yourRented: boolean;
  @observable public isOwned: boolean;
  @observable public statusPacked: string;
  @observable public yourTotalLand: number;
  @observable public rangeLV: number[];
  @observable public rangeDuration: number[];
  @observable public rangeSlotNumber: number[];
  @observable public rangePrice: Array<string | number>;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.environments = INIT_DATA_ENVIRONMENTS;
    this.rares = INIT_DATA_RARES;
    this.seasons = INIT_DATA_SEASONS;
    this.itemId = '';
    this.sortBy = '';
    this.status = -1;
    this.sale = 'all';
    this.page = 1;
    this.yourOffers = false;
    this.yourOldOffers = false;
    this.yourRented = false;
    this.isOwned = false;
    this.statusPacked = 'all';
    this.yourTotalLand = 0;
    this.rangeLV = [MIN_LEVEL, MAX_LEVEL];
    this.rangeDuration = [MIN_DURATION, MAX_DURATION];
    this.rangeSlotNumber = [MIN_SLOT_NUMBER, MAX_SLOT_NUMBER];
    this.rangePrice = [null, null];
  }

  @action public setEnvironments = (environments: EnvironmentProps[]) => {
    this.environments = environments;
    const indexChecked = environments.findIndex(item => item.checked === true);
    if (indexChecked !== -1) {
      this.resetFilterOffer();
    }
  };

  @action public setRares = (rares: EnvironmentProps[]) => {
    this.rares = rares;
    const indexChecked = rares.findIndex(item => item.checked === true);
    if (indexChecked !== -1) {
      this.resetFilterOffer();
    }
  };

  @action public setSeasons = (seasons: EnvironmentProps[]) => {
    this.seasons = seasons;
    const indexChecked = seasons.findIndex(item => item.checked === true);
    if (indexChecked !== -1) {
      this.resetFilterOffer();
    }
  };

  @action public setItemId = (itemId: string) => {
    this.itemId = itemId;
    if (itemId) {
      this.resetFilterOffer();
    }
  };

  @action public setSortBy = (sortBy: string) => {
    this.sortBy = sortBy;
    if (sortBy) {
      this.resetFilterOffer();
    }
  };

  @action public setStatus = (status: number) => {
    this.status = status;
  };

  @action public setSale = (sale: string) => {
    this.sale = sale;
    if (sale !== 'all') {
      this.resetFilterOffer();
    }
  };

  @action public setPage = (page: number) => {
    this.page = page;
  };

  @action public setYourOffers = (yourOffers: boolean) => {
    this.yourOffers = yourOffers;
    if (yourOffers) {
      this.clearFilter({ yourOffers, isOwner: false, yourOldOffers: false });
    }
  };

  @action public setYourOldOffers = (yourOldOffers: boolean) => {
    this.yourOldOffers = yourOldOffers;

    if (yourOldOffers) {
      this.clearFilter({ yourOldOffers, isOwner: false, yourOffers: false });
    }
  };

  @action public setYourRented = (yourRented: boolean) => {
    this.yourRented = yourRented;
    if (yourRented) {
      this.clearFilter({ yourRented, isOwner: false, yourOldOffers: false });
    }
  };

  @action public resetFilterOffer = () => {
    this.setYourOffers(false);
    this.setYourRented(false);
    this.setYourOldOffers(false);
    this.setPage(1);
  };

  @action public setOwned = (isOwned: boolean) => {
    this.isOwned = isOwned;
    if (isOwned) {
      this.resetFilterOffer();
    }
  };

  @action public setPacked = (statusPacked: string) => {
    this.statusPacked = statusPacked;
    if (statusPacked) {
      this.resetFilterOffer();
    }
  };

  @action public setRangeLV = (rangeLV: number[]) => {
    this.rangeLV = rangeLV;
    if (rangeLV) {
      this.resetFilterOffer();
    }
  };

  @action public setRangeDuration = (rangeDuration: number[]) => {
    this.rangeDuration = rangeDuration;
    if (rangeDuration) {
      this.resetFilterOffer();
    }
  };

  @action public setRangeSlotNumber = (rangeSlotNumber: number[]) => {
    this.rangeSlotNumber = rangeSlotNumber;
    if (rangeSlotNumber) {
      this.resetFilterOffer();
    }
  };

  @action public setRangePrice = (rangePrice: Array<string | number>) => {
    this.rangePrice = rangePrice;
    if (rangePrice[0] !== null || rangePrice[1] !== null) {
      this.resetFilterOffer();
    }
  };

  @action public clearFilter = ({
    yourOffers,
    yourRented,
    isOwned,
    yourOldOffers,
  }: ClearFilterProps | any) => {
    this.environments = INIT_DATA_ENVIRONMENTS;
    this.rares = INIT_DATA_RARES;
    this.seasons = INIT_DATA_SEASONS;
    this.itemId = '';
    this.sortBy = '';
    this.status = -1;
    this.sale = 'all';
    this.page = 1;
    this.yourOffers = yourOffers || false;
    this.yourRented = yourRented || false;
    this.yourOldOffers = yourOldOffers || false;
    this.isOwned = isOwned || false;
    this.statusPacked = 'all';
    this.rangeLV = [MIN_LEVEL, MAX_LEVEL];
    this.rangeDuration = [MIN_DURATION, MAX_DURATION];
    this.rangeSlotNumber = [MIN_SLOT_NUMBER, MAX_SLOT_NUMBER];
    this.rangePrice = [null, null];
  };

  @action public syncQueryParamsToFilter = search => {
    const queryParams = new URLSearchParams(search);
    const pageParam = Number(queryParams.get('page'));
    const idParam = queryParams.get('id');
    const isOwnedParam = queryParams.get('isOwned');
    const statusPackedParam = queryParams.get('statusPacked');
    const yourOffersParam = queryParams.get('yourOffers');
    const yourOldOffersParam = queryParams.get('yourOldOffers');
    const yourRentedParam = queryParams.get('yourRented');
    const sortByParam = queryParams.get('sortBy');
    const statusParam = queryParams.get('status');
    const saleParam = queryParams.get('sale');
    const environmentsParam = queryParams.get('environments');
    const raresParam = queryParams.get('rares');
    const seasonsParam = queryParams.get('seasons');
    const minPrice = queryParams.get('minPrice');
    const maxPrice = queryParams.get('maxPrice');
    const minLevel = queryParams.get('minLevel');
    const maxLevel = queryParams.get('maxLevel');
    const minDuration = queryParams.get('minDuration');
    const maxDuration = queryParams.get('maxDuration');
    const minSlotNumber = queryParams.get('minSlotNumber');
    const maxSlotNumber = queryParams.get('maxSlotNumber');

    if (pageParam && pageParam && this.page !== pageParam) {
      this.setPage(pageParam);
    }
    if (idParam) {
      this.setItemId(idParam);
    }
    if (isOwnedParam) {
      this.setOwned(!!isOwnedParam);
    }
    if (statusPackedParam) {
      this.setPacked(statusPackedParam);
    }
    if (yourOffersParam) {
      this.setYourOffers(!!yourOffersParam);
    }
    if (yourOldOffersParam) {
      this.setYourOldOffers(!!yourOldOffersParam);
    }
    if (yourRentedParam) {
      this.setYourRented(!!yourRentedParam);
    }
    if (sortByParam) {
      this.setSortBy(sortByParam);
    }
    if (statusParam && +statusParam !== -1) {
      this.setStatus(+statusParam);
    }
    if (saleParam) {
      this.setSale(saleParam);
    }
    if (minPrice) {
      this.setRangePrice([minPrice, this.rangePrice[1]]);
    }
    if (maxPrice) {
      this.setRangePrice([this.rangePrice[0], maxPrice]);
    }
    if (minLevel && Number(minLevel) !== MIN_LEVEL) {
      this.setRangeLV([Number(minLevel), this.rangeLV[1]]);
    }
    if (maxLevel && Number(maxLevel) !== MAX_LEVEL) {
      this.setRangeLV([this.rangeLV[0], Number(maxLevel)]);
    }
    if (minDuration && Number(minDuration) !== MIN_DURATION) {
      this.setRangeLV([Number(minDuration), this.rangeDuration[1]]);
    }
    if (maxDuration && Number(maxDuration) !== MAX_DURATION) {
      this.setRangeLV([this.rangeDuration[0], Number(maxDuration)]);
    }
    if (minSlotNumber && Number(minSlotNumber) !== MIN_SLOT_NUMBER) {
      this.setRangeLV([Number(minSlotNumber), this.rangeSlotNumber[1]]);
    }
    if (maxSlotNumber && Number(maxSlotNumber) !== MAX_SLOT_NUMBER) {
      this.setRangeLV([this.rangeSlotNumber[0], Number(maxSlotNumber)]);
    }
    if (environmentsParam) {
      const environmentsClone = this.environments.map(environment => {
        if (environmentsParam.indexOf(environment.id) !== -1) {
          environment.checked = true;
        }
        return environment;
      });
      this.setEnvironments(environmentsClone);
    }
    if (raresParam) {
      const raresClone = this.rares.map(environment => {
        if (raresParam.indexOf(environment.id) !== -1) {
          environment.checked = true;
        }
        return environment;
      });
      this.setRares(raresClone);
    }
    if (seasonsParam) {
      const seasonsClone = this.seasons.map(environment => {
        if (seasonsParam.indexOf(environment.id) !== -1) {
          environment.checked = true;
        }
        return environment;
      });
      this.setSeasons(seasonsClone);
    }
  };

  @action public syncFilterToQueryParams = history => {
    let filterParams: {
      page?: number;
      id?: string;
      isOwned?: boolean;
      statusPacked?: string;
      yourOffers?: boolean;
      yourOldOffers?: boolean;
      yourRented?: boolean;
      sortBy?: string;
      status?: string;
      sale?: string;
      environments?: string;
      rares?: string;
      seasons?: string;
      minPrice?: string | number;
      maxPrice?: string | number;
      minLevel?: string | number;
      maxLevel?: string | number;
    } = {};
    if (this.itemId) {
      filterParams = { ...filterParams, id: this.itemId };
    }
    if (this.page && this.page !== 1) {
      filterParams = { ...filterParams, page: this.page };
    }
    if (this.isOwned) {
      filterParams = { ...filterParams, isOwned: this.isOwned };
    }
    if (this.statusPacked !== 'all') {
      filterParams = { ...filterParams, statusPacked: this.statusPacked };
    }
    if (this.yourOffers) {
      filterParams = { ...filterParams, yourOffers: this.yourOffers };
    }
    if (this.yourOldOffers) {
      filterParams = { ...filterParams, yourOldOffers: this.yourOldOffers };
    }
    if (this.yourRented) {
      filterParams = { ...filterParams, yourRented: this.yourRented };
    }
    if (this.sortBy) {
      filterParams = { ...filterParams, sortBy: this.sortBy };
    }
    if (this.status !== -1) {
      filterParams = { ...filterParams, status: String(this.status) };
    }
    if (this.sale !== 'all') {
      filterParams = { ...filterParams, sale: this.sale };
    }
    if (this.rangePrice[0] !== null && this.rangePrice[0] !== MIN_PRICE) {
      filterParams = { ...filterParams, minPrice: this.rangePrice[0] };
    }
    if (this.rangePrice[1] !== null && this.rangePrice[1] !== MAX_PRICE) {
      filterParams = { ...filterParams, maxPrice: this.rangePrice[1] };
    }

    if (this.rangeLV[0] !== MIN_LEVEL) {
      filterParams = { ...filterParams, minLevel: this.rangeLV[0] };
    }

    if (this.rangeLV[1] !== MAX_LEVEL) {
      filterParams = { ...filterParams, maxLevel: this.rangeLV[1] };
    }

    if (this.rangeDuration[0] !== MIN_DURATION) {
      filterParams = { ...filterParams, minLevel: this.rangeDuration[0] };
    }

    if (this.rangeDuration[1] !== MAX_DURATION) {
      filterParams = { ...filterParams, maxLevel: this.rangeDuration[1] };
    }

    if (this.rangeSlotNumber[0] !== MIN_SLOT_NUMBER) {
      filterParams = { ...filterParams, minLevel: this.rangeSlotNumber[0] };
    }

    if (this.rangeSlotNumber[1] !== MAX_SLOT_NUMBER) {
      filterParams = { ...filterParams, maxLevel: this.rangeSlotNumber[1] };
    }

    const environmentsSelected = this.getEnvironmentsSelected(
      this.environments
    );
    if (environmentsSelected.length !== 0) {
      filterParams = {
        ...filterParams,
        environments: environmentsSelected.toString(),
      };
    }

    const raresSelected = this.getRaresSelected(this.rares);
    if (raresSelected.length !== 0) {
      filterParams = {
        ...filterParams,
        rares: raresSelected.toString(),
      };
    }

    const seasonsSelected = this.getSeasonsSelected(this.seasons);
    if (seasonsSelected.length !== 0) {
      filterParams = {
        ...filterParams,
        seasons: seasonsSelected.toString(),
      };
    }

    history.replace({ search: qs.stringify(filterParams) });
  };

  public getEnvironmentsSelected = environments => {
    const environmentsSelected = [];
    if (Array.isArray(environments) && environments.length !== 0) {
      for (let k = 0; k < environments.length; k++) {
        if (environments[k].checked === true) {
          environmentsSelected.push(this.environments[k].name);
        }
      }
    }
    return environmentsSelected;
  };

  public getRaresSelected = rares => {
    const raresSelected = [];
    if (Array.isArray(rares) && rares.length !== 0) {
      for (let k = 0; k < rares.length; k++) {
        if (rares[k].checked === true) {
          raresSelected.push(this.rares[k].name);
        }
      }
    }
    return raresSelected;
  };

  public getSeasonsSelected = seasons => {
    const seasonsSelected = [];
    if (Array.isArray(seasons) && seasons.length !== 0) {
      for (let k = 0; k < seasons.length; k++) {
        if (seasons[k].checked === true) {
          seasonsSelected.push(this.seasons[k].name);
        }
      }
    }
    return seasonsSelected;
  };

  public getOwnerOf = async (itemId: number) => {
    try {
      const { providerStore } = this.rootStore;
      const { account } = providerStore.providerStatus;
      if (!account) {
        return false;
      }
      const contractMetadata = providerStore.getContractMetaData();
      const wLandContract = await providerStore.getContract(
        ContractTypes.wLand,
        contractMetadata.wLandContract
      );

      const data = await wLandContract.ownerOf(itemId);
      return data;
    } catch (e) {
      return false;
    }
  };

  public getLandDataByID = async itemId => {
    try {
      if (!itemId) {
        return false;
      }
      const { providerStore } = this.rootStore;
      const contractMetadata = providerStore.getContractMetaData();
      const contractWland = await providerStore.getContract(
        ContractTypes.wLand,
        contractMetadata.wLandContract
      );
      const data = await contractWland.getLandData(itemId);
      return data;
    } catch (e) {
      console.log('ERROR getLandDataByID', e);
      return false;
    }
  };
}
