import React from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip/index';
import { networkConnectors } from '../../provider/networkConnectors';
import { useAddTokenToMetaMask } from '../../hooks/useAddTokenToMetaMask';

const StyledToken = styled.div`
  padding: 0 4px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  text-align: center;
  position: relative;
  z-index: 1;
  img {
    z-index: 2;
    width: 32px;
    margin-top: 2px;
  }
  margin-right: 31px;
  &.HOPE {
    margin-bottom: 6px;
    img {
      z-index: 2;
      width: 42px;
    }
  }
`;

const StyledMetamask = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 0;
  img {
    width: 28px;
  }
`;

const StyledButton = styled.div`
  cursor: pointer;
  position: relative;
  background: #3e3e3e;
  padding: 2px 10px 2px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &:hover {
    background: #656565;
  }
  &.HOPE {
    padding: 2px 16px 0 16px;
  }
`;

function AddTokens({ tokenName }) {
  const tokens = networkConnectors.getProtocolTokens();
  const token = tokens[tokenName];
  const {
    valid: validToTokenToMetaMask,
    callback: addTokenToMetaMaskCallback,
  } = useAddTokenToMetaMask(token);

  if (!validToTokenToMetaMask) return null;

  return (
    <Tooltip title={`Add ${token.symbol} to your MetaMask wallet`}>
      <StyledButton
        className={token.symbol}
        onClick={addTokenToMetaMaskCallback}
      >
        <StyledToken className={token.symbol}>
          <img src={token.image || '/token-token.svg'} alt={''} />
        </StyledToken>
        <StyledMetamask>
          <img src={'/images/metamask.svg'} alt={''} />
        </StyledMetamask>
      </StyledButton>
    </Tooltip>
  );
}

export default AddTokens;
