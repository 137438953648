import { createTheme } from '@material-ui/core';
import { useMemo } from 'react';
import { MEDIA_WIDTHS, theme as styledTheme } from './index';
// import { theme as styledTheme } from './index';
import { getMuiButtonOverride } from './override-items';

const useMuiThemeOverrides = darkMode => {
  const theme = styledTheme(darkMode);

  return useMemo(
    () =>
      createTheme({
        palette: {
          type: darkMode ? 'dark' : 'light',
          primary: {
            main: theme.primary,
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: MEDIA_WIDTHS.Small,
            md: MEDIA_WIDTHS.Medium,
            lg: MEDIA_WIDTHS.Large,
            xl: 1920,
          },
        },
        spacing: factor => `${factor * 10}px`,
        overrides: {
          MuiTypography: {
            body1: {
              fontFamily: 'inherit',
            },
          },
          MuiListItemText: {
            primary: {
              fontSize: '95%',
            },
          },
          MuiTab: {
            root: {
              fontFamily: 'inherit',
            },
          },
          /* MuiButton */
          MuiButton: getMuiButtonOverride(theme),
          MuiOutlinedInput: {
            input: {
              paddingTop: '14.5px',
              paddingBottom: '14.5px',
            },
          },
        },
      }),
    [darkMode, theme]
  );
};

export default useMuiThemeOverrides;
