import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useStores } from '../../contexts/storesContext';
import { deleteCookie, getCookie, setCookie } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Card = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 32px 24px;
  background-color: ${({ theme }) => theme.cardBG};
`;
const Container = styled.div`
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: calc(100vh - 159px);
  `}
`;
const StyledWrapLogin = styled.div`
  max-width: 420px;
  width: 100%;
`;

const StyledButtonLoginAccount = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const StyledButtonLink = styled(Button)`
  .MuiButton-label {
    text-transform: capitalize;
  }
`;

const LoginForm = ({ setShowForm }) => {
  const {
    root: { userStore },
  } = useStores();
  const { handleLoginAccount } = userStore;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const loginFormRef = useRef(null);
  const { from } = location?.state || { from: { pathname: '/profile' } };

  useEffect(() => {
    const account = getCookie('account');
    const passwordParam = getCookie('password');
    if (account && passwordParam) {
      setEmail(account);
      setPassword(passwordParam);
      setRemember(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeRemember(e) {
    setRemember(e?.target?.checked);
  }

  function handleSubmitLogin(e) {
    if (remember) {
      setCookie('account', email, 1);
      setCookie('password', password, 1);
    } else {
      deleteCookie('account');
      deleteCookie('password');
    }
    e.preventDefault();
    setLoading(true);
    loginFormRef.current.isFormValid().then(res => {
      if (res) {
        handleLoginAccount({ email, password })
          .then(() => {
            setLoading(false);
            history.replace(from);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    });
  }

  function handleChangeMail(e) {
    setEmail(e?.target?.value);
  }

  function handleChangePassword(e) {
    setPassword(e?.target?.value);
  }

  return (
    <Card>
      <ValidatorForm onSubmit={handleSubmitLogin} ref={loginFormRef}>
        <TextValidator
          validators={['required', 'isEmail']}
          errorMessages={['This field is required', 'Email is not valid']}
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus={true}
          value={email}
          onChange={handleChangeMail}
        />
        <TextValidator
          validators={['required', 'minStringLength:8']}
          errorMessages={[
            'This field is required',
            'This field must be at least 8 characters long',
          ]}
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={handleChangePassword}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={remember}
              color="primary"
              onChange={changeRemember}
            />
          }
          label="Remember me"
        />
        <Button
          disabled={loading}
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          Sign In
        </Button>
        {/* <Grid container={true}>
          <Grid item={true} xs={true}>
            <StyledButtonLink
              color="primary"
              onClick={() => {
                setShowForm('forgot-pass');
              }}
            >
              {'Forgot password?'}
            </StyledButtonLink>
          </Grid>
        </Grid> */}
      </ValidatorForm>
    </Card>
  );
};

// const SignUPForm = ({ setShowForm }) => {
//   const classes = useStyles();
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<any>('');
//   const [repeatPassword, setRepeatPassword] = useState<any>('');
//   const [loading, setLoading] = useState<boolean>(false);
//   const singUpFormRef = useRef(null);
//   const {
//     root: { userStore },
//   } = useStores();
//   const { handleRegister } = userStore;

//   function resetForm() {
//     setEmail('');
//     setPassword('');
//     setRepeatPassword('');
//     singUpFormRef.current.resetValidations();
//   }

//   useEffect(() => {
//     ValidatorForm.addValidationRule('isPasswordMatch', value => {
//       if (value !== password) {
//         return false;
//       }
//       return true;
//     });
//     return () => {
//       ValidatorForm.removeValidationRule('isPasswordMatch');
//     };
//   }, [password]);

//   function handleChangeMail(e) {
//     setEmail(e?.target?.value);
//   }
//   function handleChangePassword(e) {
//     setPassword(e?.target?.value);
//   }
//   function handleChangeRepeatPassword(e) {
//     setRepeatPassword(e?.target?.value);
//   }
//   function handleSubmit(e) {
//     e.preventDefault();
//     singUpFormRef.current.submit();
//     singUpFormRef.current.isFormValid().then(res => {
//       if (res) {
//         setLoading(true);
//         handleRegister({ email, password })
//           .then(() => {
//             resetForm();
//             setShowForm('login');
//           })
//           .catch(() => {})
//           .finally(() => {
//             setLoading(false);
//           });
//       }
//     });
//   }

//   return (
//     <Card>
//       <ValidatorForm onSubmit={handleSubmit} ref={singUpFormRef}>
//         <TextValidator
//           validators={['required', 'isEmail']}
//           errorMessages={['This field is required', 'Email is not valid']}
//           variant="outlined"
//           margin="normal"
//           required={true}
//           fullWidth={true}
//           id="email"
//           label="Email Address"
//           name="email"
//           autoFocus={true}
//           value={email}
//           onChange={handleChangeMail}
//         />
//         <TextValidator
//           validators={['required', 'minStringLength:8']}
//           errorMessages={[
//             'This field is required',
//             'This field must be at least 8 characters long',
//           ]}
//           variant="outlined"
//           margin="normal"
//           required={true}
//           fullWidth={true}
//           name="password"
//           label="Password"
//           type="password"
//           id="password"
//           value={password}
//           onChange={handleChangePassword}
//         />
//         <TextValidator
//           validators={['isPasswordMatch', 'required', 'minStringLength:8']}
//           errorMessages={[
//             'Password mismatch',
//             'This field is required',
//             'This field must be at least 8 characters long',
//           ]}
//           variant="outlined"
//           margin="normal"
//           required={true}
//           fullWidth={true}
//           name="confirm-password"
//           label="Confirm Password"
//           type="password"
//           id="confirm-password"
//           value={repeatPassword}
//           onChange={handleChangeRepeatPassword}
//         />
//         <Button
//           disabled={loading}
//           type="submit"
//           fullWidth={true}
//           variant="contained"
//           color="primary"
//           className={classes.submit}
//           onClick={handleSubmit}
//         >
//           Sign Up
//         </Button>
//         <Grid container={true}>
//           <Grid item={true} xs={true}>
//             <StyledButtonLink color="primary">
//               {'Forgot password?'}
//             </StyledButtonLink>
//           </Grid>
//           <Grid item={true}>
//             <StyledButtonLink
//               color="primary"
//               onClick={() => {
//                 setShowForm('login');
//               }}
//             >
//               {'Login'}
//             </StyledButtonLink>
//           </Grid>
//         </Grid>
//       </ValidatorForm>
//     </Card>
//   );
// };
const ForgotPassForm = ({ setShowForm }) => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<any>('');
  const [repeatPassword, setRepeatPassword] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const singUpFormRef = useRef(null);
  const {
    root: { userStore },
  } = useStores();
  const { handleRegister } = userStore;

  function resetForm() {
    setEmail('');
    setPassword('');
    setRepeatPassword('');
    singUpFormRef.current.resetValidations();
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value !== password) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [password]);

  function handleChangeRepeatPassword(e) {
    setRepeatPassword(e?.target?.value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    singUpFormRef.current.submit();
    singUpFormRef.current.isFormValid().then(res => {
      if (res) {
        setLoading(true);
        handleRegister({ email, password })
          .then(() => {
            resetForm();
            setShowForm('login');
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }

  return (
    <Card>
      <ValidatorForm onSubmit={handleSubmit} ref={singUpFormRef}>
        <TextValidator
          validators={['isPasswordMatch', 'required', 'minStringLength:8']}
          errorMessages={[
            'Password mismatch',
            'This field is required',
            'This field must be at least 8 characters long',
          ]}
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          name="confirm-password"
          label="Confirm Password"
          type="password"
          id="confirm-password"
          value={repeatPassword}
          onChange={handleChangeRepeatPassword}
        />
        <Button
          disabled={loading}
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Sign Up
        </Button>
        <Grid container={true}>
          <Grid item={true}>
            <StyledButtonLink
              color="primary"
              onClick={() => {
                setShowForm('login');
              }}
            >
              {'Login'}
            </StyledButtonLink>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Card>
  );
};

const RenderForm = () => {
  const [showForm, setShowForm] = useState(null);

  if (showForm === 'login') {
    return <LoginForm setShowForm={setShowForm} />;
  } else if (showForm === 'forgot-pass') {
    return <ForgotPassForm setShowForm={setShowForm} />;
  } else {
    return (
      <StyledButtonLoginAccount
        variant="outlined"
        onClick={() => {
          setShowForm('login');
        }}
      >
        <img
          width="32"
          src={require('../../assets/images/mail-white.png')}
          alt=""
          style={{ marginRight: '12px' }}
        />
        <div>Login with email & password</div>
      </StyledButtonLoginAccount>
    );
  }
};

const SignIn = observer(() => {
  const {
    root: { dropdownStore, userStore, providerStore },
  } = useStores();
  const { handleLoginWallet } = userStore;
  const { providerStatus } = providerStore;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const { from } = location?.state || { from: { pathname: '/profile' } };

  const toggleWalletDropdown = async () => {
    dropdownStore.toggleWalletDropdown();
  };

  const loginWallet = () => {
    if (providerStatus.account) {
      setLoading(true);
      handleLoginWallet()
        .then(() => {
          setLoading(false);
          history.replace(from);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      providerStore.setNavigator(history);
      // localStorage.setItem('process_login', 'true');
      toggleWalletDropdown();
    }
  };
  return (
    <Container>
      <StyledWrapLogin>
        <>
          <StyledButtonLoginAccount
            variant="outlined"
            onClick={loginWallet}
            disabled={loading}
          >
            <img
              width="32"
              src={require('../../assets/images/metamask-white.png')}
              alt=""
              style={{ marginRight: '12px' }}
            />
            <div>
              {providerStore.providerStatus.account
                ? 'Login with wallet'
                : 'Connect wallet'}
            </div>
          </StyledButtonLoginAccount>
          <RenderForm />
        </>
      </StyledWrapLogin>
    </Container>
  );
});

export default SignIn;
