import { Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import CountdownTimer from './CountdownTimer';

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin: 0;
    font-weight: 500;
    margin-top: 0.5rem;
  }
`;

const StyledCountdownTimer = styled(CountdownTimer)`
  font-family: ChakraPetch, 'UTM Colossalis', sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.primary3};
`;
const StyledTextConfirm = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const StyledTitle = styled.h2`
  margin: 0 10px 0;
`;

const StyledTextHelp = styled.div`
  color: #bebfc4;
  text-align: center;
  button {
    padding: 0 5px
    height: 28px
    &:hover {
      background-color: unset;
      text-decoration: underline;
    }
  }
`;
const WaitConfirmModal = observer(() => {
  const {
    root: { withdrawStore, assetStore },
  } = useStores();
  const {
    idDBWithdraw,
    openConfirm,
    dataContractWithdraw,
    loadingWithdraw,
    popupWithdraw,
    setPopupConfirm,
    resendContractData,
  } = withdrawStore;
  const [timeCodeExpired, setTimeCodeExpired] = useState<number>(
    moment()
      .add(5, 'm')
      .unix()
  );
  useEffect(() => {
    setTimeCodeExpired(dataContractWithdraw.deadline);
  }, [dataContractWithdraw.deadline, openConfirm]);
  const TOKENS = assetStore.getTokensConfig();
  const [isLoading, setLoading] = useState<boolean>(false);
  // const renderer = ({ hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     return null;
  //   } else {
  //     return <StyledCountdownTimer>{hours * 60 * 60 + minutes * 60 + seconds} s</StyledCountdownTimer>;
  //   }
  // };

  const onClose = (event, reason: string) => {
    if (reason !== 'backdropClick') {
      setPopupConfirm(false);
    }
  };

  const onResendContract = () => {
    setLoading(true);
    resendContractData(
      dataContractWithdraw,
      idDBWithdraw,
      TOKENS[popupWithdraw.token].historyType,
      TOKENS[popupWithdraw.token].value
    ).finally(() => {
      setLoading(false);
    });
  };

  return (
    <StyledModal
      open={openConfirm}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={() => onClose(null, null)}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <StyledTitle>Confirm Send Transaction</StyledTitle>
          <StyledTextConfirm>
            Please find and confirm the transaction in your Wallet or in
            Activity within{' '}
            <StyledCountdownTimer
              daysInHours={true}
              endTime={timeCodeExpired}
              onComplete={() => {
                onClose(null, null);
              }}
            />
          </StyledTextConfirm>
          {loadingWithdraw ? (
            'Withdrawing...'
          ) : (
            <StyledTextHelp>
              <Button
                color="primary"
                className="primary2"
                size="small"
                disabled={loadingWithdraw || isLoading}
                onClick={onResendContract}
              >
                Can't find the transaction?
              </Button>
            </StyledTextHelp>
          )}

          <div>
            <Button
              style={{ marginTop: '12px' }}
              color="primary"
              variant="outlined"
              className="primary2"
              onClick={() => onClose(null, null)}
            >
              Close
            </Button>
          </div>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default WaitConfirmModal;
