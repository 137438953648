import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TagID } from '../../components/Common';
import { SEASON } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import { WlandMetaDataProps } from '../../types';
import {
  abbreviateNumber,
  amountFormat,
  bnum,
  fromWei,
} from '../../utils/helpers';
import {
  getColorByEnvironment,
  getImageRareByName,
  getImageSeasonByName,
} from '../../utils/land';

const StyledItem = styled(Link)`
  text-decoration: none;
  color: unset;
  width: 220px;
  min-height: 356px;
  height: calc(100% - 16px);
  margin: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0 8px rgb(124 238 252);
  }
  background: ${({ theme }) => theme.cardBG};
  @media screen and (max-width: 1600px) {
    width: 208px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;
const StyledLand = styled.img`
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledBgItem = styled.img`
  height: 250px;
  width: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 0 1rem;
`;

const StyledOrderPrice = styled.div`
  line-height: 1.3px;
  display: flex;
  align-items: center;
  font-weight: 500;
  width: max-content;
  margin: auto;
  min-height: 30px;
  span {
    font-size: 20px;
  }
  .number-decimal {
    font-size: 18px;
    color: #98a0b9;
  }
`;

const StyledBottomPrice = styled.div`
  padding: 5px 8px 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 0.8rem 0;
  background: url('${require('../../assets/images/bg-nft.svg')}') center no-repeat;
  background-size: contain;
  img.item {
    width: 60%;
    margin-bottom: 8px;
  }
`;

const StyledContentContainer = styled.div`
  position: relative;
`;

const StyledContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 24px;
  background: url('${require('../../assets/images/bg-nft.svg')}') center no-repeat;
  margin: 0 0.8rem 1rem;
  background-size: contain;
  img.nft-unbox {
    max-width: 93%;
    padding: 20px 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      max-width: 68%;
    `};
  }
`;

const StyledSeason = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 118px;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
  &.spring {
    top: 54px;
  }
  &.summer {
    top: 86px;
  }
  &.winter {
    top: 150px;
  }
`;

const StyledBottom = styled.div`
  padding: 0 12px 12px;
`;

const StyleWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface LandsProps {
  landItem: WlandMetaDataProps;
}

const Lands = observer(({ landItem }: LandsProps) => {
  const {
    root: { tokenStore },
  } = useStores();

  return (
    <StyledItem to={`/lands/${landItem.itemId}`}>
      <StyledHeader>
        <TagID color={getColorByEnvironment(landItem.environment)}>
          #{landItem.itemId}
        </TagID>
      </StyledHeader>

      <StyleWrap>
        {JSON.parse(landItem.isPacked) ? (
          <StyledContentCenter>
            <img className={'nft-unbox'} src={landItem.image} alt="" />
          </StyledContentCenter>
        ) : (
          <StyledContent>
            <StyledContentContainer>
              <StyledBgItem
                src={getImageRareByName(landItem.rare)}
                alt={landItem.rare}
              />
              <StyledLand className={'item'} src={landItem.image} alt="" />
              {SEASON.map(season => (
                <StyledSeason
                  src={getImageSeasonByName(season)}
                  className={`${season?.toLowerCase()} ${
                    landItem?.season &&
                    landItem.season
                      ?.split(',')
                      ?.findIndex(v => v?.toLowerCase() === season) !== -1
                      ? 'active'
                      : ''
                  }`}
                  key={season + 'season'}
                />
              ))}
            </StyledContentContainer>
          </StyledContent>
        )}
      </StyleWrap>

      <StyledBottom>
        <StyledBottomPrice>
          <StyledOrderPrice>
            <ShoppingCartIcon
              style={{ fontSize: '20px', marginRight: '3px' }}
            />
            {landItem.onSale ? (
              <>
                <span>
                  {
                    abbreviateNumber(
                      amountFormat(fromWei(landItem.salePrice || '0'), 3),
                      0
                    )?.split('.')[0]
                  }{' '}
                </span>
                <span className="number-decimal">
                  {abbreviateNumber(
                    amountFormat(fromWei(landItem.salePrice || '0'), 3),
                    0
                  )?.split('.')[1] &&
                    `.${
                      abbreviateNumber(
                        amountFormat(fromWei(landItem.salePrice || '0'), 3),
                        0
                      )?.split('.')[1]
                    }`}{' '}
                </span>
                <span>&nbsp;WANA</span>
              </>
            ) : (
              '__'
            )}
          </StyledOrderPrice>
          <div style={{ color: '#98a0b9' }}>
            {landItem.onSale && tokenStore.priceWANA
              ? `≈ $${amountFormat(
                  bnum(fromWei(landItem.salePrice || '0')).multipliedBy(
                    bnum(tokenStore.priceWANA)
                  ),
                  0
                )}`
              : ''}
          </div>
        </StyledBottomPrice>
      </StyledBottom>
    </StyledItem>
  );
});

export default Lands;
