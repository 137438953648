import { action, observable } from 'mobx';
import RootStore from './Root';

export default class NotificationStore {
  @observable public open: boolean;
  @observable public autoHideDuration: number;
  @observable public severity: string;
  @observable public message: string;
  @observable public anchorOrigin: any;
  public rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.open = null;
    this.autoHideDuration = 6000;
    this.severity = 'success';
    this.message = '';
    this.anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    };
  }
  @action public showNotification = (message, type) => {
    this.message = message;
    this.open = true;
    this.severity = type || this.severity;
  };
  @action public onClose = () => {
    this.open = false;
    this.message = null;
  };
  @action public showErrorNotification = message => {
    this.showNotification(message, 'error');
  };
  @action public showSuccessNotification = message => {
    this.showNotification(message, 'success');
  };
}
