import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../contexts/storesContext';
import { getEtherscanLink } from '../utils';

const StyledSnackBar = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #353444;
  max-width: 384px;
  width: 100%;
`;

const StyledSnackBarContent = styled.div`
  display: flex;
  font-weight: 500;
  padding: 16px 10px 16px 16px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: #1cc48c;
  }
`;

const StyledMessage = styled.div`
  font-size: 24px;
`;

const StyledLink = styled.a`
  color: #1890ff;
  text-decoration: none;
`;

const StyledWrapMessage = styled.div`
  margin-left: 10px;
`;

const StyledCheckMark = styled.div`
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash 1s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 1s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 1s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
`;

const SuccessIcon = () => {
  return (
    <StyledCheckMark>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          stroke="#1cc48c"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          stroke="#1cc48c"
          strokeWidth="8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
    </StyledCheckMark>
  );
};

const ErrorIcon = () => {
  return (
    <StyledCheckMark>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          stroke="#D06079"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <line
          className="path line"
          fill="none"
          stroke="#D06079"
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="34.4"
          y1="37.9"
          x2="95.8"
          y2="92.3"
        />
        <line
          className="path line"
          fill="none"
          stroke="#D06079"
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="95.8"
          y1="38"
          x2="34.4"
          y2="92.2"
        />
      </svg>
    </StyledCheckMark>
  );
};
let onHover = false;

const SnackBarWaitTx = observer(
  forwardRef<
    HTMLDivElement,
    { id: string | number; message: any | React.ReactNode }
  >(({ id, message }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const {
      root: { transactionStore, providerStore },
    } = useStores();
    const [isLoading, setLoading] = useState(true);
    const [timerId, setTimerId] = useState<any>(0);
    const [isError, setError] = useState(false);
    const library = providerStore.providerStatus.library;

    useEffect(() => {
      setError(false);
      transactionStore
        .waitTx(message.txHash)
        .then(() => {
          library
            .getTransactionReceipt(message.txHash)
            .then(async receipt => {
              const { status } = receipt;
              if (!status) {
                setError(true);
              }
            })
            .catch(e => {});
        })
        .finally(() => {
          setLoading(false);
          if (!onHover) {
            setTimerId(
              setTimeout(() => {
                closeSnackbar(id);
              }, 6000)
            );
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, message.txHash, transactionStore]);

    function onMouseEnterHandler() {
      onHover = true;
      if (timerId) {
        clearTimeout(timerId);
      }
    }
    function onMouseLeaveHandler() {
      onHover = false;
      if (!isLoading) {
        setTimerId(
          setTimeout(() => {
            closeSnackbar(id);
          }, 3000)
        );
      }
    }
    return (
      <StyledSnackBar
        ref={ref}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        <StyledSnackBarContent>
          <div style={{ width: '40px' }}>
            {isLoading ? (
              <StyledCircularProgress />
            ) : isError ? (
              <ErrorIcon />
            ) : (
              <SuccessIcon />
            )}
          </div>
          <StyledWrapMessage>
            <StyledMessage>{message.content}</StyledMessage>
            <div>
              Detail transaction{' '}
              <StyledLink
                href={getEtherscanLink(
                  undefined,
                  message.txHash,
                  'transaction'
                )}
                target="bank"
              >
                here
              </StyledLink>
            </div>
          </StyledWrapMessage>
        </StyledSnackBarContent>
        <div>
          <IconButton onClick={() => closeSnackbar(id)}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </StyledSnackBar>
    );
  })
);

export default SnackBarWaitTx;
