import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../../components/Spinner';
import { WlandMetaDataProps } from '../../types';
import ViewListItem from './viewListItem';
import ViewModuleItem from './viewModuleItem';
import { NoDataText } from '../Common';

const StyledItems = styled.div<{ isViewList: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
  ${({ isViewList }) =>
    isViewList
      ? css`
          align-items: center;
          ${({ theme }) => theme.mediaWidth.upToSmall`
          min-height: calc(100vh - 540px);
        `};
        `
      : css`
          min-height: calc(100vh - 374px);
          ${({ theme }) => theme.mediaWidth.upToSmall`
            min-height: calc(100vh - 540px);
          `};
        `}
`;

interface LandsProps {
  listLands: WlandMetaDataProps[];
  loading?: boolean;
  isViewList: boolean;
}

const Lands = ({ listLands, loading, isViewList = false }: LandsProps) => {
  return (
    <StyledItems isViewList={isViewList}>
      {listLands?.map((item, index) =>
        isViewList ? (
          <ViewListItem landItem={item} key={index} type="wland" />
        ) : (
          <ViewModuleItem landItem={item} key={index} />
        )
      )}
      <Spinner loading={loading} />
      {(!listLands || (listLands && listLands.length === 0)) && !loading ? (
        <NoDataText size="16px" />
      ) : null}
    </StyledItems>
  );
};

export default Lands;
