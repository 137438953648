import { DefaultTheme as StyledDefaultTheme } from 'styled-components';
import { Colors } from './styled';

export const HeightsConfig = {
  LARGE: 48,
  MEDIUM: 44,
  SMALL: 38,
};

type DefaultTheme = StyledDefaultTheme & Colors;

export const getMuiButtonOverride = (theme: DefaultTheme): any => {
  return {
    root: {
      textTransform: 'none',
      fontFamily: '"ChakraPetch", "Roboto", "Arial", sans-serif',
      fontSize: '1rem',
      height: HeightsConfig.MEDIUM,
      lineHeight: 1,
    },
    sizeLarge: {
      height: HeightsConfig.LARGE,
      fontSize: '20px',
      fontWeight: 600,
    },
    sizeSmall: {
      fontSize: '1rem',
      height: HeightsConfig.SMALL,
    },
    contained: {
      backgroundColor: '#46465a',
      color: theme.white,
      '&.btn-flat': {
        boxShadow: 'none !important',
      },
      '&.btn-flat:hover': {
        backgroundColor: `${theme.hover('#46465a')} !important`,
      },
      '&$disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
      '&.primary2:not(.Mui-disabled)': {
        backgroundColor: '#fea500',
        color: '#181a26',
        '&:hover': {
          backgroundColor: theme.hover('#fea500'),
        },
        '&$disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
    outlinedPrimary: {
      borderColor: theme.primary,
      color: theme.primary,
      '&:hover': {
        color: theme.hover(theme.primary),
        borderColor: theme.hover(theme.primary),
      },
      '&$disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
      '&.primary2': {
        borderColor: '#fea500',
        color: '#fea500',
        '&$disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
    containedPrimary: {
      color: '#fff',
      backgroundColor: theme.primary,
      '&:hover': {
        backgroundColor: theme.hover(theme.primary),
      },
      '&$disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    },
    containedSecondary: {
      backgroundColor: '#d52e31',
      '&:hover': {
        backgroundColor: theme.hover('#d52e31'),
      },
      '&$disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    },
  };
};

export const getMuiInputBaseOverride = (theme: DefaultTheme) => {
  return {
    '&$focused': {
      borderColor: theme.primary,
    },
  };
};
