import { Button, IconButton, Modal } from '@material-ui/core';
import React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import styled from 'styled-components';

import { CloseRounded } from '@material-ui/icons';

const StyledModal = styled(Modal)`
  display: flex;
`;
const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
  }
`;

const PopupConfirm = ({
  onConfirm,
  loading,
  onClose,
  open,
  title,
  children,
}) => {
  function handelClose() {
    onClose();
  }

  return (
    <StyledModal open={open} onClose={handelClose}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={handelClose}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <h2>{title}</h2>
          <ValidatorForm onSubmit={onConfirm} style={{ with: '100%' }}>
            {children}
            <StyledWrap>
              <div>
                <Button
                  style={{
                    marginRight: '5px',
                    minHeight: '44px',
                  }}
                  onClick={onClose}
                  variant="contained"
                  color="primary"
                  className="primary2"
                >
                  Wait
                </Button>
                <Button
                  style={{
                    marginLeft: '5px',
                    minHeight: '44px',
                  }}
                  disabled={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Continue with fee
                </Button>
              </div>
            </StyledWrap>
          </ValidatorForm>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
};

export default PopupConfirm;
