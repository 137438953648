import { Button } from '@material-ui/core';
import { NavigateBeforeOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row } from '../../components/Common';
import HistoryOffer from '../../components/Details/HistoryOffer';
import PopupBuy from '../../components/Details/PopupBuy';
import PopupOffer from '../../components/Details/PopupOffer';
import PopupOrder from '../../components/Details/PopupOrder';
import PopupTransfer from '../../components/Details/PopupTransfer';
import PopupUnboxLand from '../../components/Details/PopupUnboxLand';
import Tooltip from '../../components/Tooltip/index';
import { SEASON, TIME_FETCH_DETAIL } from '../../constants';
import { useStores } from '../../contexts/storesContext';
import useAuth from '../../hooks/useAuth';
import { OrderProps, WlandMetaDataProps } from '../../types';
import GA from '../../utils/ga';
import {
  amountFormat,
  bnum,
  fromWei,
  isAddressEqual,
} from '../../utils/helpers';
import {
  getColorByEnvironment,
  getImageRareByName,
  getImageSeasonByName,
} from '../../utils/land';
import { getLandByItemId } from '../../utils/subgraph';
import AttributeData from './AttributeData';

const Left = styled.div`
  flex-basis: 40%;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
  `}
`;
const Right = styled.div`
  flex: 1;
  margin-bottom: 2rem;
  width: 100%;
`;

const StyledWrapContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin: auto;
  `}
`;

const TextButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const StyledHeaderBar = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
    align-items: flex-end;
  `}
`;

const StyledPrice = styled.div`
  font-size: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 1.4;
  .highlight {
    font-weight: 500;
    font-size: 22px;
  }
  .value {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
  }
  .number-decimal {
    color: #98a0b9;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-end;
    .highlight {
      font-size: 18px;
    }
    .value {
      font-size: 18px;
    }
  `}
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-end;
  width: 100%;
  margin: auto;
  img.item {
    position: absolute;
    max-width: 60%;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledContentWrap = styled.div`
  width: 230px;
  margin: auto;
`;

const StyledBgItem = styled.img`
  height: auto;
  width: 100%;
`;

const StyledSeason = styled.img`
  width: 55px;
  height: 55px;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
`;

const StyledContentPacked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 342px;
  img.nft-unbox {
    max-width: 60%;
    padding: 20px 20px;
  }
`;

const StyledSeasonContained = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const TagID = styled.div<{ color?: string }>`
  margin: 10px 0 10px;
  background-color: ${({ color }) => color || '#73757b'};
  padding: 4px 10px 4px;
  border-radius: 4px;
  color: #fafafa;
  font-size: 14px;
  line-height: 16px;
  min-width: 60px;
  width: max-content;
  font-weight: 500;
`;

const StyledName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 28px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const StyledWrapPrice = styled.div`
  margin-left: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 0;
  `}
`;
const StyledLabel = styled.span`
  color: #a6a7ad;
`;

const StyledContainerNFT = styled.div``;
const StyledWrapBox = styled.div`
  background: url('${require('../../assets/images/bg-nft.svg')}') center no-repeat;
  background-size: contain;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: flex-start;
  `}
`;
const StyledTopButton = styled.div`
  margin-left: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 12px;
  `}
`;

const StyledUnpack = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

interface OrderData extends OrderProps {
  nftContractAddress: string;
}

const DATA_INIT = {
  id: 0,
  itemId: 0,
  metaDataUrl: '',
  owner: '',
  name: '',
  description: '',
  isActive: false,
  salePrice: '',
  environment: '',
  inInventory: '',
  image: '',
  isPacked: '',
  rare: '',
  season: '',
  increaseMutantRate: 0,
  timeReduce: 0,
  level: 0,
  birth: 0,
  activeOrder: {
    id: 0,
    orderId: '',
    itemId: 0,
    owner: '',
    buyer: '',
    price: '',
    status: '',
  },
  onSale: false,
  saleAt: 0,
};

const LandDetail = observer(() => {
  const {
    root: {
      providerStore,
      orderStore,
      wlandStore,
      tokenStore,
      userStore,
      dropdownStore,
    },
  } = useStores();
  const { account } = providerStore.providerStatus;
  const { handleLoginWallet } = userStore;

  const history = useHistory();
  const { landId } = useParams<{ landId: any }>();
  const [isOffer, setOffer] = useState<boolean>(false);
  const [isOwner, setOwner] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderData>(null);
  const [openPopupOffer, setPopupOffer] = useState<boolean>(false);
  const [openPopupOrder, setPopupOrder] = useState<boolean>(false);
  const [openPopupBuy, setPopupBuy] = useState<boolean>(false);
  const [openPopupUnpack, setPopupUnpack] = useState<boolean>(false);
  const [openTransfer, setPopupTransfer] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [landDetail, setLandDetail] = useState<WlandMetaDataProps>(DATA_INIT);
  const isMounted = useRef(true);
  const checkAuth = useAuth();
  const metaDataContract = providerStore.getContractMetaData();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    tokenStore.fetchAllowancesData(
      account,
      [metaDataContract.wanaFarmContract],
      metaDataContract.orderContract
    );
  }, [
    account,
    metaDataContract.orderContract,
    metaDataContract.wanaFarmContract,
    tokenStore,
  ]);

  useEffect(() => {
    GA.mountedPage({
      page: 'land-detail',
      landingView: true,
    });
    return () => {
      GA.unmountedPage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDetail = useCallback(() => {
    if (!landId) {
      return;
    }
    getLandByItemId(landId)
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setLandDetail({
          ...res,
          contractAddress: metaDataContract.wLandContract,
        });
        setOwner(isAddressEqual(res.owner, account));
        if (res.onSale) {
          setOrderData({
            ...res.activeOrder,
            nftContractAddress: metaDataContract.wLandContract,
            itemId: landId,
            price: res.salePrice,
          });
        } else {
          setOrderData(null);
        }
      })
      .catch(e => {
        setLandDetail(DATA_INIT);
      });
  }, [account, landId, metaDataContract.wLandContract]);

  const handleCheckOffer = useCallback(() => {
    if (!metaDataContract.wLandContract || !landId || isOwner) {
      return;
    }
    orderStore
      .checkOffer({
        contractAddress: metaDataContract.wLandContract,
        itemId: landId,
        account,
      })
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setOffer(res);
      })
      .catch(() => {
        setOffer(false);
      });
  }, [account, isOwner, landId, metaDataContract.wLandContract, orderStore]);

  const handleCheckOwner = useCallback(() => {
    wlandStore.getOwnerOf(landId).then(res => {
      setOwner(isAddressEqual(res, account));
    });
  }, [account, landId, wlandStore]);

  const handleCheckExistOrder = useCallback(() => {
    orderStore
      .checkExistOrder({
        contractAddress: metaDataContract.wLandContract,
        itemId: landId,
        account,
      })
      .then(() => {
        orderStore
          .orderByItemId({
            contractAddress: metaDataContract.wLandContract,
            itemId: landId,
          })
          .then(res => {
            setOrderData(res);
          });
      });
  }, [account, landId, metaDataContract.wLandContract, orderStore]);

  useEffect(() => {
    let handler;
    if (landId) {
      handleGetDetail();
      handler = setInterval(handleGetDetail, TIME_FETCH_DETAIL);
    } else {
      clearInterval(handler);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [handleGetDetail, landId]);

  useEffect(() => {
    handleCheckOffer();
  }, [account, handleCheckOffer, landDetail.owner]);

  const handleMakeOffer = e => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupOffer(true);
  };

  const handleCreateOrder = () => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupOrder(true);
  };

  const handleBuy = () => {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    setPopupBuy(true);
  };

  function handleCancelOrder() {
    const isAuth = checkAuth();
    if (!isAuth) {
      return;
    }
    orderStore
      .cancelOrder({
        contractAddress: metaDataContract.wLandContract,
        itemId: landId,
      })
      .then(() => {
        handleCheckExistOrder();
      });
  }
  const onClickUnpack = () => {
    if (account) {
      if (userStore.accessToken) {
        setPopupUnpack(true);
      } else {
        setLoading(true);
        handleLoginWallet()
          .then(res => {
            setPopupUnpack(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      dropdownStore.toggleWalletDropdown();
    }
  };

  const { itemId } = landDetail;
  const isPacked = !!JSON.parse(landDetail?.isPacked || 'false');
  const dataLand = {
    itemId,
    contractAddress: metaDataContract.wLandContract,
    salePrice: landDetail?.salePrice,
  };

  return (
    <Container>
      <Row>
        <Top>
          <TextButton onClick={() => history.goBack()}>
            <NavigateBeforeOutlined />
            Back
          </TextButton>
          <StyledHeaderBar>
            <StyledWrapPrice>
              {orderData && !!BigInt(orderData.price || '0x00') ? (
                <>
                  <StyledPrice>
                    <StyledLabel>Order Price: </StyledLabel>&nbsp;
                    <img
                      src="/images/logo-token-wana.png"
                      alt=""
                      width="25px"
                    />
                    &nbsp;
                    {orderData?.price ? (
                      <>
                        <span className="highlight">
                          {
                            amountFormat(
                              fromWei(orderData.price || '0'),
                              3
                            )?.split('.')[0]
                          }
                        </span>{' '}
                        <span className="highlight number-decimal">
                          {amountFormat(
                            fromWei(orderData.price || '0'),
                            3
                          )?.split('.')[1] &&
                            `.${
                              amountFormat(
                                fromWei(orderData.price || '0'),
                                3
                              )?.split('.')[1]
                            }`}
                        </span>
                      </>
                    ) : (
                      '__'
                    )}
                    <span>&nbsp;WANA</span>
                  </StyledPrice>
                  <div style={{ color: '#98a0b9' }}>
                    {orderData?.price && tokenStore.priceWANA
                      ? `≈ $${amountFormat(
                          bnum(fromWei(orderData?.price || '0')).multipliedBy(
                            bnum(tokenStore.priceWANA)
                          ),
                          0
                        )}`
                      : ''}
                  </div>
                </>
              ) : null}
            </StyledWrapPrice>
            <div>
              {isOwner ? (
                <StyledTopButton>
                  {orderData && !!BigInt(orderData.price || '0x00') ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCancelOrder}
                      style={{ marginRight: '12px' }}
                      size="medium"
                    >
                      CANCEL LISTING
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreateOrder}
                      size="medium"
                    >
                      LIST ON SALE
                    </Button>
                  )}
                </StyledTopButton>
              ) : (
                <StyledTopButton>
                  {orderData && orderData.price ? (
                    <Button
                      variant="contained"
                      className="primary2"
                      onClick={handleBuy}
                      style={{ marginRight: '12px' }}
                      size="medium"
                    >
                      BUY
                    </Button>
                  ) : null}
                  <Tooltip
                    title={isPacked ? 'Offer function is disabled for box' : ''}
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleMakeOffer}
                        size="medium"
                        disabled={isPacked}
                      >
                        MAKE OFFER
                      </Button>{' '}
                    </span>
                  </Tooltip>
                </StyledTopButton>
              )}
            </div>
          </StyledHeaderBar>
        </Top>
      </Row>
      <StyledWrapContent>
        <Left>
          <TagID color={getColorByEnvironment(landDetail?.environment)}>
            # {itemId}
          </TagID>
          <StyledName>{landDetail?.name}</StyledName>
          <StyledContainerNFT>
            {isPacked ? (
              <StyledWrapBox>
                <StyledContentPacked>
                  <img className={'nft-unbox'} src={landDetail?.image} alt="" />
                </StyledContentPacked>
              </StyledWrapBox>
            ) : (
              <div>
                <StyledWrapBox>
                  <StyledContentWrap>
                    <StyledContent>
                      <StyledBgItem
                        src={getImageRareByName(landDetail?.rare)}
                        alt={landDetail?.rare}
                      />
                      <img className={'item'} src={landDetail?.image} alt="" />
                    </StyledContent>
                  </StyledContentWrap>
                </StyledWrapBox>
                <StyledContentWrap>
                  <StyledSeasonContained>
                    {SEASON.map(season => (
                      <Tooltip title={season} key={season + 'season'}>
                        <StyledSeason
                          src={getImageSeasonByName(season)}
                          className={`${season?.toLowerCase()} ${
                            landDetail.season
                              ?.split(',')
                              ?.findIndex(v => v?.toLowerCase() === season) !==
                            -1
                              ? 'active'
                              : ''
                          }`}
                        />
                      </Tooltip>
                    ))}
                  </StyledSeasonContained>
                </StyledContentWrap>
              </div>
            )}
          </StyledContainerNFT>
        </Left>
        <Right>
          <AttributeData
            landDetail={landDetail}
            setPopupTransfer={setPopupTransfer}
            isOwner={isOwner}
          />
          {isPacked && isOwner && (
            <StyledUnpack>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickUnpack}
                size="medium"
                disabled={isLoading}
              >
                UNPACK LAND
              </Button>
            </StyledUnpack>
          )}
        </Right>
      </StyledWrapContent>
      {/*  */}
      <HistoryOffer
        category="wland"
        data={landDetail}
        handleCheckOffer={handleCheckOffer}
        isOffer={isOffer}
      />
      <PopupOffer
        open={openPopupOffer}
        setOpen={setPopupOffer}
        data={dataLand}
        handleCheckOffer={handleCheckOffer}
        type={'wland'}
      />
      <PopupOrder
        open={openPopupOrder}
        setOpen={setPopupOrder}
        data={dataLand}
        handleCheckExistOrder={handleCheckExistOrder}
        type={'wland'}
      />
      <PopupBuy
        open={openPopupBuy}
        setOpen={setPopupBuy}
        data={orderData}
        handleCheckOwner={handleCheckOwner}
        setOrderData={setOrderData}
      />
      <PopupTransfer
        open={openTransfer}
        setOpen={setPopupTransfer}
        data={landDetail}
      />
      <PopupUnboxLand
        open={openPopupUnpack}
        setOpen={setPopupUnpack}
        itemId={landDetail.itemId}
        handleGetDetail={handleGetDetail}
      />
    </Container>
  );
});

export default LandDetail;
