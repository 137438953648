import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Countdown, { CountdownProps, zeroPad } from 'react-countdown';

const StyledCountdown = styled(({ fontSize, fontWeight, ...props }: any) => {
  return <Countdown {...props} />;
})<
  {
    fontSize?: string;
    fontWeight?: number | string;
  } & CountdownProps
>`
  font-size: ${({ fontSize }) => fontSize ?? ''};
  font-weight: ${({ fontWeight }) => fontWeight ?? ''};
`;

const StyledText = styled.span<{
  fontSize?: string;
  fontWeight?: string | number;
}>`
  font-size: ${({ fontSize }) => fontSize ?? ''};
  font-weight: ${({ fontWeight }) => fontWeight ?? ''};
`;

interface CountdownTimerProps {
  endTime: number;
  fontSize?: string;
  fontWeight?: number | string;
  dayString?: boolean;
}

const CountdownTimer = function({
  endTime,
  dayString,
  ...rest
}: CountdownProps & CountdownTimerProps) {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }): React.ReactNode => {
    const { daysInHours } = rest;
    if (daysInHours || completed) {
      return <span />;
    }
    const params = _.pick(rest, ['fontSize', 'fontWeight']);
    if (!days) {
      return (
        <StyledText {...params}>{`${zeroPad(hours)}:${zeroPad(
          minutes
        )}:${zeroPad(seconds)}`}</StyledText>
      );
    }
    return (
      <StyledText {...params}>{`${days} ${days > 1 ? 'days' : 'day'} ${zeroPad(
        hours
      )}:${zeroPad(minutes)}:${zeroPad(seconds)}`}</StyledText>
    );
  };

  const date = Math.floor(endTime * 1000);
  return (
    <StyledCountdown
      renderer={dayString ? renderer : undefined}
      {...rest}
      date={date}
    />
  );
};

export default CountdownTimer;
