import { IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background-color: ${({ theme }) => theme.cardBG};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: calc(100% - 40px);
  `};
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin: 0;
    font-weight: 500;
    margin-top: 0.5rem;
  }
`;

const NotificationModal = observer(({ message, onClose, desc }) => {
  return (
    <StyledModal open={!!message} onClose={onClose}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={() => onClose()}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <h3>{message}</h3>
          {desc ? <p>{desc}</p> : null}
          <div>
            {/* <Button
            style={{ marginTop: '9px', minHeight: '48px' }}
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => onClose()}
          >
            Got it
          </Button> */}
          </div>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
});

export default NotificationModal;
