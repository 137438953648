import {
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect } from 'react';
import Tooltip from '../../components/Tooltip';
import { useStores } from '../../contexts/storesContext';
import {
  a11yProps,
  CommonTab,
  CommonTabs,
  IconType,
  StyleAvatarType,
  StyledFilterTag,
  StyledFilterTags,
  StyledTabTitle,
  TabPanel,
} from './SidebarItem';
import {
  TypeCategory,
  TypeCategoryPet,
  TypeCategoryItem,
} from '../../constants';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 16px;
  }
  .MuiSelect-selectMenu {
    text-transform: capitalize;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    text-transform: capitalize;
  }
`;

const StyledTypeFilter = styled.div`
  border-bottom: 2px dashed #bdbdbd;
  margin-bottom: 16px;
`;
const StyledNoData = styled.div`
  text-align: center;
  color: #bebfc4;
  font-weight: 500;
  margin-bottom: 10px;
`;

const TypeFilter = observer(({ handleChecked }) => {
  const {
    root: { nftItemStore },
  } = useStores();

  const {
    filterByCategoryData,
    filterByCategory,
    checkedTypeIds,
    valueTab,
    categoryFilter,
    setValueTab,
    clearFilter,
    setCategoryFilter,
    setFilterByCategory,
    isOwned,
    yourOffers,
  } = nftItemStore;

  function handleChangeTab(e, value) {
    setValueTab(value);
    clearFilter({ isOwned, yourOffers });
  }

  const handleChangeCategoryFilter = (
    event: ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    setCategoryFilter({
      ...categoryFilter,
      [event.target.name as string]: event.target.value as string,
    });
  };

  useEffect(() => {
    if (categoryFilter.pet === 'all' && categoryFilter.item === 'all') {
      setFilterByCategory(filterByCategoryData);
      return;
    }
    let filterByCategoryClone = JSON.parse(
      JSON.stringify(filterByCategoryData)
    );
    if (categoryFilter.pet !== 'all') {
      filterByCategoryClone[TypeCategory.PET] = filterByCategoryClone[
        TypeCategory.PET
      ].filter(item => item.category === categoryFilter.pet);
    } else if (categoryFilter.item !== 'all') {
      filterByCategoryClone[TypeCategory.ITEM] = filterByCategoryClone[
        TypeCategory.ITEM
      ].filter(item => item.category === categoryFilter.item);
    }
    setFilterByCategory(filterByCategoryClone);
  }, [
    categoryFilter.item,
    categoryFilter.pet,
    filterByCategoryData,
    setFilterByCategory,
  ]);

  return (
    <StyledTypeFilter>
      <CommonTabs
        value={valueTab}
        onChange={handleChangeTab}
        aria-label="tabs"
        classes={{ indicator: 'indicator' }}
        variant="fullWidth"
        textColor="primary"
      >
        {Object.keys(filterByCategoryData).map((nameCategory, index) => (
          <CommonTab
            key={nameCategory + '-tab'}
            label={
              <StyledTabTitle>
                <div>
                  <IconType
                    type={nameCategory}
                    style={{ width: '24px' }}
                    isActive={valueTab === index}
                  />
                </div>
                {nameCategory}
              </StyledTabTitle>
            }
            {...a11yProps(index)}
          />
        ))}
      </CommonTabs>
      {Object.keys(filterByCategoryData).map((nameCategory, index) => (
        <TabPanel value={valueTab} index={index} key={nameCategory}>
          {nameCategory === TypeCategory.PET && (
            <StyledFormControl variant="outlined">
              <InputLabel id="category">Category</InputLabel>
              <Select
                value={categoryFilter.pet}
                onChange={handleChangeCategoryFilter}
                labelId="Category"
                label={'Category'}
                name="pet"
              >
                <StyledMenuItem value={'all'}>All</StyledMenuItem>
                {Object.values(TypeCategoryPet).map(item => (
                  <StyledMenuItem key={item} value={item}>
                    {item?.replace('-', ' ')}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          )}
          {nameCategory === TypeCategory.ITEM && (
            <StyledFormControl variant="outlined">
              <InputLabel id="category-item">Category</InputLabel>
              <Select
                value={categoryFilter.item}
                onChange={handleChangeCategoryFilter}
                labelId="category-item"
                label={'Category'}
                name="item"
              >
                <StyledMenuItem value={'all'}>All</StyledMenuItem>
                {Object.values(TypeCategoryItem).map(item => (
                  <StyledMenuItem key={item} value={item}>
                    {item?.replace('-', ' ')}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          )}
          <StyledFilterTags>
            {filterByCategory[nameCategory] &&
            filterByCategory[nameCategory].length !== 0 ? (
              filterByCategory[nameCategory].map(obj => {
                return (
                  <StyledFilterTag key={obj.typeId + 'type'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedTypeIds.includes(obj.typeId)}
                          onChange={e => {
                            handleChecked(e, obj.typeId);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <Tooltip title={obj.name} placement={'top'}>
                          <StyleAvatarType src={obj.avatar} alt={obj.name} />
                        </Tooltip>
                      }
                    />
                  </StyledFilterTag>
                );
              })
            ) : (
              <StyledNoData>No data to display</StyledNoData>
            )}
          </StyledFilterTags>
        </TabPanel>
      ))}
    </StyledTypeFilter>
  );
});

export default TypeFilter;
